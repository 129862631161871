import {
  FormControl,
  FormErrorMessage,
  FormHelperText
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { languagesToReactSelect } from 'utilities/availableLanguages.utility';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguages } from 'redux/slices/onboarding';
import Select from 'react-select';
import validationSchema from './validationSchema';
import { useTranslation } from 'react-i18next';

function Languages({ nextStep }) {
  const { role } = useSelector((state) => state.user);
  const { t } = useTranslation('global');

  const coachText = t('pages.onboarding.components.languages.coachText');
  const coacheeText = t('pages.onboarding.components.languages.coacheeText');

  const dispatch = useDispatch();
  const initialValues = () => ({
    languages: []
  });

  const customSelectStyles = {
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: '#299eff'
      };
    },
    multiValueLabel: (styles) => {
      return {
        ...styles,
        color: '#ffff',
        fontSize: '1em'
      };
    }
  };

  const { handleSubmit, values, errors, setValues } = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema,
    onSubmit: async (formValues) => {
      dispatch(setLanguages(formValues.languages));
      nextStep();
    }
  });

  const handleSelectChange = (selectValues) => {
    const valuesToDispatch = selectValues.map((languages) => languages.value);
    dispatch(setLanguages(valuesToDispatch));
  };

  const isCoach = role === 'coach';

  return (
    <div className="Languages ">
      <h3 className="Languages__title">
        {t('pages.onboarding.components.languages.title')}
      </h3>
      <p className="Languages__subtitle">{isCoach ? coachText : coacheeText}</p>
      <div className="Languages__picker card">
        <form
          className="Languages__form"
          onSubmit={handleSubmit}
          id="languages"
        >
          <FormControl isInvalid={errors.languages}>
            <Select
              isMulti
              styles={customSelectStyles}
              options={languagesToReactSelect}
              onChange={handleSelectChange}
              className="Languages__select"
              placeholder={t(
                'pages.onboarding.components.languages.placeholder'
              )}
            />

            <FormHelperText textAlign="left" fontSize="xl" mb="10">
              {t('pages.onboarding.components.languages.textHelper')}
            </FormHelperText>

            {errors?.languages ? (
              <FormErrorMessage fontSize="lg">
                {errors.languages}
              </FormErrorMessage>
            ) : null}
          </FormControl>
        </form>
      </div>
    </div>
  );
}

export default Languages;
