import { Textarea } from '@chakra-ui/react';
import { useState } from 'react';

const OpenQuestion = ({ question, value, setValue }) => {
  return (
    <div className="SessionEvaluation__cardtextarea">
      <Textarea
        name="suggest"
        placeholder={question}
        className="SessionEvaluation__textarea"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

export default OpenQuestion;
