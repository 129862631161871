import axios from 'axios';
import { loadAbort } from 'utilities';
import QueryString from 'qs';

const coachUrl = `${process.env.REACT_APP_USERS_URL}/api/coach`;

export const updateCoachOnboarding = (onboarding, user) => {
  const controller = loadAbort();
  const { name, lastname } = onboarding.createYourAccount;
  const { work, resume } = onboarding.aboutYouCoach;

  const focusAreasIds = onboarding.focusAreas.map((focusArea) => ({
    _id: focusArea.id
  }));

  const data = {
    urlImgCoach: onboarding.photo,
    name,
    lastname,
    focusAreas: focusAreasIds,
    howWork: work,
    resume,
    urlVideoCoach: onboarding.video,
    timezone: onboarding.timezone,
    languages: onboarding.languages
  };

  return {
    call: () =>
      axios.put(`${coachUrl}/onboarding/${user.uid}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const getCoachesByFocusAreas = (focusAreas, languages, coacheeId) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${coachUrl}/focusAreas`, {
        params: {
          focusAreas: focusAreas.map((focusArea) => focusArea.id),
          languages,
          coacheeId
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, { arrayFormat: 'brackets' });
        },
        signal: controller.signal
      }),
    controller
  };
};

export const getCoachBySearch = (search) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(coachUrl, {
        params: {
          searchText: search
        },
        signal: controller.signal
      }),
    controller
  };
};

export const updateCoach = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${coachUrl}/${data.id}`, data, { signal: controller.signal }),
    controller
  };
};

export const updateImageCoach = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${coachUrl}/UpdateImage/${data.id}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateVideoCoach = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${coachUrl}/UpdateVideo2/${data.id}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateVideoCoachYoutube = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(`${coachUrl}/UpdateVideo/${data.id}`, data.formData, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateCoachEventsCalendar = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${coachUrl}/updateEventsCalendar/${data.coach}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateEvaluationCoach = (user, data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${coachUrl}/updateRatingsCoach/${user}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const translateText = (data) => {
  const controller = loadAbort();

  const { targetLanguage, text } = data;

  return {
    call: () =>
      axios.put(
        `${coachUrl}/languages/translate`,
        {
          targetLanguage: targetLanguage,
          text: text
        },
        {
          signal: controller.signal
        }
      ),
    controller
  };
};
