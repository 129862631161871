import React from 'react';
import {
  FormLabel,
  Input,
  FormControl,
  Divider,
  FormErrorMessage
} from '@chakra-ui/react';
import { FieldArray, getIn } from 'formik';
import { size } from 'lodash';
import NoData from 'components/NoData/NoData';
import { useTranslation } from 'react-i18next';

function EvaluationFieldArray({
  errors,
  touched,
  values,
  name,
  formik,
  label
}) {
  const { t } = useTranslation('global');
  const noData = t('pages.onboarding.components.evaluationFieldArray.noData');

  return (
    <>
      <FormLabel className="Evaluation360__label">{label}</FormLabel>
      <FieldArray name={name} validateOnChange>
        <div>
          {size(values) > 0 ? (
            values.map((object, index) => {
              const arrayName = `${name}[${index}].name`;
              const touchedArrayName = getIn(touched, arrayName);
              const errorArrayName = getIn(errors, arrayName);

              const arrayLastname = `${name}[${index}].lastname`;
              const touchedArrayLastname = getIn(touched, arrayLastname);
              const errorArrayLastname = getIn(errors, arrayLastname);

              const arrayEmail = `${name}[${index}].email`;
              const touchedArrayEmail = getIn(touched, arrayEmail);
              const errorArrayEmail = getIn(errors, arrayEmail);

              return (
                <div key={object.id || object._id}>
                  <FormControl
                    className="Evaluation360__formcontrol"
                    isInvalid={
                      (touchedArrayName && errorArrayName) ||
                      (touchedArrayLastname && errorArrayLastname) ||
                      (touchedArrayEmail && errorArrayEmail)
                    }
                    isDisabled={object._id}
                  >
                    <div className="Evaluation360__formcontrol_name">
                      <Input
                        name={arrayName}
                        placeholder="Nombre"
                        className="input Evaluation360__input"
                        value={object.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      <Input
                        name={arrayLastname}
                        placeholder="Apellido"
                        className="input Evaluation360__input"
                        value={object.lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>

                    {(errorArrayName && touchedArrayName) ||
                    (errorArrayLastname && touchedArrayLastname) ? (
                      <FormErrorMessage fontSize="lg" mb="2">
                        {errorArrayName}
                        <br />
                        {errorArrayLastname}
                      </FormErrorMessage>
                    ) : null}

                    <Input
                      name={arrayEmail}
                      placeholder="Email"
                      type="email"
                      className="input Evaluation360__input"
                      value={object.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {errorArrayEmail && touchedArrayEmail ? (
                      <FormErrorMessage fontSize="lg" mb="2">
                        {errorArrayEmail}
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>
                  <Divider
                    orientation="horizontal"
                    h={1}
                    mt=".4em"
                    backgroundColor="#fafafae8"
                  />
                </div>
              );
            })
          ) : (
            <NoData title={`${noData} ${label}`} />
          )}
        </div>
      </FieldArray>
    </>
  );
}

export default EvaluationFieldArray;
