import React, { useContext, useEffect } from 'react';
import BonumCalendar from 'components/Calendar';
import CoachCalendarContext from 'pages/CoachCalendar/context/CoachCalendarContext';
import { find, forEach } from 'lodash';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { useUserUtilities } from 'hooks';
import Loading from 'components/Loading';

function Calendar() {
  const { schedules, selectedDate, setSelectedDate, blockedSchedules } =
    useContext(CoachCalendarContext);

  const { sessions } = useSelector((state) => state.user);
  const { refreshSessions, userUtilitiesLoading } = useUserUtilities();

  const getSessions = async () => {
    try {
      await refreshSessions();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!sessions) getSessions();
  }, [sessions]);

  const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  const isWorkingDay = (date) => {
    const workingDay = find(schedules, { Day: weekDays[date.getDay()] });
    return !workingDay?.Work;
  };

  const reflectSessions = (date) => {
    const calendarDate = DateTime.fromJSDate(date).toISODate();
    if (isWorkingDay(date)) return 'session session--notworking';

    const hasSession = sessions?.find((session) => {
      const sessionDate = DateTime.fromISO(session?.date).toISODate();
      if (sessionDate === calendarDate) return true;
      return false;
    });

    if (hasSession) {
      if (hasSession?.canceled) return 'session session--canceled';

      return hasSession?.status
        ? 'session session--executed'
        : 'session session--future';
    }

    if (blockedSchedules) {
      let hasBlockedSchedule = false;
      forEach(blockedSchedules, (blockedSchedule) => {
        const initialBlockedSchedule = DateTime.fromISO(
          blockedSchedule.InitialDate
        ).toISODate();
        const endBlockedSchedule = DateTime.fromISO(
          blockedSchedule.EndDate
        ).toISODate();
        if (
          calendarDate >= initialBlockedSchedule &&
          calendarDate <= endBlockedSchedule
        )
          hasBlockedSchedule = true;
      });

      if (hasBlockedSchedule) return 'session session--blockedSchedule';
      // const initialBlockedSchedule = DateTime.fromISO(
      //   blockedSchedules.InitialDate
      // ).toISODate();
      // const endBlockedSchedule = DateTime.fromISO(
      //   blockedSchedules.EndDate
      // ).toISODate();
      // if (
      //   calendarDate >= initialBlockedSchedule &&
      //   calendarDate <= endBlockedSchedule
      // )
      //   return 'session session--blockedSchedule';
    }

    return false;
  };

  if (userUtilitiesLoading) return <Loading title={'Cargando calendario'} />;

  return (
    <BonumCalendar
      value={selectedDate}
      setDate={setSelectedDate}
      tileDisabled={({ date }) => isWorkingDay(date)}
      tileClassName={({ date, view }) => reflectSessions(date, view)}
      className="BonumCalendar"
    />
  );
}

export default Calendar;
