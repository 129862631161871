import React, { useMemo, useState } from 'react';
import SuccessCalendarContext from './SuccessCalendarContext';

function SuccessCalendarContextWrapper({ children }) {
  const [calendars, setCalendars] = useState([]);

  const SuccessCalendarValue = useMemo(
    () => ({
      calendars,
      setCalendars
    }),
    [calendars]
  );

  return (
    <SuccessCalendarContext.Provider value={SuccessCalendarValue}>
      {children}
    </SuccessCalendarContext.Provider>
  );
}

export default SuccessCalendarContextWrapper;
