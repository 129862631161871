import React, { useState } from 'react';
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import {
  FormControl,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Button,
  FormLabel
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { auth } from 'utilities/firebase.utility';
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider
} from 'firebase/auth';
import validationSchema from './validationSchema';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';

function ChangePassword() {
  const [viewPassword, setViewPassword] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation('global');

  const initialValues = () => ({
    current_password: '',
    new_password: '',
    new_password_confirm: ''
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    setValues,
    setTouched
  } = useFormik({
    initialValues: initialValues(),
    validationSchema,
    onSubmit: async (formValues) => {
      setError(false);
      setLoading(true);
      try {
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          formValues.current_password
        );

        await reauthenticateWithCredential(auth.currentUser, credential);

        await updatePassword(auth.currentUser, formValues.new_password);
        displayToast('Contraseña cambiada con éxito', 'success');
        setValues(initialValues());
        setTouched(initialValues());
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err.code === 'auth/wrong-password')
          setError('La contraseña actual es incorrecta');
      }
    }
  });

  return (
    <form className="ChangePassword" onSubmit={handleSubmit}>
      <legend>{t('pages.preferences.profile.changePassword')}</legend>
      <FormControl
        isInvalid={
          (errors?.current_password && touched.current_password) ||
          errors.confirm ||
          error
        }
      >
        <FormLabel>{t('pages.preferences.profile.currentPassword')}</FormLabel>
        <InputGroup>
          <Input
            name="current_password"
            className="ChangePassword__input"
            placeholder={`${t('pages.preferences.profile.currentPassword')}`}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.current_password}
            type={viewPassword ? 'text' : 'password'}
          />
          <InputRightElement
            h="100%"
            mr={3}
            onClick={() => setViewPassword(!viewPassword)}
          >
            {viewPassword ? (
              <ViewOffIcon w={8} h={8} />
            ) : (
              <ViewIcon w={8} h={8} />
            )}
          </InputRightElement>
        </InputGroup>
        {errors?.current_password && touched.current_password ? (
          <FormErrorMessage fontSize="lg">
            {errors.current_password}
          </FormErrorMessage>
        ) : null}

        {error && <FormErrorMessage fontSize="lg">{error}</FormErrorMessage>}
      </FormControl>

      <FormControl isInvalid={errors?.new_password && touched.new_password}>
        <FormLabel>{t('pages.preferences.profile.newPassword')}</FormLabel>
        <InputGroup>
          <Input
            name="new_password"
            className="ChangePassword__input"
            placeholder={`${t('pages.preferences.profile.newPassword')}`}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.new_password}
            type={viewPassword ? 'text' : 'password'}
          />
        </InputGroup>

        {errors?.new_password && touched.new_password ? (
          <FormErrorMessage fontSize="lg">
            {errors.new_password}
          </FormErrorMessage>
        ) : null}
      </FormControl>

      <FormControl
        isInvalid={errors?.new_password_confirm && touched.new_password_confirm}
      >
        <FormLabel>{t('pages.preferences.profile.confirmPassword')}</FormLabel>
        <InputGroup>
          <Input
            name="new_password_confirm"
            className="ChangePassword__input"
            placeholder={`${t('pages.preferences.profile.confirmNewPassword')}`}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.new_password_confirm}
            type={viewPassword ? 'text' : 'password'}
          />
        </InputGroup>

        {errors?.new_password_confirm && touched.new_password_confirm ? (
          <FormErrorMessage fontSize="lg">
            {errors.new_password_confirm}
          </FormErrorMessage>
        ) : null}
      </FormControl>

      <Button
        className="Button Button--primary"
        type="submit"
        isDisabled={loading}
        isLoading={loading}
      >
        {t('pages.preferences.profile.buttonChangePassword')}
      </Button>
    </form>
  );
}

export default ChangePassword;
