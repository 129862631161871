import React, { useContext, useState, useEffect } from 'react';
import { Button, Textarea } from '@chakra-ui/react';
import MyCoacheesContext from 'pages/MyCoachees/context/MyCoacheesContext';
import { useFetchAndLoad } from 'hooks';
import displayToast from 'utilities/toast.utility';
import { updateSession } from 'services/sessions.service';
import { EditorState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import { useTranslation } from 'react-i18next';

function PrivateComments() {
  const { selectedSession: session } = useContext(MyCoacheesContext);
  const { coachPrivateComments } = session;
  const [comments, setComments] = useState(coachPrivateComments);
  const [annotations, setAnnotations] = useState(
    EditorState.createWithContent(stateFromHTML(comments))
  );
  const { loading, callEndpoint } = useFetchAndLoad();

  const { t } = useTranslation('global');
  const successSave = t('pages.myCoachees.components.saveComments');
  const errorComments = t('pages.myCoachees.components.errorComments');

  const saveComments = async () => {
    try {
      await callEndpoint(
        updateSession({ id: session.id, coachPrivateComments: comments })
      );
      displayToast(successSave, 'success');
    } catch (error) {
      displayToast(errorComments);
    }
  };

  return (
    <div className="PrivateComments__container background">
      <div className="PrivateComments">
        <p className="PrivateComments__advice">
          Aqui puedes ingresar notas sobre ésta sesión que solo tú podrás ver
        </p>
        <div className="container flex justify-center column">
          <Editor
            editorState={annotations}
            editorStyle={{ backgroundColor: 'white' }}
            className="PrivateComments__textarea"
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(e) => {
              setAnnotations(e);
              setComments(draftToHtml(convertToRaw(e.getCurrentContent())));
            }}
          />
        </div>
        <Button
          isLoading={loading}
          isDisabled={loading}
          className="Button Button--secondary"
          onClick={saveComments}
        >
          Guardar
        </Button>
      </div>
    </div>
  );
}

export default PrivateComments;
