import React from 'react';
import MyCoacheesContextWrapper from './context/MyCoacheesContextWrapper';
import MyCoachees from './MyCoachees';

export default function () {
  return (
    <MyCoacheesContextWrapper>
      <MyCoachees />
    </MyCoacheesContextWrapper>
  );
}
