import axios from 'axios';
import { loadAbort } from 'utilities';

const usersURL = `${process.env.REACT_APP_USERS_URL}/api`;

export const getUser = (id) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${usersURL}/userRol/${id}`, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateUserCalendar = (user, data) => {
  const controller = loadAbort();
  const calendar = data.data;

  let url = `${usersURL}/coachee`;
  if (user.role === 'coach') url = `${usersURL}/coach`;

  return {
    call: () =>
      axios.put(
        `${url}/${user.mongoID}`,
        { calendar },
        {
          signal: controller.signal
        }
      )
  };
};

export const updateUserLanguages = (user, role, languages) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(
        `${usersURL}/${role}/languages/${user}`,
        { languages },
        {
          signal: controller.signal
        }
      )
  };
};

export const updateUserLanguagesAndTimezone = (user, role, data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${usersURL}/${role}/languages/${user}`, data, {
        signal: controller.signal
      })
  };
};

export const updateCoachee = (id, data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${usersURL}/coachee/noShow/${id}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateNoShowAcc = (id, data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${usersURL}/coachee/noShowAcc/${id}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateStatusUser = (id) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(
        `${usersURL}/coachee/statusUser/${id}`,
        {},
        {
          signal: controller.signal
        }
      )
  };
};

export const sendChatMessage = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(`${usersURL}/userRol/sendChatNotification`, data, {
        signal: controller.signal
      }),
    controller
  };
};
