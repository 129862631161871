import Loading from 'components/Loading';
import { useFetchAndLoad } from 'hooks';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { getValidEventsCalendars } from 'services/calendar.service';
import displayToast from 'utilities/toast.utility';
import { find } from 'lodash';
import { Button } from '@chakra-ui/react';
import { updateCoachEventsCalendar } from 'services/coach.service';
import { modifyUserEventCalendar } from 'redux/slices/user';
import { FcCalendar } from 'react-icons/fc';

const EventsCalendar = () => {
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [actualCalendar, setActualCalendar] = useState(null);
  const { mongoID, calendar } = useSelector((state) => state.user);
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();

  const convertCalendarsToReactSelect = (calendars) => {
    return calendars.map((calendar) => ({
      value: calendar._id,
      label: calendar.name,
      icon: FcCalendar
    }));
  };

  const searchCalendar = (calendars, calendarID) =>
    find(calendars, { value: calendarID });

  const fetchCalendars = async () => {
    try {
      const response = await callEndpoint(getValidEventsCalendars(mongoID));
      setCalendars(convertCalendarsToReactSelect(response.data.data));
    } catch (error) {
      displayToast('Error fetching calendars', 'error');
      console.error('Error fetching calendars:', error);
    }
  };

  // Custom Option component
  const CustomOption = ({ innerProps, data }) => (
    <div {...innerProps} className="EventsCalendar__options">
      {data.icon && <data.icon style={{ marginRight: '5px' }} />}{' '}
      {/* Render the icon if available */}
      <span>{data.label}</span>
    </div>
  );

  useEffect(() => {
    fetchCalendars();
  }, []);

  useEffect(() => {
    setActualCalendar(searchCalendar(calendars, calendar));
  }, [calendars, calendar]);

  const handleCalendarChange = (selectedOption) => {
    setSelectedCalendar(selectedOption);
  };

  const handleSubmit = async () => {
    try {
      await callEndpoint(
        updateCoachEventsCalendar({
          coach: mongoID,
          calendarID: selectedCalendar.value
        })
      );
      displayToast('Calendario de eventos actualizado', 'success');
      dispatch(modifyUserEventCalendar(selectedCalendar.value));
    } catch (error) {
      displayToast('Error al actualizar el calendario', 'error');
      console.error('Error updating calendar:', error);
    }
  };

  if (loading) return <Loading title="" />;

  return (
    <div className="EventsCalendar">
      <h2>Calendario de Sesiones</h2>
      <p className="EventsCalendar__subtitle">
        Selecciona el calendario donde deseas que se agreguen los eventos de tus
        sesiones, Este cambio solo afectará a futuros eventos
      </p>

      <p className="EventsCalendar__subtitle">
        Calendario actual: <b>{actualCalendar?.label}</b>
      </p>

      <div className="EventsCalendar__select card">
        <Select
          options={calendars}
          value={selectedCalendar}
          onChange={handleCalendarChange}
          defaultValue={calendars[0]}
          placeholder="Selecciona un calendario de eventos"
          components={{
            Option: CustomOption
          }}
        />
      </div>

      <Button
        type="submit"
        className="Button Button--primary"
        mt={'1.5em'}
        onClick={handleSubmit}
      >
        Guardar
      </Button>
    </div>
  );
};

export default EventsCalendar;
