import NoData from 'components/NoData/NoData';
import React, { useEffect, useState } from 'react';
import SessionItem from '../Session_item';

export default function SessionContainer({
  isCompleted,
  isCanceled,
  sessions,
  alignment
}) {
  const [orderedSessions, setOrderedSessions] = useState(sessions);

  useEffect(() => {
    if (isCompleted) {
      const newSessions = sessions.filter((session) => session.status === true);
      setOrderedSessions(newSessions);
    } else if (isCanceled) {
      const newSessions = sessions.filter(
        (session) => session.canceled === true && session.status !== true
      );
      setOrderedSessions(newSessions);
    } else if (alignment) {
      const newSessions = sessions.filter(
        (session) => session.type === 'initial' || session.type === 'final'
      );
      setOrderedSessions(newSessions);
    } else {
      const newSessions = sessions.filter(
        (session) => session.canceled !== true && session.status !== true
      );
      setOrderedSessions(newSessions);
    }
  }, [sessions, isCompleted, isCanceled]);

  return (
    <>
      {orderedSessions.length !== 0 ? (
        orderedSessions.map((session, index) => (
          <SessionItem session={session} key={session.id} index={index + 1} />
        ))
      ) : (
        <>
          <NoData title="No tienes sesiones" />
        </>
      )}
    </>
  );
}
