import React from 'react';
import ChatContextWrapper from './context/ChatContextWrapper';
import ChatComponent from './Chat';

function Chat() {
  return (
    <ChatContextWrapper>
      <ChatComponent />
    </ChatContextWrapper>
  );
}

export default Chat;
