import React from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CoachButtons() {
  const navigate = useNavigate('/');
  const { t } = useTranslation('global');

  return (
    <>
      <Button onClick={() => navigate('/mycoachees')}>
        {t('pages.home.components.buttons.myCoachees')}
      </Button>
      <Button onClick={() => navigate('/mycalendar')}>
        {t('pages.home.components.buttons.myCalendar')}
      </Button>
      <Button onClick={() => navigate('/myevaluations')}>
        {t('pages.home.components.buttons.myEvaluations')}
      </Button>
      <Button onClick={() => navigate('/myresources')}>
        {t('components.menu.resources')}
      </Button>
    </>
  );
}

export default CoachButtons;
