import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
  getUserBlockSchedule,
  getUserWorkingHours
} from 'services/calendar.service';
import displayToast from 'utilities/toast.utility';
import { useFetchAndLoad } from 'hooks';
import Calendar from './Components/Calendar';
import Advice from './Components/Advice';
import NextSession from 'pages/Home/Components/NextSession';
import WorkingHours from './Components/WorkingHours';
import BlockSchedule from './Components/BlockSchedule';
import CoachCalendarContext from './context/CoachCalendarContext';
import DayCalendar from 'components/DayCalendar';
import { useTranslation } from 'react-i18next';

function CoachCalendar() {
  const user = useSelector((state) => state.user);
  const { updateWorkingUI, selectedDate, setBlockedSchedules } =
    useContext(CoachCalendarContext);
  const { callEndpoint } = useFetchAndLoad();
  const [workingHours, setWorkingHours] = useState(false);
  const [displayBlockSchedule, setDisplayBlockSchedule] = useState(false);
  const { t } = useTranslation('global');

  const errorWorking = t('pages.coachCalendar.errorGet');

  const getWorkingHours = async (id) => {
    try {
      const { data } = await callEndpoint(getUserWorkingHours(id));
      updateWorkingUI(data.data);
    } catch (error) {
      displayToast(errorWorking, 'error');
    }
  };

  const getBlockedSchedule = async () => {
    try {
      const { data } = await callEndpoint(getUserBlockSchedule(user.mongoID));
      setBlockedSchedules(data.data);
    } catch (error) {
      displayToast('Error obteniendo los horarios bloqueados', 'error');
    }
  };

  useEffect(() => {
    getWorkingHours(user.mongoID);
  }, []);

  useEffect(() => {
    getBlockedSchedule();
  }, [displayBlockSchedule]);

  if (workingHours) return <WorkingHours setWorkingHours={setWorkingHours} />;
  if (displayBlockSchedule)
    return <BlockSchedule setDisplayBlockSchedule={setDisplayBlockSchedule} />;

  return (
    <div className="CoachCalendar background">
      <div className="CoachCalendar__calendar-container">
        <div className="CoachCalendar__calendar-container_column">
          <Advice />
          <div className="CoachCalendar__calendar-container_calendar_buttons">
            <Button
              className="Button Button--primary"
              onClick={() => setWorkingHours(true)}
            >
              {t('pages.coachCalendar.workingHours')}
            </Button>
            <Button
              className="Button Button--secondary"
              onClick={() => setDisplayBlockSchedule(true)}
            >
              {t('pages.coachCalendar.blockSchedule')}
            </Button>
          </div>
        </div>

        <div className="CoachCalendar__calendar-container_calendar">
          <h3>{t('pages.coachCalendar.title')}</h3>
          <Calendar />
        </div>
      </div>

      <DayCalendar date={selectedDate} sessions={user.sessions} isCoach />
    </div>
  );
}

export default CoachCalendar;
