import { Button } from '@chakra-ui/react';
import { useFetchAndLoad, useCoachCalendar } from 'hooks';
import { filter, find, last } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createEventOnCoachCalendar } from 'services/calendar.service';
import { createSession } from 'services/sessions.service';
import displayToast from 'utilities/toast.utility';
import Calendar from '../../components/Calendar';
import Advice from './components/Advice';
import AvailableSchedule from './components/AvailableSchedule';
import NoMoreSessions from './components/NoMoreSessions';
import Scheduled from './components/Scheduled';
import useScheduleContext from './hooks/useScheduleContext';
import { useTranslation } from 'react-i18next';

function ScheduleAppointment() {
  const user = useSelector((state) => state.user);
  const { coach, sessions, cohort, timezone, additionalSessions } = user;
  const { date, hour, setDate } = useScheduleContext();
  const [scheduled, setScheduled] = useState(false);
  const navigate = useNavigate();
  const { getCoachCalendar, isNotWorkingDay } = useCoachCalendar(coach?._id);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [minDate, setMinDate] = useState(new Date());
  const lastSession = last(sessions);
  const { t } = useTranslation('global');
  const normalSessions = filter(sessions, (s) => !s?.type);

  const getMyCoachCalendar = async () => {
    try {
      await getCoachCalendar();
    } catch (error) {
      console.log(error);
    }
  };

  const getMinDate = () => {
    try {
      if (!lastSession) {
        setMinDate(DateTime.now().toJSDate());
        return;
      }

      const newMinDate = DateTime.fromISO(lastSession.date)
        .plus({ days: 10 })
        .toJSDate();
      setMinDate(newMinDate);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!coach) {
      navigate('/');
    }
    getMyCoachCalendar();
  }, [coach]);

  useEffect(() => {}, [hour]);

  useEffect(() => {
    getMinDate();
  }, []);

  if (!coach) return true;

  const getHoursDifference = (dateWithTimezone) => {
    const currentDate = new Date();
    const sessionDateISO = new Date(dateWithTimezone).toISOString();
    const sessionDate = new Date(sessionDateISO);
    const hoursDifference =
      (sessionDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60);

    let currentHours = Math.round(hoursDifference);
    return currentHours;
  };

  const createCoachingSession = async () => {
    try {
      const dateWithTimezone = DateTime.fromMillis(hour.startHour.ts, {
        zone: timezone
      }).toISO();

      let hours = getHoursDifference(dateWithTimezone);

      if (hours <= 24) {
        displayToast(`${t('pages.reschedule.timeLimit')}`, 'error');
        return;
      }

      const event = {
        title: t('pages.reschedule.eventTitle', {
          name: user.name,
          lastname: user.lastname
        }),
        calendarId: user.coach.calendar.id,
        status: 'confirmed',
        busy: true,
        read_only: true,
        description: t('pages.reschedule.description'),
        when: {
          object: 'timespan',
          start_time: hour.startHour.ts / 1000,
          end_time: hour.startHour.ts / 1000 + 3600,
          start_timezone: user.coach.timezone,
          end_timezone: user.coach.timezone
        }
      };

      await callEndpoint(
        createSession({
          coach: coach._id,
          coachee: user.mongoID,
          date: dateWithTimezone,
          event
        })
      );
    } catch (error) {
      displayToast('Error', error.message);
      return;
    }

    setScheduled(true);
  };

  const errorDate = t('pages.reschedule.errorDate');
  const errorHour = t('pages.reschedule.errorHour');

  const handleSchedule = () => {
    if (!date) {
      displayToast(errorDate, 'error');
      return; // Mostrar un error
    }
    if (!hour) {
      displayToast(errorHour, 'error');
      return; // Mostrar error
    }
    // Registrar en backend
    createCoachingSession();
  };

  const tileDisabled = (calendarDate) => {
    if (isNotWorkingDay(calendarDate)) return true;
  };

  const hasSession = (date) =>
    sessions?.find((session) => {
      const sessionDate = DateTime.fromISO(session.date).toISODate();
      const calendarDate = DateTime.fromJSDate(date).toISODate();
      if (sessionDate === calendarDate) return session;
      return false;
    });

  const tileClassName = (calendarDate) => {
    if (isNotWorkingDay(calendarDate)) return 'session session--notworking';
    // if (lastSession) {
    //   const lastSessionDate = DateTime.fromISO(lastSession.date).toISODate();
    //   const calendarDateIso = DateTime.fromJSDate(calendarDate).toISODate();
    //   if (lastSessionDate === calendarDateIso)
    //     return 'session session--executed';
    // }

    const session = hasSession(calendarDate);
    if (session) {
      if (session?.status) return 'session session--executed';
      else return 'session session--future';
    }
  };

  if (scheduled)
    return (
      <div className="ScheduleAppointment">
        <Scheduled />
        <Button
          mt={10}
          className="Button Button--primary"
          onClick={() => navigate('/')}
        >
          {t('pages.reschedule.buttonOk')}
        </Button>
      </div>
    );

  if (normalSessions?.length >= cohort?.program + additionalSessions)
    return <NoMoreSessions />;

  return (
    <div className="ScheduleAppointment">
      <h3 className="ScheduleAppointment__title">
        {t('pages.reschedule.title')}{' '}
        <span>
          {coach?.name} {coach?.lastname}
        </span>
      </h3>
      <div className="ScheduleAppointment__calendar">
        <Advice className="ScheduleAppointment__calendar_advice" />
        <div className="ScheduleAppointment__calendar_container">
          <Calendar
            minDate={minDate}
            value={date}
            setDate={setDate}
            tileDisabled={({ date: calendarDate }) =>
              tileDisabled(calendarDate)
            }
            tileClassName={({ date }) => tileClassName(date)}
          />
        </div>
      </div>

      {date && (
        <div className="ScheduleAppointment__available">
          <h4>{t('pages.reschedule.available')}</h4>
          <AvailableSchedule />
        </div>
      )}
      <Button
        onClick={handleSchedule}
        className="Button Button--primary ScheduleAppointment__button"
        isLoading={loading}
        isDisabled={loading}
      >
        {t('pages.reschedule.handleSchedule')}
      </Button>
    </div>
  );
}

export default ScheduleAppointment;
