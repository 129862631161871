import NoData from 'components/NoData/NoData';
import { useFetchAndLoad } from 'hooks';
import { editAssignation } from 'services/assignations.service';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';
import AssignationsItem from '../AssignationsItems/AssignationsItem';

function Assignations({ completed = false, assignations, setAssignations }) {
  const { loading, callEndpoint } = useFetchAndLoad();
  const { t } = useTranslation('global');

  const errorChange = t('pages.myAssignations.errorChange');
  const handleChange = async (changedAssignation) => {
    try {
      await callEndpoint(
        editAssignation({
          ...changedAssignation,
          status: !changedAssignation.status
        })
      );

      setAssignations(
        assignations.map((assignation) => {
          if (assignation.id !== changedAssignation.id) return assignation;
          return { ...changedAssignation, status: !changedAssignation.status };
        })
      );
    } catch (error) {
      displayToast(errorChange, 'error');
    }
  };

  const hasAssignations = assignations.some(
    (assignation) => completed === assignation.status
  );

  return (
    <div>
      <div className="flex justify-center items-center">
        {hasAssignations ? (
          <div className="flex justify-center items-center">
            <h4>{t('pages.myAssignations.title')}</h4>
          </div>
        ) : (
          <NoData
            title={`${t('pages.mySessions.components.assignations.noData')}`}
          />
        )}
      </div>
      {assignations.map((assignation) => {
        if (completed && assignation.status) {
          return (
            <AssignationsItem
              key={`session${assignation?.session}${assignation?.id}`}
              value={assignation}
              handleChange={handleChange}
            />
          );
        }

        if (!completed && !assignation.status) {
          return (
            <AssignationsItem
              key={`session${assignation?.session}${assignation?.id}`}
              value={assignation}
              handleChange={handleChange}
            />
          );
        }
      })}
    </div>
  );
}

export default Assignations;
