import Select from 'react-select';
import { languagesToReactSelect } from 'utilities/availableLanguages.utility';
import es from 'assets/images/icons/es.svg';
import en from 'assets/images/icons/us.svg';
import pt from 'assets/images/icons/br.svg';
import IconOption from './IconOption';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('es');
  const options = languagesToReactSelect.map((language) => {
    switch (language.value) {
      case 'es':
        return { ...language, icon: es };

      case 'en':
        return { ...language, icon: en };

      case 'pt':
        return { ...language, icon: pt };
    }

    return language;
  });

  const handleLanguageChange = async (value) => {
    localStorage.setItem('lang', value.value);
    setSelectedLanguage(value.value);
    await i18n.changeLanguage(value.value);
  };

  const getDefaultValue = () => {
    const option = find(options, { value: i18n.language });
    return option;
  };

  return (
    <Select
      defaultValue={() => getDefaultValue()}
      options={options}
      components={{ Option: IconOption }}
      onChange={handleLanguageChange}
      isSearchable={false}
    />
  );
}

export default LanguageSelector;
