import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Image
} from '@chakra-ui/react';
import NoData from 'components/NoData/NoData';
import { size } from 'lodash';
import { useTranslation } from 'react-i18next';
import translateFocusArea from 'utilities/translateFocusArea.utility';

function EvaluationArea({ focusArea, className }) {
  const finalTotalScore = (
    focusArea.totalFinalEvaluation / focusArea.totalFinalAnswers
  ).toFixed(1);
  const isTotalNaN = isNaN(finalTotalScore);

  const { t, i18n } = useTranslation('global');

  const [translatedTitle, setTranslatedTitle] = useState(focusArea.title);

  const translateTopicTitles = async () => {
    const title = await translateFocusArea(focusArea);
    setTranslatedTitle(title);
  };

  useEffect(() => {
    // Traduce los títulos de los temas (topic.title)
    translateTopicTitles();
  }, [i18n.language]);

  // if (loading) return <Loading title="Loading.." />;

  return (
    <Accordion allowToggle className={`EvaluationArea ${className}`}>
      <AccordionItem className="EvaluationArea__area">
        <AccordionButton className="EvaluationArea__area__header">
          <div className="EvaluationArea__area__header__title">
            <AccordionIcon className="EvaluationArea__area__header__title_icon" />
            <Image
              src={focusArea.image}
              className="EvaluationArea__area__header__title_image"
            />
            <h3 className="EvaluationArea__area__header__title_name">
              {translatedTitle}
            </h3>
          </div>
        </AccordionButton>

        <AccordionPanel pb={4} className="EvaluationArea__area__content">
          {size(focusArea.questions) > 0 ? (
            focusArea.questions.map((topic, index) => {
              const finalScore = (
                topic.finalEvaluation / topic.finalAnswers
              ).toFixed(1);
              const isFinalNaN = isNaN(finalScore);

              return (
                <div
                  className="EvaluationArea__area__content__topic"
                  key={index}
                >
                  <p className="EvaluationArea__area__content__topic_title">
                    {topic[i18n.language]}
                  </p>
                  <div className="EvaluationArea__area__content__topic_scores">
                    <div className="EvaluationArea__area__content__topic_scores_score">
                      <p>{t('components.evaluation.start')}</p>
                      <p>
                        {(
                          topic.initialEvaluation / topic.initialAnswers
                        ).toFixed(1)}
                      </p>
                    </div>
                    <div className="EvaluationArea__area__content__topic_scores_score">
                      <p>{t('components.evaluation.end')}</p>
                      <p>{isFinalNaN ? '-' : finalScore}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <NoData title={t('components.evaluation.noSubject')} />
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default EvaluationArea;
