import React, { useEffect } from 'react';
import {
  FormControl,
  Textarea,
  FormErrorMessage,
  Button,
  FormLabel
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import validationSchema from './validationSchema';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import displayToast from 'utilities/toast.utility';
import { useFetchAndLoad } from 'hooks';
import { modifyUser } from 'redux/slices/user';
import { updateCoach } from 'services/coach.service';

function AboutYouCoach() {
  const { howWork, resume, mongoID } = useSelector((state) => state.user);
  const aboutYou = { howWork, resume };
  const { loading, callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  const updateHowWork = async (data) => {
    try {
      await callEndpoint(updateCoach({ ...data, id: mongoID }));
      dispatch(modifyUser(data));
      displayToast('Cambios guardados con éxito', 'success');
    } catch (error) {
      displayToast('Error al actualizar coach', 'error');
      console.log('error');
    }
  };

  useEffect(() => {}, [howWork, resume]);

  const { handleSubmit, values, errors, handleBlur, touched, handleChange } =
    useFormik({
      initialValues: aboutYou,
      validationSchema,
      onSubmit: (formValues) => updateHowWork(formValues)
    });

  return (
    <form
      className="AboutYouCoachPreferences"
      onSubmit={handleSubmit}
      id="AboutYouCoachPreferences"
    >
      <h4>{t('pages.preferences.howDoYouWork.title')}</h4>
      <FormControl isInvalid={errors?.resume && touched?.resume}>
        <FormLabel>{t('pages.preferences.howDoYouWork.summary')}</FormLabel>
        <Textarea
          name="resume"
          placeholder={t(
            'pages.onboarding.components.aboutYouCoach.fieldResume'
          )}
          className="AboutYouCoachPreferences__textarea"
          resize="none"
          value={values.resume}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        {errors?.resume && touched?.resume ? (
          <FormErrorMessage fontSize="lg">
            {t(
              `pages.onboarding.components.aboutYouCoach.validation.${errors.resume}`
            )}
          </FormErrorMessage>
        ) : null}
      </FormControl>

      <FormControl isInvalid={errors?.howWork && touched.howWork}>
        <FormLabel>
          {t('pages.preferences.howDoYouWork.howDoYouWork')}
        </FormLabel>
        <Textarea
          name="howWork"
          placeholder={t('pages.onboarding.components.aboutYouCoach.fieldWork')}
          className="AboutYouCoachPreferences__textarea"
          resize="none"
          value={values.howWork}
          onBlur={handleBlur}
          onChange={handleChange}
        />

        {errors?.howWork && touched.howWork ? (
          <FormErrorMessage fontSize="lg">
            {t(
              `pages.onboarding.components.aboutYouCoach.validation.${errors.howWork}`
            )}
          </FormErrorMessage>
        ) : null}
      </FormControl>
      <Button
        type="submit"
        className="Button Button--primary"
        isLoading={loading}
        disabled={loading}
      >
        {t('pages.preferences.howDoYouWork.saveButton')}
      </Button>
    </form>
  );
}

export default AboutYouCoach;
