import React, { useContext, useState } from 'react';
import MyCoacheesContext from 'pages/MyCoachees/context/MyCoacheesContext';
import Assign from 'components/Assign';
import { useFetchAndLoad } from 'hooks';
import {
  createPointSesion,
  deletePointSesion,
  editPointSesion
} from 'services/sessionPoints.service';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';
import TreatedsSubjectsItems from './TreatedsSubjectsItems';
import { Textarea, Button } from '@chakra-ui/react';
import Modal from 'components/Modal';
import { AiFillPlusCircle } from 'react-icons/ai';
import {
  createAlignmentPointSesion,
  deleteAlignmentPointSesion,
  editAlignmentPointSesion
} from 'services/alignmentSessionPoints.service';

function TreatedSubjects() {
  const { selectedSession: session, setSelectedSession } =
    useContext(MyCoacheesContext);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { t } = useTranslation('global');
  const [modal, setModal] = useState('');
  const [selectedSubject, setselectedSubject] = useState(null);

  const isNormalSession = session?.type ? false : true;

  const adapter = (values) =>
    values?.map((treatedSubject) => ({
      id: treatedSubject._id,
      title: treatedSubject.title
    }));

  const addNewTreatedSubject = async (value) => {
    try {
      const response = isNormalSession
        ? await callEndpoint(
            createPointSesion({
              title: value,
              session: session.id
            })
          )
        : await callEndpoint(
            createAlignmentPointSesion({ title: value, session: session._id })
          );

      const { data } = response;
      setSelectedSession({
        ...session,
        pointsSession: [...session.pointsSession, data.data]
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editTreatedSubject = async (subject, value) => {
    try {
      const response = isNormalSession
        ? await callEndpoint(editPointSesion({ ...subject, title: value }))
        : await callEndpoint(
            editAlignmentPointSesion({ ...subject, title: value })
          );

      const { data } = response;
      setSelectedSession({
        ...session,
        pointsSession: session?.pointsSession.map((point) => {
          if (point._id === subject.id) return { ...data.data, title: value };

          return point;
        })
      });
    } catch (error) {
      console.log(error);
    }
  };

  const errorDelete = t('pages.myCoachees.components.errorDelete');
  const deleteSucess = t('pages.myCoachees.components.deleteSucess');
  const deletePoint = async (id) => {
    try {
      isNormalSession
        ? await callEndpoint(deletePointSesion(id))
        : await callEndpoint(deleteAlignmentPointSesion(id));

      setSelectedSession({
        ...session,
        pointsSession: session?.pointsSession.filter(
          (point) => point._id !== id
        )
      });
      displayToast(deleteSucess, 'success');
    } catch (error) {
      displayToast(errorDelete, 'error');
    }
  };

  const handleNewSubject = () => {
    setModal(`Agregar ${t('pages.myCoachees.components.tabSubject')}`);
  };

  const newSubject = (value) => {
    addNewTreatedSubject(value);
    if (loading) return;
    setModal('');
  };

  return adapter(session.pointsSession)?.length > 0 ? (
    <div>
      <div className="flex justify-center items-center">
        <h4>{t('pages.myCoachees.components.tabSubject')}</h4>
      </div>

      <Modal
        content={
          <ModalContent
            onSave={selectedSubject ? null : newSubject}
            loading={loading}
            text={selectedSubject?.title}
          />
        }
        isOpen={modal}
        title={modal}
        onClose={() => setModal('')}
        size="4xl"
        closeText="Close"
      />

      {adapter(session.pointsSession).map((value, i) => (
        <TreatedsSubjectsItems
          loading={loading}
          value={value}
          title={t('pages.myCoachees.components.tabSubject')}
          addSubject={addNewTreatedSubject}
          editSubject={editTreatedSubject}
          deleteSubject={deletePoint}
        />
      ))}

      <div
        className="Assign__container background2 Assign__button"
        onClick={handleNewSubject}
        role="button"
        tabIndex={0}
      >
        <p className="Assign__button_title ">
          Agregar {t('pages.myCoachees.components.tabSubject')}
        </p>
        <AiFillPlusCircle size={24} style={{ color: '#1890ff' }} />
      </div>
    </div>
  ) : (
    <>
      <Modal
        content={
          <ModalContent
            onSave={selectedSubject ? null : newSubject}
            loading={loading}
            text={selectedSubject?.title}
          />
        }
        isOpen={modal}
        title={modal}
        onClose={() => setModal('')}
        size="4xl"
        closeText="Close"
      />
      <div
        className="Assign__container background2 Assign__button"
        onClick={handleNewSubject}
        role="button"
        tabIndex={0}
      >
        <p className="Assign__button_title ">
          Agregar {t('pages.myCoachees.components.tabSubject')}
        </p>
        <AiFillPlusCircle size={24} style={{ color: '#1890ff' }} />
      </div>
    </>
  );
}

function ModalContent({ onSave, loading, text }) {
  const [value, setValue] = useState(text);

  return (
    <div className="Assign__modal">
      <Textarea
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <Button
        onClick={() => onSave(value)}
        alignSelf="center"
        className="Button Button--secondary Assign__save-button"
        isLoading={loading}
        isDisabled={loading}
      >
        Guardar
      </Button>
    </div>
  );
}

export default TreatedSubjects;
