import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import Evaluation from 'components/Evaluation';
import Comments from './components/Comments';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function MyEvaluations() {
  const { mongoID } = useSelector((state) => state.user);
  const { t } = useTranslation('global');

  return (
    <div className="MyEvaluations">
      <Tabs isFitted>
        <TabList mb="1em">
          <Tab>{t('pages.myEvaluations.tabAutoevaluation')}</Tab>
          <Tab>{t('pages.myEvaluations.tab360')}</Tab>
          <Tab>{t('pages.myEvaluations.tabComments')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Evaluation
              title={t('pages.myEvaluations.tabAutoevaluation')}
              type="autoevaluation"
              coachee={{ _id: mongoID }}
            />
          </TabPanel>
          <TabPanel>
            <Evaluation
              title={t('pages.myEvaluations.tab360')}
              type="evaluation360"
              coachee={{ _id: mongoID }}
            />
          </TabPanel>
          <TabPanel>
            <Comments coachee={mongoID} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}

export default MyEvaluations;
