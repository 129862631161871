import React, { useState, useEffect } from 'react';
import { Button, Grid, GridItem, Image } from '@chakra-ui/react';
import { find, filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setFocusArea } from 'redux/slices/onboarding';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import { getFocusAreas } from 'services/focusAreas.service';
import Loading from 'components/Loading';
import focusAreasAdapter from 'adapters/focusAreas.adapter';
import { useTranslation } from 'react-i18next';
import translateFocusArea from 'utilities/translateFocusArea.utility';

function FocusAreas({ nextStep }) {
  const selectedFocusAreas = useSelector(
    (state) => state.onboarding.focusAreas
  );

  const { loading, callEndpoint } = useFetchAndLoad();
  const { role, cohort, coachingProgram } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [focusAreas, setFocusAreas] = useState([]);
  const { t, i18n } = useTranslation('global');

  const isCoachee = role === 'coachee';
  const coacheeAreasByProgram = cohort?.program < 12 ? 2 : 3;

  const getFocusAreasApi = async () => {
    try {
      if (isCoachee) {
        setFocusAreas(focusAreasAdapter(coachingProgram?.focusAreas));
        return;
      }

      const areas = await callEndpoint(getFocusAreas());
      setFocusAreas(focusAreasAdapter(areas.data.data));
    } catch (err) {
      console.log('🚀 ~ file: FocusAreas.js ~ line 36 ~ err', err);
    }
  };

  useEffect(() => {
    getFocusAreasApi();
  }, []);

  const isASelectedArea = (id) => find(selectedFocusAreas, { id });

  const handleSelectArea = (focusArea) => {
    if (isASelectedArea(focusArea.id)) {
      const selectedAreas = filter(
        selectedFocusAreas,
        (Area) => Area.id !== focusArea.id
      );
      dispatch(setFocusArea(selectedAreas));
      return;
    }

    if (isCoachee && selectedFocusAreas.length === coacheeAreasByProgram) {
      setError({
        message: t('pages.onboarding.components.focusAreas.messages.area', {
          coacheeAreasByProgram: coacheeAreasByProgram
        })
      });
      return;
    }

    dispatch(setFocusArea([...selectedFocusAreas, focusArea]));
  };

  const handleNext = () => {
    if (selectedFocusAreas < coacheeAreasByProgram) {
      window.scroll({ behavior: 'smooth', top: 0 });
      setError({
        message: t('pages.onboarding.components.focusAreas.messages.min')
      });
      return;
    }
    nextStep();
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="FocusAreas">
      <h4 className="FocusAreas__title">
        {isCoachee
          ? t('pages.onboarding.components.focusAreas.titleCoachee', {
              coacheeAreasByProgram: coacheeAreasByProgram
            })
          : t('pages.onboarding.components.focusAreas.titleCoach')}
      </h4>
      <p className="FocusAreas__subtitle">
        {isCoachee
          ? t('pages.onboarding.components.focusAreas.subtitleCoachee')
          : t('pages.onboarding.components.focusAreas.subtitleCoach')}
      </p>
      {error ? <p className="FocusAreas__error">{error.message}</p> : null}
      <Grid className="FocusAreas__grid">
        {focusAreas?.map((focusArea) => (
          <GridItem
            key={focusArea.id}
            className={`FocusAreas__grid_area ${
              isASelectedArea(focusArea.id)
                ? 'FocusAreas__grid_area--selected'
                : ''
            }`}
            onClick={() => handleSelectArea(focusArea)}
          >
            <Image
              src={focusArea.urlImgFocusArea}
              className="FocusAreas__grid_area_icon"
              alt={`Icono ${focusArea.focusArea}`}
            />
            <h5 className="FocusAreas__grid_area_title">
              {translateFocusArea(focusArea)}
            </h5>
          </GridItem>
        ))}
      </Grid>

      <Button className="Button Button--primary" onClick={handleNext}>
        {t('pages.onboarding.components.focusAreas.button')}
      </Button>
    </div>
  );
}

export default FocusAreas;
