import React, { useState, useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import { useFormik, FormikProvider } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setEvaluation360 } from 'redux/slices/onboarding';
import validationSchema from './validationSchema';
import AddEvaluator from '../AddEvaluator';
import EvaluationFieldArray from '../EvaluationFieldArray';
import { forEach, size } from 'lodash';
import { useTranslation } from 'react-i18next';

function Evaluation360({ nextStep }) {
  const evaluation = useSelector((state) => state.onboarding.evaluation);
  const { evaluators } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [addEvaluator, setAddEvaluator] = useState(false);
  const { t } = useTranslation('global');

  const handleAddEvaluator = (evaluator) => {
    switch (evaluator.type) {
      case 'supervisor': {
        formik.setValues({
          ...formik.values,
          supervisors: [...formik.values.supervisors, evaluator]
        });
        break;
      }

      case 'colaborator':
        formik.setValues({
          ...formik.values,
          colaborators: [...formik.values.colaborators, evaluator]
        });
        break;

      case 'partner':
        formik.setValues({
          ...formik.values,
          partners: [...formik.values.partners, evaluator]
        });
        break;

      default:
        break;
    }
  };

  const addUserEvaluators = () => {
    if (size(evaluation) > 0) {
      return evaluation;
    }
    let evaluatorsTemp = {
      supervisors: [],
      colaborators: [],
      partners: []
    };
    if (size(evaluators) > 0) {
      forEach(evaluators, (evaluator) => {
        switch (evaluator.type) {
          case 'supervisor':
            evaluatorsTemp.supervisors.push(evaluator);
            break;

          case 'colaborator':
            evaluatorsTemp.colaborators.push(evaluator);
            break;

          case 'partner':
            evaluatorsTemp.partners.push(evaluator);
            break;
        }
      });
    }

    return evaluatorsTemp;
  };

  const formik = useFormik({
    initialValues: addUserEvaluators(),
    validationSchema,
    onSubmit: (formValues) => {
      dispatch(setEvaluation360(formValues));
      nextStep();
    }
  });

  const { errors, touched } = formik;

  useEffect(() => {
    dispatch(setEvaluation360(formik.values));
  }, [formik.values]);

  if (addEvaluator)
    return (
      <AddEvaluator
        setAddEvaluator={setAddEvaluator}
        handleAddEvaluator={handleAddEvaluator}
      />
    );

  return (
    <div className="Evaluation360">
      <h4 className="Evaluation360__title">
        {t('pages.onboarding.components.evaluation360.title')}
      </h4>
      <p className="Evaluation360__subtitle">
        {t('pages.onboarding.components.evaluation360.subtitle')}
      </p>
      <Button
        className="Button Button--secondary Evaluation360__button"
        onClick={() => setAddEvaluator(true)}
      >
        {t('pages.onboarding.components.evaluation360.addEvaluator')}
      </Button>
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          className="Evaluation360__form Card"
          id="Evaluation360"
        >
          <EvaluationFieldArray
            key="supervisors"
            errors={errors}
            touched={touched}
            values={formik.values?.supervisors}
            name="supervisors"
            formik={formik}
            label={t(
              'pages.onboarding.components.evaluation360.labelSupervisor'
            )}
          />

          <EvaluationFieldArray
            key="colaborators"
            errors={errors}
            touched={touched}
            values={formik.values?.colaborators}
            name="colaborators"
            formik={formik}
            label={t('pages.onboarding.components.evaluation360.labelColab')}
          />

          <EvaluationFieldArray
            key="partners"
            errors={errors}
            touched={touched}
            values={formik.values?.partners}
            name="partners"
            formik={formik}
            label={t('pages.onboarding.components.evaluation360.labelPartner')}
          />
        </form>
      </FormikProvider>
      <Button
        type="submit"
        form="Evaluation360"
        className="Button Button--primary Button--next"
      >
        {t('pages.onboarding.components.evaluation360.button')}
      </Button>
    </div>
  );
}

export default Evaluation360;
