import React, { useEffect, useState } from 'react';
// import { Checkbox, Switch } from '@chakra-ui/react';
import { dateToLongDate } from 'utilities/formatDate.utility';
import { useSelector } from 'react-redux';
import useCoacheeCalendarContext from '../../hooks/useCoacheeCalendarContext';
import { useUserUtilities } from 'hooks';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { google, outlook, msTeams, ics } from 'calendar-link';
import googleIcon from 'assets/images/icons/google.png';
import msTeamsIcon from 'assets/images/icons/msteams.png';
import iCalIcon from 'assets/images/icons/apple.png';
import outlookIcon from 'assets/images/icons/outlook.png';

function Scheduled() {
  const { date, hour } = useCoacheeCalendarContext();
  const user = useSelector((state) => state.user);
  const { name, lastname, timezone } = user;
  const { refreshSessions } = useUserUtilities(user);
  const [event, setEvent] = useState(undefined);
  const { t } = useTranslation('global');

  const navigate = useNavigate();

  const addAnotherSession = () => {
    if (window.location.pathname !== '/mycalendar') {
      navigate('/mycalendar');
    } else {
      window.location.reload();
    }
  };

  const createEvent = () => {
    const title = t('pages.reschedule.calendarSessionTitle');
    const startTime = DateTime.fromISO(hour?.startHour, {
      zone: timezone
    }).toISO();

    const event = {
      title,
      start: startTime,
      duration: [1, 'hour'],
      location: 'https://www.app.bonumcoaching.com',
      busy: true
    };

    setEvent(event);
  };

  const getLink = (link) => {
    window.open(link, '_blank');
  };

  useEffect(() => {
    refreshSessions();
    createEvent();
  }, []);

  return (
    <div className="Scheduled Card">
      <h3 className="Scheduled__name">
        {t('pages.reschedule.components.scheduled.name', {
          name: name,
          lastname: lastname
        })}
      </h3>
      <p className="Scheduled__subtitle">
        {t('pages.reschedule.components.scheduled.subtitle')}
      </p>
      <h2 className="Scheduled__date">{dateToLongDate(date)}</h2>
      <p className="Scheduled__hour">{hour?.time}</p>

      <div className="Scheduled__remember">
        <p className="Scheduled__remember_advice">
          {t('pages.reschedule.components.scheduled.remember')}
        </p>

        <p className="Scheduled__remember_advice">
          {t('pages.reschedule.components.scheduled.remember2')}
        </p>

        <p className="Scheduled__remember_advice_bold">
          {t('pages.reschedule.components.scheduled.rememberBold')}
        </p>

        {event && (
          <div className="Scheduled__add_calendar_buttons">
            <button onClick={() => getLink(msTeams(event))}>
              <img
                src={msTeamsIcon}
                className="Scheduled__add_calendar_button"
              ></img>
            </button>

            <button onClick={() => getLink(google(event))}>
              <img
                src={googleIcon}
                className="Scheduled__add_calendar_button"
              ></img>
            </button>

            <button onClick={() => getLink(outlook(event))}>
              <img
                src={outlookIcon}
                className="Scheduled__add_calendar_button"
              ></img>
            </button>

            <button onClick={() => getLink(ics(event))}>
              <img
                src={iCalIcon}
                className="Scheduled__add_calendar_button"
              ></img>
            </button>
          </div>
        )}

        {/* <div className="Scheduled__calendar_button">
          <Button className="Button Button--secondary" onClick={addToCalendar}>
            {t('pages.reschedule.addToCalendar')}
          </Button>
        </div> */}
        <div className="Scheduled__calendar_button">
          <Button
            className="Button Button--secondary"
            onClick={addAnotherSession}
          >
            {t('pages.reschedule.addAnotherSession')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Scheduled;
