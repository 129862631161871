import React, { useState, useEffect } from 'react';
import { size } from 'lodash';
import NoData from 'components/NoData/NoData';
import { Button, Image, Textarea } from '@chakra-ui/react';
import Point from 'assets/images/icons/points.png';
import AddIcon from 'assets/images/icons/add_icon.png';
import Modal from 'components/Modal';
import {
  RiDeleteBin6Line,
  RiEditBoxLine,
  RiCheckLine,
  RiCheckboxLine
} from 'react-icons/ri';

function Assign({
  values,
  title = 'Titulo a asignar',
  addSubject = () => null,
  editSubject = () => null,
  deleteSubject = () => null,
  loading
}) {
  const [selectedSubject, setselectedSubject] = useState(null);
  const [modal, setModal] = useState('');

  const handleSubjectEdit = (subject) => {
    setselectedSubject(subject);
    setModal(`Editar ${title}`);
  };

  const handleNewSubject = () => {
    setModal(`Agregar ${title}`);
  };

  const modifySubject = (value) => {
    editSubject(selectedSubject, value);
    setselectedSubject(false);
    setModal('');
  };

  const newSubject = (value) => {
    addSubject(value);
    if (loading) return;
    setModal('');
  };

  useEffect(() => {}, [values]);

  return (
    <div className="Assign__container background">
      <div className="Assign">
        <Modal
          content={
            <ModalContent
              onSave={selectedSubject ? modifySubject : newSubject}
              loading={loading}
              text={selectedSubject?.title}
            />
          }
          isOpen={modal}
          title={modal}
          onClose={() => setModal('')}
          size="4xl"
          closeText="Close"
        />
        <h4>{title}</h4>
        {size(values) > 0 ? (
          values.map((subject) => (
            <div
              className="Assign__subject"
              key={`${subject.title}${subject.id}`}
            >
              <div className="Assign__subject_title">
                <Image src={Point} className="Assign__subject_title_dot" />
                <p>{subject.title}</p>
              </div>

              {subject.status ? (
                <div className="Assign__subject_icons">
                  <p className="Assign__completed">Completada</p>
                  <RiCheckboxLine />
                </div>
              ) : (
                <div className="Assign__subject_icons">
                  <RiEditBoxLine
                    className="Assign__subject_icon"
                    onClick={() => handleSubjectEdit(subject)}
                  />
                  <RiDeleteBin6Line
                    className="Assign__subject_icon"
                    onClick={() => deleteSubject(subject.id)}
                  />
                </div>
              )}
            </div>
          ))
        ) : (
          <NoData title={`No tienes ${title}`} />
        )}

        <div
          className="Assign__button"
          onClick={handleNewSubject}
          role="button"
          tabIndex={0}
        >
          <p className="Assign__button_title">Agregar {title}</p>
          <Image src={AddIcon} className="Assign__button_icon" />
        </div>
      </div>
    </div>
  );
}

function ModalContent({ onSave, loading, text }) {
  const [value, setValue] = useState(text);

  return (
    <div className="Assign__modal">
      <Textarea
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <Button
        onClick={() => onSave(value)}
        alignSelf="center"
        className="Button Button--secondary Assign__save-button"
        isLoading={loading}
        isDisabled={loading}
      >
        Guardar
      </Button>
    </div>
  );
}

export default Assign;
