import React from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Image
} from '@chakra-ui/react';
import Select from 'react-select';
import { SiApple, SiMicrosoftoutlook } from 'react-icons/si';
import { ImGoogle } from 'react-icons/im';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { registerCalendar } from 'services/calendar.service';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import IconOption from './components/IconOption';
import iconCalendar from 'assets/images/icons/calendario.png';

function ConnectCalendar() {
  const initialValues = () => ({ email: '', provider: 'gmail' });
  const { loading, callEndpoint } = useFetchAndLoad();

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    setValues
  } = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Debe ser un email válido')
        .required('El email es requerido'),
      provider: Yup.string()
        .oneOf(['gmail', 'icloud'], 'Proveedor no válido')
        .required('Necesitamos tu proveedor de calendario')
    }),
    onSubmit: async (formValues) => {
      try {
        const { data } = await callEndpoint(
          registerCalendar(formValues.email, formValues.provider)
        );
        window.open(data.data, '_self', 'noopener,noreferrer');
      } catch (error) {
        console.log(error);
      }
    }
  });

  const providers = [
    { value: 'gmail', label: 'Gmail', icon: ImGoogle },
    { value: 'icloud', label: 'iCloud Mail', icon: SiApple }
  ];

  const handleSelectChange = (option) => {
    setValues({ ...values, provider: option.value });
  };

  return (
    <div className="ConnectCalendar background">
      <Image
        src={iconCalendar}
        width="100"
        className="ConnectCalendar__icon"
        objectFit="cover"
      />
      <h3 className="ConnectCalendar__title">Conectar Calendario</h3>
      <p className="ConnectCalendar__subtitle">
        Conectaremos el calendario de tu preferencia para ver tu disponibilidad
      </p>

      <form className="ConnectCalendar__form Card" onSubmit={handleSubmit}>
        <Select
          defaultValue={providers[0]}
          options={providers}
          components={{ Option: IconOption }}
          onChange={handleSelectChange}
          className="ConnectCalendar__select"
        />
        <FormControl
          isInvalid={(errors.email && touched.email) || errors.provider}
        >
          <Input
            className="AboutYou__input"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
          />
          {(errors?.email && touched.email) || errors?.provider ? (
            <FormErrorMessage fontSize="lg">
              {errors.email} {errors.provider}
            </FormErrorMessage>
          ) : null}
        </FormControl>
        <Button
          className="Button Button--primary"
          isLoading={loading}
          disabled={loading}
          type="submit"
        >
          Conectar
        </Button>
      </form>
    </div>
  );
}

export default ConnectCalendar;
