import { Button, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CongratulationsIcon from 'assets/images/icons/congratulations.png';
import { useTranslation } from 'react-i18next';

function Congratulations(props) {
  const { coachee, evaluator } = props;

  const navigate = useNavigate();
  const { t } = useTranslation('global');

  return (
    <div className="Congratulations">
      <Image className="Congratulations__icon" src={CongratulationsIcon} />
      <p className="Congratulations__description">
        {t('pages.evaluation360.congratulations.thanks')}{' '}
        <strong>
          {evaluator?.name} {evaluator?.lastname}
        </strong>
        {t('pages.evaluation360.congratulations.connection')}{' '}
        <strong>
          {coachee?.name} {coachee?.lastname}
        </strong>{' '}
        {t('pages.evaluation360.congratulations.description')}{' '}
      </p>
      <Button
        className="Button Button--primary Congratulations__button"
        onClick={() => window.open('https://www.bonumcoaching.com', '_self')}
      >
        {t('pages.evaluation360.congratulations.button')}
      </Button>
    </div>
  );
}

export default Congratulations;
