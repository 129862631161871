import React from 'react';

function EventWrapper({ event, children }) {
  const { isExecuted, isCanceled } = event;

  const isExecutedClass = isExecuted
    ? 'DayCalendar__card DayCalendar__card--executed'
    : 'DayCalendar__card';

  const isCanceledClass = isCanceled ? 'DayCalendar__card--canceled' : '';

  return (
    <div className={`${isExecutedClass} ${isCanceledClass}`}>{children}</div>
  );
}

export default EventWrapper;
