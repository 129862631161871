import React, { useMemo, useState } from 'react';
import MySessionsContext from './MySessionsContext';

function MySessionsContextWrapper({ children }) {
  const [selectedSession, setSelectedSession] = useState(null);
  const MySessionsContextValue = useMemo(
    () => ({
      selectedSession,
      setSelectedSession
    }),
    [selectedSession]
  );

  return (
    <MySessionsContext.Provider value={MySessionsContextValue}>
      {children}
    </MySessionsContext.Provider>
  );
}

export default MySessionsContextWrapper;
