import React from 'react';
import MySessionsContextWrapper from './context/MySessionsContextWrapper';
import MySessions from './MySessions';

export default function () {
  return (
    <MySessionsContextWrapper>
      <MySessions />
    </MySessionsContextWrapper>
  );
}
