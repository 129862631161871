import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Image
} from '@chakra-ui/react';
import Select from 'react-select';
import {
  timezonesToReactSelect,
  IANATimezones
} from 'utilities/timezones.utility';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { findIndex } from 'lodash';
import { useDispatch } from 'react-redux';
import { setTimezone } from 'redux/slices/onboarding';
import { TimeZoneSelectDialog } from 'react-timezone-map-select';
import displayToast from 'utilities/toast.utility';
import iconClock from 'assets/images/icons/zona-horaria.png';
import { useTranslation } from 'react-i18next';

function PickTimezone({ nextStep, setDisableArrows }) {
  const dispatch = useDispatch();
  const [isShowButton, setIsShowButton] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [timezones] = useState(timezonesToReactSelect());
  const { t } = useTranslation('global');

  const auto = t('pages.onboarding.components.pickTimezone.messages.auto');
  const validation = t(
    'pages.onboarding.components.pickTimezone.messages.validation'
  );
  const required = t(
    'pages.onboarding.components.pickTimezone.messages.required'
  );

  const initialValues = () => ({
    timezone: ''
  });

  const { handleSubmit, values, errors, setValues } = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object().shape({
      timezone: Yup.string().oneOf(IANATimezones, validation).required(required)
    }),
    onSubmit: async (formValues) => {
      dispatch(setTimezone(formValues.timezone));
      setDisableArrows(false);
      nextStep();
    }
  });

  const handleCloseMap = (mapTimezone) => {
    setValues({ ...values, timezone: mapTimezone });
    setOpenMap(false);
  };

  const getIndexOfTimezone = (timezone) =>
    findIndex(
      timezones,
      (timezoneItem) =>
        timezoneItem.name === timezone || timezoneItem.group.includes(timezone)
    );

  const setTimeZoneFromBrowser = () => {
    const index = getIndexOfTimezone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    displayToast(auto, 'success');
    if (index >= 0) {
      setValues({ ...values, timezone: timezones[index].value });
      dispatch(setTimezone(timezones[index].value));
    } else {
      setValues({ ...values, timezone: timezones[0].value });
      dispatch(setTimezone(timezones[0].value));
    }
  };

  const handleSelectChange = (option) => {
    setDisableArrows(true);
    setIsShowButton(true);
    setValues({ ...values, timezone: option.value });
  };

  useEffect(() => {
    if (timezones) setTimeZoneFromBrowser();
  }, [timezones]);

  return (
    <div className="PickTimezone ">
      <Image
        src={iconClock}
        width="100"
        className="PickTimezone__icon"
        objectFit="cover"
      />
      <h3 className="PickTimezone__title">
        {t('pages.onboarding.components.pickTimezone.title')}
      </h3>
      <p className="PickTimezone__subtitle">
        {t('pages.onboarding.components.pickTimezone.subtitle')}
      </p>
      <div className="PickTimezone__picker card">
        <form
          className="TimezonePicker__form"
          onSubmit={handleSubmit}
          id="timezone"
        >
          <FormControl isInvalid={errors.timezone}>
            <Select
              defaultValue={timezones[getIndexOfTimezone(values.timezone)]}
              options={timezones}
              onChange={handleSelectChange}
              className="TimezonePicker__select"
              value={timezones[getIndexOfTimezone(values.timezone)]}
            />

            <FormHelperText textAlign="left" fontSize="xl" mb="10">
              {t('pages.onboarding.components.pickTimezone.textHelper')}
            </FormHelperText>

            <Button
              className="Button--primary PickTimezone__open"
              onClick={() => setOpenMap(true)}
            >
              {t('pages.onboarding.components.pickTimezone.openMap')}
            </Button>

            {errors?.timezone ? (
              <FormErrorMessage fontSize="lg">
                {errors.timezone}
              </FormErrorMessage>
            ) : null}
          </FormControl>

          <TimeZoneSelectDialog
            open={openMap}
            timeZoneName={values.timezone}
            onClose={handleCloseMap}
            description={t(
              'pages.onboarding.components.pickTimezone.selectDialog.description'
            )}
            buttonLabelCancel={t(
              'pages.onboarding.components.pickTimezone.selectDialog.cancelButton'
            )}
            title={t(
              'pages.onboarding.components.pickTimezone.selectDialog.title'
            )}
          />
        </form>
      </div>
      {isShowButton && (
        <Button
          type="submit"
          className="Button Button--primary"
          mt={'1.5em'}
          onSubmit={handleSubmit}
          form="timezone"
        >
          {t('pages.onboarding.components.pickTimezone.saveButton')}
        </Button>
      )}
    </div>
  );
}

export default PickTimezone;
