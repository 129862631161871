import React, { useEffect, useRef } from 'react';
import { Avatar, Img } from '@chakra-ui/react';

function LocalVideoPlayer({
  localStream,
  activeVideo,
  callAccepted,
  expand,
  user
}) {
  const localVideoref = useRef(null);

  useEffect(() => {
    if (localStream) {
      localVideoref.current.srcObject = localStream;
    }
  }, [localStream]);

  return (
    <>
      {callAccepted ? (
        <div className="flex-container second-video">
          <video
            playsInline
            id="user-video"
            ref={localVideoref}
            autoPlay
            muted
          />

          {!activeVideo && (
            <Img id="user-video" className="img-preview" src={user.photo} />
          )}
        </div>
      ) : (
        <div
          id="main"
          className="flex-container"
          style={{
            margin: 0,
            padding: 0,
            width: expand ? '100%' : '70rem',
            height: expand ? '100%' : '500px'
          }}
        >
          <video id="my-video" ref={localVideoref} playsInline autoPlay muted />
          {!activeVideo && (
            <div id="my-img">
              <Avatar src={user.photo} size={'2xl'} className="image-preview" />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default LocalVideoPlayer;
