const availableLanguages = ['es', 'en', 'pt'];
const languagesToReactSelect = [
  {
    value: 'es',
    label: 'Español'
  },
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'pt',
    label: 'Portugues '
  }
];

export { availableLanguages, languagesToReactSelect };

// t('pages.lang.pt')
