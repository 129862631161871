import { useEffect, useState } from 'react';
import { Grid, GridItem, Divider, Button, Textarea } from '@chakra-ui/react';
import { useFetchAndLoad } from 'hooks';
import { useParams } from 'react-router-dom';
import {
  getEvaluation360ById,
  getInitialEvaluation360ByEvaluator,
  updateEvaluation360Answers
} from 'services/evaluations.service';
import displayToast from 'utilities/toast.utility';
import NoData from 'components/NoData/NoData';
import Loading from 'components/Loading';
import { filter, find, size } from 'lodash';
import Question from './components/Question';
import Congratulations from './components/Congratulations';
import { useTranslation } from 'react-i18next';

function Evaluation360() {
  const { id_evaluation360 } = useParams();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [evaluation360, setEvaluation360] = useState(null);
  const [errors, setErrors] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [evaluatorType, setEvaluatorType] = useState('');
  const [initialEvaluation, setInitialEvaluation] = useState({});
  const { t, i18n } = useTranslation('global');

  const coacheeName = `${evaluation360?.coachee?.name} ${evaluation360?.coachee?.lastname}`;
  const coacheeDepartment = evaluation360?.coachee?.department;
  const evaluatorName = `${evaluation360?.evaluator?.name} ${evaluation360?.evaluator?.lastname}`;
  const errorGet = t('pages.evaluation360.errorGet');

  const getEvaluation = async () => {
    if (!id_evaluation360) return;
    try {
      const { data } = await callEndpoint(
        getEvaluation360ById(id_evaluation360)
      );
      setEvaluation360(data.data);
      if (data.data?.evaluationType === 'final')
        getInitialEvaluation(data.data);
    } catch (error) {
      displayToast(errorGet, 'error');
    }
  };

  const getEvaluatorType = () => {
    switch (evaluation360?.evaluator?.type) {
      case 'partner':
        setEvaluatorType(t('pages.evaluation360.partner'));
        break;

      case 'supervisor':
        setEvaluatorType(t('pages.evaluation360.supervisor'));
        break;

      case 'colaborator':
        setEvaluatorType(t('pages.evaluation360.colaborator'));
        break;
    }

    return evaluatorType;
  };

  const handleValueChange = (id, value) => {
    const newAnswers = evaluation360.answers.map((answer) => {
      if (answer._id === id) return { ...answer, value: parseInt(value) };
      return answer;
    });

    setEvaluation360({ ...evaluation360, answers: newAnswers });
  };

  const handleCommentsChange = (event) => {
    setEvaluation360({ ...evaluation360, comments: event.target.value });
  };

  const messageSend = t('pages.evaluation360.handleSend');
  const errorSend = t('pages.evaluation360.errorSend');

  const handleSend = async () => {
    const unaswered = filter(evaluation360.answers, { value: 0 });
    if (size(unaswered) > 0) {
      setErrors(unaswered);
      displayToast(messageSend, 'error');
      return;
    }

    try {
      await callEndpoint(updateEvaluation360Answers(evaluation360));

      setCompleted(true);
    } catch (error) {
      displayToast(errorSend, 'error');
    }
  };

  const getInitialEvaluation = async (evaluation) => {
    try {
      const { data } = await callEndpoint(
        getInitialEvaluation360ByEvaluator(evaluation?.evaluator?._id)
      );
      setInitialEvaluation(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEvaluation();
  }, []);

  useEffect(() => {
    getEvaluatorType();
    if (evaluation360?.status === 'resolved') setCompleted(true);
  }, [evaluation360]);

  useEffect(() => {}, [errors, i18n.language]);

  if (loading) return <Loading title={t('pages.evaluation360.loading')} />;

  if (!id_evaluation360 || !evaluation360)
    return <NoData title={t('pages.evaluation360.noData')} />;

  if (completed)
    return (
      <div className="AutoEvaluation__resolved">
        <Congratulations
          coachee={evaluation360.coachee}
          evaluator={evaluation360.evaluator}
        />
      </div>
    );

  return (
    <div className="Evaluation360">
      <div className="Evaluation360__title">
        {t('pages.evaluation360.title')}
      </div>
      <div className="Evaluation360__desc">
        {t('pages.evaluation360.description', { coacheeName: coacheeName })}
      </div>
      <div className="Evaluation360__data_user">
        <h5>{t('pages.evaluation360.dataUser')}</h5>
        <Grid templateColumns="repeat(auto-fit, minmax(350px, 1fr))" gap={6}>
          <GridItem w="100%">
            <p>
              {t('pages.evaluation360.fieldName')} <b>{coacheeName}</b>
            </p>
          </GridItem>
          <GridItem w="100%">
            <p>
              {t('pages.evaluation360.fieldDepartment')}{' '}
              <b>{coacheeDepartment}</b>
            </p>
          </GridItem>
        </Grid>
        <h5>{t('pages.evaluation360.dataEva')}</h5>
        <Grid templateColumns="repeat(auto-fit, minmax(350px, 1fr))" gap={6}>
          <GridItem w="100%">
            <p>
              {t('pages.evaluation360.fieldName')} <b>{evaluatorName}</b>
            </p>
          </GridItem>
          <GridItem w="100%">
            <p>
              {t('pages.evaluation360.fieldRelation')} <b>{evaluatorType}</b>
            </p>
          </GridItem>
        </Grid>
      </div>
      <Divider />
      <div className="Evaluation360__checkbox">
        <p className="Evaluation360__checkbox_subtitle">
          {t('pages.evaluation360.subtitle')}
          {coacheeName}.
        </p>
        <p className="Evaluation360__questions_title">
          {t('pages.evaluation360.questionsTitle')}
        </p>

        <div className="Evaluation360__questions">
          {size(evaluation360?.answers) > 0 &&
            evaluation360?.answers.map((answer, index) => (
              <Question
                index={index + 1}
                error={find(errors, { _id: answer._id })}
                answer={answer}
                initialEvaluation={initialEvaluation}
                key={answer.question._id}
                handleValueChange={handleValueChange}
                language={i18n.language}
              />
            ))}
        </div>

        <div className="Evaluation360__commentsWrapper">
          <p className="Evaluation360__questions_title">
            {evaluation360.evaluationType === 'initial'
              ? t('pages.evaluation360.commentsTitle')
              : t('pages.evaluation360.commentsTitleFinal')}
          </p>
          <p className="Evaluation360__questions_subtitle">
            {t('pages.evaluation360.commentsSubtitle')}
          </p>
          <Textarea
            className="Evaluation360__comments"
            onChange={handleCommentsChange}
          />
        </div>

        <div className="AutoEvaluation__button">
          <Button className="Button Button--primary" onClick={handleSend}>
            {t('pages.evaluation360.buttonSave')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Evaluation360;
