import { useState, useEffect } from 'react';
import NoData from 'components/NoData/NoData';
import { Image } from '@chakra-ui/react';
import Point from 'assets/images/icons/points.png';
import { RiCheckboxLine } from 'react-icons/ri';

function TreatedsSubjectsItem({
  value,
  title = 'Titulo a asignar',
  addSubject = () => null,
  editSubject = () => null,
  deleteSubject = () => null,
  loading
}) {
  useEffect(() => {}, [value]);

  return (
    <div className="background2 ">
      <div className="Assign">
        {value ? (
          <div className="Assign__subject" key={`${value.title}${value.id}`}>
            <div className="Assign__subject_title">
              <Image src={Point} className="Assign__subject_title_dot" />
              <p>{value.title}</p>
            </div>

            {value.status ? (
              <div className="Assign__subject_icons">
                <p className="Assign__completed">Completada</p>
                <RiCheckboxLine />
              </div>
            ) : null}
          </div>
        ) : (
          <NoData title={`No tienes ${title}`} />
        )}
      </div>
    </div>
  );
}

export default TreatedsSubjectsItem;
