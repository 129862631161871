import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useFetchAndLoad } from 'hooks';
import { getCoachAvailability } from 'services/calendar.service';
import displayToast from 'utilities/toast.utility';
import Loading from 'components/Loading';
import { compact, map, size } from 'lodash';
import { DateTime } from 'luxon';
import useScheduleContext from '../../hooks/useScheduleContext';
import { useTranslation } from 'react-i18next';
import NoData from 'components/NoData/NoData';

function AvailableSchedule() {
  const { loading, callEndpoint } = useFetchAndLoad();
  const [schedule, setSchedule] = useState([]);
  const { coach } = useSelector((state) => state.user);
  const { setHour, hour, date } = useScheduleContext();
  const { t } = useTranslation('global');

  const getHours = (workHours) => {
    const hours = map(workHours, (unformattedHour, index) => {
      const start = DateTime.fromISO(unformattedHour);
      const today = DateTime.now();
      if (start < today) return false;
      const finish = start.plus({ hours: 1 });
      return {
        id: index,
        time: `${start.toFormat('hh:mma')} - ${finish.toFormat('hh:mma')}`,
        startHour: start
      };
    });

    return compact(hours);
  };

  const errorCoach = t(
    'pages.reschedule.components.availableSchedule.errorCoach'
  );

  const coachAvailability = async () => {
    try {
      const response = await callEndpoint(
        getCoachAvailability(date, coach._id)
      );
      const workHours = response.data.data;
      setSchedule(getHours(workHours));
    } catch (error) {
      console.log(error);
      displayToast(errorCoach, 'error');
    }
  };

  useEffect(() => {
    if (date) coachAvailability();
  }, [date]);

  if (loading)
    return (
      <Loading
        title={t('pages.reschedule.components.availableSchedule.loading')}
      />
    );

  if (size(schedule) < 1)
    return <NoData title={t('pages.reschedule.noSchedule')} />;

  return (
    <div className="AvailableSchedule">
      {schedule.map((scheduleObj) => (
        <Button
          key={scheduleObj.id}
          className={`Button AvailableSchedule__button ${
            hour?.time === scheduleObj?.time
              ? 'AvailableSchedule__button--selected'
              : ''
          }`}
          onClick={() => setHour(scheduleObj)}
        >
          {scheduleObj.time}
        </Button>
      ))}
    </div>
  );
}

export default AvailableSchedule;
