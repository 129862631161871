import React, { useContext } from 'react';
import { Checkbox } from '@chakra-ui/react';
import SuccessCalendarContext from 'pages/SuccessCalendar/context/SuccessCalendarContext';

function SavedCalendar({ calendar }) {
  const { calendars, setCalendars } = useContext(SuccessCalendarContext);

  const isBonumCalendar = calendarToCheck => calendarToCheck.name.includes('Calendario Bonum');

  const handleCalendarChange = calendarData => {
    setCalendars(
      calendars.map(tempCalendar => {
        if (tempCalendar.id !== calendarData.id) return tempCalendar;
        return { ...tempCalendar, active: !calendarData?.active };
      })
    );
  };

  return (
    <div className='SavedCalendar'>
      <Checkbox
        isChecked={calendar.active || isBonumCalendar(calendar)}
        className='SavedCalendar__checkbox'
        size='lg'
        disabled={isBonumCalendar(calendar)}
        defaultChecked={isBonumCalendar(calendar)}
        onChange={() => handleCalendarChange(calendar)}
      >
        {calendar.name}
      </Checkbox>
    </div>
  );
}

export default SavedCalendar;
