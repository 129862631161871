import { MainContainer } from '@chatscope/chat-ui-kit-react';
import Conversation from './components/Conversation';
import Contacts from './components/Contacts';
import { BsChatText } from 'react-icons/bs';
import useChatContext from './hooks/useChatContext';
import { size } from 'lodash';
import { useEffect } from 'react';

function Chat() {
  const {
    isContactsOpen,
    setIsContactsOpen,
    conversations,
    hasUnreadMessages,
    handleRemoveCompleteChat
  } = useChatContext();

  const handleOpenContacts = () => {
    setIsContactsOpen(!isContactsOpen);
    handleRemoveCompleteChat(conversations);
  };

  useEffect(() => {}, [hasUnreadMessages]);

  return (
    <div className="Chat">
      <div className="Chat__conversations">
        {size(conversations) > 0 &&
          conversations.map((conversation) => (
            <Conversation
              data={conversation}
              key={`Conversation__${conversation._id}`}
            />
          ))}
      </div>
      <MainContainer
        responsive
        className={`${!isContactsOpen && 'Chat--hidden'}`}
      >
        <Contacts />
      </MainContainer>
      <div className="Chat__icon_wrapper">
        <BsChatText className="Chat__icon" onClick={handleOpenContacts} />
        <i
          className={`Chat__unread ${
            hasUnreadMessages ? 'Chat__unread--active' : ''
          }`}
        />
      </div>
    </div>
  );
}

export default Chat;
