import * as Yup from 'yup';

const minCharacters = 100;

const validationSchema = Yup.object().shape({
  resume: Yup.string()
    .required('resumeRequire')
    .min(minCharacters, `resumeMin`),
  work: Yup.string().required('workRequire').min(minCharacters, `workMin`)
});

export default validationSchema;
