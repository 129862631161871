import React from 'react';
import { Button, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BonumIcon from 'assets/images/icons/Bonum_icon.png';
import { useTranslation } from 'react-i18next';

function NoMoreSessions() {
  const { name, lastname } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  return (
    <div className="Congratulations">
      <Image className="Congratulations__icon" src={BonumIcon} />
      <p className="Congratulations__description">
        {t('pages.reschedule.components.noMoreSessions.title')}{' '}
        <strong>
          {name} {lastname}{' '}
        </strong>
        {t('pages.reschedule.components.noMoreSessions.description')}
      </p>
      <Button
        className="Button Button--primary Congratulations__button Congratulations__button--secondary"
        onClick={() => navigate('/')}
      >
        {t('pages.reschedule.components.noMoreSessions.button')}
      </Button>
    </div>
  );
}

export default NoMoreSessions;
