import { createRoot } from 'react-dom/client';
import './scss/global.scss';
import { ChakraProvider } from '@chakra-ui/provider';
import { extendTheme } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import store from './redux/store';
import App from './App';
import 'react-calendar/dist/Calendar.css';
import 'intecerptors/axios.interceptor';
import 'react-toastify/dist/ReactToastify.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';
import global_pt from './translations/pt/global.json';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const theme = extendTheme({
  colors: {
    bonumBlue: { 500: '#299eff' }
  }
});

const getLanguage = () => {
  const language = navigator.language.split('-')[0];
  if (language === 'es' || language === 'en' || language === 'pt') {
    return language;
  } else {
    return 'es';
  }
};

i18next.init({
  interpolation: { escapeValue: true },
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    },
    pt: {
      global: global_pt
    }
  },
  lng: getLanguage()
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <App />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </I18nextProvider>
      </BrowserRouter>
    </ChakraProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
