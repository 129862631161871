function Step(props) {
  const { step, index } = props;

  return (
    <div
      className={`NoAuthHome__how-it-works__steps_step ${
        index % 2 === 0 ? 'even' : 'odd'
      }`}
    >
      <div className="NoAuthHome__how-it-works__steps_step_number">
        <p>Paso</p>
        <span>{index + 1}</span>
      </div>
      <div className="NoAuthHome__how-it-works__steps_step-description">
        <h3>{step.title}</h3>
        <p>{step.description}</p>
      </div>
    </div>
  );
}

export default Step;
