import { Outlet } from 'react-router-dom';

function CoachEvaluation() {
  return (
    <div className="CoachEvaluation">
      <Outlet />
    </div>
  );
}

export default CoachEvaluation;
