import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import Evaluation from 'components/Evaluation';
import CoacheeSessions from '../CoacheeSessions';
import Comments from './components/Comments/Comments';
import { useTranslation } from 'react-i18next';

function CoacheeTabs({ coachee }) {
  const { t } = useTranslation('global');
  return (
    <Tabs isFitted isLazy={true} lazyBehavior="keepMounted">
      <TabList mb="1em">
        <Tab>{t('pages.myCoachees.components.tabSessions')}</Tab>
        <Tab>{t('pages.myCoachees.components.tabAuto')}</Tab>
        <Tab>{t('pages.myCoachees.components.tab360')}</Tab>
        <Tab>{t('pages.myEvaluations.tabInitialComments')}</Tab>
        <Tab>{t('pages.myEvaluations.tabFinalComments')}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <CoacheeSessions sessions={coachee.sessions} />
        </TabPanel>
        <TabPanel>
          <Evaluation
            title={t('pages.myCoachees.components.tabAuto')}
            type="autoevaluation"
            coachee={coachee}
          />
        </TabPanel>
        <TabPanel>
          <Evaluation
            title={t('pages.myCoachees.components.tab360s')}
            type="evaluation360"
            coachee={coachee}
          />
        </TabPanel>
        <TabPanel>
          <Comments coachee={coachee._id} type="initial" />
        </TabPanel>
        <TabPanel>
          <Comments coachee={coachee._id} type="final" />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default CoacheeTabs;
