import React, { useEffect } from 'react';
import { Button, Image } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCoach } from 'redux/slices/user';
import CongratulationsIcon from 'assets/images/icons/thumbs-up.png';
import { useUserUtilities } from 'hooks';
import { useTranslation } from 'react-i18next';

function Congratulations(props) {
  const { coach } = props;
  const dispatch = useDispatch();
  const { refreshUser } = useUserUtilities();
  const { t } = useTranslation('global');

  useEffect(() => {
    dispatch(setCoach(coach));
    refreshUser();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="Congratulations">
      <Image className="Congratulations__icon" src={CongratulationsIcon} />
      <p className="Congratulations__description">
        {t('pages.onboarding.components.congratulations.title')}{' '}
        <strong>
          {coach.name} {coach.lastname}
        </strong>{' '}
        {t('pages.onboarding.components.congratulations.description')}{' '}
      </p>
      <Button
        className="Button Button--primary Congratulations__button"
        onClick={() => navigate('/schedule')}
      >
        {t('pages.onboarding.components.congratulations.button1')}
      </Button>
      <Button
        className="Button Button--primary Congratulations__button Congratulations__button--secondary"
        onClick={() => navigate('/')}
      >
        {t('pages.onboarding.components.congratulations.button2')}
      </Button>
    </div>
  );
}

export default Congratulations;
