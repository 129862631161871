import { Button, FormControl, FormLabel } from '@chakra-ui/react';
import Modal from 'components/Modal';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useFetchAndLoad, useUserUtilities } from 'hooks';
import {
  cancelSession,
  cancelSessionByCoach,
  EndSession,
  updateSession,
  updateSessionNoShow
} from 'services/sessions.service';
import { updateCoachee, updateNoShowAcc } from 'services/user.service';
import displayToast from 'utilities/toast.utility';
import { useDispatch, useSelector } from 'react-redux';
import { modifySession } from 'redux/slices/session';
import adaptedSession from 'adapters/sessionsAdapter.adapter';
import { useNavigate } from 'react-router-dom';
import { endByAlternatecall } from 'utilities/alternateCall.utility';
import { Input } from 'antd';

function ModalCloseSession({
  session,
  showModal,
  setShowModal,
  getMyCoacheeSessions
}) {
  const [option, setOption] = useState('alternate');
  const { loading, callEndpoint } = useFetchAndLoad();
  const { mongoID } = useSelector((state) => state.user);
  const { refreshSessions } = useUserUtilities();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cancelReason, setCancelReason] = useState('');

  const handleCloseSession = async () => {
    try {
      if (option === 'alternate') {
        await callEndpoint(EndSession({ _id: session._id || session.id }));

        endByAlternatecall(
          { ...session, status: true },
          mongoID,
          session?.coachee?._id
        );
        dispatch(modifySession(adaptedSession({ ...session, status: true })));
        navigate('/sessionevaluation');
      }

      if (option === 'canceled') {
        console.log(session);
        await callEndpoint(
          cancelSession(adaptedSession({ ...session, noShow: true }))
        );
      }

      if (option === 'coach_cancel') {
        // Validación de cancelReason
        if (!cancelReason.trim()) {
          displayToast(
            'Por favor, especifique la razón de la cancelación.',
            'error'
          );
          return;
        }

        // Copia de session con la cancelReason añadida
        const updatedSession = {
          ...session,
          cancelReason: cancelReason
        };

        await callEndpoint(cancelSessionByCoach(updatedSession));
      }

      await refreshSessions();

      if (getMyCoacheeSessions) {
        getMyCoacheeSessions();
      }

      displayToast('La sessión fue cerrada correctamente', 'success');
      setShowModal(false);
    } catch (error) {
      displayToast('Hubo un error al tratar de cerrar la llamada', 'error');
    }
  };

  // const reschedule = (id) => navigate(`/reschedule/${id}`);

  return (
    <Modal
      content={
        <ModalContent
          option={option}
          setOption={setOption}
          setCancelReason={setCancelReason}
        />
      }
      isOpen={showModal}
      title={'¿Cuál es el mótivo de cerrar la sesión?'}
      onClose={() => setShowModal(false)}
      size="4xl"
      footer={
        <ModalFooter
          setShowModal={setShowModal}
          handleCloseSession={handleCloseSession}
          loading={loading}
        />
      }
      closeText="Cerrar"
    />
  );
}

function ModalContent({ option, setOption, setCancelReason, cancelReason }) {
  return (
    <div className="Assign__modal">
      <RadioGroup value={option} onChange={setOption}>
        <Stack direction={'column'} defaultValue={'alternate'}>
          <Radio value="alternate" size={'lg'}>
            La llamada se hizo por llamada alterna
          </Radio>
          <Radio value="canceled" size={'lg'}>
            El Coachee no se presentó
          </Radio>

          <Radio value="coach_cancel" size={'lg'}>
            No podré asistir a la sesión
          </Radio>
          {/* <Radio value="other">Otra</Radio> */}
        </Stack>
      </RadioGroup>
      {option === 'coach_cancel' && (
        <FormControl mt={4} isRequired>
          <FormLabel>Especifique la razón de la cancelación:</FormLabel>
          <Input
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            placeholder="Ingrese la razón"
          />
        </FormControl>
      )}
    </div>
  );
}

function ModalFooter({ loading, handleCloseSession }) {
  return (
    <Button
      onClick={() => handleCloseSession()}
      alignSelf="center"
      className="Button Button--primary "
      isLoading={loading}
      isDisabled={loading}
      style={{ marginLeft: '2em' }}
    >
      Cerrar Sesión
    </Button>
  );
}

export default ModalCloseSession;
