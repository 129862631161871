import { Button, Image } from '@chakra-ui/react';
import iphone from 'assets/images/iphone13.png';
import women from 'assets/images/women.png';
import Loading from 'components/Loading';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Step from './components/Step';

function NoAuthHome() {
  const navigate = useNavigate();
  const { userLoading } = useSelector((state) => state.user);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const coachSteps = [
    {
      title: 'Aprueba como coach',
      description:
        'Bonum Coaching selecciona a los mejores coaches coaches a través de un proceso de selección riguroso, garantizando que tus sesiones sean las mejores.'
    },
    {
      title: 'Realiza el onboarding',
      description:
        'Entra a nuestra app o desde la web e introduce tus datos para que tus futuros coachees conozcan tus fortalezas y puedas ayudarlos de la mejor manera.'
    },
    {
      title: 'Acceso a tu calendario',
      description:
        'Necesitaremos el acceso a tu calendario para ver tu horario disponible y evitar que tengas reuniones sobrepuestas o en tus días ocupados.'
    }
  ];

  const coacheeSteps = [
    {
      title: 'Se seleccionado por tu empresa',
      description:
        'Tu empresa debe comunicarse con Bonum Coaching para crear un convenio y puedas acceder a nuestra plataforma.'
    },
    {
      title: 'Realiza el onboarding',
      description:
        'Entra a nuestra app o desde la web e introduce tus datos para que podamos escoger a los mejores coach para ti y tus areas de foco.'
    },
    {
      title: 'Escoge tu coach',
      description: 'Elige al coach de tu preferencia y agenda tus sesiones.'
    }
  ];

  if (userLoading) return <Loading />;

  return (
    <div className="NoAuthHome">
      <div className="NoAuthHome__hero">
        <div className="NoAuthHome__hero_description">
          <h1>
            La plataforma de coaching que acelera el crecimiento de personas y
            organizaciones
          </h1>
          <p>
            Ofrecemos programas de coaching digitales personalizados, escalables
            y que se adaptan a los objetivos de las empresas.
          </p>

          <Button
            className="Button Button--primary NoAuthHome__hero_description_button"
            mt={'2em'}
            onClick={handleLoginClick}
          >
            Iniciar Sesión
          </Button>
        </div>

        <div className="NoAuthHome__hero__images">
          <Image src={iphone} className="NoAuthHome__hero__images_iphone" />
          <Image src={women} className="NoAuthHome__hero__images_women" />
        </div>
      </div>

      <div className="NoAuthHome__how-it-works Coach">
        <h1 className="NoAuthHome__how-it-works__title">
          Soy Coach, <br /> ¿Cómo funciona?
        </h1>
        <div className="NoAuthHome__how-it-works__steps">
          {coachSteps.map((step, index) => (
            <Step step={step} index={index} key={`CoachStep__${index}`} />
          ))}
        </div>
      </div>

      <div className="NoAuthHome__how-it-works">
        <div className="NoAuthHome__how-it-works__steps">
          {coacheeSteps.map((step, index) => (
            <Step step={step} index={index} key={`CoacheeStep__${index}`} />
          ))}
        </div>

        <h1 className="NoAuthHome__how-it-works__title">
          Soy Coachee, <br /> ¿Cómo funciona?
        </h1>
      </div>
    </div>
  );
}

export default NoAuthHome;
