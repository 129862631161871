import { useContext } from 'react';
import MySessionsContext from 'pages/MySessions/context/MySessionsContext';
import NoData from 'components/NoData/NoData';
import { useTranslation } from 'react-i18next';
import TreatedsSubjectsItems from './TreatedsSubjectsItems';

function TreatedSubjects() {
  const { selectedSession } = useContext(MySessionsContext);
  const { t } = useTranslation('global');

  return selectedSession.pointsSession.length > 0 ? (
    <div>
      <div className="flex justify-center items-center">
        <h4>{t('pages.myCoachees.components.tabSubject')}</h4>
      </div>
      {selectedSession.pointsSession.map((value, i) => (
        <TreatedsSubjectsItems
          key={i}
          value={value}
          title={t('pages.myCoachees.components.tabSubject')}
        />
      ))}
    </div>
  ) : null;
}

export default TreatedSubjects;
