import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Necesitamos tu nombre').min(2, 'Deben ser minimo 2 caracteres'),
  surname: Yup.string().required('Necesitamos tu apellido').min(2, 'Deben ser minimo 2 caracteres'),
  charge: Yup.string().required('Necesitamos tu cargo').min(3, 'Deben ser minimo 3 caracteres'),
  department: Yup.string()
    .required('Necesitamos saber tu departamento')
    .min(4, 'Deben ser minimo 4 caracteres')
});

export default validationSchema;
