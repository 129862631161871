import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import { updateCoachOnboarding } from 'services/coach.service';
import { Steps, useSteps } from './components/Steps';
import useCoachStepsValidation from './hooks/useCoachStepsValidation';
import useCoacheeStepsValidation from './hooks/useCoacheeStepsValidation';
import { useUserUtilities } from 'hooks';
import {
  AboutYouCoach,
  UploadVideo,
  CreateYourAccount,
  UploadPhoto,
  FocusAreas,
  Evaluation360,
  ChooseCoach,
  Arrows,
  WorkSchedule,
  Languages
} from './components';
import PickTimezone from './components/PickTimezone';
import { saveUserWorkingHours } from 'services/calendar.service';
import { useTranslation } from 'react-i18next';

function Onboarding() {
  const user = useSelector((state) => state.user);
  const onboarding = useSelector((state) => state.onboarding);
  const coacheeValidation = useCoacheeStepsValidation();
  const coachValidation = useCoachStepsValidation();
  const [showArrows, setShowArrows] = useState(true);
  const [disableArrows, setDisableArrows] = useState(false);
  const { t } = useTranslation('global');
  const { refreshUser } = useUserUtilities(user);

  const coachSteps = [
    { step: 0, label: 'Crea tu Cuenta', content: CreateYourAccount },
    { step: 1, label: 'Muestranos Tu Sonrisa', content: UploadPhoto },
    {
      step: 2,
      label: 'Cuéntanos acerca de ti',
      content: AboutYouCoach
    },
    {
      step: 3,
      label: 'Compartenos un video de presentación',
      content: UploadVideo
    },
    { step: 4, label: 'Selecciona tu zona horaria', content: PickTimezone },
    { step: 5, label: 'Selecciona', content: Languages },
    { step: 6, label: 'Selecciona hasta 3 areas de foco', content: FocusAreas },
    {
      step: 7,
      label: 'Selecciona tu horario de trabajo',
      content: WorkSchedule
    }
  ];

  const coacheeSteps = [
    { step: 0, label: 'Crea tu cuenta', content: CreateYourAccount },
    { step: 1, label: 'Muestranos tu sonrisa', content: UploadPhoto },
    { step: 2, label: 'Selecciona tu zona horaria', content: PickTimezone },
    { step: 3, label: 'Selecciona tus idiomas preferidos', content: Languages },
    {
      step: 4,
      label: 'Dejanos Saber quienes te acompañaran en el camino',
      content: Evaluation360
    },
    { step: 5, label: 'Selecciona hasta 3 areas de foco', content: FocusAreas },
    {
      step: 6,
      label: '',
      content: ChooseCoach
    }
  ];

  const [modal, setModal] = useState(false);
  const [errorsContent, setErrorsContent] = useState(null);
  const navigate = useNavigate();
  const { callEndpoint } = useFetchAndLoad();

  const updateCoach = async () => {
    try {
      await callEndpoint(updateCoachOnboarding(onboarding, user));
      await callEndpoint(
        saveUserWorkingHours(onboarding.workSchedule, user.mongoID)
      );
      await refreshUser();
      navigate('/connectcalendar');
    } catch (error) {
      console.log(error);
    }
  };

  const { nextStep, prevStep, activeStep, errors, barActive, activeBarStep } =
    useSteps({
      initialStep: 0,
      stepsCount:
        user.role === 'coach' ? coachSteps.length : coacheeSteps.length,
      activeBarStep: user.role === 'coach' ? 90 : 3,
      validationStep: user.role === 'coach' ? 7 : coacheeSteps.length - 2,
      userRole: user.role,
      validation: user.role === 'coach' ? coachValidation : coacheeValidation,
      onLastStep: user.role === 'coach' ? updateCoach : null
    });

  useEffect(() => {
    if (errors) {
      setModal(true);
      const content = errors.map((FormError) => {
        const p = FormError.errors.map((error) => (
          <li className="Onboarding__error_description" key={error}>
            {error}
          </li>
        ));
        return [
          <h4 className="Onboarding__error_title" key={FormError.title}>
            {FormError.title}
          </h4>,
          <ul className="Onboarding__error_list" key={p}>
            {p}
          </ul>
        ];
      });
      setErrorsContent(content);
    }
  }, [errors]);

  useEffect(() => {
    if (user.onboardingCompleted) navigate('/');
  }, []);

  const onClose = () => setModal(false);

  return (
    <div className="Onboarding">
      <Modal
        isOpen={modal}
        onClose={onClose}
        content={errorsContent}
        title={t('pages.onboarding.title')}
        closeText="Close"
      />
      <Steps
        steps={user.role === 'coach' ? coachSteps : coacheeSteps}
        activeStep={activeStep}
        nextStep={nextStep}
        barActive={barActive}
        prevStep={prevStep}
        activeBarStep={activeBarStep}
        setShowArrows={setShowArrows}
        setDisableArrows={setDisableArrows}
      />
      {activeStep > 0 && showArrows && (
        <Arrows
          prevStep={prevStep}
          nextStep={nextStep}
          disableArrows={disableArrows}
        />
      )}
    </div>
  );
}

export default Onboarding;
