import React, { useEffect } from 'react';
// import { Checkbox, Switch } from '@chakra-ui/react';
import { dateToLongDate } from 'utilities/formatDate.utility';
import { useSelector } from 'react-redux';
import useScheduleContext from '../../hooks/useScheduleContext';
import { useUserUtilities } from 'hooks';
import { useTranslation } from 'react-i18next';

function Scheduled() {
  const { date, hour } = useScheduleContext();
  const user = useSelector((state) => state.user);
  const { name, lastname } = user;
  const { refreshSessions } = useUserUtilities(user);
  const { t } = useTranslation('global');

  useEffect(() => {
    refreshSessions();
  }, []);

  return (
    <div className="Scheduled Card">
      <h3 className="Scheduled__name">
        {t('pages.reschedule.components.scheduled.name', {
          name: name,
          lastname: lastname
        })}
      </h3>
      <p className="Scheduled__subtitle">
        {t('pages.reschedule.components.scheduled.subtitle')}
      </p>
      <h2 className="Scheduled__date">{dateToLongDate(date)}</h2>
      <p className="Scheduled__hour">{hour.time}</p>
      {/* <div className="Scheduled__calendar-schedule">
        <p className="Scheduled__calendar-schedule_description">
          Agendar en tu calendario
        </p>
        <Switch className="Scheduled__calendar-schedule_switch" />
      </div> */}
      <div className="Scheduled__remember">
        <p className="Scheduled__remember_advice">
          {t('pages.reschedule.components.scheduled.remember')}
        </p>

        <p className="Scheduled__remember_advice">
          {t('pages.reschedule.components.scheduled.remember2')}
        </p>

        <p className="Scheduled__remember_advice_bold">
          {t('pages.reschedule.components.scheduled.rememberBold')}
        </p>
        {/* <div className="Scheduled__remember_one-day">
          <Checkbox />
          <p>Un día antes</p>
        </div>
        <div className="Scheduled__remember_one-hour">
          <Checkbox />
          <p>Una hora antes</p>
        </div> */}
      </div>
    </div>
  );
}

export default Scheduled;
