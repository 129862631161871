import React, { useState } from 'react';
import { Button, Input, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { auth } from 'utilities/firebase.utility';
import { sendPasswordResetEmail } from 'firebase/auth';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';

function ForgotPassword({ setLogin }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('global');

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Debe ser un correo válido')
      .required('El email es requerido')
  });

  const initialValues = () => ({
    email: ''
  });

  const { handleSubmit, handleChange, values, errors, handleBlur, touched } =
    useFormik({
      initialValues: initialValues(),
      validationSchema,
      onSubmit: async ({ email }) => {
        try {
          setLoading(true);
          await sendPasswordResetEmail(auth, email);
          displayToast(
            'Enviamos un correo con el enlace para que reestablezcas tu contraseña',
            'success'
          );
          setLoading(false);
        } catch (error) {
          setLoading(false);
          displayToast('Ups! tenemos un error, contacta a soporte', 'error');
        }
      }
    });

  return (
    <div className="Auth">
      <div className="Auth__hint">
        <h2>{t('components.auth.forgotPassword.title')}</h2>
        <p>{t('components.auth.forgotPassword.description')}</p>
      </div>
      <form className="Auth__card Card" onSubmit={handleSubmit}>
        <h2 className="Auth__title">
          {t('components.auth.forgotPassword.title2')}
        </h2>
        <FormControl isInvalid={errors?.email && touched.email}>
          <Input
            name="email"
            className="Auth__input"
            placeholder={t('components.auth.forgotPassword.placeholder')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {errors?.email && touched.email ? (
            <FormErrorMessage fontSize="lg">{errors.email}</FormErrorMessage>
          ) : null}
        </FormControl>

        <Button
          className="Auth__button"
          type="submit"
          isLoading={loading}
          isDisabled={loading}
        >
          {t('components.auth.forgotPassword.button')}
        </Button>
        <p className="Auth__question">
          {t('components.auth.forgotPassword.remember')}{' '}
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() => setLogin(true)}
            onClick={() => setLogin(true)}
          >
            {t('components.auth.forgotPassword.button2')}
          </span>
        </p>
      </form>
    </div>
  );
}

export default ForgotPassword;
