import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useFetchAndLoad } from 'hooks';
import { getCoachAvailability } from 'services/calendar.service';
import displayToast from 'utilities/toast.utility';
import Loading from 'components/Loading';
import { compact, map } from 'lodash';
import { DateTime } from 'luxon';
import useScheduleContext from '../../hooks/useRescheduleContext';
import { useTranslation } from 'react-i18next';
import NoData from 'components/NoData/NoData';

function AvailableSchedule({
  handleSchedule,
  isLoadingSchedule,
  isDisableSchedule
}) {
  const { loading, callEndpoint } = useFetchAndLoad();
  const [schedule, setSchedule] = useState([]);
  const { coach, timezone } = useSelector((state) => state.user);
  const { setHour, hour, date } = useScheduleContext();
  const { t } = useTranslation('global');

  const getHours = (workHours) => {
    const hours = map(workHours, (unformattedHour, index) => {
      const start = DateTime.fromISO(unformattedHour).setZone(timezone);
      const today = DateTime.now().plus({ hours: 2 }).setZone(timezone);
      if (start < today) return false;
      const finish = start.plus({ hours: 1 });
      return {
        id: index,
        time: `${start.toFormat('hh:mma')} - ${finish.toFormat('hh:mma')}`,
        startHour: start
      };
    });

    return compact(hours);
  };

  const errorCoach = t(
    'pages.reschedule.components.availableSchedule.errorCoach'
  );

  const coachAvailability = async () => {
    try {
      const response = await callEndpoint(
        getCoachAvailability(date, coach._id)
      );
      const workHours = response.data.data;
      setSchedule(getHours(workHours));
    } catch (error) {
      console.log(error);
      displayToast(errorCoach, 'error');
    }
  };

  useEffect(() => {
    if (date) coachAvailability();
  }, [date]);

  if (loading)
    return (
      <Loading
        title={t('pages.reschedule.components.availableSchedule.loading')}
      />
    );

  return (
    <>
      {schedule.length !== 0 && <h4>Horario disponible</h4>}
      <div className="AvailableSchedule">
        {schedule.map((scheduleObj) => (
          <Button
            key={scheduleObj.id}
            className={`Button AvailableSchedule__button ${
              hour?.time === scheduleObj?.time
                ? 'AvailableSchedule__button--selected'
                : ''
            }`}
            onClick={() => setHour(scheduleObj)}
          >
            {scheduleObj.time}
          </Button>
        ))}
      </div>
      {schedule.length !== 0 && (
        <Button
          onClick={handleSchedule}
          className="Button Button--primary CoacheeCalendar__button"
          isLoading={isLoadingSchedule}
          isDisabled={isDisableSchedule}
        >
          Agendar
        </Button>
      )}

      {schedule.length < 1 && date && (
        <NoData title="No hay horarios disponibles" />
      )}
    </>
  );
}

export default AvailableSchedule;
