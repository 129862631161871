import { ConversationList } from '@chatscope/chat-ui-kit-react';
import { find, forEach } from 'lodash';
import { useSelector } from 'react-redux';
import Conversation from './Contact';
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  setDoc
} from '@firebase/firestore';
import { firestore } from 'utilities/firebase.utility';
import { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import useChatContext from '../hooks/useChatContext';

function Contacts() {
  const { coachees, role, coach, email } = useSelector((state) => state.user);
  const isCoach = role === 'coach';
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);

  //Chat Context
  const { contacts, setContacts, handleNewMessage } = useChatContext();

  const chatsQuery = query(
    collection(firestore, 'rooms'),
    where('participantsArray', 'array-contains', email || '')
  );

  useEffect(() => {
    const unsubscribe = onSnapshot(chatsQuery, (querySnapshot) => {
      const [roomChange] = querySnapshot
        .docChanges()
        .filter(({ type }) => type === 'modified')
        .map(({ doc }) => {
          const message = doc.data();
          return { ...message, id: doc.id };
        });

      if (roomChange?.lastMessage?.unread) {
        handleNewMessage(roomChange);
      }

      const parsedChats = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        userB: doc.data().participants.find((p) => p.email !== email),
        lastMessage: doc.data().lastMessage
      }));
      setRooms(parsedChats);
    });
    return () => unsubscribe();
  }, []);

  const getCoachContacts = (roomsAux) => {
    if (rooms.length < 1) {
      setContacts(coachees);
      setLoading(false);
      return;
    }

    const coachContacts = [];

    forEach(coachees, (coachee) => {
      const room = find(
        roomsAux,
        (room) => room?.userB?.email === coachee?.email
      );

      if (room) {
        coachContacts.push({
          ...coachee,
          roomId: room.id,
          lastMessage: room.lastMessage
        });
        return;
      }

      coachContacts.push(coachee);
    });

    setLoading(false);
    setContacts(coachContacts);
  };

  const getCoacheeContacts = (rooms) => {
    if (rooms.length < 1) {
      setContacts([coach]);
      setLoading(false);
      return;
    }

    const coacheeContacts = [
      { ...coach, roomId: rooms[0].id, lastMessage: rooms[0].lastMessage }
    ];
    setContacts(coacheeContacts);
    setLoading(false);
  };

  useEffect(() => {
    if (isCoach) {
      getCoachContacts(rooms);
      return;
    }

    getCoacheeContacts(rooms);
  }, [rooms]);

  return (
    <ConversationList>
      {!loading ? (
        contacts.map((contact) => (
          <Conversation user={contact} key={contact._id} />
        ))
      ) : (
        <Loading title={'Cargando contactos'} />
      )}
    </ConversationList>
  );
}

export default Contacts;
