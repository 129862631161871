import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import TreatedsSubjects from '../TreatedsSubjects';
import Assignations from '../Assignations';
import PrivateComments from '../PrivateComments';
import { useTranslation } from 'react-i18next';

function SessionTabs({ session }) {
  const { t } = useTranslation('global');
  const isNormalSession = session?.type ? false : true;
  return (
    <Tabs isFitted>
      <TabList mb="1em">
        <Tab>{t('pages.myCoachees.components.tabSubject')}</Tab>
        {isNormalSession && (
          <Tab>{t('pages.myCoachees.components.tabAssignation')}</Tab>
        )}

        <Tab>{t('pages.myCoachees.components.tabComments')}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <TreatedsSubjects />
        </TabPanel>

        {isNormalSession && (
          <TabPanel>
            <Assignations />
          </TabPanel>
        )}

        <TabPanel>
          <PrivateComments />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default SessionTabs;
