import userAdapted from 'adapters/user.adapter';
import { find, map, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { modifySessions, modifyUser } from 'redux/slices/user';
import {
  getAllAlignmentSessionCoach,
  getAllAlignmentSessionCoachee,
  getCoacheeSessions,
  getCoachSessions
} from 'services/sessions.service';
import { getUser } from 'services/user.service';
import displayToast from 'utilities/toast.utility';
import useFetchAndLoad from './useFetchAndLoad';
import oldSessionAdapted from 'adapters/oldAppSessions.adapter';

const useUserUtilities = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { loading: userUtilitiesLoading, callEndpoint } = useFetchAndLoad();

  const refreshUser = async () => {
    try {
      const userData = await callEndpoint(getUser(user.uid));
      if (userData) dispatch(modifyUser(userAdapted(userData.data)));
    } catch (error) {
      displayToast('Error refrescando el usuario', 'error');
    }
  };

  const refreshSessions = async () => {
    try {
      let sessions = [];

      if (user.role === 'coach') {
        const { data } = await callEndpoint(getCoachSessions(user.mongoID));
        const res = await callEndpoint(
          getAllAlignmentSessionCoach(user.mongoID)
        );
        sessions = [...res.data.data, ...data.data];
      } else {
        const { data } = await callEndpoint(getCoacheeSessions(user.mongoID));
        const res = await callEndpoint(
          getAllAlignmentSessionCoachee(user.mongoID)
        );

        sessions = [...res.data.data, ...data.data];
      }

      sessions = map(sessions, (session) => oldSessionAdapted(session));

      dispatch(modifySessions(sessions));

      return sessions;
    } catch (error) {
      dispatch(modifySessions([]));
      console.log(error);
    }
  };

  return { refreshUser, userUtilitiesLoading, refreshSessions };
};

export default useUserUtilities;
