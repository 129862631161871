import React, { useEffect, useRef } from 'react';
import { Img } from '@chakra-ui/react';

function RemoteVideoPlayer({
  expand,
  remoteVideoOn,
  remoteUserInfo,
  remoteStream
}) {
  const remoteVideoRef = useRef(null);

  useEffect(() => {
    if (remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  return (
    <div
      id="main"
      className={`flex-container ${!remoteVideoOn ? 'video-preview' : ''} `}
      style={{
        margin: 0,
        padding: 0,
        width: expand ? '100%' : '70rem',
        height: expand ? '100%' : '500px'
      }}
    >
      <video id="my-video" ref={remoteVideoRef} playsInline autoPlay />

      {!remoteVideoOn && (
        <div id="my-img">
          <Img src={remoteUserInfo.photo} className="image-preview" />
        </div>
      )}
    </div>
  );
}

export default RemoteVideoPlayer;
