import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Button, Img, Skeleton } from '@chakra-ui/react';
import {
  mongoDateToLongDate,
  mongoDateToTime,
  mongoDateToTimePlusOneHour
} from 'utilities/formatDate.utility';
import {
  updateSession,
  AlternalCall,
  EndSession
} from 'services/sessions.service';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import AcceptCall from 'assets/images/icons/llamar.png';
import {
  post,
  meetingExists,
  UpdateMeeting,
  getJitsiToken
} from 'services/meeting.service';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { modifySession } from 'redux/slices/session';
import { DateTime } from 'luxon';
import { filter } from 'lodash';
import adaptedSession from 'adapters/sessionsAdapter.adapter';
import displayToast from 'utilities/toast.utility';
import { io } from 'socket.io-client';
import {
  connectToAlternateCallSocket,
  makeAlternateCall
} from 'utilities/alternateCall.utility';
import ModalCloseSession from '../../../../components/ModalCloseSession';
import { useTranslation } from 'react-i18next';
import { useUserUtilities } from 'hooks';

function NextSession({ user, userStatus, parentNode }) {
  const socket = io(process.env.REACT_APP_STREAMING_URL, {
    transports: ['websocket']
  });

  const { refreshSessions, userUtilitiesLoading } = useUserUtilities();
  const navigate = useNavigate();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [nextSession, setNextSession] = useState(null);
  const [closeSessionModal, setCloseSessionModal] = useState(false);
  const session = useSelector((state) => state.session);

  const dispatch = useDispatch();

  const isCoach = user.role === 'coach';
  const isCoachee = user.role === 'coachee';
  const { t, i18n } = useTranslation('global');

  useEffect(() => {
    connectToAlternateCallSocket(user);
    getSessions();
  }, []);

  const getNextSession = async (allSessions) => {
    const sessions = filter(allSessions, { status: false, canceled: false });

    if (sessions.length < 1) {
      setNextSession(null);
      return null;
    }

    let closestSession = null;
    for (let i = 0; i < sessions.length; i++) {
      const session = sessions[i];
      const sessionDate = DateTime.fromISO(session?.date).toUnixInteger();
      const today = DateTime.now().minus({ hours: 12 }).toUnixInteger();
      const isLowerThanToday = sessionDate < today;

      if (!closestSession && !isLowerThanToday) {
        closestSession = session;
      } else if (!isLowerThanToday) {
        const closestSessionDate = DateTime.fromISO(
          closestSession?.date
        ).toUnixInteger();
        if (sessionDate < closestSessionDate) closestSession = session;
      }
    }

    setNextSession(closestSession);

    return closestSession;
  };

  const getSessions = async () => {
    try {
      const sessions = await refreshSessions();

      const session = await getNextSession(sessions);
      if (isCoachee && session) {
        socket.emit('connect-session', { room: session._id });
        socket.on('AcceptCallCoach', ({ session }) => {
          setNextSession(session);
        });
      }
    } catch (error) {
      displayToast('Error obteniendo sesiones', 'error');
    }
  };

  // const domain = 'meet.jit.si';
  const domain = 'bonum-meet.bonumcoaching.com';
  const api = useRef(null);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [durationInSeconds, setDurationInSeconds] = useState(0);
  const [meetingId, setMeetingId] = useState('');

  const startCallTimer = () => {
    setStartTime(new Date());
  };

  const endCallTimer = () => {
    setEndTime(new Date());
    const duration = Math.floor((endTime - startTime) / 1000); // Duración en segundos
    setDurationInSeconds(duration);
  };

  const handleClick = async (callId) => {
    if (parentNode.current) {
      // Agregar estilos al parentNode para que cubra toda la pantalla
      parentNode.current.style.position = 'fixed';
      parentNode.current.style.top = 0;
      parentNode.current.style.left = 0;
      parentNode.current.style.width = '100%';
      parentNode.current.style.height = '100%';
      parentNode.current.style.background = 'rgba(0, 0, 0, 0.5)'; // Cambia el color de fondo según tus preferencias
      parentNode.current.style.zIndex = 9999; // Asegura que el elemento esté por encima de otros elementos
      parentNode.current.style.display = 'flex';
      parentNode.current.style.justifyContent = 'center';
      parentNode.current.style.alignItems = 'center';

      const userData = {
        context: {
          user: {
            avatar: user.photo,
            email: user.email,
            name: `${user?.name} ${user?.lastname}`
          }
        },
        role: user.role,
        sub: 'bonum-meet.bonumcoaching.com',
        room: '*'
      };

      const { data } = await callEndpoint(getJitsiToken(userData));
      const token = data.data;

      const options = {
        roomName: `${callId}`,
        width: '100%', // Usar porcentaje para que ocupe todo el ancho del contenedor
        height: '100%', // Usar porcentaje para que ocupe todo el alto del contenedor
        parentNode: parentNode.current,
        lang: user?.languages[0],
        userInfo: {
          displayName: `${user?.name} ${user?.lastname}`,
          email: user.email,
          role: user?.role
        },
        configOverwrite: {
          enableClosePage: false, // Habilitar el cierre de la página al finalizar la llamada
          buttonsWithNotifyClick: ['hangup', 'hangup-menu'] // capturar eventos de boton de colgar
        },
        interfaceConfigOverwrite: { end_conference: false },
        jwt: token
      };

      api.current = new window.JitsiMeetExternalAPI(domain, options);

      api.current.addEventListener('videoConferenceJoined', () => {
        startCallTimer();
      });

      // Cuando se presiona el botón de colgar
      api.current.addEventListener('toolbarButtonClicked', (e) => {
        if (isCoachee) {
          displayToast('Solo tu coach puede cerrar la llamada', 'info');
          return;
        }

        const buttonPressed = e.key;

        if (buttonPressed === 'hangup-menu' || buttonPressed === 'hangup') {
          endCallTimer();
          endSession();
          const durationString = segundosAHMS(durationInSeconds);
          UpdateMeeting(meetingId, { Duration: durationString });
          api.current.executeCommand('endConference');
        }
      });

      api.current.addEventListener('readyToClose', () => {
        endSession();
        navigate('/sessionevaluation');
        displayToast('Sesión terminada con éxito', 'success');
      });
    }
  };

  const endSession = async () => {
    try {
      await callEndpoint(
        EndSession({
          _id: nextSession._id || nextSession.id,
          MeetingId: nextSession.callSession
          // coachPrivateComments: anotations
        })
      );

      // await callEndpoint(
      //   updateSessionNumber({
      //     id: nextSession._id || nextSession.id,
      //     coacheeId: nextSession?.coachee?._id
      //   })
      // );
    } catch (error) {
      console.log('Error al cerrar llamada', error);
    }
  };

  function segundosAHMS(durationInSeconds) {
    const horas = Math.floor(durationInSeconds / 3600);
    const minutos = Math.floor((durationInSeconds % 3600) / 60);
    const segundos = durationInSeconds % 60;

    return `${horas} horas, ${minutos} minutos, ${segundos} segundos`;
  }

  const minutesTolerance = () => {
    const currentTime = new Date();
    const sessionTime = new Date(nextSession?.date);
    const timeDifference = sessionTime.getTime() - currentTime.getTime();
    const minutesDifference = Math.ceil(timeDifference / (1000 * 60));

    if (minutesDifference <= 5) {
      // Si faltan 5 minutos o menos, se cumple la tolerancia
      return true;
    }

    // Si faltan más de 5 minutos, no se cumple la tolerancia
    displayToast(
      t('pages.home.components.nextSession.minutesTolerance'),
      'info'
    );
    return false;
  };

  const AcceptCallMethod = async (e) => {
    try {
      if (user && nextSession) {
        const { data: meetingExist } = await callEndpoint(
          meetingExists(
            nextSession.callSession ? nextSession.callSession : 'NOID'
          )
        );

        // Verificar la tolerancia de minutos antes de continuar
        const isWithinTolerance = minutesTolerance();
        if (!isWithinTolerance) {
          // Si no se cumple la tolerancia, salir de la función
          return;
        }

        if (nextSession.callSession && !meetingExist.data) {
          const { data } = await callEndpoint(
            post({
              IdCoachee: user.role === 'coachee' ? user.mongoID : '',
              IdCoach: user.role === 'coach' ? user.mongoID : user.coach._id,
              Date: new Date(),
              InProcess: true,
              IdSesion: nextSession._id,
              PointsSesions: nextSession.pointsSession
            })
          );
          const { data: session } = await callEndpoint(
            updateSession({
              _id: nextSession._id,
              callSession: data.data._id
            })
          );
          localStorage.setItem(
            'session',
            JSON.stringify(
              adaptedSession({
                ...nextSession,
                callSession: data.data._id
              })
            )
          );

          dispatch(
            modifySession(
              adaptedSession({
                ...nextSession,
                callSession: data.data._id
              })
            )
          );
          setMeetingId(data.data._id);
          // navigate(`/meeting/${data.data._id}`);
          handleClick(data.data._id);
        }
        if (user.role === 'coach' && !nextSession.callSession) {
          const { data } = await callEndpoint(
            post({
              IdCoachee: user.role === 'coachee' ? user.mongoID : '',
              IdCoach: user.role === 'coach' ? user.mongoID : user.coach._id,
              Date: new Date(),
              InProcess: true,
              IdSesion: nextSession._id,
              PointsSesions: nextSession.pointsSession
            })
          );
          const { data: session } = await callEndpoint(
            updateSession({ _id: nextSession._id, callSession: data.data._id })
          );
          localStorage.setItem(
            'session',
            JSON.stringify(
              adaptedSession({
                ...nextSession,
                callSession: data.data._id
              })
            )
          );
          socket.emit('CallAcceptCallCoach', {
            session: { ...nextSession, callSession: data.data._id },
            room: session.data._id
          });
          dispatch(
            modifySession(
              adaptedSession({
                ...nextSession,
                callSession: data.data._id
              })
            )
          );
          setMeetingId(data.data._id);
          // navigate(`/meeting/${data.data._id}`);
          handleClick(data.data._id);
        } else if (
          user &&
          user.role === 'coachee' &&
          !nextSession.callSession &&
          nextSession.coach
        ) {
          displayToast(
            t('pages.home.components.nextSession.await', {
              name: nextSession.coach.name,
              lastname: nextSession.coach.lastname
            }),
            'info'
          );
          getSessions();
        } else if (nextSession.callSession) {
          dispatch(
            modifySession(
              adaptedSession({
                ...nextSession,
                callSession: nextSession.callSession
              })
            )
          );
          localStorage.setItem(
            'session',
            JSON.stringify(
              adaptedSession({
                ...nextSession,
                callSession: nextSession.callSession
              })
            )
          );
          setMeetingId(nextSession.callSession);
          // navigate(`/meeting/${nextSession.callSession}`);
          handleClick(nextSession.callSession);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const callAlternal = async () => {
    makeAlternateCall(user, nextSession);
    if (user?.alternateCall) {
      await callEndpoint(
        AlternalCall({
          sessionId: nextSession._id,
          userId: nextSession?.coachee?._id
            ? nextSession?.coachee?._id
            : nextSession?.coachee,
          AlternalCallLink: user?.alternateCall
        })
      );
    }
  };

  useEffect(() => {}, [nextSession]);
  useEffect(() => {
    getNextSession(user.sessions);
  }, [user.sessions]);

  if (!nextSession && !userUtilitiesLoading)
    return (
      <div className="Home__next_session">
        <p className="Home__next_session_title">
          {t('pages.home.components.nextSession.title')}
        </p>
        <h3>{t('pages.home.components.nextSession.subtitle')}</h3>
        {!isCoach && userStatus && (
          <Button
            className="Button Button--primary"
            mt={'1em'}
            onClick={() => navigate('/mycalendar')}
          >
            {t('pages.home.components.nextSession.schedule')}
          </Button>
        )}
      </div>
    );

  return (
    <div className="Home__next_session">
      <ModalCloseSession
        session={nextSession}
        showModal={closeSessionModal}
        setShowModal={setCloseSessionModal}
      />
      <p className="Home__next_session_title">
        {nextSession?.type !== 'initial' && nextSession?.type !== 'final'
          ? t('pages.home.components.nextSession.title')
          : t('pages.home.components.nextSession.alignSession')}
      </p>
      <Skeleton
        isLoaded={!userUtilitiesLoading}
        fadeDuration={2}
        borderRadius={10}
      >
        <h3 className="Home__next_session_date">
          {mongoDateToLongDate({
            unformatedDate: nextSession?.date,
            language: i18n.language
          })}
        </h3>
      </Skeleton>
      <Skeleton
        isLoaded={!userUtilitiesLoading}
        mt={1}
        fadeDuration={3}
        borderRadius={10}
      >
        <p className="Home__next_session_hour">
          {mongoDateToTime(nextSession?.date)} -{' '}
          {mongoDateToTimePlusOneHour(nextSession?.date)}
        </p>
      </Skeleton>
      <div className="Home__next_session_with">
        <div className="Home__next_session_with_user w-100">
          <div className="Home__next_session_with_user_buttons">
            <p className="Home__next_session_with_user_role">
              {isCoach ? 'Coachee' : 'Coach'}
            </p>
            <div className="flex btns-calls">
              {isCoach && (
                <div className="Home__next_session_alternate-call-buttons">
                  <Button
                    variant={'link'}
                    onClick={callAlternal}
                    fontSize="small"
                    textDecoration={'underline'}
                  >
                    {t('pages.home.components.nextSession.alternateCall')}
                  </Button>

                  <Button
                    variant={'link'}
                    onClick={() => setCloseSessionModal(true)}
                    fontSize="small"
                    textDecoration={'underline'}
                  >
                    {t('pages.home.components.nextSession.signOff')}
                  </Button>
                </div>
              )}
              <Img src={AcceptCall} onClick={AcceptCallMethod} width={50} />

              {/* <div ref={parentNode}></div> */}
            </div>
          </div>
          <div className="flex justify-between w-100">
            {isCoach ? (
              <div className="Home__next_session_with_user_info">
                <Avatar
                  name={`${nextSession?.coachee?.name} ${nextSession?.coachee?.lastname}`}
                  src={nextSession?.coachee?.urlImgCoachee}
                  className="Home__next_session_with_user_info_avatar"
                />
                <Skeleton
                  isLoaded={!userUtilitiesLoading}
                  mt={1}
                  fadeDuration={3}
                  borderRadius={10}
                >
                  <p className="Home__next_session_with_user_info_name">
                    {nextSession?.coachee?.name}{' '}
                    {nextSession?.coachee?.lastname}
                  </p>
                </Skeleton>
              </div>
            ) : (
              <div className="Home__next_session_with_user_info">
                <Avatar
                  name={`${nextSession?.coach?.name} ${nextSession?.coach?.lastname}`}
                  src={nextSession?.coach?.urlImgCoach}
                  className="Home__next_session_with_user_info_avatar"
                />
                <Skeleton
                  isLoaded={!userUtilitiesLoading}
                  mt={1}
                  fadeDuration={3}
                  borderRadius={10}
                >
                  <p className="Home__next_session_with_user_info_name">
                    {nextSession?.coach?.name} {nextSession?.coach?.lastname}
                  </p>
                </Skeleton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NextSession;
