import { createContext } from 'react';

const SessionEvaluationContext = createContext({
  selectedRating: null,
  selectedText: null,
  questionsCoach: [],
  questionsCoachee: [],
  setQuestionsCoachee: () => null,
  setQuestionsCoach: () => null,
  setSelectedRating: () => null,
  setSelectedText: () => null
});

export default SessionEvaluationContext;
