import React from 'react';
import ReactCalendar from 'react-calendar';

function Calendar({
  value,
  setDate,
  tileDisabled,
  className = '',
  tileClassName,
  minDate
}) {
  const formatDayNames = (date) => date.toString().substring(0, 3);

  return (
    <ReactCalendar
      className={`Calendar ${className}`}
      value={value}
      minDate={minDate}
      tileDisabled={tileDisabled}
      onChange={setDate}
      tileClassName={tileClassName}
      calendarType="US"
      showNeighboringMonth={false}
      formatShortWeekday={(locale, date) => formatDayNames(date)}
    />
  );
}

export default Calendar;
