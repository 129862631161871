import React, { useContext } from 'react';
import Assignation from 'components/Assignation';
import NoData from 'components/NoData/NoData';
import { size } from 'lodash';
import MySessionsContext from 'pages/MySessions/context/MySessionsContext';
import displayToast from 'utilities/toast.utility';
import { useFetchAndLoad } from 'hooks';
import { editAssignation } from 'services/assignations.service';
import { useTranslation } from 'react-i18next';
import AssignationsItem from './AssignationsItems/AssignationsItem';

function Assignations({ completed = false }) {
  const { selectedSession, setSelectedSession } = useContext(MySessionsContext);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { t } = useTranslation('global');
  const errorMessage = t('pages.mySessions.components.assignations.error');

  const handleChange = async (changedAssignation) => {
    try {
      await callEndpoint(
        editAssignation({
          ...changedAssignation,
          id: changedAssignation._id,
          status: !changedAssignation.status
        })
      );
      const newAssignations = selectedSession.assignations.map(
        (newAssignation) => {
          if (newAssignation._id !== changedAssignation._id)
            return newAssignation;
          return {
            ...changedAssignation,
            status: !changedAssignation.status
          };
        }
      );
      setSelectedSession({ ...selectedSession, assignations: newAssignations });
    } catch (error) {
      displayToast(errorMessage, 'error');
    }
  };

  const hasAssignations = selectedSession.assignations.some(
    (assignation) => completed === assignation.status
  );

  return (
    <div>
      <div className="flex justify-center items-center">
        {hasAssignations ? (
          <div
            style={{ marginTop: '1.5em' }}
            className="flex justify-center items-center"
          >
            <h4>{t('pages.myAssignations.title')}</h4>
          </div>
        ) : (
          <NoData
            title={`${t('pages.mySessions.components.assignations.noData')}`}
          />
        )}
      </div>
      {selectedSession.assignations.map((assignation) => {
        if (completed && assignation.status) {
          return (
            <AssignationsItem
              key={`session${assignation?.session}${assignation?.id}`}
              value={assignation}
              handleChange={handleChange}
            />
          );
        }

        if (!completed && !assignation.status) {
          return (
            <AssignationsItem
              key={`session${assignation?.session}${assignation?.id}`}
              value={assignation}
              handleChange={handleChange}
            />
          );
        }
      })}
    </div>
  );
}

export default Assignations;
