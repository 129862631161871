import React from 'react';
import { useTranslation } from 'react-i18next';

function Advice(props) {
  const {
    executed = true,
    future = true,
    nonWorking = true,
    blocked = true
  } = props;
  const { t } = useTranslation('global');
  return (
    <div className="Advice__advice">
      <div className="CoachCalendar__advice">
        {executed && (
          <p className="CoachCalendar__advice__message CoachCalendar__advice__message--executed">
            {t('pages.coacheeCalendar.executed')}
          </p>
        )}

        {future && (
          <p className="CoachCalendar__advice__message CoachCalendar__advice__message--future">
            {t('pages.coacheeCalendar.future')}
          </p>
        )}

        {nonWorking && (
          <p className="CoachCalendar__advice__message CoachCalendar__advice__message--nonworking">
            {t('pages.reschedule.components.advice.noWorking')}
          </p>
        )}

        {blocked && (
          <p className="CoachCalendar__advice__message CoachCalendar__advice__message--blocked">
            {t('pages.coacheeCalendar.blocked')}
          </p>
        )}
      </div>

      <p className="Advice__legend">
        Para reagendar una cita, podrás reagendarla 5 días despúes de la última
        sesión, y 5 días antes de tu próxima sesión
      </p>
    </div>
  );
}

export default Advice;
