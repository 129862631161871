import { Button, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import es from 'assets/images/icons/es.svg';
import br from 'assets/images/icons/br.svg';
import en from 'assets/images/icons/us.svg';
import Languages from './components/Languages';
import { useTranslation } from 'react-i18next';
import PickTimezone from './components/PickTimezone';
import { useState } from 'react';
import { useFetchAndLoad, useUserUtilities } from 'hooks';
import { updateUserLanguagesAndTimezone } from 'services/user.service';
import displayToast from 'utilities/toast.utility';

function UpdateLanguages() {
  const { role, mongoID } = useSelector((state) => state.user);
  const isCoach = role === 'coach';
  const { t } = useTranslation('global');
  const [values, setValues] = useState({ timezone: '' });
  const [errors, setErrors] = useState({});
  const { loading, callEndpoint } = useFetchAndLoad();
  const { refreshUser, userUtilitiesLoading } = useUserUtilities();

  const handleSubmit = async () => {
    if (!values.languages || values.languages.length < 1) {
      setErrors({ languages: 'Debes seleccionar al menos un lenguaje' });
      return;
    }

    if (!values.timezone) {
      setErrors({
        timezone: 'Debes seleccionar una zona horaria'
      });
      return;
    }

    //Update language and timezone
    try {
      setErrors({});
      await callEndpoint(updateUserLanguagesAndTimezone(mongoID, role, values));

      await refreshUser();

      displayToast('Lenguajes actualizados con éxito', 'success');
    } catch (error) {
      displayToast('Error actualizando tus idiomas', 'error');
    }
  };

  return (
    <div className="UpdateLanguages background">
      <h1>{t('pages.updateLanguages.title')}</h1>
      <p className="UpdateLanguages__subtitle">
        {t('pages.updateLanguages.subtitle')}{' '}
        {isCoach
          ? t('pages.updateLanguages.textCoach')
          : t('pages.updateLanguages.textCoachee')}
      </p>
      <div className="UpdateLanguages__languages">
        <Image src={es} boxSize={'24px'} />
        <Image src={br} boxSize={'24px'} />
        <Image src={en} boxSize={'24px'} />
      </div>
      <Languages values={values} setValues={setValues} errors={errors} />
      <PickTimezone values={values} setValues={setValues} errors={errors} />
      <Button
        className="Button Button--primary"
        mt={10}
        form="languages"
        onClick={handleSubmit}
        isDisabled={loading || userUtilitiesLoading}
        isLoading={loading || userUtilitiesLoading}
      >
        {t('pages.updateLanguages.buttonSave')}
      </Button>
    </div>
  );
}

export default UpdateLanguages;
