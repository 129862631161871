import React from 'react';
import UploadVideo from './components/UploadVideo';

const Video = () => {
  return (
    <div>
      <UploadVideo />
    </div>
  );
};

export default Video;
