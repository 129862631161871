import axios from 'axios';
import { loadAbort } from 'utilities';

interface IMidNPS {
  coachee: string;
  recomendation: number;
  usefulAspect: string;
  obstacles: string;
}

interface IFinalNPS {
  coachee: string;
  recomendation: number;
  coachValoration: number;
  feelingsAboutCareer: string;
  feelingsAboutBonum: string;
  programFeedback: string;
}

const midNps = `${process.env.REACT_APP_USERS_URL}/api/midnps`;
const finalNps = `${process.env.REACT_APP_USERS_URL}/api/finalnps`;

export const createMidNPS = (data: IMidNPS) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(midNps, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const createFinalNps = (data: IFinalNPS) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(finalNps, data, {
        signal: controller.signal
      }),
    controller
  };
};
