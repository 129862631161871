import axios from 'axios';
import { loadAbort } from 'utilities';

const route = `${process.env.REACT_APP_USERS_URL}/api/focusAreas`;

export const getFocusAreas = () => {
  const controller = loadAbort();
  return {
    call: () => axios.get(route, { signal: controller.signal }),
    controller
  };
};
