import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  block_date_from: Yup.date('dateValid').required('dateRequire'),
  block_date_until: Yup.date('dateValid')
    .required('dateFinal')
    .min(Yup.ref('block_date_from'), 'dateDiff'),
  block_time_from: Yup.string('timeValid').required('timeRequire'),
  block_time_until: Yup.string('timeValid')
    .required('timeFinal')
    .test('is-sameday-and-greater', 'timeDiff', (no, testContext) => {
      const { block_time_from, block_time_until } = testContext.parent;
      return block_time_from > block_time_until ? false : true;
    })
});

export default validationSchema;
