import axios from 'axios';
import { useCheckForCalendar } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CoachButtons from './Components/CoachButtons';
import CoacheeButtons from './Components/CoacheeButtons';
import NextSession from './Components/NextSession';

function Home() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { checkForCalendar } = useCheckForCalendar(user);
  const coacheeURL = `${process.env.REACT_APP_USERS_URL}/api/coachee`;
  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    if (!user.onboardingCompleted) {
      navigate('/onboarding');
      return;
    }
    checkForCalendar();

    if (!user.languages || user?.languages?.length < 1)
      navigate('/updatelanguages');
  }, [user.onboardingCompleted]);

  // get User Status
  const fetchUserData = async () => {
    try {
      const userData = await axios.get(`${coacheeURL}/${user.mongoID}`);
      setUserStatus(userData?.data.data.statusUser);
    } catch (err) {
      console.log('error', err);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const parentNode = useRef(null);

  return (
    <>
      <div className="Home">
        <NextSession
          parentNode={parentNode}
          user={user}
          userStatus={userStatus}
        />
        <div className="Home__buttons">
          {user?.role === 'coach' ? <CoachButtons /> : <CoacheeButtons />}
        </div>
      </div>
      <div ref={parentNode}></div>
    </>
  );
}

export default Home;
