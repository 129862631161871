import React, { useMemo, useState } from 'react';
import SessionEvaluationContext from './SessionEvaluationContext';
import { useTranslation } from 'react-i18next';

function SessionEvaluationContextWrapper({ children }) {
  const [selectedRating, setselectedRating] = useState(null);
  const [selectedText, setSelectedText] = useState(null);

  const { t } = useTranslation('global');

  const questionCoachee = [
    {
      id: 1,
      title: t('pages.sessionEvaluation.coachee.rateCoachingSession'),
      ratingValue: 0
    },
    {
      id: 2,
      title: t('pages.sessionEvaluation.coachee.rateCoach'),
      ratingValue: 0
    },
    {
      id: 3,
      title: t('pages.sessionEvaluation.coachee.callQuality'),
      ratingValue: 0
    }
  ];

  const questionCoach = [
    {
      id: 1,
      title: t('pages.sessionEvaluation.coach.rateCoachingSession'),
      ratingValue: 0
    },
    {
      id: 2,
      title: t('pages.sessionEvaluation.coach.rateCoachee'),
      ratingValue: 0
    },
    {
      id: 3,
      title: t('pages.sessionEvaluation.coach.callQuality'),
      ratingValue: 0
    }
  ];

  const [questionsCoach, setQuestionsCoach] = useState(questionCoach);
  const [questionsCoachee, setQuestionsCoachee] = useState(questionCoachee);

  const SessionEvaluationContextValue = useMemo(
    () => ({
      selectedRating,
      selectedText,
      questionsCoach,
      questionsCoachee,
      setselectedRating,
      setSelectedText,
      setQuestionsCoach,
      setQuestionsCoachee
    }),
    [selectedRating, selectedText, questionsCoach, questionsCoachee]
  );

  return (
    <SessionEvaluationContext.Provider value={SessionEvaluationContextValue}>
      {children}
    </SessionEvaluationContext.Provider>
  );
}

export default SessionEvaluationContextWrapper;
