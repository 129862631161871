import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, Image } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { setVideo } from 'redux/slices/onboarding';
import iconUpload from 'assets/images/icons/subir-video.png';
import { storage } from 'utilities/firebase.utility';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import displayToast from 'utilities/toast.utility';
import Uploader from 'components/Uploader';
import { useTranslation } from 'react-i18next';
import { Player } from 'video-react';
import YouTube from 'react-youtube';
import { useFetchAndLoad } from 'hooks';
import { updateVideoCoach } from 'services/coach.service';

function UploadVideo() {
  const { video, uid: userid } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [urlVideoCoach, setUrlVideoCoach] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation('global');
  const { mongoID } = useSelector((state) => state.user);
  const [newVideo, setNewVideo] = useState(null);

  const { callEndpoint } = useFetchAndLoad();

  const errorUpload = t('pages.onboarding.components.uploadVideo.errorUpload');
  const videoRef = ref(storage, `profileVideos/${userid}/profileVideo.mp4`);

  let uploadTask = null;

  const onErrorUpload = (error) => {
    if (error.code !== 'storage/canceled') displayToast(errorUpload, 'error');
  };

  const onSuccessfullLoad = async () => {
    const downloadUrl = await getDownloadURL(videoRef);
    setUrlVideoCoach(downloadUrl);
    dispatch(setVideo(downloadUrl));
  };

  function obtenerVideoId(url) {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('v');
  }

  const uploadVideo = (toUploadVideo) => {
    try {
      uploadTask = uploadBytesResumable(
        videoRef,
        toUploadVideo
        // 'data_url'
      );

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          setLoading(true);
          const progressTemp =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          setProgress(progressTemp);
          if (progressTemp === 100) {
            setProgress(100);
            setLoading(false);
          }
        },
        onErrorUpload,
        onSuccessfullLoad
      );
    } catch (error) {
      console.log(
        '🚀 ~ file: UploadPhoto.js ~ line 25 ~ uploadImage ~ error',
        error.message
      );
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {}, [progress]);

  const updateVideo = async () => {
    if (urlVideoCoach !== null) {
      await callEndpoint(updateVideoCoach({ urlVideoCoach, id: mongoID }));
      setNewVideo(urlVideoCoach);
    }
  };

  useEffect(() => {
    updateVideo();
  }, [urlVideoCoach]);

  const onDrop = useCallback(
    async (file) => {
      const videoUrl = Object.assign(file[0], {
        preview: URL.createObjectURL(file[0])
      });

      if (uploadTask) uploadTask.cancel();
      try {
        uploadVideo(videoUrl);
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'video/*'
  });

  return (
    <>
      <div className="flex justify-center items-center">
        {newVideo &&
        newVideo.startsWith('https://firebasestorage.googleapis.com') ? (
          <div style={{ width: '25vw' }}>
            <Player>
              <source src={video} />
            </Player>
          </div>
        ) : video && video !== 'pending' ? (
          video.startsWith('https://firebasestorage.googleapis.com') ? (
            <div style={{ width: '25vw' }}>
              <Player>
                <source src={video} />
              </Player>
            </div>
          ) : (
            <div>
              <YouTube
                videoId={obtenerVideoId(video)}
                opts={{ width: '800px', height: '500px' }}
              />
            </div>
          )
        ) : null}
      </div>

      <div {...getRootProps()} className="UploadVideoPreferences">
        <Input {...getInputProps()} />
        {loading && (
          <Uploader
            progress={progress}
            title={t('pages.onboarding.components.uploadVideo.subtitle')}
          />
        )}
        {!loading && !video && !isDragActive ? (
          <Image
            src={iconUpload}
            width="60%"
            className="UploadVideoPreferences__icon"
            objectFit="cover"
          />
        ) : null}

        {!loading && video && !isDragActive ? (
          <Button className="Button Button--primary">
            {t('pages.onboarding.components.uploadVideo.replace')}
          </Button>
        ) : null}
      </div>
    </>
  );
}

export default UploadVideo;
