import React, { useState, useEffect, useRef } from 'react';

import { io, Socket } from 'socket.io-client';
import displayToast from 'utilities/toast.utility';
import { useDispatch, useSelector } from 'react-redux';
import { useForceUpdate } from 'hooks/useForceUpdate';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import { EndSession, updateSession } from 'services/sessions.service';
import { modifySession } from 'redux/slices/session';
import adaptedSession from 'adapters/sessionsAdapter.adapter';
import Loading from 'components/Loading';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Img } from '@chakra-ui/react';
import MicIcon from 'assets/images/icons/microfono.png';
import CameraIcon from 'assets/images/icons/camara.png';
import EndIcon from 'assets/images/icons/colgar.png';
import ExpandIcon from 'assets/images/icons/expandir.png';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { Popconfirm } from 'antd';
import { Detector } from 'react-detect-offline';
import RemoteVideoPlayer from './components/RemoteVideoPlayer';
import LocalVideoPlayer from './components/LocalVideoPlayer';

let socket: Socket<any>;
let IsOnline;

let temps: any[] = [];
let candidatesReviced: any[] = [];

const Meeting = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onClose = () => setIsOpen(false);

  const cancelRef = React.useRef(null);
  const dispatch = useDispatch();

  const [video, setVideo] = useState<boolean>(true);
  const [audio, setAudio] = useState<boolean>(true);
  const [candidates, setCandidates] = useState<any[]>([]);
  const [candidatesTemp, setCandidatesTemp] = useState<any[]>([]);

  const [localStream, setLocalStream] = useState<MediaStream>();
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);

  const [callAccepted, setCallAccepted] = useState(true);
  const [remoteVideoOn, setRemoteVideoOn] = useState(true);
  const [remoteAudioOn, setRemoteAudioOn] = useState(true);
  const [remoteUserInfo, setRemoteUserInfo] = useState<any>(null);
  const [callEnded, setCallEnded] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();

  const user = useSelector((state: any) => state.user);

  const isCoach: boolean = user.role === 'coach';
  const session = useSelector((state: any) => state.session);
  const forceUpdate = useForceUpdate();
  const [anotations, setAnotations] = useState<string>('');
  const [annotations, setAnnotations] = useState(EditorState.createEmpty());
  let byClick = false;

  const [expand, setExpand] = useState(false);

  const pc: React.MutableRefObject<RTCPeerConnection> =
    useRef<RTCPeerConnection>();
  const servers = {
    iceServers: [
      {
        urls: 'stun:stun1.l.google.com:19302'
      },
      {
        urls: 'turn:54.237.64.85:3478',
        credential: 'BONUM123',
        username: 'BONUMCOACHING'
      }
    ],
    iceCandidatePoolSize: 10
  };

  useEffect(() => {
    getUserMedia();
  }, [audio, video]);

  const getUserMedia = () => {
    if (socket) {
      socket.emit('SetMediaData', {
        userId: user.uid,
        room: session?.callSession,
        infoUser: { photo: user?.photo },
        video,
        audio
      });
    }
  };

  const navigate = useNavigate();

  const toggleExpand = () => {
    setExpand((expand) => !expand);
  };

  const toggleMute = () => {
    handleAudio();
    localStream
      ?.getAudioTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const toggleVideo = () => {
    handleVideo();
    localStream
      ?.getVideoTracks()
      .forEach((track) => (track.enabled = !track.enabled));
  };

  const JoinMeeting = async () => {
    try {
      pc.current = new RTCPeerConnection(servers);
      const local: MediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          frameRate: 30,
          facingMode: 'user'
        }
      });

      local.getTracks().forEach((track) => {
        pc.current.addTrack(track, local);
      });

      setLocalStream(local);

      pc.current.onicecandidate = async ({ candidate }: any) => {
        if (!candidate) {
          // console.log('Got final candidate!');
          return;
        }

        socket.emit('SendCandidate', {
          candidate,
          room: session.callSession
        });
        temps.push(candidate);
        setCandidatesTemp(temps);
      };

      pc.current.onnegotiationneeded = () => {
        if (candidatesReviced.length > 0) {
          handleCandidates(candidatesReviced);
        }
      };

      pc.current.ontrack = (e: any) => {
        try {
          const newStream = new MediaStream(e.streams[0]);

          e.streams[0].getTracks().forEach((track) => {
            newStream.addTrack(track);
          });

          if (pc.current.iceConnectionState === 'connected') {
            setCallAccepted(true);
          }

          setRemoteStream(newStream);
        } catch (err) {
          console.log('ERROR ONTRACK: ', err);
        }
      };

      // pc.current.ontrack = (e: any) => {
      //   if (e.stream) {
      //     try {
      //       const newStream = new MediaStream(e.stream);
      //       setRemoteStream(newStream);

      //       setCallAccepted(true);
      //     } catch (e) {
      //       console.log('=============', { e }, '================');
      //     }
      //   }
      // };

      pc.current.onicecandidateerror = (event) => {
        console.log(
          '=====================ERROR CANDIDATE: ',
          event,
          '================'
        );
      };

      pc.current.oniceconnectionstatechange = (event) => {
        console.log('iceConnectionState', pc.current.iceConnectionState);
        if (pc.current) {
          if (pc.current.iceConnectionState === 'failed') {
            socket?.disconnect();
          }

          if (pc.current.iceConnectionState === 'connected') {
            setCallAccepted(true);
          }
        }
      };
      let offerDescription = null;
      const { data } = await axios.get(
        `${process.env.REACT_APP_STREAMING_URL}/socketsInRoom/${session.callSession}`
      );

      if (data?.sockets >= 1) {
        offerDescription = await pc.current.createOffer();

        await pc.current.setLocalDescription(
          new RTCSessionDescription(offerDescription)
        );
      }

      socket.emit('join-meeting', {
        userId: user.uid,
        user: {
          name: user.name,
          email: user.email,
          role: user.role
        },
        offerDescription,
        room: session.callSession
      });
    } catch (err) {
      console.log('ERROR INIT: ', { err });
    }
  };

  const leaveCall = async () => {
    if (session) {
      try {
        await callEndpoint(
          EndSession({
            _id: session._id || session.id,
            MeetingId: session.callSession,
            coachPrivateComments: anotations
          })
        );
      } catch (error) {
        console.log('Error al cerrar llamada', error);
      }
    }
    onClose();

    dispatch(modifySession(adaptedSession({ ...session, status: true })));
    setCallEnded(true);
    if (socket) {
      socket.emit('CallEndMeeting', {
        userId: user.uid,
        room: session.callSession
      });
    }
    destroyConnection();
    navigate('/sessionevaluation');
  };

  useEffect(() => {
    socket = io(process.env.REACT_APP_STREAMING_URL as string, {
      transports: ['websocket']
    });

    socket.on('user-disconnected', () => {
      setCallAccepted(false);
      // setRemoteStream(null);
    });

    socket.on('connect', () => {
      socket.sendBuffer = [];
      JoinMeeting();
    });

    socket.on('disconnect', () => {
      console.log('disconnect');
    });

    socket.on('error', (err: any) => {
      if (socket?.disconnected) {
        socket?.connect();
      }
      console.error('==========ERROR FROM SOCKET==========: ', err);
    });

    socket.on('OnCandidate', async (candidate) => {
      if (pc && pc.current && candidate) {
        try {
          if (
            pc.current.remoteDescription !== null &&
            pc.current.remoteDescription !== undefined
          ) {
            await pc.current.addIceCandidate(
              new RTCIceCandidate({
                candidate: candidate.candidate,
                sdpMid: candidate.sdpMid,
                sdpMLineIndex: candidate.sdpMLineIndex
              })
            );
          } else {
            candidatesReviced.push(candidate);
          }
        } catch (err) {
          console.log(
            '================ADDICECANDIDATE ERROR==================: ',
            pc?.current?.remoteDescription
          );
        }
      }
    });

    socket.on('OnCandidates', async (candidates) => {
      if (pc && pc.current) {
        if (candidates) {
          try {
            setCandidates(candidates);
          } catch (err) {
            console.log(
              '================ADDICECANDIDATE ERROR==================: ',
              err
            );
          }
        }
      }
    });

    socket.on('user-connected-offer-response', async (answer) => {
      try {
        if (!pc?.current?.remoteDescription && answer) {
          const answerDescription = new RTCSessionDescription(answer);
          await pc?.current?.setRemoteDescription(answerDescription);

          if (candidatesTemp.length > 0) {
            handleCandidates(candidatesReviced);
          }
        }
      } catch (err) {
        console.log('ERROR: ', err, user.role);
      }
    });

    socket.on('user-connected-offer', async (offer) => {
      try {
        if (pc.current?.signalingState !== 'have-local-offer' && offer) {
          // Use the received offerDescription
          const offerDescription = new RTCSessionDescription(offer);
          await pc?.current?.setRemoteDescription(offerDescription);
        }

        if (
          pc.current?.signalingState === 'have-remote-offer' ||
          pc.current?.signalingState === 'have-local-pranswer'
        ) {
          const answerDescription: any = await pc?.current?.createAnswer();
          await pc?.current?.setLocalDescription(answerDescription);
          // Here is a good place to process candidates.
          if (candidatesTemp.length > 0) {
            handleCandidates(candidatesReviced);
          }
          socket.emit('set-user-connected-offer-response', answerDescription);
        }
      } catch (err) {
        console.log(
          '==============SIGNALINGSTATE',
          pc.current?.signalingState,
          '================='
        );

        console.log('ERROR WHILE CONNECTING: ', { err }, user.role);
        // Handle Errors
      }
    });

    socket.on('MediaData', ({ audio, video, infoUser, userId }) => {
      if (
        user &&
        user.uid.length > 0 &&
        userId.length > 0 &&
        user.uid !== userId
      ) {
        // eslint-disable-next-line no-debugger
        debugger;
        setRemoteUserInfo(infoUser);
        setRemoteVideoOn(video);
        setRemoteAudioOn(audio);
      }
    });

    socket.on('message', (message: string) => {
      displayToast(message, 'info');
    });

    socket.on('EndIsSoon', (message: string) => {
      displayToast(message, 'info');
    });

    socket.on('EndMeeting', () => {
      destroyConnection();
      dispatch(modifySession(adaptedSession({ ...session, status: true })));
      navigate('/sessionevaluation');
    });

    return () => {
      destroyConnection();
    };
  }, []);

  const handleCandidates = async (candidates) => {
    if (
      pc.current &&
      Array.isArray(candidates) &&
      candidates.length > 0 &&
      pc.current?.remoteDescription
    ) {
      for (const candidate of candidates) {
        await pc.current.addIceCandidate(
          new RTCIceCandidate({
            candidate: candidate.candidate,
            sdpMid: candidate.sdpMid,
            sdpMLineIndex: candidate.sdpMLineIndex
          })
        );
      }
    }
  };

  useEffect(() => {
    handleCandidates(candidates);
  }, [candidates]);

  useEffect(() => {
    if (remoteStream) {
      socket.emit('SendCandidates', {
        candidates: temps,
        room: session.callSession
      });
    }
  }, [remoteStream]);

  useEffect(() => {
    if (
      pc?.current?.remoteDescription !== null &&
      pc?.current?.remoteDescription !== undefined &&
      candidatesReviced.length > 0
    ) {
      handleCandidates(candidatesReviced);
    }
  }, [pc.current, pc.current?.remoteDescription]);

  const destroyConnection = () => {
    candidatesReviced = [];
    temps = [];
    localStream?.getTracks().forEach(function (track) {
      track.stop();
    });

    remoteStream?.getTracks().forEach(function (track) {
      track.stop();
    });

    if (pc.current) {
      pc.current.close();
    }

    socket?.disconnect();
    setAudio(true);
    setVideo(true);
    setRemoteAudioOn(true);
    setRemoteVideoOn(true);
    setRemoteStream(null);
  };

  const handleVideo = () => setVideo(!video);
  const handleAudio = () => setAudio(!audio);

  if (loading) return <Loading title={'LOADING...'} />;

  return (
    <div className="containervideo">
      <div style={{ width: '100%' }}>
        <div id="container-videos-controls">
          {/* <Detector
            render={({ online }) => {
              if (IsOnline === false && online === true) {
                location.reload();
              }
              IsOnline = online;
              if (online === false) {
                destroyConnection();
              }
              return <></>;
            }}
          /> */}
          <div
            className="videos"
            style={{
              width: expand ? '100%' : '70rem',
              height: expand ? '100%' : '500px'
            }}
          >
            {callAccepted && !callEnded && (
              <RemoteVideoPlayer
                expand={expand}
                remoteVideoOn={remoteVideoOn}
                remoteStream={remoteStream}
                remoteUserInfo={remoteUserInfo}
              />
            )}

            <LocalVideoPlayer
              callAccepted={callAccepted}
              localStream={localStream}
              activeVideo={video}
              expand={expand}
              user={user}
            />
          </div>

          <div className="btn-down">
            <Img
              src={MicIcon}
              width="4rem"
              className={`${
                audio !== true && 'icon-image--disable'
              } icon-image`}
              objectFit="cover"
              id="mic-icon"
              onClick={toggleMute}
            />
            <Img
              src={CameraIcon}
              width="4rem"
              className={`${
                video !== true && 'icon-image--disable'
              } icon-image`}
              objectFit="cover"
              id="camera-icon"
              onClick={toggleVideo}
            />
            <Img
              className="expand-img"
              id="expand-img"
              onClick={() => {
                if (
                  (document['webkitIsFullScreen'] === false ||
                    document['mozFullScreen'] === false ||
                    document['msFullscreenElement'] === false) &&
                  !byClick
                ) {
                  byClick = true;
                }
                toggleExpand();
              }}
              src={ExpandIcon}
            />

            {user && user.role === 'coach' && (
              <Popconfirm
                title="¿Estás seguro de terminar esta reunión?"
                onConfirm={leaveCall}
                okText="Si"
                okButtonProps={{
                  id: 'OkleaveCall'
                }}
                cancelButtonProps={{
                  id: 'CancelleaveCall'
                }}
                cancelText="No"
              >
                <Img
                  src={EndIcon}
                  id="leaveCall"
                  width="4rem"
                  className="icon-image"
                  objectFit="cover"
                />
              </Popconfirm>
            )}
          </div>
        </div>

        {user && user.role === 'coach' && (
          <div className="container flex justify-center column">
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
              Aqui puedes ingresar notas sobre ésta sesión.
            </p>
            <div className="container flex justify-center column editor-container">
              <Editor
                ariaOwneeID="notes"
                editorState={annotations}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  setAnnotations(e);
                  setAnotations(
                    draftToHtml(convertToRaw(e.getCurrentContent()))
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Meeting;
