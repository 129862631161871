import React from 'react';
import CoachCalendarContextWrapper from './context/CoachCalendarContextWrapper';
import CoachCalendar from './CoachCalendar';

export default function () {
  return (
    <CoachCalendarContextWrapper>
      <CoachCalendar />
    </CoachCalendarContextWrapper>
  );
}
