import React, { useEffect } from 'react';
import Loading from 'components/Loading';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import { size } from 'lodash';
import SaveCalendars from 'pages/SuccessCalendar/components/SaveCalendars';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  createBonumCoachingCalendar,
  createUserCalendar,
  saveUserToken
} from 'services/calendar.service';
import displayToast from 'utilities/toast.utility';
import useUserUtilities from 'hooks/useUserUtilities';
import NoData from 'components/NoData/NoData';
//import CreateCalendar from './components/CreateCalendar';
import { updateUserCalendar } from 'services/user.service';

function SuccessCalendar() {
  const [searchParams] = useSearchParams();
  const { refreshUser, userUtilitiesLoading } = useUserUtilities();
  const code = searchParams.get('code');
  const user = useSelector((state) => state.user);
  const { mongoID, providers, calendar } = user;
  const { loading, callEndpoint } = useFetchAndLoad();

  const saveCalendarToken = async () => {
    try {
      await callEndpoint(saveUserToken(code, mongoID));
      displayToast('Calendario guardado con éxito', 'success');
    } catch (error) {
      if (error.response.data.message !== 'No access token in response')
        displayToast(error.response.data.message, 'error');
    }
  };

  const createCalendar = async () => {
    try {
      const bonumCalendar = await callEndpoint(
        createBonumCoachingCalendar(user.timezone, user.providers[0].provider)
      );

      const createMongoCalendar = await callEndpoint(
        createUserCalendar(bonumCalendar.data.data, user)
      );

      await callEndpoint(updateUserCalendar(user, createMongoCalendar.data));

      await refreshUser();
      displayToast('Calendario creado con éxito', 'success');
    } catch (error) {
      displayToast('Error creando el calendario', 'error');
    }
  };

  useEffect(() => {
    (async () => {
      if (code) {
        await saveCalendarToken();
        await refreshUser();
      }
    })();
  }, []);

  if (loading || userUtilitiesLoading)
    return <Loading title="Guardando calendario" />;

  if (size(providers) < 1)
    return <NoData title="Aún no tienes un proveedor de calendario" />;

  if (size(providers) > 0 && (!calendar || size(calendar) === 0)) {
    createCalendar();
    return <Loading title="Guardando Calendario" />;
  }

  return (
    <div className="SuccessCalendar">
      <SaveCalendars />
    </div>
  );
}

export default SuccessCalendar;
