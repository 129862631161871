import React from 'react';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { MdOutlineUploadFile } from 'react-icons/md';

function Uploader(props) {
  const { progress = 0, onCancel, title = 'Cargando Archivo' } = props;

  const cancel = () => {
    onCancel();
  };

  return (
    <div className="Uploader">
      <CircularProgress
        value={progress}
        className="Uploader__progress"
        color="bonumBlue.500"
        thickness={'4px'}
      >
        <CircularProgressLabel className="Uploader__progress_label">
          <MdOutlineUploadFile />
          <p>{title}</p>
          {parseInt(progress)}%
        </CircularProgressLabel>
      </CircularProgress>

      {onCancel && (
        <p className="Uploader__cancel" onClick={cancel}>
          Cancel
        </p>
      )}
    </div>
  );
}

export default Uploader;
