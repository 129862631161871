import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { languagesToReactSelect } from 'utilities/availableLanguages.utility';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import validationSchema from './validationSchema';
import { useFetchAndLoad, useUserUtilities } from 'hooks';
import { updateUserLanguages } from 'services/user.service';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Languages({ values, setValues, errors }) {
  const { role, languages } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const { t } = useTranslation('global');

  const customSelectStyles = {
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: '#299eff'
      };
    },
    multiValueLabel: (styles) => {
      return {
        ...styles,
        color: '#ffff',
        fontSize: '1em'
      };
    }
  };

  useEffect(() => {
    if (languages && languages.length > 0) navigate('/');
  }, [languages]);

  const handleSelectChange = (selectValues) => {
    const valuesToDispatch = selectValues.map((languages) => languages.value);
    setValues({ ...values, languages: valuesToDispatch });
  };

  const isCoach = role === 'coach';

  return (
    <div className="Languages">
      <p className="Languages__subtitle">
        {isCoach
          ? t('pages.updateLanguages.selectCoach')
          : t('pages.updateLanguages.selectCoachee')}
      </p>
      <div className="Languages__picker card">
        <FormControl isInvalid={errors.languages}>
          <Select
            isMulti
            styles={customSelectStyles}
            options={languagesToReactSelect}
            onChange={handleSelectChange}
            className="Languages__select"
            placeholder={t('pages.updateLanguages.placeholder')}
          />

          <FormHelperText textAlign="left" fontSize="xl" mb="10">
            {t('pages.updateLanguages.helperText')}
          </FormHelperText>

          {errors?.languages ? (
            <FormErrorMessage fontSize="lg">
              {errors.languages}
            </FormErrorMessage>
          ) : null}
        </FormControl>
      </div>
    </div>
  );
}

export default Languages;
