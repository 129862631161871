import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './components/Sidebar';

function Preferences() {
  return (
    <div className="Preferences background">
      <div className="Preferences__sidebar">
        <Sidebar />
      </div>

      <div className="Preferences__content">
        <Outlet />
      </div>
    </div>
  );
}

export default Preferences;
