import { createContext } from 'react';

const CoachCalendarContext = createContext({
  schedules: [],
  setSchedules: () => null,
  updateWorkingUI: () => null,
  selectedDate: null,
  setSelectedDate: () => null,
  blockedSchedules: null,
  setBlockedSchedules: () => null
});

export default CoachCalendarContext;
