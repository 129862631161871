import React, { useEffect, useState } from 'react';
import { map, size } from 'lodash';
import NoData from 'components/NoData/NoData';
import Comment from '../Comment/Comment';
import { useFetchAndLoad } from 'hooks';
import { getEvaluation360ByCoacheeId } from 'services/evaluations.service';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';

function Comments(props) {
  const { coachee } = props;
  const [comments, setComments] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { t } = useTranslation('global');

  const getComments = async () => {
    try {
      if (!coachee) return;
      const { data } = await callEndpoint(getEvaluation360ByCoacheeId(coachee));

      const commentsTemp = data.data
        .filter((evaluation) => evaluation.comments !== '') // Filtrar comentarios no vacíos
        .map((evaluation) => ({
          title: evaluation.dateSended,
          comment: evaluation.comments
        }));

      setComments(commentsTemp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="Comments">
      {size(comments) > 0 ? (
        comments.map((comment) => <Comment comment={comment} />)
      ) : (
        <NoData title={t('pages.myEvaluations.noData')} />
      )}
    </div>
  );
}

export default Comments;
