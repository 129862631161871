import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="PrivacyPolicy">
      <h1 className="PrivacyPolicy__title">
        POLITICAS DE PRIVACIDAD DE BONUM COACHING LLC
      </h1>
      <h2>POLITICAS DE PRIVACIDAD</h2>
      <p>
        Nos importa tu privacidad y queremos informar todo lo que hacemos con
        tus datos personales. A lo largo de la Política de Privacidad de BONUM
        COACHING LLC con dirección 1761 NE 197th Terrace Miami, FL 33179 USA
        podrás verificar qué derechos tienes a tu disposición, qué datos
        tratamos y con quién los compartimos, el periodo por el cual los
        conservamos, entre otra información. BONUM ofrece una plataforma única
        en la que profesionales ‘’Coaches’’, empresas y sus empleados
        ‘’coachees’’ pueden acceder a un proceso de entrenamiento personalizado
        y confidencial mediante un gran conjunto de herramientas que fomentan el
        crecimiento empresarial de la mano del crecimiento personal.
      </p>
      <h2>APLICACIÓN</h2>
      <p>
        Esta Política de Privacidad se aplica a todos los usuarios de la página
        web https://www.bonumcoaching.com/ y sus subdominios, sean registrados o
        no y a todos los usuarios de la aplicación móvil, desde el momento en
        que instalan en el dispositivo móvil. La aplicación de esta Política de
        Privacidad es indiferente al marco territorial, aplicándose a todos los
        usuarios a partir del momento en que abran nuestro sitio web o nuestra
        aplicación móvil, independientemente de dónde se encuentren.
      </p>
      <h2>DATOS RECOPILADOS</h2>
      <p>
        La recolección y tratamiento de datos es fundamental para el
        funcionamiento de BONUM. Es con base en esos datos que nuestro proyecto
        se construye y son ellos quienes nos permiten prestar un servicio de
        referencia en el área de; Coaching y gestión de conocimientos. Limitamos
        y revisamos los datos y el plazo de conservación de los mismos es de 10
        años. Hay varios grupos de información y datos que recogemos y tratamos.
        Para simplificar, vamos a dividir los diferentes tipos de información en
        Coach y Coachees.
      </p>
      <h2>Datos requeridos al Coach</h2>
      <p>
        Datos exigidos al Coach en el registro: todos los datos introducidos por
        el Profesional al registrarse en la plataforma son almacenados y
        tratados. Es este registro que nos permite identificar el Profesional y
        darle acceso al área reservada de gestión de las consultas, material,
        sesiones y asociarlo correctamente a los coachees que se les asigne.
        Además, estos son los datos que consideramos indispensables, junto a los
        datos de facturación, para que se concretice regularmente la relación
        contractual entre BONUM y el Profesional. Los datos que obligatoriamente
        exigimos en el registro son: el nombre completo, el nombre del lugar de
        trabajo principal, el género, el país de residencia, licencias,
        certificaciones, resume, diplomas, el correo electrónico y, por
        supuesto, una contraseña. Datos de Calendario: Los datos referentes a
        eventos del calendario del coach son procesados a través del sistema de
        BONUM, estos datos no son almacenados, son únicamente para procesar la
        disponibilidad del coach. Datos de pago: los datos de pago son también
        procesados por BONUM, los datos son: datos de cuenta bancaria en caso de
        que así lo prefiera el profesional, o datos de su cuenta de PayPal para
        recibir sus pagos. Datos de facturación: con el fin de cumplir con
        nuestras obligaciones fiscales tenemos, obligatoriamente, que pedir
        algunos datos de facturación como: nombre, número de identificación
        fiscal, dirección, ciudad, código postal y país. Datos recogidos de
        forma automática: además de los datos referidos recogemos, de forma
        automática, a través de cookies y otros métodos y servicios, un conjunto
        de datos que nos permiten conocer con precisión la forma como utilizas
        la plataforma, el país, la fecha y la hora en que efectúas la entrada en
        la plataforma, entre otras informaciones. Se recogen datos como la
        dirección I.P., el navegador en el que accedes y tu versión, el idioma,
        sistema operativo, entre otros. Nos gustaría subrayar que este tipo de
        recogida de datos está destinado, sobre todo, a facilitar el trabajo de
        nuestro equipo siempre que necesites nuestro apoyo y soporte. Se trata
        de informaciones que permiten la rápida resolución de problemas en
        nuestras plataformas y que de otro modo no nos permitiría garantizar el
        normal funcionamiento de nuestro servicio y su mantenimiento. Para más
        información, consulta nuestra política de cookies.
      </p>
      <h2>Datos de los Coachees</h2>
      <p>
        BONUM podrá pedir al Coachee, varios datos personales que podrán ser
        desde categorías de datos personales "simples" (por ejemplo: número de
        identificación de usuario, entre otros, como el nombre completo,
        dirección, y muchos más) a datos considerados como "especiales" (son
        ejemplos de este tipo de datos la raza, historia personal y social,
        lugar donde trabaja, cargo que desempeña, entre otros). BONUM sólo trata
        los datos de sus usuarios en la medida en que sean insertados por el
        profesional o, directamente, a través de la web y/o aplicación móvil. La
        utilización de la aplicación móvil está destinada al uso de los Coachees
        siendo, obviamente, facultativa y, en los casos en que se utiliza,
        recolectamos los siguientes datos: Datos introducidos por el Coachee:
        todos los datos introducidos, ya sea al iniciar sesión en la aplicación
        móvil, o posteriormente, se almacenan y se tratan. Es este conjunto de
        datos nos permite identificarte, darte acceso a tu área reservada,
        asociarte correctamente al Profesional y darte acceso a tus sesiones.
        Son ejemplos de estos datos las credenciales de inicio de sesión.
      </p>
      <h2>Datos recopilados de los Coaches y Coachees</h2>
      <p>
        Datos de ubicación: con la utilización de la aplicación móvil podremos
        proceder al registro de información del GPS del dispositivo móvil. Sin
        embargo, esta operación es opcional, siendo siempre consentida por el
        usuario, previamente, en el momento de entrada del área reservada.
        Archivos locales, notificaciones y otros datos: en determinadas
        ocasiones la aplicación móvil podrá acceder, siempre con tu
        consentimiento previo, a archivos locales o a información almacenada en
        otras aplicaciones. Todas estas operaciones son opcionales, sirviendo
        sólo como forma de complementar y exponer la utilidad del servicio por
        nosotros prestado. Notificaciones: para la comodidad del usuario se
        envían notificaciones sobre las sesiones, comunicaciones entre las
        partes, material de apoyo, entre otras relacionadas. Estas
        notificaciones podrán ser actualizadas diariamente de forma automática.
        Esta funcionalidad se podrá deshabilitar en cualquier momento en las
        configuraciones de la aplicación. Cámara y Galería: la aplicación podrá
        acceder a la cámara y a la galería de imágenes del dispositivo móvil,
        permitiendo a los usuarios tomar fotos y enviar imágenes ya guardadas en
        la galería. Otros datos recogidos de forma automática: además de los
        datos referidos recogemos, de forma automática, a través de cookies, un
        conjunto de datos que nos permiten conocer con precisión la forma en que
        se utiliza la aplicación móvil y el sitio web. Se recogen datos como la
        dirección I.P., el navegador en el que accede, el sistema operativo de
        su dispositivo móvil, entre otros. Queremos subrayar que este tipo de
        recolección de datos está destinado, sobre todo, a facilitar el trabajo
        de nuestro equipo siempre que necesitas nuestro apoyo y soporte.
        También, es recolectada para uso académico y para la rápida resolución
        de problemas en nuestras plataformas, y que de lo contrario no nos
        permitiría garantizar el normal funcionamiento de nuestro servicio y su
        mantenimiento. Para más información, consulta nuestra sección dedicada
        al intercambio de datos con terceros y nuestra política de cookies.
      </p>
      <h2>UTILIZACIÓN DE LA DATA</h2>
      <p>
        Prestación de nuestro servicio: utilizamos la gran mayoría de los datos
        introducidos, sea por Profesionales o Coachees, para prestar nuestro
        servicio de la forma más eficiente posible, en el marco de la relación
        contractual establecida entre BONUM, los profesionales, empresas y
        coachees. Mantenimiento y mejora de los servicios: se procede al
        análisis conductual del uso efectuado por los Profesionales y los
        coachees del Sitio Web y de la aplicación móvil. Es fundamentalmente
        este tipo de análisis que nos permite determinar la utilidad de ciertas
        funcionalidades y alterarlas o corregirlas en función del resultado.
        Facturación: nos sería imposible cumplir con nuestras obligaciones
        legales y fiscales si no recurriéramos al tratamiento de alguna
        información de facturación. Sólo con el fin de recoger, en el momento
        del pago, datos personales como: el número de identificación fiscal,
        entre otros ya enumerados anteriormente. Cuestiones legales: podremos
        utilizar tus datos personales para dar cumplimiento a mandatos
        judiciales y fiscalizaciones tributarias y administrativas, entre otras
        exigencias legales. En vista de un mandamiento judicial todos los datos
        personales, sean de categorías simples o especiales, de los
        Profesionales, empresas o coachees, podrán ser, si nuestro equipo legal
        considera legítimo, puesta a disposición de la autoridad administrativa,
        judicial o judicial en cuestión. Marketing: podremos utilizar tus datos
        personales a efectos de marketing como el envío de correos electrónicos,
        notificaciones, sms y correspondencia postal. Nunca lo haremos sin tu
        autorización expresa y puedes optar libremente por no suscribir y
        continuar. Para que tengas una buena experiencia, el procesamiento de
        este tipo de comunicaciones puede estar sujeto a decisiones individuales
        automatizadas, incluyendo la definición de perfiles. Seguridad y
        finalidad contractual: utilizamos tus datos para analizar los
        comportamientos sospechosos o fraudulentos y para garantizar que las
        relaciones contractuales entre BONUM, el profesional, empresas y
        coachees se cumplen puntualmente.
      </p>
      <h2>PLAZO DE CONSERVACIÓN</h2>
      <p>
        Los datos personales podrán conservarse por períodos diferentes de
        tiempo según su relevancia legal o la duración de la relación
        contractual durante el tiempo que sea necesario para los fines
        originalmente descriptos o para otros fines comerciales legítimos,
        incluido el cumplimiento de nuestras obligaciones legales,
        reglamentarias o de otro tipo.
      </p>
      <h2>PROTECCIÓN DE DATOS SENSIBLES</h2>
      <p>
        <b>Cifrado:</b> Todos los datos se cifran tanto en tránsito como en
        reposo utilizando protocolos de seguridad estándar de la industria, como
        TLS y AES-256.
      </p>
      <p>
        <b>Acceso restringido:</b> Limitamos el acceso a los datos personales
        solo al personal autorizado que necesita esa información para llevar a
        cabo su trabajo.
      </p>
      <p>
        <b>Cumplimiento normativo:</b> Cumplimos con las leyes y regulaciones de
        protección de datos aplicables, como el Reglamento General de Protección
        de Datos (GDPR) y la Ley de Privacidad del Consumidor de California
        (CCPA), según corresponda.
      </p>
      <h2>POLITICAS DE PRIVACIDAD DE TERCEROS</h2>
      <p>
        La información que nos proporcionas podrá ser compartida o procesada por
        terceros ajenos a nuestros servicios. Por lo cual, nos sujetamos a las
        políticas de privacidad, términos y condiciones dispuestas por cada
        tercero.
      </p>
      <h2>Acceso a calendarios y eventos de calendario</h2>
      <p>
        Utilizamos Nylas para procesar el acceso, eliminación, actualización y
        lectura de calendarios y eventos en los calendarios. Los datos
        compartidos con Nylas se manejarán de acuerdo con la{' '}
        <a href="https://www.nylas.com/privacy-policy/">
          política de privacidad de Nylas.
        </a>
        <p>
          cada proveedor de calendario manejará su propia politica de
          privacidad.
        </p>
      </p>
      <h2>Análisis del uso del software</h2>
      <p>
        recurrimos a aplicaciones orientadas al análisis de la utilización de
        nuestro software, como{' '}
        <a
          href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es"
          target="_blank"
        >
          Google Analytics
        </a>{' '}
        y{' '}
        <a href="https://support.google.com/analytics/answer/9327974?hl=en">
          Funnel Analytics
        </a>
        .
      </p>
      <p>
        El uso y la transferencia de Bonum a cualquier otra aplicación de
        información recibida de las API de Google se adherirán a la Política de
        datos de usuario de los{' '}
        <a href="https://developers.google.com/terms/api-services-user-data-policy">
          servicios API de Google
        </a>
        , incluidos los requisitos de uso limitado.
      </p>
      <h2>Detalles de pago</h2>
      <p>
        los datos de pago son procesados integralmente por servicios externos de
        pago como{' '}
        <a href="https://www.paypal.com/va/webapps/mpp/ua/privacy-full">
          PayPal
        </a>
        .
      </p>
      <h2>Publicidad y otras herramientas</h2>
      <p>
        recurrimos a herramientas de análisis citadas para fines de marketing y
        publicidad. Así como también, mantenemos actividad en Redes Sociales
        como:{' '}
        <a
          href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0"
          target="_blank"
        >
          Facebook
        </a>
        ,
        <a href="https://help.instagram.com/155833707900388" target="_blank">
          Instagram
        </a>
        ,
        <a href="https://policies.google.com/privacy?hl=en" target="_blank">
          YouTube
        </a>
        ,
        <a href="https://blog.zoom.us/es/zoom-privacy-policy/" target="_blank">
          Zoom
        </a>{' '}
        y{' '}
        <a
          href="https://policies.google.com/technologies/ads?hl=en-US"
          target="_blank"
        >
          GoogleAds.
        </a>
      </p>
      <h2>Aplicaciones de dispositivo</h2>
      <p>
        Para poder acceder a nuestra aplicación móvil hacemos uso de plataformas
        como{' '}
        <a href="https://www.apple.com/legal/privacy/en-ww/" target="_blank">
          Apple Store
        </a>{' '}
        y{' '}
        <a
          href="https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=privacynotice&ldl=en"
          target="_blank"
        >
          Play Store
        </a>
        .
      </p>
      <h2>Almacenamiento y procesamiento de datos</h2>
      <p>
        el almacenamiento, el procesamiento y la copia de seguridad de tus datos
        personales se realiza de forma segura en empresas de alojamiento y
        computación ubicadas mayoritariamente en Estados Unidos.
      </p>
      <h2>Auditorías y mantenimiento</h2>
      <p>
        tus datos podrán ser accedidos en el marco de auditorías independientes
        de control de calidad y seguridad de nuestros servicios. Todas las
        auditorías están sujetas a confidencialidad y son acompañadas por el
        equipo de BONUM LLC.{' '}
      </p>
      <h2>POLÍTICA DE COOKIES</h2>
      <h2>Uso de cookies</h2>
      <p>
        BONUM recurre, a través de la web y app bajo el dominio web
        https://www.bonumcoaching.com/ al uso de cookies con el fin de mejorar y
        comprender el uso de la plataforma. También permiten reconocer cuando
        alguien utiliza la plataforma, así como ofrecer al usuario una
        experiencia de navegación positiva. Al utilizar la plataforma, tanto el
        Profesional como el Coachee, aceptan que BONUM, puede utilizar cookies
        para estos fines u otros, como se describe en la Política de Privacidad.{' '}
      </p>
      <p>
        Las cookies consisten en pequeños archivos de texto que se colocan
        dentro de la computadora, tableta o teléfono del Profesional y el
        coachee con previo consentimiento. Nuestras cookies tienen diferentes
        funciones:
      </p>
      <h2>Cookies estrictamente necesarias (imprescindibles)</h2>
      <p>
        Permitir la navegación en el sitio web y el uso de sus aplicaciones, así
        como permitir el acceso a áreas seguras del sitio web. Sin estas
        cookies, los servicios que ha solicitado no se pueden proporcionar.
      </p>
      <h2>Cookies analíticas</h2>
      <p>
        Estas cookies se utilizan para analizar cómo los usuarios usan el sitio
        web/ app y monitorear el desempeño de estos. Esto nos permite brindar
        una experiencia de alta calidad personalizando nuestra oferta e
        identificando y corrigiendo rápidamente cualquier problema que surja.
        Por ejemplo, utilizamos cookies de rendimiento para averiguar cuáles son
        las páginas más populares, qué método de conexión entre páginas es más
        eficaz o para determinar por qué algunas páginas reciben mensajes de
        error.
      </p>
      <p>
        Empleamos Google Analytics para recopilar información sobre el
        comportamiento de los visitantes y la demografía de los visitantes en
        algunos de nuestros Servicios, y para desarrollar el contenido del sitio
        web y app. Estos datos analíticos no están vinculados a ninguna
        Información Personal.
      </p>
      <h2>Cookies de funcionalidad</h2>
      <p>
        Estos mantienen las preferencias del usuario con respecto al uso del
        sitio web, por lo que no es necesario configurar el sitio web para cada
        nueva visita.
      </p>
      <h2>Cookies de terceros</h2>
      <p>
        Miden el éxito de las aplicaciones y la eficacia de la publicidad de
        terceros.
      </p>
      <h2>Las cookies pueden ser:</h2>
      <h2>Cookies permanentes</h2>
      <p>
        Estos permanecen almacenados, durante un tiempo variable, en el
        navegador de Internet de los dispositivos que utiliza para acceder a él
        (PC, móvil y tableta) y se utilizan cada vez que el usuario realiza una
        nueva visita al sitio web. Suelen utilizarse para la navegación directa
        según los intereses del usuario, lo que nos permite brindar un servicio
        más personalizado.
      </p>
      <h2>Cookies de sesión</h2>
      <p>
        Estos son temporales y permanecen almacenados en su navegador web hasta
        que salga del sitio web. La información obtenida nos permite identificar
        problemas y brindar una mejor experiencia de navegación.
      </p>
      <h2>¿Cómo se pueden administrar las cookies?</h2>
      <p>
        Como se explicó anteriormente, las cookies lo ayudan a aprovechar al
        máximo nuestro sitio. Para más información acerca de la funcionalidad de
        las cookies{' '}
        <a href="https://allaboutcookies.org/es" target="_blank">
          presiona acá
        </a>
        .
      </p>
      <p>
        La gran mayoría de los navegadores de Internet permiten al usuario
        aceptar, rechazar o eliminar las cookies, en particular mediante la
        selección de la configuración adecuada en el propio software. De esta
        forma, después de autorizar el uso de cookies, puede deshabilitar
        algunas o todas nuestras cookies; pero al hacer esto podrían perderse
        algunas funcionalidades de la web y app.
      </p>
      <h2>LOS DERECHOS DE LOS USUARIOS</h2>
      <p>
        Queremos garantizar que tus derechos se respetan plenamente. En las
        situaciones en que los mecanismos automáticos ya implementados no
        permitan asegurar en su totalidad estos derechos podrás entrar en
        contacto con nosotros en{' '}
        <a href="mailto:info@bonumcoaching.com">info@bonumcoaching.com</a>
      </p>
      <h2>Derecho de acceso</h2>
      <p>
        el titular de los datos personales tiene derecho a acceder a la
        información que le concierne y a conocer las finalidades del tratamiento
        de sus datos personales, las categorías de datos tratados, entre otras
        informaciones. Ya podrá acceder de inmediato a la mayoría de la
        información en el perfil. Tenga en cuenta que podemos retener cierta
        información según lo requiera la ley o para fines comerciales legítimos.
      </p>
      <h2>GDPR /RGPD</h2>
      <p>
        Si es parte de la UE nos sujetamos a las regulaciones expresas en{' '}
        <a href="https://rgpd.es/" target="_blank">
          GDPR
        </a>{' '}
        en español RGPD. BONUM siempre respetará plenamente sus derechos con
        respecto al tratamiento de sus datos personales, y ha facilitado a
        continuación los datos de contacto:{' '}
        <a href="mailto:info@bonumcoaching.com">info@bonumcoaching.com</a> del
        departamento al cual contactar si tiene alguna duda o pregunta sobre
        cómo tratamos sus datos, o si desea ejercer algún derecho.{' '}
      </p>
      <h2>CCPA - California Consumer Privacy Act- California</h2>
      <p>
        La{' '}
        <a href="https://oag.ca.gov/privacy/ccpa" target="_blank">
          CCPA
        </a>{' '}
        brinda a los consumidores de California con derechos específicos con
        respecto a su Información Personal. Usted tiene derecho a solicitar que
        le revelemos cierta información sobre nuestra recopilación y uso de su
        Información Personal durante los últimos 12 meses. Una vez que recibamos
        y confirmemos su solicitud de consumidor verificable, y confirmemos que
        tenemos Información Personal sobre usted que es aplicable bajo esta
        normativa. Para más información contáctenos al e-mail:{' '}
        <a href="mailto:info@bonumcoaching.com">info@bonumcoaching.com</a>
      </p>
      <h2>Otros Países</h2>
      <p>
        Al acceder o utilizar los Servicios o al proporcionarnos información de
        otra manera, usted acepta el procesamiento y la transferencia de
        información en y hacia los Estados Unidos y otros países, donde es
        posible que no tenga los mismos derechos y protecciones que tiene según
        la ley local. Si tiene una inquietud no resuelta puede comunicarse con
        nosotros por medio del e-mail:{' '}
        <a href="mailto:info@bonumcoaching.com">info@bonumcoaching.com</a>{' '}
      </p>
      <h2>TRANSFERENCIA INTERNACIONAL</h2>
      <p>
        Su Información Personal podrá ser transferida a o almacenada en
        computadoras localizadas fuera de su estado, provincia, país u otra
        jurisdicción gubernamental donde las leyes sobre la privacidad puedan
        ser no tan protectoras como lo son en su jurisdicción. Si usted se
        encuentra fuera de los Estados Unidos y decide brindarnos su Información
        Personal, nosotros podemos transferir su Información Personal a los
        Estados Unidos y procesarla allí. En el momento en que decide visitar
        nuestro Sitio Web, utilizar nuestros Servicios o proveernos su
        información de alguna otra manera, usted acepta que cualquier disputa o
        conflicto que surja sobre la privacidad o los términos incluidos en esta
        Política de Privacidad se regirá por las leyes de los Estados Unidos y
        el Estado de Florida.
      </p>
      <h2>ACTUALIZACIÓN DE LA POLÍTICA</h2>
      <p>
        La Política de Privacidad de BONUM LLC está sujeta a revisión constante
        y periódica. Debido a los constantes cambios jurídicos,
        jurisprudenciales y de las recomendaciones emitidas por las autoridades
        de control o, aún, de cambios a nuestro modelo de negocio, entre otras,
        podríamos vernos en la necesidad de modificar esta Política. Nos
        comprometemos a no realizar cambios sustanciales en esta Política que
        puedan poner en peligro tus derechos.
      </p>
      <h2>CONTACTOS</h2>
      Si al final de esta lectura aún te quedan dudas o para hacer efectivos tus
      derechos, entra en contacto con nosotros: BONUM LLC con dirección 1761 NE
      197th Terrace Miami, FL 33179 USA, e-mail:{' '}
      <a href="mailto:info@bonumcoaching.com">info@bonumcoaching.com</a>
    </div>
  );
};

export default PrivacyPolicy;
