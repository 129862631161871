import { Button } from '@chakra-ui/react';
import DayCalendar from 'components/DayCalendar';
import { useFetchAndLoad, useCoachCalendar } from 'hooks';
import { last, size } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createSession } from 'services/sessions.service';
import displayToast from 'utilities/toast.utility';
import Calendar from '../../components/Calendar';
import Advice from './components/Advice';
import AvailableSchedule from './components/AvailableSchedule';
import Scheduled from './components/Scheduled';
import useCoacheeCalendarContext from './hooks/useCoacheeCalendarContext';
import { useTranslation } from 'react-i18next';

function CoacheeCalendar() {
  const user = useSelector((state) => state.user);
  const { coach, sessions, timezone } = user;
  const { date, hour, setDate } = useCoacheeCalendarContext();
  const [scheduled, setScheduled] = useState(false);
  const navigate = useNavigate();
  const { getCoachCalendar, isNotWorkingDay } = useCoachCalendar(coach?._id);
  const { loading, callEndpoint } = useFetchAndLoad();
  const lastSession = last(sessions);
  const { t } = useTranslation('global');

  const hasSession = (date) => {
    if (size(sessions) < 1) return;

    return sessions.find((session) => {
      const sessionDate = DateTime.fromISO(session.date).toISODate();
      const calendarDate = DateTime.fromJSDate(date).toISODate();
      if (sessionDate === calendarDate) return session;
      return false;
    });
  };

  const getMyCoachCalendar = async () => {
    try {
      await getCoachCalendar();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!coach) {
      navigate('/');
    }
    getMyCoachCalendar();
  }, [coach]);

  useEffect(() => {}, [date]);

  const getHoursDifference = (dateWithTimezone) => {
    const currentDate = new Date();
    const sessionDateISO = new Date(dateWithTimezone).toISOString();
    const sessionDate = new Date(sessionDateISO);
    const hoursDifference =
      (sessionDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60);

    let currentHours = Math.round(hoursDifference);
    return currentHours;
  };

  const environment = process.env.REACT_APP_ENVIRONMENT;

  const createCoachingSession = async () => {
    try {
      const dateWithTimezone = DateTime.fromMillis(hour.startHour.ts, {
        zone: timezone
      }).toISO();

      let hours = getHoursDifference(dateWithTimezone);

      if (hours <= 24) {
        displayToast(`${t('pages.reschedule.timeLimit')}`, 'error');
        return;
      }

      const event = {
        title: t('pages.reschedule.eventTitle', {
          name: user.name,
          lastname: user.lastname
        }),
        calendarId: user.coach.calendar.id,
        status: 'confirmed',
        busy: true,
        read_only: true,
        description: t('pages.reschedule.description'),
        when: {
          object: 'timespan',
          start_time: hour.startHour.ts / 1000,
          end_time: hour.startHour.ts / 1000 + 3600,
          start_timezone: user.coach.timezone,
          end_timezone: user.coach.timezone
        }
      };

      await callEndpoint(
        createSession({
          coach: coach._id,
          coachee: user.mongoID,
          date: dateWithTimezone,
          event
        })
      );
    } catch (error) {
      if (error.response.status === 400) {
        setScheduled(true);
      }

      if (environment !== 'development') {
        // displayToast(error.message, 'error');
        // return;
        setScheduled(true);
      }

      setScheduled(true);
    }

    setScheduled(true);
  };

  const errorDate = t('pages.reschedule.errorDate');
  const errorHour = t('pages.reschedule.errorHour');

  const handleSchedule = () => {
    if (!date) {
      displayToast(errorDate, 'error');
      return; // Mostrar un error
    }
    if (!hour) {
      displayToast(errorHour, 'error');
      return; // Mostrar error
    }
    // Registrar en backend
    createCoachingSession();
  };

  const tileDisabled = (calendarDate) => {
    if (isNotWorkingDay(calendarDate)) return true;
    if (hasSession(calendarDate)) return false;
    const today = DateTime.now().toISODate();
    const isoDate = DateTime.fromJSDate(calendarDate).toISODate();
    if (isoDate <= today) return true;
    if (lastSession) {
      const lastSessionDatePlusTenDays = DateTime.fromISO(lastSession.date)
        .plus({ days: 4 })
        .toISODate();

      if (isoDate <= lastSessionDatePlusTenDays) return true;
    }
  };

  const tileClassName = (calendarDate) => {
    if (isNotWorkingDay(calendarDate)) return 'session session--notworking';

    const session = hasSession(calendarDate);
    if (session) {
      if (session?.canceled) return 'session session--canceled';
      if (session?.status) return 'session session--executed';
      else return 'session session--future';
    }
  };

  if (scheduled)
    return (
      <div className="CoacheeCalendar">
        <Scheduled />
        <Button
          mt={10}
          className="Button Button--primary"
          onClick={() => navigate('/')}
        >
          {t('pages.reschedule.buttonOk')}
        </Button>
      </div>
    );

  return (
    <div className="CoacheeCalendar">
      <h3 className="CoacheeCalendar__title">
        {t('pages.coacheeCalendar.title')}{' '}
        <span>
          {coach?.name} {coach?.lastname}
        </span>
      </h3>
      <div className="CoacheeCalendar__calendar">
        <Advice className="CoacheeCalendar__calendar_advice" />
        <div className="CoacheeCalendar__calendar_container">
          <Calendar
            value={date}
            setDate={setDate}
            tileDisabled={({ date: calendarDate }) =>
              tileDisabled(calendarDate)
            }
            tileClassName={({ date }) => tileClassName(date)}
          />
        </div>
      </div>

      {date && hasSession(date) ? (
        <DayCalendar date={date} sessions={sessions} />
      ) : (
        <>
          <div className="CoacheeCalendar__available">
            <AvailableSchedule
              handleSchedule={handleSchedule}
              isLoadingSchedule={loading}
              isDisableSchedule={loading}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default CoacheeCalendar;
