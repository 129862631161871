import React, { useEffect } from 'react';
// import { Checkbox, Switch } from '@chakra-ui/react';
import { dateToLongDate } from 'utilities/formatDate.utility';
import { useSelector } from 'react-redux';
import useScheduleContext from '../../hooks/useRescheduleContext';
import { useUserUtilities } from 'hooks';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

function Scheduled() {
  const { date, hour } = useScheduleContext();
  const user = useSelector((state) => state.user);
  const { name, lastname } = user;
  const { refreshSessions } = useUserUtilities(user);
  const { t } = useTranslation('global');

  useEffect(() => {
    refreshSessions();
  }, []);

  const navigate = useNavigate();

  const addToCalendar = () => {
    // Parámetros del evento
    const title = t('pages.reschedule.calendarSessionTitle');
    const startTime = DateTime.fromISO(hour.startHour, { zone: user.timezone });

    // hora de inicio y finalización del evento con 1 hora de diferencia
    const startTimeFormatted = startTime.toFormat("yyyyMMdd'T'HHmmss");
    const endTimeFormatted = startTime
      .plus({ hours: 1 })
      .toFormat("yyyyMMdd'T'HHmmss");

    // URL base para crear un nuevo evento en Google Calendar
    const googleCalendarBaseUrl =
      'https://calendar.google.com/calendar/render?action=TEMPLATE';

    // Ubicación del evento
    const location = 'app.bonumcoaching.com';

    // Construir la URL con los parámetros del evento, incluyendo la ubicación
    const eventUrl = `${googleCalendarBaseUrl}&text=${encodeURIComponent(
      title
    )}&dates=${startTimeFormatted}/${endTimeFormatted}&location=${encodeURIComponent(
      location
    )}`;

    // Abrir una nueva pestaña con la URL del evento en Google Calendar
    window.open(eventUrl, '_blank');
  };

  const addAnotherSession = () => {
    if (window.location.pathname !== '/mycalendar') {
      navigate('/mycalendar');
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="Scheduled Card">
      <h3 className="Scheduled__name">
        {t('pages.reschedule.components.scheduled.name', {
          name: name,
          lastname: lastname
        })}
      </h3>
      <p className="Scheduled__subtitle">
        {t('pages.reschedule.components.scheduled.subtitle')}
      </p>
      <h2 className="Scheduled__date">{dateToLongDate(date)}</h2>
      <p className="Scheduled__hour">{hour.time}</p>
      {/* <div className="Scheduled__calendar-schedule">
        <p className="Scheduled__calendar-schedule_description">
          Agendar en tu calendario
        </p>
        <Switch className="Scheduled__calendar-schedule_switch" />
      </div> */}
      <div className="Scheduled__remember">
        <p className="Scheduled__remember_advice">
          {t('pages.reschedule.components.scheduled.remember')}
        </p>

        <p className="Scheduled__remember_advice">
          {t('pages.reschedule.components.scheduled.remember2')}
        </p>

        <p className="Scheduled__remember_advice_bold">
          {t('pages.reschedule.components.scheduled.rememberBold')}
        </p>

        <div className="Scheduled__calendar_button">
          <Button className="Button Button--secondary" onClick={addToCalendar}>
            {t('pages.reschedule.addToCalendar')}
          </Button>
        </div>
        <div className="Scheduled__calendar_button">
          <Button
            className="Button Button--secondary"
            onClick={addAnotherSession}
          >
            {t('pages.reschedule.addAnotherSession')}
          </Button>
        </div>
        {/* <div className="Scheduled__remember_one-day">
          <Checkbox />
          <p>Un día antes</p>
        </div>
        <div className="Scheduled__remember_one-hour">
          <Checkbox />
          <p>Una hora antes</p>
        </div> */}
      </div>
    </div>
  );
}

export default Scheduled;
