const FocusAreaItem = ({ focusArea, selected, onClick }) => {
  return (
    <div className="FocusAreaItem" onClick={() => onClick(focusArea)}>
      <div
        className={`FocusAreaItem__icon ${
          selected ? 'FocusAreaItem__icon--selected' : ''
        }`}
      >
        <img src={focusArea.urlImgFocusArea} alt={focusArea.focusArea} />
      </div>
      <div className="FocusAreaItem__title">{focusArea.focusArea}</div>
    </div>
  );
};

export default FocusAreaItem;
