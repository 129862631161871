import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  meetingModal: false
};

export const meetingModalSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    openModal: (state) => {
      state.meetingModal = true;
    },
    closeModal: (state) => {
      state.meetingModal = false;
    }
  }
});

export const { openModal, closeModal } = meetingModalSlice.actions;

export default meetingModalSlice.reducer;
