import { Message } from '@chatscope/chat-ui-kit-react';
import Linkify from 'react-linkify';
import { AiOutlineFile } from 'react-icons/ai';

// Función para limpiar el contenido del mensaje
const cleanMessageContent = (content) => {
  const element = document.createElement('div');
  element.innerHTML = content;
  return element.textContent || element.innerText || '';
};

function MyMessage({ message, email, userName }) {
  const decodedText = decodeURIComponent(message.text);
  const cleanedText = cleanMessageContent(decodedText);

  if (message.user.email === email) {
    if (message.type === 'image') {
      return (
        <Message>
          <Message.ImageContent src={message.url} width="100%" />
        </Message>
      );
    }

    if (message.type === 'file') {
      return (
        <Message
          model={{
            direction: 'outgoing',
            position: 'first'
          }}
        >
          <Message.CustomContent>
            <a
              href={message.url}
              target="_blank"
              className="Chat__message-file"
            >
              <AiOutlineFile />
              {cleanedText}
            </a>
          </Message.CustomContent>
        </Message>
      );
    }

    return (
      <Message
        model={{
          direction: 'outgoing',
          position: 'first'
        }}
      >
        <Message.CustomContent>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                target="blank"
                href={decoratedHref}
                key={key}
                className={'Chat__message-link'}
              >
                {decoratedText}
              </a>
            )}
          >
            {cleanedText}
          </Linkify>
        </Message.CustomContent>
      </Message>
    );
  }

  if (message?.type === 'image') {
    return (
      <Message
        model={{
          sender: userName,
          direction: 'incoming',
          position: 'single'
        }}
      >
        <Message.ImageContent src={message.url} width="100%" />
      </Message>
    );
  }

  if (message.type === 'file') {
    return (
      <Message
        model={{
          direction: 'incoming',
          position: 'first'
        }}
      >
        <Message.CustomContent>
          <a href={message.url} target="_blank" className="Chat__message-file">
            <AiOutlineFile />
            {cleanedText}
          </a>
        </Message.CustomContent>
      </Message>
    );
  }

  return (
    <Message
      model={{
        sender: userName,
        direction: 'incoming',
        position: 'single'
      }}
    >
      <Message.CustomContent>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target="blank"
              href={decoratedHref}
              key={key}
              className={'Chat__message-link'}
            >
              {decoratedText}
            </a>
          )}
        >
          {cleanedText}
        </Linkify>
      </Message.CustomContent>
    </Message>
  );
}

export default MyMessage;
