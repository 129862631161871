import React from 'react';
import { Checkbox } from '@chakra-ui/react';

function SavedCalendar({ calendar, calendars, setCalendars }) {
  const isBonumCalendar = (calendarToCheck) =>
    calendarToCheck.name.includes('Calendario Bonum');

  const handleCalendarChange = (calendarData) => {
    setCalendars(
      calendars.map((tempCalendar) => {
        if (tempCalendar.id !== calendarData.id) return tempCalendar;
        return { ...tempCalendar, active: !calendarData?.active };
      })
    );
  };

  return (
    <div className="SavedCalendar">
      <Checkbox
        isChecked={calendar.active || isBonumCalendar(calendar)}
        className="SavedCalendar__checkbox"
        size="lg"
        disabled={isBonumCalendar(calendar)}
        defaultChecked={isBonumCalendar(calendar)}
        onChange={() => handleCalendarChange(calendar)}
      >
        {calendar.name}
      </Checkbox>
    </div>
  );
}

export default SavedCalendar;
