import React from 'react';
import { Image } from '@chakra-ui/react';
import BonumIcon from 'assets/images/icons/Bonum_icon.png';

function Loading({ title }) {
  return (
    <div className="Loading">
      <Image src={BonumIcon} className="Loading__dim" />
      {title && <h5 className="Loading__title Loading__dim">{title}</h5>}
    </div>
  );
}

export default Loading;
