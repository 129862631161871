import { storage } from 'utilities/firebase.utility';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { nanoid } from '@reduxjs/toolkit';

export const uploadFile = async (uri, path, fName, extension) => {
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError('Network request failed'));
    };
    xhr.responseType = 'blob';
    xhr.open('GET', uri, true);
    xhr.send(null);
  });

  let type = 'file';

  if (blob.type.includes('image')) {
    type = 'image';
  }

  if (blob.type.includes('video')) {
    type = 'video';
  }

  let filename = '';
  if (fName) filename = fName;
  else filename = `${nanoid()}.${extension}`;

  const imageRef = ref(storage, `${path}/${filename}`);

  const snapshot = await uploadBytes(imageRef, blob, {
    contentType: blob.type
  });

  const url = await getDownloadURL(snapshot.ref);

  return { url, filename, type };
};
