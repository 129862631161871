import axios from 'axios';
import { loadAbort } from 'utilities';

const sessionsURL = `${process.env.REACT_APP_STREAMING_URL}/api/meeting`;

export const updateStreaming = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/${data._id}`, data, {
        signal: controller.signal
      }),
    controller
  };
};
