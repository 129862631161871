import React from 'react';
import Modal from 'components/Modal';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function FinalModal({ modal, setModal }) {
  const navigate = useNavigate('/');
  const { t } = useTranslation('global');
  const session = useSelector((state) => state.session);
  const user = useSelector((state) => state.user);

  const handleBackHome = () => {
    setModal('');
    navigate('/');
  };
  return (
    <>
      <div className="FinalModal">
        <Modal
          content={<ModalContent session={session} user={user} />}
          isOpen={modal}
          title={modal}
          onClose={handleBackHome}
          size="4xl"
          closeText={
            user && user.role === 'coach'
              ? t('pages.sessionEvaluation.finalModal.asignLater')
              : t('pages.sessionEvaluation.finalModal.scheduleLater')
          }
        />
      </div>
    </>
  );
}

function ModalContent({ session, user }) {
  const { t } = useTranslation('global');
  const navigate = useNavigate('/');

  return (
    <div className="FinalModal__modal">
      <h4 className="FinalModal__modal_text">
        {t('pages.sessionEvaluation.finalModal.title')}
      </h4>
      <Button
        onClick={() => {
          if (user && user.role === 'coach') {
            navigate(`/mycoachees/${session.coachee._id}/${session.id}`);
          } else {
            navigate('/mycalendar');
          }
        }}
        className="Button Button--secondary FinalModal__next-button"
      >
        {user && user.role === 'coach'
          ? t('pages.sessionEvaluation.finalModal.coachOption')
          : t('pages.sessionEvaluation.finalModal.coacheeOption')}
      </Button>
    </div>
  );
}

export default FinalModal;
