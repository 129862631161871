import { Button } from '@chakra-ui/react';
import CheckedIcon from 'assets/images/icons/checked_icon.png';
import Modal from 'components/Modal';
import { useState } from 'react';
import { alternateCallSocket } from 'utilities/alternateCall.utility';

function AlternateCoachModal({ user }) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (coach) => {
    if (user.mongoID === coach) {
      setShowModal(true);
    }
  };

  alternateCallSocket.on('success-alternate-call', ({ coach }) => {
    handleShowModal(coach);
  });

  return (
    <Modal
      content={<ModalContent />}
      isOpen={showModal}
      title={
        <div className="flex justify-center text-center">
          <img src={CheckedIcon}></img>
        </div>
      }
      onClose={() => {
        localStorage.removeItem('session');
        setShowModal('');
      }}
      size="4xl"
      footer={<ModalFooter setShowModal={showModal} user={user} />}
      closeText="Close"
    />
  );
}

function ModalContent() {
  return (
    <div className="Assign__modal">
      <h4>Tu coachee ha recibido la alerta correctamente</h4>
      <p className="AlternateCall__coachee__description">
        Cuando finalize la sesión, puedes cerrarla en el botón de cerrar sesión
        para evaluarla y posteriormente llenar los puntos tratados y
        asignaciones
      </p>
    </div>
  );
}

function ModalFooter({ loading, setShowModal, user }) {
  const handleAlternateCallClick = () => {
    let url = user.alternateCall;
    if (!url.match(/^https?:\/\//i)) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  };

  return (
    <Button
      onClick={handleAlternateCallClick}
      alignSelf="center"
      className="Button Button--secondary Assign__evaluation-button"
      isLoading={loading}
      isDisabled={loading}
    >
      Ir a llamada alterna
    </Button>
  );
}

export default AlternateCoachModal;
