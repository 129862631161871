import React, { useContext, useState } from 'react';
import MyCoacheesContext from 'pages/MyCoachees/context/MyCoacheesContext';
import Assign from 'components/Assign';
import { useFetchAndLoad } from 'hooks';
import {
  createAssignation,
  deleteAssignation,
  editAssignation
} from 'services/assignations.service';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';
import AssignationsItems from './AssignationsItems';
import Modal from 'components/Modal';
import { Textarea, Button, Image } from '@chakra-ui/react';
import { AiFillPlusCircle } from 'react-icons/ai';

function Assignations() {
  const {
    selectedSession: session,
    setSelectedSession,
    selectedCoachee
  } = useContext(MyCoacheesContext);

  const { loading, callEndpoint } = useFetchAndLoad();

  const adapter = (values) =>
    values?.map((value) => ({
      id: value._id,
      title: value.title,
      status: value.status
    }));

  const { t } = useTranslation('global');
  const errorUpdate = t('pages.myCoachees.components.errorUpdate');
  const [modal, setModal] = useState('');
  const [selectedSubject, setselectedSubject] = useState(null);

  const addNewAssignation = async (value) => {
    try {
      const { data } = await callEndpoint(
        createAssignation({
          title: value,
          sessionId: session.id,
          user: selectedCoachee._id
        })
      );

      setSelectedSession({
        ...session,
        assignations: [...session.assignations, data.data]
      });
    } catch (error) {
      displayToast(errorUpdate, 'error');
      console.log(error);
    }
  };

  const editCoacheeAssignation = async (subject, value) => {
    try {
      const { data } = await callEndpoint(
        editAssignation({ ...subject, title: value })
      );
      setSelectedSession({
        ...session,
        assignations: session?.assignations?.map((assignation) => {
          if (assignation._id === subject.id)
            return { ...data.data, title: value };
          return assignation;
        })
      });
    } catch (error) {
      displayToast(errorUpdate, 'error');
    }
  };

  const deleteSucess = t('pages.myCoachees.components.deleteSucess');

  const deleteCoacheeAssignation = async (id) => {
    try {
      await callEndpoint(deleteAssignation(id));
      setSelectedSession({
        ...session,
        assignations: session?.assignations.filter(
          (assignation) => assignation._id !== id
        )
      });
      displayToast(deleteSucess, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewSubject = () => {
    setModal(`Agregar ${t('pages.myAssignations.title')}`);
  };

  const newSubject = (value) => {
    addNewAssignation(value);
    if (loading) return;
    setModal('');
  };

  return adapter(session.assignations)?.length > 0 ? (
    <div>
      <div className="flex justify-center items-center">
        <h4>{t('pages.myAssignations.title')}</h4>
      </div>

      <Modal
        content={
          <ModalContent
            onSave={selectedSubject ? null : newSubject}
            loading={loading}
            text={selectedSubject?.title}
          />
        }
        isOpen={modal}
        title={modal}
        onClose={() => setModal('')}
        size="4xl"
        closeText="Close"
      />

      {adapter(session.assignations)?.map((value, i) => (
        <AssignationsItems
          loading={loading}
          value={value}
          title={t('pages.myAssignations.title')}
          addSubject={addNewAssignation}
          editSubject={editCoacheeAssignation}
          deleteSubject={deleteCoacheeAssignation}
        />
      ))}

      <div
        className="Assign__container background2 Assign__button"
        onClick={handleNewSubject}
        role="button"
        tabIndex={0}
      >
        <p className="Assign__button_title ">
          Agregar {t('pages.myAssignations.title')}
        </p>
        <AiFillPlusCircle size={24} style={{ color: '#1890ff' }} />
      </div>
    </div>
  ) : (
    <>
      <Modal
        content={
          <ModalContent
            onSave={selectedSubject ? null : newSubject}
            loading={loading}
            text={selectedSubject?.title}
          />
        }
        isOpen={modal}
        title={modal}
        onClose={() => setModal('')}
        size="4xl"
        closeText="Close"
      />
      <div
        className="Assign__container background2 Assign__button"
        onClick={handleNewSubject}
        role="button"
        tabIndex={0}
      >
        <p className="Assign__button_title ">
          Agregar {t('pages.myAssignations.title')}
        </p>
        <AiFillPlusCircle size={24} style={{ color: '#1890ff' }} />
      </div>
    </>
  );
}

function ModalContent({ onSave, loading, text }) {
  const [value, setValue] = useState(text);

  return (
    <div className="Assign__modal">
      <Textarea
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <Button
        onClick={() => onSave(value)}
        alignSelf="center"
        className="Button Button--secondary Assign__save-button"
        isLoading={loading}
        isDisabled={loading}
      >
        Guardar
      </Button>
    </div>
  );
}

export default Assignations;
