import { Avatar, Conversation } from '@chatscope/chat-ui-kit-react';
import useChatContext from '../hooks/useChatContext';

function Contact({ user }) {
  const isCoach = user.role === 'coach';
  const { handleAddChat } = useChatContext();

  const getUnreadMessage = () => {
    if (!user?.lastMessage) return false;
    if (!user.lastMessage.unread) return false;
    if (user.lastMessage.userB.email !== user.email) return true;
  };

  return (
    <Conversation
      name={`${user.name} ${user.lastname}`}
      key={user._id}
      onClick={() => handleAddChat(user)}
      unreadDot={getUnreadMessage()}
    >
      <Avatar
        src={isCoach ? user.urlImgCoach : user.urlImgCoachee}
        name={`${user.name} ${user.lastname}`}
      />
    </Conversation>
  );
}

export default Contact;
