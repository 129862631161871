import { Button } from '@chakra-ui/react';
import adaptedSession from 'adapters/sessionsAdapter.adapter';
import CheckedIcon from 'assets/images/icons/alerta.png';
import Modal from 'components/Modal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { alternateCallSocket } from 'utilities/alternateCall.utility';

function AlternateCoacheeModal() {
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state: any) => state.user);
  const [alternateCallEnded, setAlternateCallEnded] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  alternateCallSocket.on('alternate-call', ({ coachee, link, session }) => {
    if (user.mongoID === coachee) {
      setData({ coachee, link, session });

      alternateCallSocket.emit('alternate-call-received', {
        coach: user.coach._id
      });
      setShowModal(true);
    }
  });

  alternateCallSocket.on('alternate-call-ended', ({ session, coachee }) => {
    setAlternateCallEnded(true);
    if (user.mongoID === coachee) {
      dispatch(adaptedSession(session));
      navigate('/sessionevaluation');
    }
  });

  return (
    <Modal
      content={
        alternateCallEnded ? (
          <AlternateCallEnded />
        ) : (
          <AlternateCallStarted data={data} />
        )
      }
      isOpen={showModal}
      title={
        <div className="flex justify-center text-center">
          <img src={CheckedIcon}></img>
        </div>
      }
      onClose={() => {
        localStorage.removeItem('session');
        setShowModal('');
      }}
      size="4xl"
      footer={<ModalFooter setShowModal={showModal} data={data} />}
      closeText="Close"
    />
  );
}

function AlternateCallStarted({ data }) {
  return (
    <div className="Assign__modal">
      <h4>Tu coach ha decidido realizar tu sesión via llamada alterna</h4>
      <p className="AlternateCall__coachee__description">
        Puedes copiar este link <span>{data.link}</span> y pegarlo en una nueva
        pestaña o puedes dar click en el boton de ir a llamada alterna
      </p>
    </div>
  );
}

function AlternateCallEnded() {
  return (
    <div className="Assign__modal">
      <h4>Tu coach ha cerrado la sesión</h4>
      <p className="AlternateCall__coachee__description">
        Tu coach cerró la sesión debido a que se realizó por llamada alterna, se
        te redirigirá para evaluar la sesión
      </p>
    </div>
  );
}

function ModalFooter({ loading, setModal, data }) {
  const handleAlternateCallClick = () => {
    let url = data.link;
    if (!url.match(/^https?:\/\//i)) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  };
  return (
    <Button
      onClick={handleAlternateCallClick}
      alignSelf="center"
      className="Button Button--secondary Assign__evaluation-button"
      isLoading={loading}
      isDisabled={loading}
    >
      Ir a llamada alterna
    </Button>
  );
}

export default AlternateCoacheeModal;
