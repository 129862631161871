import { DateTime } from 'luxon';
import store from 'redux/store';

const dateToLongDate = (unformatedDate) => {
  const formatedDate = DateTime.fromJSDate(unformatedDate);
  return formatedDate.toLocaleString(DateTime.DATE_FULL, { locale: 'es' });
};

const mongoDateToLongDate = ({ unformatedDate, language }) => {
  const { user } = store.getState();
  const formatedDate = DateTime.fromISO(unformatedDate);
  return formatedDate
    .setZone(user.timezone)
    .toLocaleString(DateTime.DATE_FULL, { locale: language });
};

const mongoDateToLongDateWithTime = ({ unformatedDate, language }) => {
  const { user } = store.getState();
  const formatedDate = DateTime.fromISO(unformatedDate, {
    zone: user.timezone
  });
  return formatedDate.toFormat('DDDD hh:mm a', { locale: language });
};

const mongoDateToShortDate = (unformatedDate) => {
  const { user } = store.getState();
  const formatedDate = DateTime.fromISO(unformatedDate, {
    zone: user.timezone
  });
  return formatedDate.toFormat('dd-MM-yyyy', { locale: 'es' });
};

const mongoDateToShortDateYearFirst = (unformatedDate) => {
  const formatedDate = DateTime.fromISO(unformatedDate);
  return formatedDate.toFormat('yyyy-MM-dd');
};

const mongoDateToTime = (unformatedDate) => {
  const { user } = store.getState();
  const formatedDate = DateTime.fromISO(unformatedDate, {
    zone: user.timezone
  });
  return formatedDate.toFormat('hh:mma');
};

const mongoDateToHour = (unformatedDate) => {
  const { user } = store.getState();
  const formatedDate = DateTime.fromISO(unformatedDate, {
    zone: user.timezone
  });
  return formatedDate.toFormat('HH:mm');
};

const mongoDateToTimePlusOneHour = (unformatedDate) => {
  const { user } = store.getState();
  const formatedDate = DateTime.fromISO(unformatedDate, {
    zone: user.timezone
  }).plus({ hours: 1 });
  return formatedDate.toFormat('hh:mma');
};

const mongoDateToSessionDate = (unformatedDate) => {
  const { user } = store.getState();
  const formatedDate = DateTime.fromISO(unformatedDate, {
    zone: user.timezone
  });
  return formatedDate.toFormat('DDD hh:mm a', { locale: 'es' });
};

export {
  dateToLongDate,
  mongoDateToLongDate,
  mongoDateToShortDate,
  mongoDateToHour,
  mongoDateToShortDateYearFirst,
  mongoDateToTime,
  mongoDateToTimePlusOneHour,
  mongoDateToLongDateWithTime,
  mongoDateToSessionDate
};
