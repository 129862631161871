import axios from 'axios';
import { loadAbort } from 'utilities';

const sessionsURL = `${process.env.REACT_APP_SESSIONS_URL}/api/session`;
const alignmentSessionsURL =
  process.env.REACT_APP_SESSIONS_URL + '/api/alignmentSession';

export const createSession = (data) => {
  const controller = loadAbort();

  return {
    call: () => axios.post(sessionsURL, data, { signal: controller.signal }),
    controller
  };
};

export const EndSession = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/EndSession/${data._id || data.id}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const AlternalCall = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.patch(`${sessionsURL}/AlternalCall`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateSession = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/${data._id || data.id}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateSessionNoShow = ({ id }) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/updateSessionNoShow/${id}/`, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateSessionNumber = ({ coacheeId, id }) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/updateSessionNumber/${id}/${coacheeId}`, {
        signal: controller.signal
      }),
    controller
  };
};

export const resetSessionNumber = ({ id }) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/resetSessionNumber/${id}`, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateSessionWithEvaluation = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(
        `${sessionsURL}/updateWithEvaluation/${data._id || data.id}`,
        data,
        {
          signal: controller.signal
        }
      ),
    controller
  };
};

export const getCoachSessions = (coach) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${sessionsURL}/coach/${coach}`, { signal: controller.signal }),
    controller
  };
};

export const getCoacheeSessions = (coachee) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${sessionsURL}/coachee/${coachee}`, {
        signal: controller.signal
      }),
    controller
  };
};

export const getSessionByID = (id) => {
  const controller = loadAbort();

  return {
    call: () => axios.get(`${sessionsURL}/${id}`),
    controller
  };
};

export const cancelSession = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/cancel/${data.id}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const cancelSessionByCoach = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/coach/cancel/${data._id}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const rescheduleSession = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${sessionsURL}/reschedule/${data._id || data.id}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const DeleteDemoSessions = (id) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.delete(`${sessionsURL}/DeleteDemoSession/${id}`, {
        signal: controller.signal
      }),
    controller
  };
};

export const getAllAlignmentSessionCoachee = (id) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${alignmentSessionsURL}/coachee/${id}`, {
        signal: controller.signal
      }),
    controller
  };
};

export const getAllAlignmentSessionCoach = (id) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${alignmentSessionsURL}/coach/${id}`, {
        signal: controller.signal
      }),
    controller
  };
};

export const coachAddAlignmentSession = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(`${alignmentSessionsURL}/coachAddAlignmentSession`, data, {
        signal: controller.signal
      }),
    controller
  };
};
