import { Avatar, AvatarBadge, Input, Spinner } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdModeEdit, MdOutlineEmail } from 'react-icons/md';
import { AiOutlinePhone } from 'react-icons/ai';
import { RiBuilding4Line, RiOrganizationChart } from 'react-icons/ri';
import ChangePassword from './components/ChangePassword';
import { useDropzone } from 'react-dropzone';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { storage, auth } from 'utilities/firebase.utility';
import { updateProfile } from 'firebase/auth';
import iconUpload from 'assets/images/icons/subir-foto.png';
import { setUserPhoto } from 'redux/slices/user';
import { updateCoach } from 'services/coach.service';
import { useFetchAndLoad } from 'hooks';

function Profile() {
  const {
    name,
    lastname,
    email,
    role,
    photo,
    company,
    department,
    phone,
    uid,
    mongoID
  } = useSelector((state) => state.user);

  const { callEndpoint } = useFetchAndLoad();

  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);

  const uploadImage = async (toUploadImage) => {
    try {
      const imageRef = ref(
        storage,
        `profilePictures/${uid}/profilepicture.png`
      );
      await uploadBytes(imageRef, toUploadImage, 'data_url');
      const downloadUrl = await getDownloadURL(imageRef);
      return downloadUrl;
    } catch (error) {
      console.log(
        '🚀 ~ file: UploadPhoto.js ~ line 25 ~ uploadImage ~ error',
        error.message
      );
      return false;
    }
  };

  const onDrop = useCallback(
    async (file) => {
      const imageURL = Object.assign(file[0], {
        preview: URL.createObjectURL(file[0])
      });
      try {
        setUploading(true);
        const uploadUrl = await uploadImage(imageURL);
        await updateProfile(auth.currentUser, { photoURL: uploadUrl });
        await callEndpoint(
          updateCoach({ id: mongoID, urlImgCoach: uploadUrl })
        );
        dispatch(setUserPhoto(uploadUrl));
        setUploading(false);
      } catch (error) {
        console.log(
          '🚀 ~ file: UploadPhoto.js ~ line 22 ~ UploadPhoto ~ error',
          error
        );
      }
    },
    [uploadImage]
  );

  useEffect(() => {}, [dispatch]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*'
  });

  return (
    <div className="Profile">
      {isDragActive ? (
        <div {...getRootProps()}>
          <Input {...getInputProps()} />
          <Avatar
            name={`${name} ${lastname}`}
            src={iconUpload}
            className="Profile__photo"
            size="3xl"
          >
            <AvatarBadge boxSize="2em" bg="#299eff">
              <MdModeEdit w={20} color="" />
            </AvatarBadge>
          </Avatar>
        </div>
      ) : (
        <div {...getRootProps()}>
          <Input {...getInputProps()} />
          {uploading ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              boxSize={'40'}
            />
          ) : (
            <Avatar
              name={`${name} ${lastname}`}
              src={photo}
              className="Profile__photo"
              size="3xl"
              loading="lazy"
            >
              <AvatarBadge boxSize="2em" bg="#299eff">
                <MdModeEdit w={20} color="" />
              </AvatarBadge>
            </Avatar>
          )}
        </div>
      )}

      <h3 className="Profile__name">
        {name} {lastname}
      </h3>

      <div className="Profile__data">
        <p className="Profile__data_role">{role}</p>
        {company && (
          <p>
            <RiBuilding4Line /> {company}
          </p>
        )}
        {department && (
          <p>
            <RiOrganizationChart /> {department}
          </p>
        )}

        <p>
          <AiOutlinePhone /> {phone}
        </p>

        <p>
          <MdOutlineEmail /> {email}
        </p>

        <ChangePassword />
      </div>
    </div>
  );
}

export default Profile;
