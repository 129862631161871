import { io, Socket } from 'socket.io-client';
import displayToast from './toast.utility';

const alternateCallSocket: Socket = io(
  process.env.REACT_APP_ALTERNATECALL_URL,
  {
    transports: ['websocket'],
    path: '/session'
  }
);

const makeAlternateCall = (user, nextSession) => {
  if (!user.alternateCall) {
    displayToast('No tienes tu link de llamada alterna configurado', 'error');
    return;
  }
  alternateCallSocket.emit('make-alternate-call', {
    link: user.alternateCall,
    room: user.mongoID,
    coachee: nextSession.coachee._id,
    session: nextSession
  });
};

const endByAlternatecall = (session, coach, coachee) => {
  alternateCallSocket.emit('end-alternate-call', {
    session,
    room: coach,
    coachee
  });
};

const connectToAlternateCallSocket = (user) => {
  if (user.mongoID) {
    if (user.role === 'coachee' && user?.coach) {
      alternateCallSocket.emit('join-alternate-call-room', {
        room: user.coach._id
      });
      // displayToast('Tu coach se a unido a la llamada', 'info');
    }

    if (user.role === 'coach') {
      alternateCallSocket.emit('join-alternate-call-room', {
        room: user.mongoID
      });
    }
  }
};

export {
  alternateCallSocket,
  makeAlternateCall,
  connectToAlternateCallSocket,
  endByAlternatecall
};
