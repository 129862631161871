import React, { useEffect } from 'react';
import { useCoachCalendar } from 'hooks';
import Calendar from 'components/Calendar';
import Modal from 'components/Modal';
import Loading from 'components/Loading';
import Advice from 'pages/CoachCalendar/Components/Advice';

function ViewCoachCalendar(props) {
  const { isOpen, onClose, coach } = props;
  const {
    getCoachCalendar,
    isNotWorkingDay,
    loadingCoachCalendar,
    workingHours
  } = useCoachCalendar(coach._id);

  useEffect(() => {
    getCoachCalendar();
  }, []);

  useEffect(() => {}, [workingHours]);

  const reflectWorkingHours = (date) => {
    if (isNotWorkingDay(date)) return 'session session--notworking';
  };

  return (
    <Modal
      title={`Este es el calendario del coach ${coach.name} ${coach.lastname}`}
      isOpen={isOpen}
      onClose={onClose}
      modalClass="ViewCoachCalendar__modal"
      size="6xl"
      closeText="Close"
    >
      {!loadingCoachCalendar ? (
        <div className="ViewCoachCalendar">
          <div className="ViewCoachCalendar__calendar">
            <Advice
              executed={false}
              future={false}
              blocked={false}
              className="ViewCoachCalendar__calendar_advice"
            />
            <Calendar
              tileDisabled={({ date }) => isNotWorkingDay(date)}
              tileClassName={({ date, view }) =>
                reflectWorkingHours(date, view)
              }
            />
          </div>

          {workingHours && (
            <div className="ViewCoachCalendar__workSchedules">
              {workingHours.map((schedule) => (
                <div className="ViewCoachCalendar__workSchedules_schedule">
                  <h5>{schedule.Day}</h5>
                  {schedule.Work ? (
                    <p className="ViewCoachCalendar__workSchedules_schedule_hours">
                      {schedule.InitialDate} - {schedule.EndDate}
                    </p>
                  ) : (
                    <p>Dia no laborable</p>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <Loading title="Cargando datos del Coach" />
      )}
    </Modal>
  );
}

export default ViewCoachCalendar;
