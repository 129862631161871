import { Button, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CongratulationsIcon from 'assets/images/icons/congratulations.png';
import { useTranslation } from 'react-i18next';

function Congratulations(props) {
  const { coachee, final } = props;
  const { t, i18n } = useTranslation('global');
  const nps = {
    es: 'https://bonumcoaching.typeform.com/bonum-final',
    en: 'https://bonumcoaching.typeform.com/npsfin-en',
    pt: 'https://bonumcoaching.typeform.com/npsfin-pt'
  };

  return (
    <div className="Congratulations">
      <Image className="Congratulations__icon" src={CongratulationsIcon} />
      <p className="Congratulations__description">
        {t('pages.autoevaluation.components.congratulations.description')}{' '}
        <strong>
          {coachee?.name} {coachee?.lastname}
        </strong>{' '}
        {t('pages.autoevaluation.components.congratulations.description2')}{' '}
      </p>
      <Button
        className="Button Button--primary Congratulations__button"
        onClick={() =>
          window.open(
            final ? nps[i18n.language] : 'https://app.bonumcoaching.com',
            '_self'
          )
        }
      >
        {final
          ? t('pages.autoevaluation.components.congratulations.buttonFinal')
          : t('pages.autoevaluation.components.congratulations.button')}
      </Button>
    </div>
  );
}

export default Congratulations;
