import React from 'react';
import Rating from '../Rating/Rating';

function EvaluationRate({ question }) {
  return (
    <div className="EvaluationRate">
      <h3 className="EvaluationRate__title">{question && question.title}</h3>
      <Rating id={question.id} ratingValue={question.ratingValue} />
    </div>
  );
}

export default EvaluationRate;
