import React from 'react';
import { useTranslation } from 'react-i18next';

function Advice(props) {
  const {
    executed = true,
    future = true,
    nonWorking = true,
    blocked = true,
    canceled = true
  } = props;
  const { t } = useTranslation('global');
  return (
    <div className="CoachCalendar__advice">
      {executed && (
        <p className="CoachCalendar__advice__message CoachCalendar__advice__message--executed">
          {t('pages.coachCalendar.executed')}
        </p>
      )}

      {future && (
        <p className="CoachCalendar__advice__message CoachCalendar__advice__message--future">
          {t('pages.coachCalendar.future')}
        </p>
      )}

      {nonWorking && (
        <p className="CoachCalendar__advice__message CoachCalendar__advice__message--nonworking">
          {t('pages.coachCalendar.nonWorking')}
        </p>
      )}

      {blocked && (
        <p className="CoachCalendar__advice__message CoachCalendar__advice__message--blocked">
          {t('pages.coachCalendar.blocked')}
        </p>
      )}

      {canceled && (
        <p className="CoachCalendar__advice__message CoachCalendar__advice__message--canceled">
          {t('pages.coachCalendar.cancelled')}
        </p>
      )}
    </div>
  );
}

export default Advice;
