import { RadioGroup, Stack, Radio } from '@chakra-ui/react';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Question = ({
  answer,
  handleValueChange,
  index,
  error,
  initialEvaluation,
  language
}) => {
  const { question } = answer;
  const { t } = useTranslation('global');
  const [initialEvaluationAnswer, setinitialEvaluationAnswer] = useState(false);

  const getInitialEvaluationAnswer = () => {
    if (!initialEvaluation?.answers) return;

    const initialAnswer = find(
      initialEvaluation?.answers,
      (initialAnswer) => initialAnswer?.question?._id === question?._id
    );
    setinitialEvaluationAnswer(initialAnswer?.value);
  };

  const getQuestionByLanguage = () => {
    switch (language) {
      case 'es': {
        return question.question;
      }

      case 'pt': {
        return question?.pt;
      }

      case 'en': {
        return question?.en;
      }
    }
  };

  useEffect(() => {
    getInitialEvaluationAnswer();
  }, [initialEvaluation]);

  useEffect(() => {}, [language]);

  return (
    <div className={`Question ${error ? `Question--error` : ''}`}>
      <p className="Question__question">
        {index}.- {getQuestionByLanguage()}
      </p>
      <div className="Question__answer">
        {initialEvaluationAnswer && (
          <div className="Question__initial-evaluation">
            <p>Calificación Inicial</p>
            <p>{initialEvaluationAnswer}</p>
          </div>
        )}

        <RadioGroup
          value={`${answer.value}`}
          onChange={(value) => handleValueChange(answer._id, value)}
          className="Question__radio_group"
        >
          {t('pages.autoevaluation.components.question.value1')}
          <Stack direction={'row'}>
            <Radio value="1">1</Radio>
            <Radio value="2">2</Radio>
          </Stack>
        </RadioGroup>

        <RadioGroup
          value={`${answer.value}`}
          onChange={(value) => handleValueChange(answer._id, value)}
          className="Question__radio_group"
        >
          {t('pages.autoevaluation.components.question.value2')}
          <Stack direction={'row'}>
            <Radio value="3">3</Radio>
            <Radio value="4">4</Radio>
          </Stack>
        </RadioGroup>

        <RadioGroup
          value={`${answer.value}`}
          onChange={(value) => handleValueChange(answer._id, value)}
          className="Question__radio_group"
        >
          {t('pages.autoevaluation.components.question.value4')}
          <Stack direction={'row'}>
            <Radio value="5">5</Radio>
            <Radio value="6">6</Radio>
          </Stack>
        </RadioGroup>

        <RadioGroup
          value={`${answer.value}`}
          onChange={(value) => handleValueChange(answer._id, value)}
          className="Question__radio_group"
        >
          {t('pages.autoevaluation.components.question.value5')}
          <Stack direction={'row'}>
            <Radio value="7">7</Radio>
            <Radio value="8">8</Radio>
          </Stack>
        </RadioGroup>

        <RadioGroup
          value={`${answer.value}`}
          onChange={(value) => handleValueChange(answer._id, value)}
          className="Question__radio_group"
        >
          {t('pages.autoevaluation.components.question.value6')}
          <Stack direction={'row'}>
            <Radio value="9">9</Radio>
            <Radio value="10">10</Radio>
          </Stack>
        </RadioGroup>
      </div>
    </div>
  );
};

export default Question;
