import React from 'react';
import {
  FormControl,
  Textarea,
  FormErrorMessage,
  Button
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setAboutYouCoach } from 'redux/slices/onboarding';
import validationSchema from './validationSchema';
import { useTranslation } from 'react-i18next';

function AboutYouCoach({ nextStep }) {
  const aboutYou = useSelector((state) => state.onboarding.aboutYouCoach);
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  const {
    handleSubmit,
    handleChange: formikHandleChange,
    values,
    errors,
    handleBlur,
    touched
  } = useFormik({
    initialValues: aboutYou,
    validationSchema,
    onSubmit: () => {
      nextStep();
    }
  });

  const handleChange = (e) => {
    dispatch(
      setAboutYouCoach({ ...aboutYou, [e.target.name]: e.target.value })
    );
    formikHandleChange(e);
  };

  return (
    <form className="AboutYouCoach" onSubmit={handleSubmit} id="AboutYouCoach">
      <h4>{t('pages.onboarding.components.aboutYouCoach.title')}</h4>
      <FormControl isInvalid={errors?.resume && touched?.resume}>
        <Textarea
          name="resume"
          placeholder={t(
            'pages.onboarding.components.aboutYouCoach.fieldResume'
          )}
          className="AboutYouCoach__textarea"
          resize="none"
          onChange={handleChange}
          value={values.resume}
          onBlur={handleBlur}
        />

        {errors?.resume && touched?.resume ? (
          <FormErrorMessage fontSize="lg">
            {t(
              `pages.onboarding.components.aboutYouCoach.validation.${errors.resume}`
            )}
          </FormErrorMessage>
        ) : null}
      </FormControl>

      <FormControl isInvalid={errors?.work && touched.work}>
        <Textarea
          name="work"
          placeholder={t('pages.onboarding.components.aboutYouCoach.fieldWork')}
          className="AboutYouCoach__textarea"
          resize="none"
          onChange={handleChange}
          value={values.work}
          onBlur={handleBlur}
        />

        {errors?.work && touched.work ? (
          <FormErrorMessage fontSize="lg">
            {t(
              `pages.onboarding.components.aboutYouCoach.validation.${errors.work}`
            )}
          </FormErrorMessage>
        ) : null}
      </FormControl>
    </form>
  );
}

export default AboutYouCoach;
