import React, { useState } from 'react';
import { Button, Image } from '@chakra-ui/react';
import CheckedIcon from 'assets/images/icons/alerta.png';
import Modal from 'components/Modal';
import { useNavigate } from 'react-router-dom';

function CoacheeModal({ modal, setModal, message = null, pathTo = null }) {
  return (
    <Modal
      content={<ModalContent message={message} />}
      isOpen={modal}
      title={
        <div className="flex justify-center text-center">
          <img className="Assign__image" src={CheckedIcon}></img>
        </div>
      }
      onClose={() => {
        localStorage.removeItem('session');
        setModal('');
      }}
      size="4xl"
      footer={<ModalFooter pathTo={pathTo} setModal={setModal} />}
      closeText="Close"
    />
  );
}

function ModalContent({ message }) {
  return (
    <div className="Assign__modal">
      <h4>
        {message
          ? message
          : 'Tu coach ha finalizado la sesion. Por favor evalua la sesion para continuar.'}
      </h4>
    </div>
  );
}

function ModalFooter({ loading, pathTo, setModal }) {
  const navigate = useNavigate('/');

  const windowWidth = window.innerWidth;

  const buttonStyle = {
    minWidth: windowWidth <= 600 ? '12rem' : '24rem',
    fontSize: windowWidth <= 600 ? '1.4rem' : 'inherit'
  };

  return (
    <Button
      onClick={() => {
        setModal(false);
        navigate(pathTo ? pathTo : '/sessionevaluation');
      }}
      alignSelf="center"
      className="Button Button--secondary Assign__evaluation-button"
      isLoading={loading}
      isDisabled={loading}
      style={buttonStyle}
    >
      Evaluar
    </Button>
  );
}

export default CoacheeModal;
