import React, { useEffect, useState } from 'react';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import { useSelector } from 'react-redux';
import {
  deleteCoachCalendar,
  getUserCalendars,
  updateUserCalendars
} from 'services/calendar.service';
import Loading from 'components/Loading';
import { groupBy, map, size } from 'lodash';
import NoData from 'components/NoData/NoData';
import userCalendars from 'adapters/userCalendars.adapter';
import { Button, Icon } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { BiCalendarPlus } from 'react-icons/bi';
import Calendar from './components/Calendar';
import displayToast from 'utilities/toast.utility';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Calendars() {
  const { mongoID } = useSelector((state) => state.user);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [calendars, setCalendars] = useState([]);
  const navigate = useNavigate();

  const { t } = useTranslation('global');

  const getCalendars = async () => {
    try {
      const response = await callEndpoint(getUserCalendars(mongoID));
      setCalendars(userCalendars(response.data));
    } catch (error) {
      displayToast('Error al obtener calendarios', 'error');
    }
  };

  const saveCalendars = async () => {
    try {
      await callEndpoint(updateUserCalendars(calendars));
      displayToast('Calendarios guardados con éxito', 'success');
    } catch (error) {
      console.log(
        '🚀 ~ file: SaveCalendars.js ~ line 35 ~ saveCalendars ~ error',
        error
      );
    }
  };

  const handleDeleteCalendar = async (calendar) => {
    try {
      await callEndpoint(deleteCoachCalendar(calendar));
      displayToast('Calendario borrado con éxito', 'success');
      getCalendars();
    } catch (error) {
      console.log(error);
      displayToast(error.response.data.message, 'error');
    }
  };

  useEffect(() => {
    getCalendars();
  }, []);

  useEffect(() => {}, [calendars]);

  if (loading) return <Loading />;

  return (
    <div className="SaveCalendars">
      <h2 className="SaveCalendars__title">
        {t('pages.preferences.calendar.title')}
      </h2>
      <p className="SaveCalendars__subtitle">
        {t('pages.preferences.calendar.subtitle')}
      </p>
      <Button
        className="Button Button--primary"
        leftIcon={<BiCalendarPlus />}
        onClick={() => navigate('/connectcalendar')}
      >
        {t('pages.preferences.calendar.connectButton')}
      </Button>
      {size(calendars) > 0 ? (
        map(groupBy(calendars, 'email'), (group, index) => (
          <div className="SaveCalendars__group background" key={index}>
            <div className="SaveCalendars__group__title">
              <Icon
                as={FcGoogle}
                className="SaveCalendars__group__title_icon"
              />
              <p className="SaveCalendars__group__title_description">{index}</p>
            </div>
            {group.map((calendar) => (
              <>
                <Calendar
                  key={calendar.id}
                  calendar={calendar}
                  group={index}
                  calendars={calendars}
                  setCalendars={setCalendars}
                />
              </>
            ))}

            <Button
              className="Button Button--delete"
              onClick={() => handleDeleteCalendar(group[0].email)}
            >
              Eliminar
            </Button>
          </div>
        ))
      ) : (
        <NoData title="No tienes ningun calendario" />
      )}

      {size(calendars) > 0 && (
        <Button className="Button Button--primary" onClick={saveCalendars}>
          {t('pages.preferences.calendar.saveButton')}
        </Button>
      )}
    </div>
  );
}

export default Calendars;
