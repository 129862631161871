import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CoacheeModal from '../ModalCoachee/CoacheeModals';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function CoacheeButtons() {
  const navigate = useNavigate();

  const { t } = useTranslation('global');

  const session = useSelector((state: any) => state.session);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (session && session.status && !session.evaluatedByCoachee) {
      setModal(true);
    }
  }, [session]);

  return (
    <>
      <CoacheeModal setModal={setModal} modal={modal} />
      <Button onClick={() => navigate('/mycoach')}>
        {t('pages.home.components.buttons.myCoach')}
      </Button>
      <Button onClick={() => navigate('/mycalendar')}>
        {t('pages.home.components.buttons.myCalendar')}
      </Button>
      <Button onClick={() => navigate('/mysessions')}>
        {t('pages.home.components.buttons.mySessions')}
      </Button>
      <Button onClick={() => navigate('/myassignations')}>
        {t('pages.home.components.buttons.myAssignations')}
      </Button>
    </>
  );
}

export default CoacheeButtons;
