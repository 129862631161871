import React from 'react';
import { Progress } from '@chakra-ui/react';
import { useSteps } from '../../hooks/useSteps';
import { useTranslation } from 'react-i18next';

function Steps({
  steps,
  activeStep,
  nextStep,
  barActive,
  activeBarStep,
  setShowArrows,
  setDisableArrows
}) {
  const Step = steps[activeStep].content;
  const { t } = useTranslation('global');

  return (
    <div className={`Steps ${barActive ? 'Steps__barActive' : ''}`}>
      {barActive ? (
        <div className="Steps__bar">
          <p className="Steps__step">
            {t('pages.onboarding.components.steps.step', {
              no: activeStep - (activeBarStep - 1)
            })}
          </p>
          <Progress
            className="Steps__progress"
            value={activeStep - activeBarStep + 2}
            min={1}
            max={4}
            colorScheme="bonumBlue"
            height="5"
          />
        </div>
      ) : null}

      <Step
        nextStep={nextStep}
        setShowArrows={setShowArrows}
        setDisableArrows={setDisableArrows}
      />
    </div>
  );
}

export { Steps, useSteps };
