import { Button } from '@chakra-ui/react';
import NoData from 'components/NoData/NoData';
import { useFetchAndLoad, useCoachCalendar, useUserUtilities } from 'hooks';
import { find, orderBy } from 'lodash';

import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  rescheduleSession,
  resetSessionNumber
} from 'services/sessions.service';
import { mongoDateToLongDate } from 'utilities';
import displayToast from 'utilities/toast.utility';
import Calendar from '../../components/Calendar';
import Advice from './components/Advice';
import AvailableSchedule from './components/AvailableSchedule';
import Scheduled from './components/Scheduled';
import useScheduleContext from './hooks/useRescheduleContext';
import { useTranslation } from 'react-i18next';

function RescheduleAppointment() {
  const user = useSelector((state) => state.user);
  const { sessionId } = useParams();
  const { coach, sessions, timezone } = user;
  const { date, hour, setDate } = useScheduleContext();
  const [scheduled, setScheduled] = useState(false);
  const navigate = useNavigate();
  const { getCoachCalendar, isNotWorkingDay } = useCoachCalendar(coach?._id);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [minDate, setMinDate] = useState(null);
  const [sessionToReschedule, setSessionToReschedule] = useState(false);
  const [orderedSessionsByDate, setOrderedSessionsByDate] = useState(
    orderBy(sessions, 'date', 'asc')
  );
  const { t, i18n } = useTranslation('global');
  const { refreshSessions } = useUserUtilities();
  const [disabledDates, setDisabledDates] = useState([]);

  const getMyCoachCalendar = async () => {
    try {
      await getCoachCalendar();
    } catch (error) {
      console.log(error);
    }
  };

  const getSessions = async () => {
    try {
      await refreshSessions();
    } catch (error) {
      console.log(error);
    }
  };

  const getSessionToReschedule = async () => {
    setSessionToReschedule(
      find(sessions, (session) => session._id === sessionId)
    );
  };

  const getMinDate = () => {
    if (!sessionToReschedule) {
      setMinDate(null);
      return;
    }
    setMinDate(
      //DateTime.fromISO(sessionToReschedule.date).minus({ days: 5 }).toJSDate()
      DateTime.now().toJSDate()
    );
  };

  useEffect(() => {
    if (!coach) {
      navigate('/');
    }
    getMyCoachCalendar();
  }, [coach]);

  useEffect(() => {}, [hour, orderedSessionsByDate]);

  useEffect(() => {
    getSessionToReschedule(sessionId);
  }, [sessionId]);

  useEffect(() => {
    getMinDate();
  }, [sessionToReschedule]);

  useEffect(() => {
    getSessionToReschedule(sessionId);
    setOrderedSessionsByDate(orderBy(sessions, 'date', 'asc'));
    getDisabledDates();
  }, [sessions]);

  useEffect(() => {
    getSessions();
  }, []);

  if (!coach) return true;

  const getHoursDifference = (dateWithTimezone) => {
    const currentDate = new Date();
    const sessionDateISO = new Date(dateWithTimezone).toISOString();
    const sessionDate = new Date(sessionDateISO);
    const hoursDifference =
      (sessionDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60);

    let currentHours = Math.round(hoursDifference);
    return currentHours;
  };

  const updateCoachingSession = async () => {
    try {
      const dateWithTimezone = DateTime.fromMillis(hour.startHour.ts, {
        zone: timezone
      }).toISO();

      let hours = getHoursDifference(dateWithTimezone);

      console.log('hours', hours);

      if (hours <= 24) {
        displayToast(`${t('pages.reschedule.timeLimit')}`, 'error');
        return;
      }

      const event = {
        title: t('pages.reschedule.eventTitle', {
          name: user.name,
          lastname: user.lastname
        }),
        calendarId: user.coach.calendar.id,
        status: 'confirmed',
        busy: true,
        read_only: true,
        description: t('pages.reschedule.description'),
        when: {
          object: 'timespan',
          start_time: hour.startHour.ts / 1000,
          end_time: hour.startHour.ts / 1000 + 3600,
          start_timezone: user.coach.timezone,
          end_timezone: user.coach.timezone
        }
      };

      await callEndpoint(
        rescheduleSession({
          date: dateWithTimezone,
          id: sessionId,
          canceled: false,
          noShow: false,
          status: false,
          event
        })
      );

      await callEndpoint(
        resetSessionNumber({
          id: sessionId
        })
      );

      setScheduled(true);
    } catch (error) {
      console.log(error);
      displayToast('Error', 'error');
    }
  };

  const errorDate = t('pages.reschedule.errorDate');
  const errorHour = t('pages.reschedule.errorHour');

  const handleSchedule = () => {
    if (!date) {
      displayToast(errorDate, 'error');
      return; // Mostrar un error
    }
    if (!hour) {
      displayToast(errorHour, 'error');
      return; // Mostrar error
    }
    // Registrar en backend
    updateCoachingSession();
  };

  const getDisabledDates = () => {
    const newDisabledDates = [];
    sessions?.forEach((session) => {
      for (let i = -4; i <= 4; i++) {
        const date = DateTime.fromISO(session.date)
          .plus({ days: i })
          .toISODate();
        // const sessionToRescheduleDate = DateTime.fromISO(
        //   sessionToReschedule.date
        // );
        // if (
        //   date === sessionToRescheduleDate.minus({ days: -1 }).toISODate() ||
        //   date === sessionToRescheduleDate.minus({ days: 1 }).toISODate()
        // )
        //   continue;
        newDisabledDates.push(date);
      }
    });

    setDisabledDates(newDisabledDates);
  };

  const tileDisabled = (calendarDate) => {
    if (isNotWorkingDay(calendarDate)) return true;
    const calendarDateAsIso = DateTime.fromJSDate(calendarDate).toISODate();
    const sessionToRescheduleDate = DateTime.fromISO(
      sessionToReschedule?.date
    ).toISODate();

    if (calendarDateAsIso === sessionToRescheduleDate) {
      return false;
    }

    if (hasSession(calendarDate)) return true;

    return disabledDates.includes(
      DateTime.fromJSDate(calendarDate).toISODate()
    );
  };

  const hasSession = (date) =>
    sessions?.find((session) => {
      if (session._id === sessionId) return false;
      const sessionDate = DateTime.fromISO(session.date)
        .setZone(timezone)
        .toISODate();
      const calendarDate = DateTime.fromJSDate(date)
        .setZone(timezone)
        .toISODate();
      if (sessionDate === calendarDate) return true;
      return false;
    });

  const tileClassName = (calendarDate) => {
    if (isNotWorkingDay(calendarDate)) return 'session session--notworking';

    const session = hasSession(calendarDate);
    if (session) {
      if (session?.canceled) return 'session session--canceled';
      if (session?.status) return 'session session--executed';
      else return 'session session--future';
    }
  };

  if (scheduled)
    return (
      <div className="RescheduleAppointment">
        <Scheduled />
        <Button
          mt={10}
          className="Button Button--primary"
          onClick={() => navigate('/')}
        >
          {t('pages.reschedule.buttonOk')}
        </Button>
      </div>
    );

  if (!sessionId) return <NoData title={t('pages.reschedule.noData')} />;

  if (sessionToReschedule && sessionToReschedule?.noShow)
    return <NoData title={t('pages.reschedule.noShow')} />;

  return (
    <div className="RescheduleAppointment">
      <h3 className="RescheduleAppointment__title">
        {t('pages.reschedule.title')}{' '}
        <span>
          {sessionToReschedule &&
            mongoDateToLongDate({
              unformatedDate: sessionToReschedule?.date,
              language: i18n.language
            })}
        </span>
      </h3>
      <div className="RescheduleAppointment__calendar">
        <Advice className="RescheduleAppointment__calendar_advice" />
        <div className="RescheduleAppointment__calendar_container">
          <Calendar
            minDate={minDate}
            value={date}
            setDate={setDate}
            tileDisabled={({ date: calendarDate }) =>
              tileDisabled(calendarDate)
            }
            tileClassName={({ date }) => tileClassName(date)}
          />
        </div>
      </div>

      {date && (
        <div className="RescheduleAppointment__available">
          <AvailableSchedule
            handleSchedule={handleSchedule}
            isLoadingSchedule={loading}
            isDisableSchedule={loading}
          />
        </div>
      )}
    </div>
  );
}

export default RescheduleAppointment;
