import React, { useState, useEffect } from 'react';
import { size } from 'lodash';
import NoData from 'components/NoData/NoData';
import { Button, Image, Textarea } from '@chakra-ui/react';
import Point from 'assets/images/icons/points.png';
import AddIcon from 'assets/images/icons/add_icon.png';
import Modal from 'components/Modal';
import {
  RiDeleteBin6Line,
  RiEditBoxLine,
  RiCheckLine,
  RiCheckboxLine
} from 'react-icons/ri';

function TreatedsSubjectsItem({
  value,
  title = 'Titulo a asignar',
  addSubject = () => null,
  editSubject = () => null,
  deleteSubject = () => null,
  loading
}) {
  const [selectedSubject, setselectedSubject] = useState(null);
  const [modal, setModal] = useState('');

  const handleSubjectEdit = (subject) => {
    setselectedSubject(subject);
    setModal(`Editar ${title}`);
  };

  const handleNewSubject = () => {
    setModal(`Agregar ${title}`);
  };

  const modifySubject = (value) => {
    editSubject(selectedSubject, value);
    setselectedSubject(false);
    setModal('');
  };

  const newSubject = (value) => {
    addSubject(value);
    if (loading) return;
    setModal('');
  };

  useEffect(() => {}, [value]);

  return (
    <div className="Assign__container background2 ">
      <div className="Assign">
        <Modal
          content={
            <ModalContent
              onSave={selectedSubject ? modifySubject : newSubject}
              loading={loading}
              text={selectedSubject?.title}
            />
          }
          isOpen={modal}
          title={modal}
          onClose={() => setModal('')}
          size="4xl"
          closeText="Close"
        />
        {value ? (
          <div className="Assign__subject" key={`${value.title}${value.id}`}>
            <div className="Assign__subject_title">
              <Image src={Point} className="Assign__subject_title_dot" />
              <p>{value.title}</p>
            </div>

            {value.status ? (
              <div className="Assign__subject_icons">
                <p className="Assign__completed">Completada</p>
                <RiCheckboxLine />
              </div>
            ) : (
              <div className="Assign__subject_icons">
                <RiEditBoxLine
                  className="Assign__subject_icon"
                  size={24}
                  onClick={() => handleSubjectEdit(value)}
                />
                <RiDeleteBin6Line
                  className="Assign__subject_icon"
                  size={24}
                  onClick={() => deleteSubject(value.id)}
                />
              </div>
            )}
          </div>
        ) : (
          <NoData title={`No tienes ${title}`} />
        )}
      </div>
    </div>
  );
}

function ModalContent({ onSave, loading, text }) {
  const [value, setValue] = useState(text);

  return (
    <div className="Assign__modal">
      <Textarea
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <Button
        onClick={() => onSave(value)}
        alignSelf="center"
        className="Button Button--secondary Assign__save-button"
        isLoading={loading}
        isDisabled={loading}
      >
        Guardar
      </Button>
    </div>
  );
}

export default TreatedsSubjectsItem;
