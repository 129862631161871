import LanguageSelector from 'components/LanguageSelector';
import bonumLogo from '../../assets/images/icons/Bonum_icon.png';
import { useTranslation } from 'react-i18next';

function Footer() {
  const year = new Date().getFullYear();

  const { t } = useTranslation('global');

  return (
    <footer className="Footer">
      <div>
        <img src={bonumLogo} alt="Logo Bonum" className="Footer__logo" />
        <p className="Footer__rights">
          &copy; {year} {t('pages.footer.rightsReserved')}
        </p>
        <a href="https://app.bonumcoaching.com/privacy-policy">
          {t('pages.footer.privacyPolicy')}
        </a>
        <p>
          <a href="https://app.bonumcoaching.com/terms-and-conditions">
            {t('pages.footer.termsAndConditions')}
          </a>
        </p>
      </div>

      <div className="Footer__translations">
        <span className="Footer__translations-text">
          {t('pages.footer.googleT')}
        </span>
        <LanguageSelector />
      </div>
    </footer>
  );
}

export default Footer;
