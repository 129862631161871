import React from 'react';
import BonumIcon from 'assets/images/icons/Bonum_icon.png';
import { Image } from '@chakra-ui/react';

function NoData(props) {
  const { title = 'No hay datos para mostrar' } = props;

  return (
    <div className='NoData'>
      <Image src={BonumIcon} className='NoData__icon' />
      <p className='NoData__title'>{title}</p>
    </div>
  );
}

export default NoData;
