const focusAreasAdapter = (areas) => {
  return areas?.map((area) => ({
    focusArea: area.focusArea,
    statusArea: area.statusArea,
    createdAt: area.createdAt,
    updatedAt: area.updatedAt,
    urlImgFocusArea: area.urlImgFocusArea,
    id: area._id,
    en: area.en,
    pt: area.pt
  }));
};

export default focusAreasAdapter;
