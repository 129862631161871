// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const environment = process.env.REACT_APP_ENVIRONMENT;

const firebaseConfig =
  environment !== 'development'
    ? {
        apiKey: 'AIzaSyB5McU4c6SV_i8GQdawViwZv1tjwuSfbGQ',
        authDomain: 'bonum-web-production.firebaseapp.com',
        projectId: 'bonum-web-production',
        storageBucket: 'bonum-web-production.appspot.com',
        messagingSenderId: '257283497244',
        appId: '1:257283497244:web:68112e9d12ce94660f2ed9',
        measurementId: 'G-LTFVRX7YN3'
      }
    : {
        apiKey: 'AIzaSyBePGAmFdmabeI9jat4cFyxPg5RwML-arI',
        authDomain: 'bonum-web-test.firebaseapp.com',
        projectId: 'bonum-web-test',
        storageBucket: 'bonum-web-test.appspot.com',
        messagingSenderId: '312546331631',
        appId: '1:312546331631:web:2d20f2f62b8dcd0d91041a'
      };

export function translateFirebaseErrors({ code }) {
  let message = '';
  switch (code) {
    case 'auth/wrong-password': {
      message = 'La contraseña es incorrecta';
      break;
    }

    case 'auth/user-not-found': {
      message = 'El usuario no existe';
      break;
    }

    case 'auth/too-many-requests': {
      message = 'Demasiados intentos, vuelva a intentar en 10 minutos';
      break;
    }

    case 'EMAIL_NOT_FOUND': {
      message = 'El correo no existe';
      break;
    }

    case 'INVALID_PASSWORD': {
      message = 'Contraseña incorrecta';
      break;
    }

    default: {
      message = 'Error, contacte al administrador';
      break;
    }
  }

  return message;
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
