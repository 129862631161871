import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import Chat from 'pages/Chat';
import { io, Socket } from 'socket.io-client';
import { modifyUser } from 'redux/slices/user';

let socket: Socket<any> = null;

function BasicLayout({ children }) {
  const { uid, role, coach, coachees } = useSelector(
    (state: any) => state.user
  );
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  const isCoach = role === 'coach';
  const [isChatActive, setIsChatActive] = useState(false);

  const getIsChatActive = () => {
    if (!uid) {
      setIsChatActive(false);
      return;
    }
    if (isCoach && coachees?.length > 0) setIsChatActive(true);
    if (!isCoach && coach) setIsChatActive(true);
  };

  useEffect(() => {
    joinToListen();
    getIsChatActive();
  }, [uid, coachees, coach]);

  const joinToListen = () => {
    if (isCoach) {
      socket.emit('join-user-room', {
        room: uid
      });
      socket.on('update-user', ({ coachee, coach }) => {
        if (coach && coach.firebaseUID === uid) {
          let allcoachees = [coachee];
          if (
            user.coachees &&
            Array.isArray(user.coachees) &&
            user.coachees.length > 0
          ) {
            allcoachees = [user.coachees, ...allcoachees];
          }
          dispatch(modifyUser({ ...user, coachees: allcoachees }));
        }
      });
    }
  };

  useEffect(() => {
    socket = io(process.env.REACT_APP_USER_URL, {
      transports: ['websocket'],
      path: '/user'
    });

    socket.on('connect', () => {
      console.log('connected', isCoach, { role });
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  return (
    <div className="BasicLayout">
      <Header />
      <div className="container">{children}</div>
      {isChatActive && <Chat />}
      <Footer />
    </div>
  );
}

export default BasicLayout;
