import axios from 'axios';
import { loadAbort } from 'utilities';

const coacheeUrl = `${process.env.REACT_APP_USERS_URL}/api/coachee`;

export const updateCoacheeOnboarding = (onboarding, user) => {
  const controller = loadAbort();
  const { name, lastname } = onboarding.createYourAccount;
  const evaluation = onboarding.evaluation;

  const coacheeEvaluators = [
    ...evaluation.colaborators,
    ...evaluation.supervisors,
    ...evaluation.partners
  ];

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();

  const data = {
    firebaseUID: user.uid,
    urlImgCoachee: onboarding.photo,
    name,
    lastname,
    focusAreas: onboarding.focusAreas.map((focusArea) => focusArea.id),
    evaluators: coacheeEvaluators,
    coach: user.coach._id,
    timezone: onboarding.timezone,
    languages: onboarding.languages,
    onboardingDate: formattedDate
  };

  return {
    call: () =>
      axios.put(`${coacheeUrl}/onboarding/${user.uid}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const getCoacheeBySearch = (search, coach) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${coacheeUrl}/coach`, {
        params: {
          searchText: search,
          coach: coach
        },
        signal: controller.signal
      }),
    controller
  };
};

export const updateEvaluationCoachee = (user, data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${coacheeUrl}/${user}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const updateDemoCoachee = (user, data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.put(`${coacheeUrl}/updateDemo/${user}`, data, {
        signal: controller.signal
      }),
    controller
  };
};

export const deleteCoachee = (id) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.delete(`${coacheeUrl}/delete/${id}`, {
        signal: controller.signal
      }),
    controller
  };
};

export const post = (data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.post(coacheeUrl, { ...data }, { signal: controller.signal }),
    controller
  };
};

export const createEvaluationsPDF = (coacheeId, evaluationType, language) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(
        `${coacheeUrl}/createEvaluationsPDF`,
        { coacheeId, evaluationType, language },
        {
          signal: controller.signal,
          responseType: 'blob'
        }
      ),
    controller
  };
};
