import { Button } from '@chakra-ui/react';
import { mongoDateToSessionDate } from 'utilities';

export type BlockedScheduleProps = {
  blockedSchedule: any;
  handleDeleteSchedule: any;
  loading: boolean;
};

const BlockedSchedule: React.FC<BlockedScheduleProps> = ({
  blockedSchedule,
  handleDeleteSchedule
}) => {
  return (
    <div className="BlockedSchedule__list_item">
      <div className="BlockedSchedule__list_item_date">
        <h3 className="BlockedSchedule__list_item_date_title">Fecha Inicial</h3>
        <p className="BlockedSchedule__list_item_date_description">
          {mongoDateToSessionDate(blockedSchedule.InitialDate)}
        </p>
      </div>

      <div className="BlockedSchedule__list_item_date">
        <h3 className="BlockedSchedule__list_item_date_title">Fecha Final</h3>
        <p className="BlockedSchedule__list_item_date_description">
          {mongoDateToSessionDate(blockedSchedule.EndDate)}
        </p>
      </div>

      <Button
        className="Button Button--secondary"
        onClick={() => handleDeleteSchedule(blockedSchedule)}
      >
        Eliminar
      </Button>
    </div>
  );
};

export default BlockedSchedule;
