import React from 'react';
import { components } from 'react-select';
import { Icon } from '@chakra-ui/react';

function IconOption(props) {
  const { Option } = components;
  const { data } = props;
  return (
    <Option {...props} className='IconOption'>
      <Icon as={data.icon} className='IconOption__icon' />
      {data.label}
    </Option>
  );
}

export default IconOption;
