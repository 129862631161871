import React from 'react';
import {
  Modal as ModalChakra,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalContent,
  Button
} from '@chakra-ui/react';

function Modal({
  content,
  isOpen,
  onClose,
  title,
  children,
  modalClass,
  size = '2xl',
  scrollBehavior = 'inside',
  footer,
  closeText
}) {
  const windowWidth = window.innerWidth;

  const modalSize = windowWidth <= 600 ? 'lg' : size;

  const buttonStyle = {
    minWidth: windowWidth <= 600 ? '12rem' : '24rem',
    fontSize: windowWidth <= 600 ? '1.4rem' : 'inherit'
  };

  return (
    <ModalChakra
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc
      isCentered
      size={modalSize}
      className={modalClass}
      scrollBehavior={scrollBehavior}
    >
      <ModalOverlay />
      <ModalContent>
        {title ? (
          <ModalHeader textAlign="center">
            <h4>{title}</h4>
          </ModalHeader>
        ) : null}
        <ModalBody>
          {content} {children}
        </ModalBody>

        <ModalFooter>
          <Button
            className="Button Button--cancel"
            alignSelf="center"
            onClick={onClose}
          >
            {closeText}
          </Button>
          {footer}
        </ModalFooter>
      </ModalContent>
    </ModalChakra>
  );
}

export default Modal;
