import { useEffect, useState } from 'react';
import { Calendar, luxonLocalizer } from 'react-big-calendar';
import { DateTime, Settings } from 'luxon';
import { filter, forEach, map } from 'lodash';
import { useSelector } from 'react-redux';
import EventWrapper from './components/EventWrapper';
import { useTranslation } from 'react-i18next';
import { dateToLongDate } from 'utilities';

function DayCalendar({ date, sessions, isCoach }) {
  //const { selectedDate } = useContext(CoachCalendarContext);
  const [events, setEvents] = useState([]);
  const { timezone } = useSelector((state) => state.user);
  Settings.defaultZone = timezone;
  const localizer = luxonLocalizer(DateTime);
  const { t } = useTranslation('global');

  const formats = {
    timeGutterFormat: (date, culture, localizer) =>
      localizer.format(date, 'hh:mm a', culture),
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'hh:mm a')} - ${localizer.format(
        end,
        'hh:mm a'
      )}`,
    agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
      `${localizer.format(start, 'hh:mm a')} a ${localizer.format(
        end,
        'hh:mm a'
      )}`,

    agendaDateFormat: (date, culture, localizer) =>
      localizer.format(date, 'EEE dd MMM', culture)
  };

  useEffect(() => {
    console.log('Events', events);
  }, [events]);

  const getEvents = () => {
    const matchedEvents = filter(
      sessions,
      (session) =>
        DateTime.fromJSDate(date).toISODate() ===
        DateTime.fromISO(session.date).toISODate()
    );

    const parsedEvents = [];

    forEach(matchedEvents, (matchedEvent) => {
      if (isCoach) {
        const { coachee } = matchedEvent;
        parsedEvents.push({
          title: t('components.dayCalendar.title', {
            name: coachee?.name,
            lastname: coachee?.lastname
          }),
          start: DateTime.fromISO(matchedEvent.date, {
            zone: timezone,
            setZone: true
          }).toJSDate(),
          end: DateTime.fromISO(matchedEvent.date, {
            zone: timezone,
            setZone: true
          })
            .plus({ hours: 1 })
            .toJSDate(),
          isExecuted: matchedEvent.status,
          isCanceled: matchedEvent.canceled
        });
      } else {
        const { coach } = matchedEvent;
        parsedEvents.push({
          title: t('components.dayCalendar.title', {
            name: coach?.name,
            lastname: coach?.lastname
          }),
          start: DateTime.fromISO(matchedEvent.date, {
            zone: timezone,
            setZone: true
          }).toJSDate(),
          end: DateTime.fromISO(matchedEvent.date, {
            zone: timezone,
            setZone: true
          })
            .plus({ hours: 1 })
            .toJSDate(),
          isExecuted: matchedEvent.status,
          isCanceled: matchedEvent.canceled
        });
      }

      setEvents(parsedEvents);
    });
  };

  useEffect(() => {
    getEvents();
  }, [date]);

  return (
    <div className="DayCalendar">
      <h3>{date ? dateToLongDate(date) : ''}</h3>
      <Calendar
        scrollToTime
        className="DayCalendar__calendar"
        onNavigate={() => null}
        date={date}
        localizer={localizer}
        defaultView="day"
        toolbar={false}
        events={events}
        formats={formats}
        allDayAccessor={false}
        components={{
          eventWrapper: EventWrapper
        }}
      />
    </div>
  );
}

export default DayCalendar;
