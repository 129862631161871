import { useEffect } from 'react';
import NoData from 'components/NoData/NoData';
import { Image } from '@chakra-ui/react';
import Point from 'assets/images/icons/points.png';
import noPoint from 'assets/images/icons/nopoints2.png';
import { useTranslation } from 'react-i18next';

function AssignationsItem({ value, title = 'Titulo a asignar', handleChange }) {
  const { t } = useTranslation('global');

  useEffect(() => {}, [value]);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString)
      .toLocaleDateString(undefined, options)
      .replace(/\//g, '-');
  };

  return (
    <div className="Assign__container background2 ">
      <div className="Assign">
        {value ? (
          <div>
            <h5
              style={{
                marginLeft: '1.5em',
                marginBottom: '1em',
                marginTop: '0.4em'
              }}
            >
              {value.title}
            </h5>
            <div>
              <span style={{ marginLeft: '1.5em', color: '#87d1f2' }}>
                {t('pages.myCoachees.components.session')} {value?.session}{' '}
              </span>
              <span style={{ marginLeft: '0.6em', color: '#a9bacd' }}>
                {' '}
                {formatDate(value?.date)}
              </span>
            </div>
            <div className="Assign__subject" key={`${value.title}${value.id}`}>
              {value.status ? (
                <button onClick={() => handleChange(value)}>
                  <div className="Assign__subject_title">
                    <Image src={Point} className="Assign__subject_title_dot" />
                    <p>Completado</p>
                  </div>
                </button>
              ) : (
                <button onClick={() => handleChange(value)}>
                  <div className="Assign__subject_title">
                    <Image
                      src={noPoint}
                      className="Assign__subject_title_dot"
                    />
                    <p>In progress</p>
                  </div>
                </button>
              )}
            </div>
          </div>
        ) : (
          <NoData title={`No tienes ${title}`} />
        )}
      </div>
    </div>
  );
}

export default AssignationsItem;
