const TermsAndConditions = () => {
  return (
    <div className="PrivacyPolicy">
      <h1 className="PrivacyPolicy__title">
        TERMINOS Y CONDICIONES BONUM COACHING LLC
      </h1>
      <h2>TERMINOS Y CONDICIONES</h2>
      <p>
        El presente documento tiene como finalidad el establecer y regular las
        normas acceso a nuestros servicios, uso de este portal web y aplicación
        móvil entendiendo por éste todas las páginas y sus contenidos propiedad
        de BONUM COACHING LLC con domicilio en 1761 NE 197th Terrace, Miami, FL
        33179 USA, en adelante (‘’La Empresa, BONUM’’) a las cuales se accede a
        través del dominio{' '}
        <a href="https://app.bonumcoaching.com/">
          https://app.bonumcoaching.com/
        </a>
      </p>
      <p>
        La utilización del portal web atribuye la condición de usuario del mismo
        e implica la aceptación de todas las condiciones incluidas en el
        presente Aviso Legal. El usuario en adelante ‘’coach profesional,
        coachee y/o contratante’’ se comprometen a leer atentamente el presente
        Aviso Legal en cada una de las ocasiones en que se proponga utilizar
        nuestro portal web ya que éste y sus condiciones de uso recogidas en el
        presente Aviso Legal pueden sufrir modificaciones.
      </p>
      <h2>ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES GENERALES.</h2>
      <p>
        El registro en la web y/o en la aplicación móvil de BONUM y la
        prestación del servicio dependerá de la aceptación de estas condiciones,
        por lo que cualquier Coach profesional, contratante y/o coachee que no
        esté de acuerdo o no se comprometa a comportarse de acuerdo con éstas,
        no podrá utilizar dicho servicio.
      </p>
      <p>
        De esta forma, para cada persona que es un Coach profesional,
        contratante, o coachee del servicio que ofrece BONUM se asume mediante
        el registro, que ha leído completamente los Términos y Condiciones y la
        Política de Privacidad, aceptado expresamente ambos.
      </p>
      <p>
        BONUM se reserva el derecho de cambiar en cualquier momento y sin previo
        aviso los Términos y Condiciones (parcial o totalmente), y cada nueva
        versión de los Términos y Condiciones entrará en vigor después de su
        publicación en el sitio web. Al aceptar estos Términos y Condiciones,
        los usuarios se comprometen a visitar y estudiar estos términos con
        regularidad.
      </p>
      <h2>VINCULACIÓN A LAS PRESENTES DISPOSICIONES</h2>
      <p>
        A partir del momento en que el Coach profesional, el contratante y/o el
        coachee aceptan estos Términos están siendo vinculado legalmente a las
        estipulaciones y aceptando someterse a las versiones más actuales de
        estos términos, de la Política de Privacidad y demás contratos alternos
        producto de la presente relación y servicio.
      </p>
      <p>
        Podrá retirar su consentimiento en cualquier momento y, así, proceder a
        la revocación de este contrato, no pudiendo ser reconocido ningún
        derecho compensatorio o devolución de los importes ya pagados.
      </p>
      <p>
        Las presentes disposiciones se aplicarán entre las partes durante toda
        la duración del servicio que se determinará en función del plan como
        profesional, como contratante y/o como coachee.
      </p>
      <h2>REGISTRO Y USUARIOS AUTORIZADOS</h2>
      <p>
        Si desea acceder y utilizar los Servicios, debe crear una cuenta
        ("Cuenta"). Es importante que nos proporcione información precisa,
        completa y actualizada para su Cuenta. Si no lo hace, es posible que
        tengamos que suspender o cancelar su Cuenta. Usted acepta que no
        revelará la contraseña de su Cuenta a nadie y nos notificará
        inmediatamente de cualquier uso no autorizado de su Cuenta.
      </p>
      <p>
        Los usuarios deberán proveer la siguiente información, y seguir las
        instrucciones descriptas:
      </p>
      <ul
        style={{
          listStyleType: 'circle',
          paddingLeft: '2rem',
          paddingRight: '2rem'
        }}
      >
        <li>Nombre completo (nombre y apellido);</li>
        <li>Género</li>
        <li>Correo electrónico;</li>
        <li>Creación de contraseña;</li>
        <li>País de residencia;</li>
        <li>Empresa para la que trabajan;</li>
        <li>Profesión;</li>
        <li>
          Adicionalmente los Coach Profesionales deberán proveer sus respectivas
          credenciales, certificaciones, y/o diplomas.
        </li>
        <li>
          Los Coachees deberán contar con previa autorización de ingreso
          derivada de la previa contratación con la entidad Contratante.
        </li>
        <li>
          Los Contratantes del servicio de BONUM COACHING destinara los
          servicios a un grupo determinado de coachees, cada uno de estos será
          presentado con 3 coaches profesionales, debiendo el coachee
          seleccionar a un coach para su proceso.
        </li>
        <li>
          El Grupo seleccionado, recibirá 12 sesiones de coaching ejecutivo
          individual de manera virtual, cada sesión tendrá una duración de
          aproximadamente 30 minutos cada 2 semanas.
        </li>
        <li>
          Al comenzar el proceso el coachee deberá realizar una evaluación 360
          que será utilizada para ayudarlos en el proceso de coaching. Al
          finalizar el proceso, se volverá a realizar la encuesta para
          determinar el avance obtenido.
        </li>
        <li>
          Durante el primer mes del servicio, el coachee podrá realizar un
          cambio de coach si no está satisfecho con el coach elegido, no
          pudiendo cambiar nuevamente de coach.
        </li>
        <li>
          Al finalizar la sesión, el coach agregara en la plataforma los puntos
          y avances obtenidos en la sesión y algunas tareas o asignaciones para
          que el coachee las realice antes de su próxima sesión.
        </li>
        <li>
          El coachee deberá marcar las tareas como finalizadas una vez
          realizadas.
        </li>
      </ul>
      <p>
        No aceptamos el registro en información de menores de 18 años bajo
        ningún motivo.
      </p>
      <p>
        Los registros son personales e intransferibles, siendo el titular de
        estos el único responsable de las acciones efectuadas con su registro.
        Cada profesional y coachee solo podrán ser titulares de una cuenta.
      </p>
      <h2>USO POR EL COACH PROFESIONAL</h2>
      <h3>Prestación De Servicios</h3>
      <p>
        El profesional, al inscribirse y al utilizar los servicios de BONUM,
        estará suscribiendo herramientas que permitirán simplificar sus tareas y
        prestación de servicios, como la planificación, el análisis y la
        creación de planes y materiales académicos, sesiones de coaching,
        análisis, gestión y seguimiento de los coachees.
      </p>
      <p>
        El profesional que ha sido suscripto como parte del Staff profesional de
        BONUM ha debido aplicar y pasar por un proceso de entrevistas,
        evaluaciones, y recepción del respectivo correo electrónico con el
        acceso a la plataforma y Aplicación móvil.
      </p>
      <p>
        El Profesional se encuentra constantemente conectado a sus coachees
        facilitándose, de esta forma, el acceso a la evolución de su progreso y
        la comunicación directa. Esta funcionalidad se traduce en un seguimiento
        más cercano al coachee en la medida en que permite al profesional, en
        tiempo real, cambiar los materiales académicos, responder a las
        preguntas planteadas por el coachee y efectuar la programación de
        sesiones.
      </p>
      <h3>Responsabilidad del Profesional</h3>
      <p>
        Al aceptar los presentes Términos y Condiciones, el Profesional se
        obliga a proveer sus respectivas credenciales y certificaciones y a
        adoptar únicamente comportamientos que no infrinjan el ordenamiento
        jurídico vigente o que lesionen, de alguna forma, posiciones
        jurídicamente protegidas, obligándose, en particular, a seguir las
        normativas dispuestas en su jurisdicción relacionadas a su desempeño
        profesional y a las legislaciones de privacidad de datos con
        consentimiento previo de los coachees.
      </p>
      <h3>No Renovación automática de la suscripción</h3>
      <p>
        Sin perjuicio de la oposición del Profesional, al aceptar los presentes
        Términos el Profesional acepta y está de acuerdo que la renovación
        automática de su suscripción estará sujeta a la discreción de BONUM LLC,
        en caso de la discontinuidad de su suscripción, el profesional acepta
        que no accederá a compensación alguna más que las debidas cantidades que
        deban ser cobradas por sesiones anteriores ya finalizadas.
      </p>
      <h3>Sistema de pagos con PayPal</h3>
      <p>
        BONUM permite la integración de sus servicios con la plataforma de pago
        PayPal, proporcionando al profesional la posibilidad de utilizar su
        cuenta PayPal para el procesamiento de las transacciones
        correspondientes al cobro de sus servicios.
      </p>
      <p>
        Al utilizar este servicio declaras haber aprobado los Términos y
        Políticas de PayPal, BONUM no interfiere en los Términos PayPal y, de
        esta forma, no tienen obligación o responsabilidad contigo en virtud de
        estas normas o de cualquier servicio que PayPal proporcione o valores
        adicionales que te sean cobrados. Si tienes preguntas relacionadas con
        PayPal{' '}
        <a href="https://www.paypal.com/ar/legalhub/useragreement-full">
          presiona acá.
        </a>
      </p>
      <p>
        El Profesional es exclusivamente responsable por todas las transacciones
        (sean únicas, recurrentes y/o reembolsos) procesadas a través de PayPal.
        BONUM no se responsabiliza por cualquier situación referente a
        transacciones financieras y monetarias entre el Profesional y su coachee
        y/o cualquier otra parte fuera de la plataforma de BONUM, incluyendo
        PayPal, en particular situaciones referentes a reembolsos y fraude.
      </p>
      <h3>Condiciones específicas</h3>
      <p>
        El sistema de pagos implica el registro válido en{' '}
        <a href="https://www.paypal.com">PayPal</a>, por lo que solamente tras
        la verificación de la cuenta por esta entidad podrás realizar
        transacciones con tu cuenta sin problemas. Este registro está a tu total
        responsabilidad y es obligatorio para el cobro de los servicios
        indicados.
      </p>
      <p>
        El saldo disponible producto de los servicios prestados puede
        transferirse hacia una cuenta bancaria o cuenta de PayPal que el
        Profesional elija. El costo por los servicios será cancelado a cada
        profesional una vez culminada y cargada en la App la prestación del plan
        de sesiones por mes.
      </p>
      <h2>USO POR EL COACHEE</h2>
      <h3>Condiciones de acceso a servicios</h3>
      <p>
        El coachee, al suscribirse en nuestras plataformas, tendrá acceso
        directo a sus planes de académicos diseñados por el Coach y por BONUM,
        consultas con los profesionales, sesiones pendientes y notificaciones
        relativas a las distintas actividades asignadas por el Profesional.
        Además de esta funcionalidad, la aplicación móvil permite el seguimiento
        y acompañamiento constante y en tiempo real por el Profesional, ya que
        éste puede consultar esta información y actualizar el plan según la
        evolución de su coachee.
      </p>
      <p>
        En las suscripciones de acompañamiento, a estas funcionalidades añade la
        posibilidad de registrar el avance y la comunicación directa con el
        Profesional. Podrá acceder a la gestión de consultas (programación,
        confirmación y cancelación dentro al menos 48 horas previas), compartir
        el progreso y la aclaración de dudas.
      </p>
      <p>
        No existe ninguna influencia de BONUM en la relación que se establece
        entre el Coachee y el Profesional.
      </p>
      <h3>Responsabilidad del Coachee</h3>
      <p>
        Al aceptar los presentes Términos y Condiciones, el paciente se obliga a
        adoptar únicamente comportamientos que no infrinjan el ordenamiento
        jurídico vigente o que lesionen de alguna forma posiciones jurídicamente
        protegidas en su relación con BONUM.
      </p>
      <h3>Cambio de los Términos de Uso y de la Política de Privacidad</h3>
      <p>
        BONUM podrá, en cualquier momento, actualizar los Términos y Condiciones
        de Uso y la Política de Privacidad para responder a las exigencias
        legales o cambios de funcionamiento de la aplicación móvil.
      </p>
      <h2>LLAMADAS CONJUNTAS</h2>
      <p>
        Queda expresamente prohibida la agrupación de varias personas para
        adquirir conocimientos de nuestros servicios bajo una misma suscripción,
        es decir, será causal de acciones legales correspondientes que con el
        fin de ‘’ahorrar’’ costes del precio final correspondiente por persona,
        se reúnan varias personas para adquirir dicho conocimiento. Asimismo, se
        prohíbe la transferencia de las credenciales de acceso y uso con
        personas distintas a las habilitadas.
      </p>
      <h2>PROPIEDAD INTELECTUAL</h2>
      <p>
        Las aplicaciones producidas por BONUM son obras intelectuales protegidas
        por el Derecho de Propiedad Intelectual y están protegidos por la
        legislación de los Estados Unidos. Cada uno de sus elementos que las
        componen (tales como diseño, textos, vídeos, música, gráficos, imágenes,
        información, aplicaciones, sonidos, colores, logotipos, diseño de la
        página web, aplicaciones y herramientas, entre otros) son propiedad
        exclusiva de la empresa BONUM, y es la única habilitada para utilizar
        los derechos de propiedad intelectual.
      </p>
      <p>
        Se prohíbe cualquier reproducción y/o representación completa o parcial,
        utilización, adaptación o modificación de las aplicaciones o de
        cualquiera de los elementos que las componen, en cualquier soporte, o en
        cualquier forma, para otros fines, en particular comerciales.
      </p>
      <p>
        El acceso a la plataforma y a la aplicación no constituye licencia
        exclusiva alguna de uso o disposición de las plataformas.
      </p>
      <h2>CONFIDENCIALIDAD</h2>
      <p>
        El Profesional y coachee se obligan a mantener en absoluta y total
        confidencialidad, cualquier dato personal o comercial, aunque no
        contenga la mención de confidenciales, de toda la información disponible
        a la que tendrá acceso en virtud de la distribución de la aplicación web
        del software.
      </p>
      <p>
        Por información protegida o confidencial, se entiende toda la
        información que, independientemente del soporte utilizado consista en
        información técnica y no técnica, de naturaleza financiera, comercial o
        tecnológica, en particular: propiedad intelectual e industrial,
        "know-how", conclusiones, modelos de negocio, modelos de trabajo,
        secretos comerciales, estudios, fórmulas, métodos, "drafts", dibujos,
        fotografías, muestras prototipo, modelos, datos financieros, o cualquier
        otro elemento comercial, jurídico y/o fiscal, o cualquier otra
        información relativa al software, que se distribuya en cualquier forma.
      </p>
      <h2>EXCLUSIÓN DE GARANTÍAS</h2>
      <p>Los Usuarios, aceptan expresamente que:</p>
      <ol style={{ paddingRight: '2rem', paddingLeft: '2rem' }}>
        <li>
          La utilización del software se efectúa por cuenta y riesgo del
          Usuario, siendo de su exclusiva responsabilidad;
        </li>
        <li>
          El Usuario declara y asume que se encuentra legalmente apto, capaz y
          autorizado para el ejercicio de su actividad, relacionada, en
          particular, con la prestación de servicios de coaching ejecutivo y
          acceso a los servicios acá descriptos. No pudiendo, de esta forma,
          atribuirse cualquier responsabilidad a BONUM por cualquier error
          derivado de la práctica de la actividad del Usuario;
        </li>

        <li>
          BONUM no proporciona ninguna garantía en relación: con la
          correspondencia del software con las exigencias del usuario; con la
          exención de errores en el software; o con la fiabilidad, la actualidad
          o el rendimiento del software;
        </li>
      </ol>
      <h2>LIMITACIÓN DE RESPONSABILIDADES</h2>
      <p>
        El usuario entiende y acepta expresamente que BONUM no podrá ser
        responsable por cualquier daño directo, indirecto, accidental o
        especial, derivado del uso del software. Tampoco será responsable por la
        omisión de las obligaciones legalmente impuestas al Profesional.
      </p>
      <h2>FALLAS DEL SISTEMA</h2>
      <p>
        No se permite el uso de ningún dispositivo, software, u otro recurso que
        interfiera en las actividades y operaciones de BONUM. Cualquier
        intromisión, intento o actividad que viole o contradiga las leyes de
        derecho de propiedad intelectual y/o las prohibiciones estipuladas en
        estos Términos, harán que el responsable sea sujeto a las acciones
        legales pertinentes, siendo responsable de las indemnizaciones por
        eventuales daños causados.
      </p>
      <p>
        BONUM no se responsabiliza por cualquier daño, perjuicio o pérdida en el
        aparato del Usuario causada por fallas en el sistema, en el servidor o
        en Internet. Los Usuarios no podrán atribuir a BONUM ninguna
        responsabilidad ni exigir el pago debido a daños resultantes de
        dificultades técnicas o fallas en los sistemas o en Internet. BONUM no
        garantiza el acceso y uso continuo o sin interrupciones de su
        aplicación. En su caso, el sistema puede no estar disponible por motivos
        técnicos o fallos de Internet, o por cualquier otra circunstancia ajena
        a BONUM.
      </p>
      <h2>HIPERVÍNCULOS</h2>
      <p>
        Las aplicaciones web y móvil pueden contener hipervínculos a otras
        aplicaciones o sitios web, sobre los que BONUM no ejerce ningún control.
        La inclusión de hipervínculos a otras aplicaciones o sitios web es de
        carácter meramente informativo, siendo BONUM completamente ajena a los
        contenidos, servicios y/o productos ofrecidos, no pudiendo ser imputada,
        de esta forma, cualquier responsabilidad por su contenido.
      </p>
      <h2>SEGURIDAD LA INFORMACIÓN</h2>
      <p>
        Es nuestro objetivo garantizar la calidad e integridad de la información
        proporcionada por los Profesionales y Pacientes de BONUM. Hemos
        implementado las medidas necesarias, tanto a nivel tecnológico como
        organizacional, para mantener la información segura, precisa,
        actualizada y completa. Para más información consulta nuestra{' '}
        <a href="https://app.bonumcoaching.com/privacy-policy">
          Política de Privacidad.
        </a>
      </p>
      <h2>INVALIDEZ PARCIAL</h2>
      <p>
        Si cualquier disposición de los Términos y Condiciones se considera
        inválida o inejecutable, por cualquier razón o en cualquier medida,
        dicha invalidez no afectará ni hará inválida o inaplicable las
        disposiciones restantes de estos términos y la aplicación de dicha
        disposición se efectuará en la medida permitida por la ley.
      </p>
      <h2>SERVICIOS DE TERCEROS</h2>
      <p>
        Al aceptar estos Términos y Condiciones de uso el usuario da su
        autorización general para que BONUM contrate a otros proveedores de
        servicios a los efectos y funciones descritos en la Política de
        Privacidad, reservándose el derecho de aumento del número de terceros,
        para la realización de estas u otras funciones o sustitución de éstos
        por otros, bastando para ello que informe regularmente a los usuarios,
        en los términos legales y políticas de privacidad.
      </p>
      <h2>
        RELACIÓN ENTRE EL PROFESIONAL, COACHEE Y EL CONTRATANTE CON BONUM
        COACHING LLC
      </h2>
      <p>
        A efectos de estos términos y condiciones, las partes reconocen que son
        un usuario más de los servicios que ofrece BONUM a través de sus
        plataformas, y que bajo ningún motivo esta relación será prueba de
        alguna relación de laboral, o Joint Venture.
      </p>
      <h2>LEY APLICABLE Y JURISDICCIÓN</h2>
      <p>
        Los presentes Términos y Condiciones de Uso están sujetos a la ley de
        los Estados Unidos de América y para todas las cuestiones emergentes de
        carácter Estatal, las partes eligen las leyes de Florida.
      </p>
    </div>
  );
};

export default TermsAndConditions;
