import { useTranslation } from 'react-i18next';
import OpenQuestion from '../OpenQuestion';
import Rating from '../Rating';
import { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useFetchAndLoad } from 'hooks';
import displayToast from 'utilities/toast.utility';
import { createFinalNps } from 'services/nps.service';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateSession } from 'services/sessions.service';

const FinalNps = ({ session }) => {
  const { t } = useTranslation('global');
  const { mongoID } = useSelector((state) => state.user);
  const [recomendation, setRecomendation] = useState(0);
  const [coachValoration, setCoachValoration] = useState(0);
  const [feelingsAboutBonum, setFeelingsAboutBonum] = useState('');
  const [feelingsAboutCareer, setFeelingsAboutCareer] = useState('');
  const [programFeedback, setProgramFeedback] = useState('');
  const [aboutCoach, setAboutCoach] = useState('');
  const navigate = useNavigate();

  const { loading, callEndpoint } = useFetchAndLoad();

  const handleClick = async () => {
    try {
      await callEndpoint(
        createFinalNps({
          coachee: mongoID,
          recomendation,
          coachValoration,
          feelingsAboutBonum,
          feelingsAboutCareer,
          aboutCoach,
          programFeedback
        })
      );

      await callEndpoint(
        updateSession({
          _id: session,
          evaluatedByCoachee: true
        })
      );
      navigate('/');
      displayToast('Encuesta guardada con éxito', 'success');
    } catch (error) {
      displayToast(`Error creating NPS: ${error}`, 'error');
    }
  };

  return (
    <div>
      <Rating
        question={t('pages.midnps.recomendation')}
        starsNumber={10}
        value={recomendation}
        setValue={setRecomendation}
      />

      <Rating
        question={t('pages.finalnps.coachValoration')}
        starsNumber={5}
        value={coachValoration}
        setValue={setCoachValoration}
      />

      <OpenQuestion
        question={t('pages.finalnps.feelingsAboutBonum')}
        value={feelingsAboutBonum}
        setValue={setFeelingsAboutBonum}
      />

      <OpenQuestion
        question={t('pages.finalnps.feelingsAboutCareer')}
        value={feelingsAboutCareer}
        setValue={setFeelingsAboutCareer}
      />

      <OpenQuestion
        question={t('pages.finalnps.aboutCoach')}
        value={aboutCoach}
        setValue={setAboutCoach}
      />

      <OpenQuestion
        question={t('pages.finalnps.programFeedback')}
        value={programFeedback}
        setValue={setProgramFeedback}
      />

      <div className="center--button">
        <Button
          className="Button Button--primary"
          mt={'1em'}
          textAlign={'center'}
          onClick={handleClick}
          isLoading={loading}
          disabled={loading}
        >
          {t('pages.sessionEvaluation.button')}
        </Button>
      </div>
    </div>
  );
};

export default FinalNps;
