import React, { useEffect, useState } from 'react';
import { size } from 'lodash';
import { Button, Image } from '@chakra-ui/react';
import Loading from 'components/Loading';
import AddIcon from 'assets/images/icons/boton-siguiente.png';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import { getCoachesByFocusAreas, translateText } from 'services/coach.service';
import { useSelector } from 'react-redux';
import SelectedCoach from '../SelectedCoach';
import { useTranslation } from 'react-i18next';

function ChooseCoach({ setShowArrows }) {
  const [coaches, setCoaches] = useState([]);
  const { focusAreas, languages } = useSelector((state) => state.onboarding);
  const { mongoID } = useSelector((state) => state.user);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { t, i18n } = useTranslation('global');

  const translate = async (text) => {
    try {
      const { data } = await callEndpoint(
        translateText({
          targetLanguage: i18n.language,
          text: text
        })
      );

      return data.data;
    } catch (error) {
      console.error('Error translating text:', error);
      return '';
    }
  };

  const getCoaches = async () => {
    try {
      const coachesData = await callEndpoint(
        getCoachesByFocusAreas(focusAreas, languages, mongoID)
      );

      // Traducir el campo howWork de los coaches
      const translatedCoaches = await Promise.all(
        coachesData.data.data.map(async (coach) => {
          const translatedHowWork = await translate(coach?.howWork);
          return {
            ...coach,
            howWork2: translatedHowWork
          };
        })
      );

      setCoaches(translatedCoaches);
    } catch (error) {
      console.log(
        '🚀 ~ file: ChooseCoach.js ~ line 23 ~ getCoaches ~ error',
        error
      );
    }
  };

  useEffect(() => {
    getCoaches();
  }, [i18n.language]);

  const handleKeyDown = (e, coach) => {
    if (e.keyCode === 13) setSelectedCoach(coach);
  };

  if (loading)
    return (
      <Loading title={t('pages.onboarding.components.chooseCoach.loading')} />
    );

  return !selectedCoach ? (
    <div className="ChooseCoach">
      <div className="ChooseCoach__titles">
        <h3 className="ChooseCoach__titles_title">
          {t('pages.onboarding.components.chooseCoach.title')}
        </h3>
        <p className="ChooseCoach__titles_subtitle">
          {t('pages.onboarding.components.chooseCoach.subtitle1')}
        </p>
        <p className="ChooseCoach__titles_subtitle">
          {t('pages.onboarding.components.chooseCoach.subtitle2')}
        </p>

        <Button
          onClick={() => getCoaches()}
          className="SelectedCoach__selectCoach--superior Button Button--primary"
          disabled={loading}
          isLoading={loading}
        >
          {t('pages.onboarding.components.chooseCoach.button')}
        </Button>
      </div>
      {size(coaches) > 0 ? (
        coaches.map((coach, index) => (
          <div
            role="button"
            onKeyDown={(e) => handleKeyDown(e, coach)}
            tabIndex={index + 1}
            onClick={() => setSelectedCoach(coach)}
            key={coach.name}
            className="ChooseCoach__coach"
          >
            <Image
              src={
                coach?.urlImgCoach
                  ? coach.urlImgCoach
                  : '../../../assets/images/bonum_logo.png'
              }
              objectFit="cover"
              className="ChooseCoach__coach_photo"
            />
            <div className="ChooseCoach__coach_info">
              <h4>
                {coach?.name} {coach?.lastname}
              </h4>
              {/* {i18n.language === 'es' ? (
                <p>{coach?.howWork}</p>
              ) : (
                <p>{coach?.howWork2}</p>
              )} */}
              <p>{coach?.howWork2}</p>

              <span>{t('pages.onboarding.components.chooseCoach.span')}</span>
            </div>
            <Image
              className="ChooseCoach__icon ChooseCoach__icon--arrow"
              src={AddIcon}
            />
          </div>
        ))
      ) : (
        <h2>
          {t('pages.onboarding.components.chooseCoach.noData')}
          <span>🥺</span>
        </h2>
      )}
    </div>
  ) : (
    <SelectedCoach
      coach={selectedCoach}
      setSelectedCoach={setSelectedCoach}
      setShowArrows={setShowArrows}
    />
  );
}

export default ChooseCoach;
