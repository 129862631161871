import React from 'react';
import RescheduleContextWrapper from './context/RescheduleContextWrapper';
import RescheduleAppointment from './RescheduleAppointment';

function Reschedule() {
  return (
    <RescheduleContextWrapper>
      <RescheduleAppointment />
    </RescheduleContextWrapper>
  );
}

export default Reschedule;
