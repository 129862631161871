import evaluationCoachAdapted from 'adapters/evaluationCoach.adapter';
import NoData from 'components/NoData/NoData';
import { filter, find, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EvaluationContent from './components/EvaluationContent';
import ArrowBack from 'assets/images/icons/boton-anterior.png';
import { useNavigate } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function DetailsEvaluation({ isSession }) {
  const titletText = isSession ? 'sesiones' : 'coach';
  const [displayedEvaluations, setDisplayedEvaluations] = useState([]);
  const { ratingCoach, sessions } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const getRatingSessionsCoach = () => {
    const filtered = filter(sessions, {
      status: true,
      evaluatedByCoachee: true
    });

    const adaptedEvaluations = filtered.map((evaluation) =>
      evaluationCoachAdapted(evaluation)
    );

    setDisplayedEvaluations(adaptedEvaluations);
  };

  const getRatingCoach = () => {
    if (Array.isArray(ratingCoach) && ratingCoach?.length > 0) {
      const ratings = ratingCoach
        .map((rating) => {
          const session = find(sessions, { _id: rating.SessionId });
          if (!session || !session?.coachee) return false;
          console.log(session);

          return {
            id: session._id,
            image: session.coachee?.urlImgCoachee,
            name: `${session.coachee.name} ${session.coachee.lastname}`,
            date: session.date,
            comment: session?.commentCoachee,
            value: rating.Score
          };
        })
        .filter((rating) => rating);

      setDisplayedEvaluations(ratings);
    }
  };

  useEffect(() => {
    if (isSession) {
      getRatingSessionsCoach();
      return;
    }

    getRatingCoach();
  }, [ratingCoach, sessions, isSession]);

  return (
    <>
      <Image
        src={ArrowBack}
        className="DetailsEvaluation__back"
        onClick={() => navigate('/myevaluations')}
      />

      <section className="DetailsEvaluation">
        <div className="DetailsEvaluation__evaluations">
          <h2 className="DetailsEvaluation__title">
            {t('pages.coachEvaluation.title', { titletText: titletText })}
          </h2>
          <div className="DetailsEvaluation__container">
            {size(displayedEvaluations) > 0 ? (
              displayedEvaluations.map((evaluation) => (
                <EvaluationContent
                  evaluation={evaluation}
                  isSession={isSession}
                />
              ))
            ) : (
              <NoData title={t('pages.coachEvaluation.noData')} />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailsEvaluation;
