import React, { useState } from 'react';
import {
  Input,
  Button,
  FormErrorMessage,
  FormControl,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import * as Yup from 'yup';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, translateFirebaseErrors } from 'utilities/firebase.utility';
import { useTranslation } from 'react-i18next';

function Login({ setLogin, loadingUser }) {
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const { t } = useTranslation('global');

  const initialValues = () => ({
    email: '',
    password: ''
  });

  const [viewPassword, setViewPassword] = useState(false);
  const validationSchema = Yup.object({
    email: Yup.string().email('emailValid').required('emailRequire'),
    password: Yup.string().required('passRequire')
  });

  const { handleSubmit, handleChange, values, errors, handleBlur, touched } =
    useFormik({
      initialValues: initialValues(),
      validationSchema,
      onSubmit: async (formValues) => {
        try {
          setLoading(true);
          await signInWithEmailAndPassword(
            auth,
            formValues.email.toLowerCase(),
            formValues.password
          );

          setLoading(false);
        } catch (error) {
          setLoading(false);
          setLoginError(translateFirebaseErrors({ code: error.code }));
        }
      }
    });

  return (
    <div className="Auth background">
      <div className="Auth__hint">
        <h2>{t('components.auth.login.title')}</h2>
        <p>{t('components.auth.login.description')}</p>
      </div>
      <form className="Auth__card Card" onSubmit={handleSubmit}>
        <h2 className="Auth__title">{t('components.auth.login.cardTitle')}</h2>
        <FormControl isInvalid={errors?.email && touched?.email}>
          <Input
            name="email"
            data-test="signin-email"
            className="Auth__input"
            placeholder={t('components.auth.login.fieldEmail')}
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
            autoComplete="username"
          />
          {errors?.email && touched.email ? (
            <FormErrorMessage fontSize="lg">
              {t(`components.auth.login.validations.${errors.email}`)}
            </FormErrorMessage>
          ) : null}
        </FormControl>

        <FormControl
          isInvalid={(errors?.password && touched?.password) || loginError}
        >
          <InputGroup>
            <Input
              name="password"
              className="Auth__input"
              data-test="signin-password"
              placeholder={t('components.auth.login.fieldPassword')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type={viewPassword ? 'text' : 'password'}
              autoComplete="current-password"
            />
            <InputRightElement
              h="100%"
              mr={3}
              onClick={() => setViewPassword(!viewPassword)}
            >
              {viewPassword ? (
                <ViewOffIcon w={8} h={8} />
              ) : (
                <ViewIcon w={8} h={8} />
              )}
            </InputRightElement>
          </InputGroup>

          {loginError ? (
            <FormErrorMessage fontSize="lg">{loginError}</FormErrorMessage>
          ) : null}
        </FormControl>
        <Button
          className="Auth__button"
          type="submit"
          data-test="signin-submit"
          isLoading={loading || loadingUser}
          disabled={loading || loadingUser}
        >
          {t('components.auth.login.button')}
        </Button>
        <p className="Auth__question">
          {t('components.auth.login.forgotPassword')}{' '}
          <span
            role="button"
            tabIndex={0}
            onKeyDown={() => setLogin(false)}
            onClick={() => setLogin(false)}
          >
            {t('components.auth.login.recover')}
          </span>
        </p>
      </form>
    </div>
  );
}

export default Login;
