import React from 'react';
import SuccessCalendarContextWrapper from './context/SuccessCalendarContextWrapper';
import SuccessCalendar from './SuccessCalendar';

function Schedule() {
  return (
    <SuccessCalendarContextWrapper>
      <SuccessCalendar />
    </SuccessCalendarContextWrapper>
  );
}

export default Schedule;
