import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useFetchAndLoad } from 'hooks';
import { getCoachAvailability } from 'services/calendar.service';
import displayToast from 'utilities/toast.utility';
import Loading from 'components/Loading';
import { compact, filter, map } from 'lodash';
import { DateTime } from 'luxon';
import useCoacheeCalendarContext from '../../hooks/useCoacheeCalendarContext';
import { useTranslation } from 'react-i18next';
import NoMoreSessions from '../NoMoreSessions/NoMoreSessions';
import NoData from 'components/NoData/NoData';

function AvailableSchedule({
  handleSchedule,
  isLoadingSchedule,
  isDisableSchedule
}) {
  const { loading, callEndpoint } = useFetchAndLoad();
  const [schedule, setSchedule] = useState([]);
  const [enableNoMoreSessions, setEnableNoMoreSessions] = useState(false);
  const { coach, timezone, sessions, cohort, additionalSessions } = useSelector(
    (state) => state.user
  );
  const { setHour, hour, date } = useCoacheeCalendarContext();
  const { t } = useTranslation('global');

  const getHours = (workHours) => {
    const hours = map(workHours, (unformattedHour, index) => {
      const start = DateTime.fromISO(unformattedHour).setZone(timezone);
      const today = DateTime.now().setZone(timezone);
      if (start < today) return false;
      const finish = start.plus({ hours: 1 });
      return {
        id: index,
        time: `${start.toFormat('hh:mma')} - ${finish.toFormat('hh:mma')}`,
        startHour: start
      };
    });

    return compact(hours);
  };

  const errorCoach = t(
    'pages.reschedule.components.availableSchedule.errorCoach'
  );

  const coachAvailability = async () => {
    try {
      const normalSessions = filter(sessions, (s) => !s?.type);
      if (normalSessions?.length >= cohort?.program + additionalSessions) {
        setEnableNoMoreSessions(true);
        return;
      }

      const response = await callEndpoint(
        getCoachAvailability(date, coach._id)
      );
      const workHours = response.data.data;
      setSchedule(getHours(workHours));
    } catch (error) {
      console.log(error);
      displayToast(errorCoach, 'error');
    }
  };

  useEffect(() => {
    if (date) coachAvailability();
  }, [date]);

  if (loading)
    return (
      <Loading
        title={t('pages.reschedule.components.availableSchedule.loading')}
      />
    );

  if (enableNoMoreSessions) return <NoMoreSessions />;

  return (
    <>
      {schedule.length !== 0 ? (
        <>
          <h4 className="AvailableSchedule__title">Horario disponible</h4>
          <div className="AvailableSchedule">
            {schedule.map((scheduleObj) => (
              <Button
                key={scheduleObj.id}
                className={`Button AvailableSchedule__button ${
                  hour?.time === scheduleObj?.time
                    ? 'AvailableSchedule__button--selected'
                    : ''
                }`}
                onClick={() => setHour(scheduleObj)}
              >
                {scheduleObj.time}
              </Button>
            ))}
          </div>
          <Button
            onClick={handleSchedule}
            className="Button Button--primary CoacheeCalendar__button"
            isLoading={isLoadingSchedule}
            isDisabled={isDisableSchedule}
          >
            Agendar
          </Button>
        </>
      ) : (
        <NoData title="No hay horarios disponibles" />
      )}
    </>
  );
}

export default AvailableSchedule;
