import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MidNps from './components/MidNps';
import FinalNps from './components/FinalNps';

const nps = () => {
  const { state } = useLocation();
  const { mid, final, sessionId } = state;

  useEffect(() => {}, [state]);

  return (
    <div className="SessionEvaluation">
      <div className="SessionEvaluation__evaluation">
        <h2 className="SessionEvaluation__title">
          {mid && 'Mid Nps'}
          {final && 'Final Nps'}
          {/* {t('pages.sessionEvaluation.subtitle')} */}
        </h2>

        {mid && <MidNps session={sessionId} />}
        {final && <FinalNps session={sessionId} />}
      </div>
    </div>
  );
};

export default nps;
