import React, { useEffect, useState } from 'react';
import EvaluationArea from '../../components/EvaluationArea';
import NoData from 'components/NoData/NoData';
import { size, forEach, findIndex, find, map, findLastIndex } from 'lodash';
import { useFetchAndLoad } from 'hooks';
import {
  getAutoEvaluationByCoacheeId,
  getEvaluation360ByCoacheeId
} from 'services/evaluations.service';
import displayToast from 'utilities/toast.utility';
import Loading from 'components/Loading';
import { mongoDateToShortDate } from 'utilities';
import { useTranslation } from 'react-i18next';

function Evaluation(props) {
  const [evaluations, setEvaluations] = useState([]);
  const [focusAreas, setFocusAreas] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [finalEvaluationIndex, setFinalEvaluationIndex] = useState(0);

  const { t } = useTranslation('global');
  const errorGet = t('components.evaluation.errorGet');
  const error360 = t('components.evaluation.error360');
  const { title, type, coachee } = props;

  const getAutoevaluations = async () => {
    try {
      const { data } = await callEndpoint(
        getAutoEvaluationByCoacheeId(coachee)
      );
      setEvaluations(data.data);
    } catch (error) {
      displayToast(errorGet, 'error');
    }
  };

  const getEvaluations360 = async () => {
    try {
      const { data } = await callEndpoint(getEvaluation360ByCoacheeId(coachee));
      setEvaluations(data.data);
    } catch (error) {
      displayToast(error360, 'error');
    }
  };

  const getFinalEvaluation = () => {
    setFinalEvaluationIndex(
      findLastIndex(
        evaluations,
        (evaluation) => evaluation.evaluationType === 'final'
      )
    );
  };

  const getAnswersGroupedByFocusAreas = () => {
    const focusAreasTemp = [];
    forEach(evaluations, (evaluation) => {
      const isInitialEvaluation = evaluation.evaluationType === 'initial';

      forEach(evaluation.answers, (answer) => {
        const indexOfFocusArea = findIndex(
          focusAreasTemp,
          (focusArea) => focusArea?._id === answer?.question?.focusArea?._id
        );

        if (indexOfFocusArea < 0) {
          focusAreasTemp.push({
            _id: answer?.question?.focusArea?._id,
            title: answer?.question?.focusArea?.focusArea,
            image: answer?.question?.focusArea?.urlImgFocusArea,
            questions: [
              {
                _id: answer.question._id,
                title: answer.question.question,
                initialEvaluation: isInitialEvaluation ? answer.value : 0,
                finalEvaluation: !isInitialEvaluation ? answer.value : 0,
                initialAnswers: isInitialEvaluation ? 1 : 0,
                finalAnswers: !isInitialEvaluation ? 1 : 0,
                es: answer.question.question,
                en: answer.question.en,
                pt: answer.question.pt
              }
            ],
            totalInitialEvaluation: isInitialEvaluation ? answer.value : 0,
            totalFinalEvaluation: !isInitialEvaluation ? answer.value : 0,
            totalInitialAnswers: isInitialEvaluation ? 1 : 0,
            totalFinalAnswers: !isInitialEvaluation ? 1 : 0
          });
        } else {
          if (isInitialEvaluation) {
            focusAreasTemp[indexOfFocusArea].totalInitialEvaluation +=
              answer.value;
            focusAreasTemp[indexOfFocusArea].totalInitialAnswers += 1;
          } else {
            focusAreasTemp[indexOfFocusArea].totalFinalEvaluation +=
              answer.value;
            focusAreasTemp[indexOfFocusArea].totalFinalAnswers += 1;
          }

          const question = find(
            focusAreasTemp[indexOfFocusArea].questions,
            (savedQuestion) => savedQuestion._id === answer.question._id
          );

          if (!question) {
            focusAreasTemp[indexOfFocusArea].questions.push({
              _id: answer.question._id,
              title: answer.question.question,
              initialEvaluation: isInitialEvaluation ? answer.value : 0,
              finalEvaluation: !isInitialEvaluation ? answer.value : 0,
              initialAnswers: isInitialEvaluation ? 1 : 0,
              finalAnswers: !isInitialEvaluation ? 1 : 0,
              es: answer.question.question,
              en: answer.question.en,
              pt: answer.question.pt
            });

            return;
          }

          focusAreasTemp[indexOfFocusArea].questions = map(
            focusAreasTemp[indexOfFocusArea].questions,
            (savedQuestion) => {
              if (savedQuestion._id !== answer.question._id) {
                return savedQuestion;
              }

              return {
                ...savedQuestion,
                initialEvaluation: isInitialEvaluation
                  ? savedQuestion.initialEvaluation + answer.value
                  : savedQuestion.initialEvaluation,
                finalEvaluation: !isInitialEvaluation
                  ? savedQuestion.finalEvaluation + answer.value
                  : savedQuestion.finalEvaluation,
                initialAnswers: isInitialEvaluation
                  ? savedQuestion.initialAnswers + 1
                  : savedQuestion.initialAnswers,
                finalAnswers: !isInitialEvaluation
                  ? savedQuestion.finalAnswers + 1
                  : savedQuestion.finalAnswers
              };
            }
          );
        }
      });
    });

    // if (focusAreasTemp.length > 6) {
    //   focusAreasTemp.pop();
    // }

    setFocusAreas(focusAreasTemp);
  };

  useEffect(() => {
    if (type === 'autoevaluation') {
      getAutoevaluations();
    }

    if (type === 'evaluation360') {
      getEvaluations360();
    }
  }, []);

  useEffect(() => {
    getAnswersGroupedByFocusAreas();
    getFinalEvaluation();
  }, [evaluations]);

  if (loading) return <Loading title={t('components.evaluation.loading')} />;

  return (
    <div className="Evaluation background">
      {size(focusAreas) > 0 && (
        <>
          <h2 className="Evaluation__title">{title}</h2>
        </>
      )}

      {size(focusAreas) > 0 ? (
        focusAreas.map((focusArea) => (
          <EvaluationArea
            className="Evaluation__area"
            focusArea={focusArea}
            key={focusArea._id}
          />
        ))
      ) : (
        <NoData title={t('components.evaluation.noData')} />
      )}
    </div>
  );
}

export default Evaluation;
