import React, { useContext, useEffect, useState } from 'react';
import {
  Input,
  Avatar,
  InputGroup,
  InputRightElement,
  Image
} from '@chakra-ui/react';
import RightBlueArrow from 'assets/images/icons/boton-siguiente.png';
import { useSelector } from 'react-redux';
import { size } from 'lodash';
import SearchIcon from 'assets/images/icons/buscar.png';
import NoData from 'components/NoData/NoData';
import Coachee from '../Coachee';
import MyCoacheesContext from '../../context/MyCoacheesContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchAndLoad, useUserUtilities } from 'hooks';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';

function CoacheeStatus({ activeCoachees }) {
  const { coachees, mongoID } = useSelector((state) => state.user);
  const { setSelectedCoachee, selectedCoachee } = useContext(MyCoacheesContext);
  const [displayedCoachees, setDisplayedCoachees] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { refreshUser, userUtilitiesLoading } = useUserUtilities();
  const { t } = useTranslation('global');
  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectedCoachee = (coachee) => {
    navigate(coachee._id);
    setSelectedCoachee(coachee);
  };

  const getMyCoachee = async () => {
    const myCoachee = coachees.find((coachee) => coachee._id === userId);
    setSelectedCoachee(myCoachee);
  };

  useEffect(() => {
    if (!selectedCoachee && userId) getMyCoachee();
  }, [coachees]);

  useEffect(() => {
    refreshUser();
  }, []);

  useEffect(() => {
    const filteredCoachees = coachees?.filter(
      (coachee) => coachee.statusUser === activeCoachees
    );
    setDisplayedCoachees(filteredCoachees);
  }, [coachees, activeCoachees]);

  useEffect(() => {
    const searchFilteredCoachees = coachees?.filter(
      (coachee) =>
        coachee.statusUser === activeCoachees &&
        `${coachee.name} ${coachee.lastname}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setDisplayedCoachees(searchFilteredCoachees);
  }, [searchTerm, coachees, activeCoachees]);

  if (selectedCoachee) return <Coachee />;
  if (userUtilitiesLoading) return <Loading />;

  return (
    <div className="MyCoachees background">
      <InputGroup className="MyCoachees__searchbar">
        <Input
          type="search"
          placeholder={t('pages.myCoachees.placeholder')}
          className="MyCoachees__searchbar_input"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <InputRightElement h="100%" mr={6}>
          <Image src={SearchIcon} className="MyCoachees__searchIcon" />
        </InputRightElement>
      </InputGroup>

      <div className="MyCoachees__coachee_list">
        {size(displayedCoachees) > 0 ? (
          displayedCoachees.map((coachee, index) => (
            <div
              className="MyCoachees__coachee_list_item"
              key={coachee._id}
              onClick={() => handleSelectedCoachee(coachee)}
              onKeyDown={() => null}
              role="button"
              tabIndex={index}
            >
              <Avatar
                src={coachee.urlImgCoachee}
                size="lg"
                className="MyCoachees__coachee_list_item_image"
              />
              <p className="MyCoachees__coachee_list_item_name">
                {coachee.name} {coachee.lastname}
              </p>
              <Avatar src={RightBlueArrow} size="lg" />
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}

export default CoacheeStatus;
