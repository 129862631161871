import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText
} from '@chakra-ui/react';
import Select from 'react-select';
import { timezonesToReactSelect } from 'utilities/timezones.utility';
import { findIndex } from 'lodash';
import { TimeZoneSelectDialog } from 'react-timezone-map-select';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';

function PickTimezone({ values, setValues, errors }) {
  const [openMap, setOpenMap] = useState(false);
  const [timezones] = useState(timezonesToReactSelect());
  const { t } = useTranslation('global');

  const auto = t('pages.onboarding.components.pickTimezone.messages.auto');
  const validation = t(
    'pages.onboarding.components.pickTimezone.messages.validation'
  );
  const required = t(
    'pages.onboarding.components.pickTimezone.messages.required'
  );

  const handleCloseMap = (mapTimezone) => {
    setValues({ ...values, timezone: mapTimezone });
    setOpenMap(false);
  };

  const getIndexOfTimezone = (timezone) =>
    findIndex(timezones, {
      value: timezone
    });

  const setTimeZoneFromBrowser = () => {
    const index = getIndexOfTimezone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    displayToast(auto, 'success');
    if (index >= 0) setValues({ ...values, timezone: timezones[index].value });
  };

  const handleSelectChange = (option) => {
    setValues({ ...values, timezone: option.value });
  };

  useEffect(() => {
    if (timezones) setTimeZoneFromBrowser();
  }, [timezones]);

  return (
    <div className="PickTimezone ">
      <p className="PickTimezone__subtitle">
        También, para asegurarnos que tus horarios son los correctos, tendrás
        que elegir tu zona horaria
      </p>
      <div className="PickTimezone__picker card">
        <FormControl isInvalid={errors.timezone}>
          <Select
            defaultValue={timezones[getIndexOfTimezone(values.timezone)]}
            options={timezones}
            onChange={handleSelectChange}
            className="TimezonePicker__select"
            value={timezones[getIndexOfTimezone(values.timezone)]}
          />

          <FormHelperText textAlign="left" fontSize="xl" mb="10">
            {t('pages.onboarding.components.pickTimezone.textHelper')}
          </FormHelperText>

          <Button
            className="Button--primary PickTimezone__open"
            onClick={() => setOpenMap(true)}
          >
            {t('pages.onboarding.components.pickTimezone.openMap')}
          </Button>

          {errors?.timezone ? (
            <FormErrorMessage fontSize="lg">{errors.timezone}</FormErrorMessage>
          ) : null}
        </FormControl>

        <TimeZoneSelectDialog
          open={openMap}
          timeZoneName={values.timezone}
          onClose={handleCloseMap}
          description={t(
            'pages.onboarding.components.pickTimezone.selectDialog.description'
          )}
          buttonLabelCancel={t(
            'pages.onboarding.components.pickTimezone.selectDialog.cancelButton'
          )}
          title={t(
            'pages.onboarding.components.pickTimezone.selectDialog.title'
          )}
        />
      </div>
    </div>
  );
}

export default PickTimezone;
