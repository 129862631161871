import React, { useMemo, useState } from 'react';
import CoachCalendarContext from './CoachCalendarContext';

function CoachCalendarContextWrapper({ children }) {
  const [schedules, setSchedules] = useState([
    {
      id: 1,
      Day: 'Monday',
      InitialDate: '',
      EndDate: '',
      Work: false
    },
    {
      id: 2,
      Day: 'Tuesday',
      InitialDate: '',
      EndDate: '',
      Work: false
    },
    {
      id: 3,
      Day: 'Wednesday',
      InitialDate: '',
      EndDate: '',
      Work: false
    },
    {
      id: 4,
      Day: 'Thursday',
      InitialDate: '',
      EndDate: '',
      Work: false
    },
    {
      id: 5,
      Day: 'Friday',
      InitialDate: '',
      EndDate: '',
      Work: false
    },
    {
      id: 6,
      Day: 'Saturday',
      InitialDate: '',
      EndDate: '',
      Work: false
    },
    {
      id: 7,
      Day: 'Sunday',
      InitialDate: '',
      EndDate: '',
      Work: false
    }
  ]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [blockedSchedules, setBlockedSchedules] = useState(null);

  const updateWorkingUI = (workingSchedule) => {
    const tempSchedules = schedules.reduce((prev, current) => {
      const stored = workingSchedule.find(({ Day }) => Day === current.Day);
      if (stored) {
        prev.push({ ...stored, id: current.id });
      } else {
        prev.push({ ...current });
      }
      return prev;
    }, []);

    setSchedules(tempSchedules);
  };

  const CoachCalendarContextValue = useMemo(
    () => ({
      schedules,
      setSchedules,
      updateWorkingUI,
      selectedDate,
      setSelectedDate,
      blockedSchedules,
      setBlockedSchedules
    }),
    [schedules, selectedDate, blockedSchedules]
  );

  return (
    <CoachCalendarContext.Provider value={CoachCalendarContextValue}>
      {children}
    </CoachCalendarContext.Provider>
  );
}

export default CoachCalendarContextWrapper;
