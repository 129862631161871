import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';
import LeftRedArrow from 'assets/images/icons/boton-anterior.png';
import SessionTabs from '../SessionTabs';
import CoacheeTabs from '../CoacheeTabs';
import MyCoacheesContext from '../../context/MyCoacheesContext';
import {
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineUser,
  AiOutlineClockCircle
} from 'react-icons/ai';
import { RiBuilding4Line, RiUserFollowLine } from 'react-icons/ri';
import { GiProgression } from 'react-icons/gi';

import { useNavigate, useParams } from 'react-router-dom';
import {
  mongoDateToLongDate,
  mongoDateToSessionDate
} from 'utilities/formatDate.utility';
import {
  coachAddAlignmentSession,
  getAllAlignmentSessionCoachee,
  getSessionByID
} from 'services/sessions.service';
import { useFetchAndLoad } from 'hooks';
import adaptedSession from 'adapters/sessionsAdapter.adapter';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { getProgramById } from 'services/program.service';
import { DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import 'moment/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import './styles/AlignmentSession.css';
import displayToast from 'utilities/toast.utility';
import translateFocusArea from 'utilities/translateFocusArea.utility';

function Coachee() {
  const {
    selectedSession,
    setSelectedSession,
    setSelectedCoachee,
    selectedCoachee: coachee
  } = useContext(MyCoacheesContext);

  const { mongoID, timezone } = useSelector((state) => state.user);

  const { sessionId, userId } = useParams();
  const { callEndpoint, loading } = useFetchAndLoad();
  const { t, i18n } = useTranslation('global');
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [coacheeProgram, setCoacheeProgram] = useState('');
  const [evaluationType, setEvaluationType] = useState('initial');
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateWithTimezone, setDateWithTimezone] = useState(null);
  const [translatedFocusAreas, setTranslatedFocusAreas] = useState([]);
  const [alignmentSessions, setAlignmentSessions] = useState(null);

  useEffect(() => {
    if (selectedDate && timezone) {
      // Ajustar la fecha a la zona horaria seleccionada
      const adjustedDate = moment(selectedDate).tz(timezone);
      // Truncar los milisegundos
      const truncatedDate = adjustedDate.startOf('minute');
      // Formatear la fecha en el formato deseado
      const formattedDate = truncatedDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');

      setDateWithTimezone(formattedDate);
    }
  }, [selectedDate, timezone]);

  const getModalContainer = () => {
    return document.querySelector('.chakra-modal__content');
  };

  const handleAddAlignmentSession = async () => {
    try {
      const initialSessionExists = alignmentSessions.some(
        (session) => session.type === 'initial'
      );
      const finalSessionExists = alignmentSessions.some(
        (session) => session.type === 'final'
      );

      if (evaluationType === 'initial' && initialSessionExists) {
        displayToast(`${t('pages.reschedule.alreadyInitial')}`, 'error');
        return;
      } else if (evaluationType === 'final' && finalSessionExists) {
        displayToast(`${t('pages.reschedule.alreadyFinal')}`, 'error');
        return;
      }

      let alignmentSessionData = {
        coach: mongoID,
        coachee: userId,
        date: dateWithTimezone,
        type: evaluationType
      };

      const res = await callEndpoint(
        coachAddAlignmentSession(alignmentSessionData)
      );

      if (res.status === 200) {
        displayToast('La sesión fue creada correctamente', 'success');
        onClose();
        getAlignmentSessions();
      }
    } catch (err) {
      displayToast('No se pudo crear la sesión', 'error');
    }
  };

  const getCoacheeProgram = async () => {
    try {
      const { data } = await callEndpoint(
        getProgramById(coachee?.coachingProgram)
      );

      setCoacheeProgram(data?.data?.[0].name);
    } catch (error) {
      console.log(error);
    }
  };

  const getSession = async (id) => {
    try {
      const { data } = await callEndpoint(getSessionByID(id));
      setSelectedSession(adaptedSession(data.data));
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackCoachee = () => {
    navigate('/mycoachees');
    setSelectedCoachee(false);
  };

  const handleBackSession = () => {
    setSelectedSession(false);
    navigate(`/mycoachees/${coachee._id}`);
  };

  useEffect(() => {
    if (sessionId) {
      getSession(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    getCoacheeProgram();
  }, [coachee?.coachingProgram]);

  useEffect(() => {
    const translateFocusAreas = async () => {
      try {
        const translatedAreas = await Promise.all(
          coachee?.focusAreas?.map(async (area) => {
            const translatedText = await translateFocusArea(area);
            return translatedText;
          })
        );

        setTranslatedFocusAreas(translatedAreas);
      } catch (error) {
        console.error('Error translating focus areas:', error);
      }
    };

    translateFocusAreas();
  }, [coachee?.focusAreas, i18n.language]);

  const getAlignmentSessions = async () => {
    try {
      const res = await callEndpoint(getAllAlignmentSessionCoachee(userId));
      setAlignmentSessions(res.data.data);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    getAlignmentSessions();
  }, []);

  const coacheePromedioPrograma = (date) => {
    let days = coachee?.cohort?.Program * 7;

    if (date && days) {
      let fechaInicial = new Date(date);
      fechaInicial.setDate(fechaInicial.getDate() + days + 14);

      return fechaInicial.toLocaleDateString();
    } else {
      console.error('Error: onboardingDate o days no están definidos.');
      return null;
    }
  };

  let coacheeActualStatus;

  if (coachee?.statusUser === true && coachee.suspendedByCompany === false) {
    coacheeActualStatus = t('pages.myCoachees.components.active');
  } else if (
    coachee?.statusUser === false &&
    coachee?.suspendedByCompany === false
  ) {
    coacheeActualStatus = t('pages.myCoachees.components.inactive');
  } else if (
    coachee?.statusUser === false &&
    coachee?.suspendedByCompany === true
  ) {
    coacheeActualStatus = t('pages.myCoachees.components.suspended');
  } else {
    coacheeActualStatus = '-';
  }

  if (loading) return <Loading />;
  return (
    <>
      <Button
        backgroundColor="transparent"
        width="28"
        marginTop={10}
        marginBottom={5}
        onClick={selectedSession ? handleBackSession : handleBackCoachee}
      >
        <Image src={LeftRedArrow} objectFit="cover" />
      </Button>
      <div className="Coachee">
        {selectedSession && (
          <div className="Coachee__session_title">
            <h3>Sesión {selectedSession.number}</h3>
            <p>
              {mongoDateToLongDate({
                unformatedDate: selectedSession.date,
                language: i18n.language
              })}
            </p>
          </div>
        )}
        <div className="Coachee__data">
          <Image
            src={coachee.urlImgCoachee}
            className="Coachee__data_image"
            objectFit="cover"
          />
          <div className="Coachee__data_info">
            <div className="Coachee__data_info_data">
              <AiOutlineUser className="Coachee__data_info_icon" />{' '}
              <p>
                <strong>
                  {coachee.name} {coachee.lastname}
                </strong>
              </p>
            </div>

            <div className="Coachee__data_info_data">
              <RiUserFollowLine className="Coachee__data_info_icon" />
              <p>Estado del Coachee: {coacheeActualStatus}</p>
            </div>

            <div className="Coachee__data_info_data">
              <RiBuilding4Line className="Coachee__data_info_icon" />
              <p>
                {coachee.company
                  ? coachee.company.name
                  : t('pages.myCoachees.components.company')}
              </p>
            </div>

            <div className="Coachee__data_info_data">
              <GiProgression className="Coachee__data_info_icon" />
              <p>{coacheeProgram ? `Programa: ${coacheeProgram}` : ''}</p>
            </div>
            <div className="Coachee__data_info_data">
              <AiOutlineMail className="Coachee__data_info_icon" />
              <p>{coachee.email}</p>
            </div>

            <div className="Coachee__data_info_data">
              <AiOutlinePhone className="Coachee__data_info_icon" />
              <p>{coachee?.phone}</p>
            </div>

            {coachee.onboardingDate && (
              <div>
                <div className="Coachee__data_info_data">
                  <AiOutlineClockCircle className="Coachee__data_info_icon" />
                  <p>
                    {t('pages.reschedule.EstimatedDate')}
                    {coacheePromedioPrograma(coachee.onboardingDate)}
                  </p>
                </div>
              </div>
            )}

            <div className="Coachee__data_info_focus-areas">
              <div className="Coachee__data_info_focus-areas_areas">
                {translatedFocusAreas.map((translatedArea) => (
                  <p
                    className="Coachee__data_info_focus-areas_area"
                    key={translatedArea._id}
                  >
                    {translatedArea}
                  </p>
                ))}
              </div>
            </div>
            <div className="Coachee__data_info_data">
              <Button
                style={{ marginTop: '0.7em' }}
                onClick={onOpen}
                className="Button Button--secondary"
              >
                {t('pages.reschedule.AddAlignmentSession')}
              </Button>
            </div>

            <div className="flex justify-center items-center h-screen">
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW="600px" fontSize="xl">
                  <div className="flex justify-end">
                    <ModalCloseButton />
                  </div>
                  <ModalHeader fontSize="3xl" textAlign="center">
                    {t('pages.reschedule.AlignmentSessionTitle')}
                  </ModalHeader>{' '}
                  <ModalBody fontSize="2xl">
                    <div className="flex-container">
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="initial"
                          name="evaluationType"
                          value="initial"
                          checked={evaluationType === 'initial'}
                          onChange={() => setEvaluationType('initial')}
                        />
                        <label htmlFor="initial">
                          {t('pages.reschedule.InitialEvaluation')}
                        </label>
                      </div>
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="final"
                          name="evaluationType"
                          value="final"
                          checked={evaluationType === 'final'}
                          onChange={() => setEvaluationType('final')}
                        />
                        <label htmlFor="final">
                          {t('pages.reschedule.FinalEvaluation')}
                        </label>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: '2vh' }}
                      className="flex justify-center items-center"
                    >
                      <h3>{t('pages.reschedule.Date')}</h3>
                    </div>
                    <div
                      style={{ marginTop: '0.3vh' }}
                      className="flex justify-center items-center"
                    >
                      <DatePicker
                        showTime={{
                          defaultValue: moment('00:00:00', 'HH:mm:ss'),
                          format: 'HH:mm',
                          minuteStep: 15
                        }}
                        placeholder="Seleccione una fecha y hora"
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        getPopupContainer={getModalContainer}
                        locale={locale}
                      />
                    </div>

                    {evaluationType && selectedDate && (
                      <div style={{ marginTop: '1.5vh' }}>
                        <h5>
                          {t('pages.reschedule.AlignmentSessionMessage')}{' '}
                          <span style={{ color: 'red', fontWeight: 'bold' }}>
                            {evaluationType === 'initial' &&
                              t('components.evaluation.start')}{' '}
                            {evaluationType === 'final' &&
                              t('components.evaluation.end')}{' '}
                          </span>
                          {t('pages.reschedule.AlignmentSessionDate')}{' '}
                          {selectedDate ? (
                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                              {mongoDateToSessionDate(dateWithTimezone)}
                            </span>
                          ) : (
                            ''
                          )}{' '}
                        </h5>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      fontSize="2xl"
                      colorScheme="blue"
                      mr={3}
                      onClick={() => handleAddAlignmentSession()}
                    >
                      {t('pages.reschedule.AlignmentSessionButton')}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center"></div>

        <div className="Coachee__tabs">
          {selectedSession ? (
            <SessionTabs session={selectedSession} />
          ) : (
            <CoacheeTabs coachee={coachee} />
          )}
        </div>
      </div>
    </>
  );
}

export default Coachee;
