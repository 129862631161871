import React, { useEffect, useState } from 'react';
import EvaluationArea from 'components/EvaluationArea';
import NoData from 'components/NoData/NoData';
import { size, forEach, findIndex, find, map, findLastIndex } from 'lodash';
import { useFetchAndLoad } from 'hooks';
import {
  getAutoEvaluationByCoacheeId,
  getAutoEvaluationId,
  getEvaluation360ByCoacheeId
} from 'services/evaluations.service';
import displayToast from 'utilities/toast.utility';
import Loading from 'components/Loading';
import { mongoDateToShortDate } from 'utilities';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createEvaluationsPDF } from 'services/coachee.service';
import { Button } from '@chakra-ui/react';

function Evaluation(props) {
  const [evaluations, setEvaluations] = useState([]);
  const [evaluationsIds, setEvaluationsIds] = useState(null);
  const [focusAreas, setFocusAreas] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [finalEvaluationIndex, setFinalEvaluationIndex] = useState(0);
  const [isAutoevaluation, setIsAutoevaluation] = useState(false);

  const { t } = useTranslation('global');
  const errorGet = t('components.evaluation.errorGet');
  const error360 = t('components.evaluation.error360');
  const { title, type, coachee } = props;

  const { role, mongoID, languages, name, lastname } = useSelector(
    (state) => state.user
  );

  const isCoachee = role === 'coachee';

  const getAutoevaluations = async () => {
    try {
      const { data } = await callEndpoint(
        getAutoEvaluationByCoacheeId(coachee._id)
      );
      setEvaluations(data.data);
    } catch (error) {
      displayToast(errorGet, 'error');
    }
  };

  const getAutoevaluationsIds = async () => {
    try {
      const { data } = await callEndpoint(getAutoEvaluationId(coachee._id));
      console.log('data', data);
      setEvaluationsIds(data.data);
    } catch (error) {
      displayToast(errorGet, 'error');
    }
  };

  const getEvaluations360 = async () => {
    try {
      const { data } = await callEndpoint(
        getEvaluation360ByCoacheeId(coachee._id)
      );
      setEvaluations(data.data);
    } catch (error) {
      displayToast(error360, 'error');
    }
  };

  const getFinalEvaluation = () => {
    setFinalEvaluationIndex(
      findLastIndex(
        evaluations,
        (evaluation) => evaluation.evaluationType === 'final'
      )
    );
  };

  const getAnswersGroupedByFocusAreas = () => {
    const focusAreasTemp = [];
    forEach(evaluations, (evaluation) => {
      const isInitialEvaluation = evaluation.evaluationType === 'initial';

      forEach(evaluation.answers, (answer) => {
        const indexOfFocusArea = findIndex(
          focusAreasTemp,
          (focusArea) => focusArea?._id === answer?.question?.focusArea?._id
        );

        if (indexOfFocusArea < 0) {
          focusAreasTemp.push({
            _id: answer?.question?.focusArea?._id,
            title: answer?.question?.focusArea?.focusArea,
            image: answer?.question?.focusArea?.urlImgFocusArea,
            en: answer?.question?.focusArea?.en,
            pt: answer?.question?.focusArea?.pt,
            questions: [
              {
                _id: answer.question._id,
                title: answer.question.question,
                initialEvaluation: isInitialEvaluation ? answer.value : 0,
                finalEvaluation: !isInitialEvaluation ? answer.value : 0,
                initialAnswers: isInitialEvaluation ? 1 : 0,
                finalAnswers: !isInitialEvaluation ? 1 : 0,
                es: answer.question.question,
                en: answer.question.en,
                pt: answer.question.pt
              }
            ],
            totalInitialEvaluation: isInitialEvaluation ? answer.value : 0,
            totalFinalEvaluation: !isInitialEvaluation ? answer.value : 0,
            totalInitialAnswers: isInitialEvaluation ? 1 : 0,
            totalFinalAnswers: !isInitialEvaluation ? 1 : 0
          });
        } else {
          if (isInitialEvaluation) {
            focusAreasTemp[indexOfFocusArea].totalInitialEvaluation +=
              answer.value;
            focusAreasTemp[indexOfFocusArea].totalInitialAnswers += 1;
          } else {
            focusAreasTemp[indexOfFocusArea].totalFinalEvaluation +=
              answer.value;
            focusAreasTemp[indexOfFocusArea].totalFinalAnswers += 1;
          }

          const question = find(
            focusAreasTemp[indexOfFocusArea].questions,
            (savedQuestion) => savedQuestion._id === answer.question._id
          );

          if (!question) {
            focusAreasTemp[indexOfFocusArea].questions.push({
              _id: answer.question._id,
              title: answer.question.question,
              initialEvaluation: isInitialEvaluation ? answer.value : 0,
              finalEvaluation: !isInitialEvaluation ? answer.value : 0,
              initialAnswers: isInitialEvaluation ? 1 : 0,
              finalAnswers: !isInitialEvaluation ? 1 : 0,
              es: answer.question.question,
              en: answer.question.en,
              pt: answer.question.pt
            });

            return;
          }

          focusAreasTemp[indexOfFocusArea].questions = map(
            focusAreasTemp[indexOfFocusArea].questions,
            (savedQuestion) => {
              if (savedQuestion._id !== answer.question._id) {
                return savedQuestion;
              }

              return {
                ...savedQuestion,
                initialEvaluation: isInitialEvaluation
                  ? savedQuestion.initialEvaluation + answer.value
                  : savedQuestion.initialEvaluation,
                finalEvaluation: !isInitialEvaluation
                  ? savedQuestion.finalEvaluation + answer.value
                  : savedQuestion.finalEvaluation,
                initialAnswers: isInitialEvaluation
                  ? savedQuestion.initialAnswers + 1
                  : savedQuestion.initialAnswers,
                finalAnswers: !isInitialEvaluation
                  ? savedQuestion.finalAnswers + 1
                  : savedQuestion.finalAnswers
              };
            }
          );
        }
      });
    });

    // if (focusAreasTemp.length > 6) {
    //   focusAreasTemp.pop();
    // }

    setFocusAreas(focusAreasTemp);
  };

  useEffect(() => {
    if (type === 'autoevaluation') {
      getAutoevaluations();
      getAutoevaluationsIds();
      setIsAutoevaluation(true);
    }

    if (type === 'evaluation360') {
      getEvaluations360();
    }
  }, []);

  useEffect(() => {
    getAnswersGroupedByFocusAreas();
    getFinalEvaluation();
  }, [evaluations]);

  const downloadPdf = async (evaluationType) => {
    try {
      let id = isCoachee ? mongoID : coachee._id;
      let language = isCoachee ? languages[0] : coachee.languages[0];
      const pdf = await callEndpoint(
        createEvaluationsPDF(id, evaluationType, language)
      );

      const pdfBlob = new Blob([pdf.data], { type: 'application/pdf' });

      /***** To Open in a new Window ****/
      // const url = window.URL.createObjectURL(pdfBlob);
      // window.open(url, '_blank');
      // window.URL.revokeObjectURL(url);

      const fileName = isCoachee
        ? `${evaluationType}_Evaluations_${name}_${lastname}.pdf`
        : `${evaluationType}_Evaluations_${coachee.name}_${coachee.lastname}.pdf`;

      /****** To Directly Download ******/
      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', fileName);
      tempLink.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      displayToast(
        `The user doesnt have ${evaluationType} evaluations`,
        'error'
      );
    }
  };

  if (loading) return <Loading title={t('components.evaluation.loading')} />;

  return (
    <>
      <div className="flex flex-col items-center justify-center space-y-4 mt-6">
        <div className="flex space-x-4">
          {isAutoevaluation &&
            isCoachee &&
            evaluationsIds?.autoevaluationInitial !== null && (
              <a
                href={`https://www.app.bonumcoaching.com/autoevaluation/${evaluationsIds?.autoevaluationInitial}`}
                target="_blank"
                className="btn btn-autoevaluacion-inicial"
              >
                {t('components.evaluation.initialAutoevaluation')}
              </a>
            )}

          {isAutoevaluation &&
            isCoachee &&
            evaluationsIds?.autoevaluationFinal !== null && (
              <a
                href={`https://www.app.bonumcoaching.com/autoevaluation/${evaluationsIds?.autoevaluationFinal}`}
                target="_blank"
                className="btn btn-autoevaluacion-final"
              >
                {t('components.evaluation.finalAutoevaluation')}
              </a>
            )}
        </div>

        <div className="flex space-x-4">
          <a
            onClick={() => downloadPdf('initial')}
            className="btn btn-autoevaluacion-inicial"
            style={{ cursor: 'pointer' }}
          >
            {t('pages.myEvaluations.downloadInitialPdf')}
          </a>

          <a
            onClick={() => downloadPdf('final')}
            className="btn btn-autoevaluacion-final"
            style={{ cursor: 'pointer' }}
          >
            {t('pages.myEvaluations.downloadFinalPdf')}
          </a>
        </div>
      </div>

      <div className="Evaluation background">
        {size(focusAreas) > 0 && (
          <>
            <div className="Evaluation__hours">
              <div>
                <h5>{t('components.evaluation.start')}</h5>
                <p>{mongoDateToShortDate(evaluations[0]?.dateSended)}</p>
              </div>
              <div>
                <h5>{t('components.evaluation.end')}</h5>
                <p>
                  {finalEvaluationIndex >= 0
                    ? mongoDateToShortDate(
                        evaluations[finalEvaluationIndex]?.dateSended
                      )
                    : 'dd-mm-yyy'}
                </p>
              </div>
            </div>

            <h2 className="Evaluation__title">{title}</h2>
          </>
        )}

        {size(focusAreas) > 0 ? (
          focusAreas.map((focusArea) => (
            <EvaluationArea
              className="Evaluation__area"
              focusArea={focusArea}
              key={focusArea._id}
            />
          ))
        ) : (
          <NoData title={t('components.evaluation.noData')} />
        )}
      </div>
    </>
  );
}

export default Evaluation;
