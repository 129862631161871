import React from 'react';
import {
  FormControl,
  Input,
  Button,
  FormErrorMessage,
  Select
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { uniqueId } from 'lodash';
import validationSchema from './validationSchema';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon } from '@chakra-ui/icons';

function AddEvaluator({ setAddEvaluator, handleAddEvaluator }) {
  const initialValues = () => ({
    type: 'supervisor',
    name: '',
    lastname: '',
    email: ''
  });

  const { mongoID } = useSelector((state) => state.user);
  const { t } = useTranslation('global');

  const { handleSubmit, handleChange, values, errors, handleBlur, touched } =
    useFormik({
      initialValues: initialValues(),
      validationSchema,
      onSubmit: (formValues) => {
        const { name, lastname, email, type } = formValues;

        handleAddEvaluator({
          id: uniqueId(`${type}`),
          name,
          lastname,
          email,
          type,
          coachee: mongoID
        });

        setAddEvaluator(false);
      }
    });

  return (
    <div className="AddEvaluator">
      <h4 className="AddEvaluator__title">
        {t('pages.onboarding.components.addEvaluator.title')}
      </h4>
      <p className="AddEvaluator__subtitle">
        {t('pages.onboarding.components.addEvaluator.subtitle')}
      </p>
      <form
        onSubmit={handleSubmit}
        className="AddEvaluator__form Card"
        id="AddEvaluator"
      >
        <FormControl isInvalid={errors?.type && touched?.type}>
          <Select
            name="type"
            className="AddEvaluator__select"
            icon={<ArrowDownIcon className="AddEvaluator__select_icon" />}
            onChange={handleChange}
          >
            <option value="supervisor">
              {t('pages.onboarding.components.addEvaluator.value1')}
            </option>
            <option value="colaborator">
              {t('pages.onboarding.components.addEvaluator.value2')}
            </option>
            <option value="partner">
              {t('pages.onboarding.components.addEvaluator.value3')}
            </option>
          </Select>
          {errors?.type && touched.type ? (
            <FormErrorMessage fontSize="lg">
              {t(
                `pages.onboarding.components.addEvaluator.validation.${errors.type}`
              )}
            </FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl isInvalid={errors.name && touched.name}>
          <Input
            className="AddEvaluator__input"
            name="name"
            placeholder={t(
              'pages.onboarding.components.addEvaluator.fieldName'
            )}
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
          />
          {errors?.name && touched.name ? (
            <FormErrorMessage fontSize="lg">
              {t(
                `pages.onboarding.components.addEvaluator.validation.${errors.name}`
              )}
            </FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl isInvalid={errors.lastname && touched.lastname}>
          <Input
            className="AddEvaluator__input"
            name="lastname"
            placeholder={t(
              'pages.onboarding.components.addEvaluator.fieldLastname'
            )}
            onChange={handleChange}
            value={values.lastname}
            onBlur={handleBlur}
          />
          {errors?.lastname && touched.lastname ? (
            <FormErrorMessage fontSize="lg">
              {t(
                `pages.onboarding.components.addEvaluator.validation.${errors.lastname}`
              )}
            </FormErrorMessage>
          ) : null}
        </FormControl>
        <FormControl isInvalid={errors.email && touched.email}>
          <Input
            className="AddEvaluator__input"
            name="email"
            type="email"
            placeholder={t(
              'pages.onboarding.components.addEvaluator.fieldEmail'
            )}
            onChange={handleChange}
            value={values.email}
            onBlur={handleBlur}
          />
          {errors?.email && touched.email ? (
            <FormErrorMessage fontSize="lg">
              {t(
                `pages.onboarding.components.addEvaluator.validation.${errors.email}`
              )}
            </FormErrorMessage>
          ) : null}
        </FormControl>
      </form>

      <div className="AddEvaluator__actions">
        <Button
          className="Button Button--secondary Button--cancel"
          type="button"
          onClick={() => setAddEvaluator(false)}
        >
          {t('pages.onboarding.components.addEvaluator.buttonCancel')}
        </Button>
        <Button
          className="Button Button--primary"
          type="submit"
          form="AddEvaluator"
        >
          {t('pages.onboarding.components.addEvaluator.buttonSave')}
        </Button>
      </div>
    </div>
  );
}

export default AddEvaluator;
