import React from 'react';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';

function WarningModal({ modal, setModal }) {
  const { t } = useTranslation('global');
  return (
    <>
      <div className="WarningModal">
        <Modal
          content={<ModalContentWarning />}
          isOpen={modal}
          title={modal}
          onClose={() => setModal('')}
          closeColor="#f94015"
          size="4xl"
          closeText={t('pages.sessionEvaluation.close')}
        />
      </div>
    </>
  );
}

function ModalContentWarning() {
  const { t } = useTranslation('global');
  return (
    <div className="WarningModal__modal">
      <h4>{t('pages.sessionEvaluation.warningModal')}</h4>
    </div>
  );
}

export default WarningModal;
