import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  MessageInput,
  MessageList
  // MessageSeparator
} from '@chatscope/chat-ui-kit-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { VscChromeMinimize, VscClose } from 'react-icons/vsc';
import Loading from 'components/Loading';
import useChatContext from '../hooks/useChatContext';
import { useSelector } from 'react-redux';

//firebase imports
import { firestore } from 'utilities';
import {
  addDoc,
  collection,
  doc,
  limit,
  onSnapshot,
  Timestamp,
  orderBy,
  query,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import { uploadFile } from '../utilities/chat.utility';
import Message from './MyMessage';
import displayToast from 'utilities/toast.utility';
import { sendChatMessage } from 'services/user.service';
import { useFetchAndLoad } from 'hooks';

function Conversation({ data }) {
  //Functions and states for conversation window (conversation With this user)
  const isCoach = data.role === 'coach';
  const avatar = isCoach ? data.urlImgCoach : data.urlImgCoachee;
  const userName = `${data.name} ${data.lastname}`;
  const [isMinimized, setIsMinimized] = useState(false);
  const handleMinimized = () => setIsMinimized(!isMinimized);
  const { handleRemoveChat } = useChatContext();

  //Chat Context
  const { modifyContactWithNewRoom } = useChatContext();

  //User Data (auth user)
  const { name, lastname, email, photo, uid, mongoID } = useSelector(
    (state) => state.user
  );

  //Firebase and chat functions
  const MESSAGES_LIMIT = 50;
  const [roomHash, setRoomHash] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [lastVisibleMessage, setLastVisibleMessage] = useState(undefined);
  let idRoom = data?.roomId ? data.roomId : nanoid(20);
  const { callEndpoint } = useFetchAndLoad();

  const roomRef = doc(firestore, 'rooms', idRoom);
  const roomMessagesRef = collection(firestore, 'rooms', idRoom, 'messages');
  const roomQuery = query(
    roomMessagesRef,
    limit(MESSAGES_LIMIT),
    orderBy('createdAt', 'asc')
  );

  //Messages functions
  const hiddenFileInput = useRef(null);
  const appendMessages = useCallback(
    (messages) => {
      setMessages((previousMessages) => {
        return [...previousMessages, ...messages];
      });
    },
    [messages]
  );

  const handleAttachClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    (async () => {
      if (!data?.roomId) {
        const currUserData = {
          displayName: `${name} ${lastname}`,
          email: email
        };
        if (photo) {
          currUserData.photoURL = photo;
        }
        const userBData = {
          displayName: userName,
          email: data.email
        };
        if (avatar) {
          userBData.photoURL = avatar;
        }
        const roomData = {
          participants: [currUserData, userBData],
          participantsArray: [email, data.email]
        };
        try {
          await setDoc(roomRef, roomData);
          modifyContactWithNewRoom(idRoom, data._id);
        } catch (error) {
          console.log('Set RoomData UserBData Error', error);
        }
      }

      const emailHash = `${email}:${data.email}`;
      setRoomHash(emailHash);
    })();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(roomQuery, (querySnapshot) => {
      const messagesFirestore = querySnapshot
        .docChanges()
        .filter(({ type }) => type === 'added')
        .map(({ doc }) => {
          const message = doc.data();
          return { ...message, id: doc.id };
        });
      appendMessages(messagesFirestore);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (data?.lastMessage) {
      updateDoc(roomRef, {
        lastMessage: { ...data.lastMessage, unread: false }
      });
    }
  }, []);

  const handleSendMessage = async (newMessage) => {
    const message = {
      text: encodeURIComponent(newMessage),
      user: {
        avatar: photo,
        name: `${name} ${lastname}`,
        _id: uid,
        email
      },
      userB: {
        email: data.email,
        fullname: `${data.name} ${data.lastname}`,
        image: avatar
      },
      createdAt: Timestamp.now()
    };

    const userB = {
      email: data.email,
      fullname: `${data.name} ${data.lastname}`,
      image: avatar
    };

    const writes = [];

    writes.push(addDoc(roomMessagesRef, message));
    writes.push(
      updateDoc(roomRef, {
        lastMessage: { ...message, userB, unread: true }
      })
    );
    await Promise.all(writes);

    const pushNotification = {
      receiverID: data._id,
      name: `${name} ${lastname}`,
      message: newMessage,
      senderID: mongoID
    };

    await callEndpoint(sendChatMessage(pushNotification));
  };

  const handleUploadFile = async (e) => {
    const maxFileSize = 20 * 1024 * 1024;
    if (e?.target?.files[0]?.size > maxFileSize) {
      displayToast('Error, el archivo es demasiado grande', 'error');
      return;
    }

    const fileURL = Object.assign(e.target.files[0], {
      preview: URL.createObjectURL(e.target.files[0])
    });

    const re = /(?:\.([^.]+))?$/;
    const extension = re.exec(fileURL.name);
    console.log(fileURL);

    const { url, filename, type } = await uploadFile(
      fileURL.preview,
      `chatFiles/rooms/${roomHash}`,
      fileURL.name,
      extension
    );

    const message = {
      _id: `${filename}-${nanoid()}`,
      text: filename,
      createdAt: Timestamp.now(),
      user: {
        name: `${name} ${lastname}`,
        _id: uid,
        email
      },
      userB: {
        email: data.email,
        fullname: `${data.name} ${data.lastname}`,
        image: avatar
      },
      url,
      type
    };

    const lastMessage = {
      ...message,
      text: filename,
      unread: true
    };

    await Promise.all([
      addDoc(roomMessagesRef, { ...message }),
      updateDoc(roomRef, { lastMessage })
    ]);
  };

  return (
    <div
      className={`Chat__conversation ${
        isMinimized ? 'Chat__conversation--minimized' : ''
      }`}
    >
      <input
        type="file"
        multiple={false}
        ref={hiddenFileInput}
        onChange={handleUploadFile}
        style={{ display: 'none' }}
      />
      <ChatContainer>
        <ConversationHeader>
          <Avatar src={avatar} size="fluid" />
          <ConversationHeader.Content
            userName={userName}
            onClick={handleMinimized}
          />
          <ConversationHeader.Actions>
            <VscChromeMinimize onClick={handleMinimized} />
            <VscClose onClick={() => handleRemoveChat(data)} />
          </ConversationHeader.Actions>
        </ConversationHeader>

        {!isMinimized && (
          <MessageList>
            {loading && <Loading />}
            {messages.map((message) => (
              <Message
                message={message}
                email={email}
                userName={userName}
                key={message.id}
              />
            ))}
          </MessageList>
        )}

        {!isMinimized && (
          <MessageInput
            onSend={handleSendMessage}
            onAttachClick={handleAttachClick}
          />
        )}
      </ChatContainer>
    </div>
  );
}

export default Conversation;
