import { useEffect, useState } from 'react';
import { Image, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import ViewCoachCalendar from 'components/ViewCoachCalendar';
import { useTranslation } from 'react-i18next';
import { Rate } from 'antd';
import YouTube from 'react-youtube';
import { useFetchAndLoad } from 'hooks';
import { translateText } from 'services/coach.service';
import { Player } from 'video-react';
import VPlayer from 'react-vplayer';
import translateFocusArea from 'utilities/translateFocusArea.utility';

function MyCoach() {
  const { coach } = useSelector((state) => state.user);
  const [viewCalendar, setViewCalendar] = useState(false);
  const { t, i18n } = useTranslation('global');
  const [translatedHowWork, setTranslatedHowWork] = useState(coach?.howWork);
  const [translatedResume, setTranslatedResume] = useState(coach?.resume);
  const [translatedFocusAreas, setTranslatedFocusAreas] = useState([]);

  const { loading, callEndpoint } = useFetchAndLoad();

  const translate = async (text) => {
    try {
      const { data } = await callEndpoint(
        translateText({
          targetLanguage: i18n.language,
          text: text
        })
      );

      return data.data;
    } catch (error) {
      console.error('Error translating text:', error);
      return '';
    }
  };

  useEffect(() => {
    const translateHowWork = async () => {
      try {
        // Realizar la traducción del campo "howWork"
        const translatedText = await translate(coach?.howWork);
        setTranslatedHowWork(translatedText);
      } catch (error) {
        console.error('Error translating "howWork":', error);
      }
    };

    translateHowWork();
  }, [coach?.howWork, i18n.language]);

  useEffect(() => {
    const translateResume = async () => {
      try {
        // Realizar la traducción del campo "resume"
        const translatedText = await translate(coach?.resume);
        setTranslatedResume(translatedText);
      } catch (error) {
        console.error('Error translating "resume":', error);
      }
    };

    translateResume();
  }, [coach?.resume, i18n.language]);

  useEffect(() => {
    const translateFocusAreas = async () => {
      try {
        const translatedAreas = await Promise.all(
          coach?.focusAreas?.map(async (area) => {
            const translatedText = await translateFocusArea(area);
            return translatedText;
          })
        );

        setTranslatedFocusAreas(translatedAreas);
      } catch (error) {
        console.error('Error translating focus areas:', error);
      }
    };

    translateFocusAreas();
  }, [coach?.focusAreas, i18n.language]);

  console.log('translatedFocusAreas', translatedFocusAreas);

  function obtenerVideoId(url) {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('v');
  }

  const isMobile = window.innerWidth < 950;
  const isSmallScreen = window.innerWidth < 500;

  return (
    <div className="SelectedCoach background">
      <ViewCoachCalendar
        isOpen={viewCalendar}
        onClose={() => setViewCalendar(false)}
        coach={coach}
      />
      <div className="SelectedCoach__info">
        <div className="SelectedCoach__info_photo">
          <Image
            className="SelectedCoach__info_photo"
            src={coach?.urlImgCoach}
            objectFit="cover"
          />
          <Button
            className="Button Button--secondary SelectedCoach__calendarbutton"
            onClick={() => setViewCalendar(true)}
          >
            {t('pages.myCoach.viewCalendar')}
          </Button>
        </div>
        <div className="SelectedCoach__info_data">
          <h4 className="SelectedCoach__info_data_name">
            {coach?.name} {coach?.lastname}
          </h4>
          <p className="SelectedCoach__info_data_focus">Superación Personal</p>
          <div className="SelectedCoach__info_data_rating">
            <Rate
              allowHalf
              disabled
              defaultValue={0}
              value={5}
              className="SelectedCoach__info_data_rating_stars"
            />
          </div>
          <p className="SelectedCoach__info_data_description">
            {translatedResume ? translatedResume : coach?.resume}
          </p>
          <div className="SelectedCoach__info_focus-areas">
            <h4 className="SelectedCoach__info_focus-areas_title">
              {t('pages.myCoach.focusAreasTile')}
            </h4>
            <div className="SelectedCoach__info_focus-areas_areas">
              {translatedFocusAreas?.map((translatedArea) => (
                <p
                  key={translatedArea._id}
                  className="SelectedCoach__info_focus-areas_area"
                >
                  {translatedArea}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>

      {coach?.urlVideoCoach && coach?.urlVideoCoach !== 'pending' ? (
        <div className="">
          {coach.urlVideoCoach.startsWith(
            'https://firebasestorage.googleapis.com'
          ) ? (
            <div style={{ width: '24vw' }}>
              <VPlayer source={coach.urlVideoCoach} />
            </div>
          ) : (
            <YouTube
              videoId={obtenerVideoId(coach.urlVideoCoach)} // Debes definir una función para obtener el ID del video de la URL
              opts={{
                width: isSmallScreen ? '300px' : isMobile ? '500px' : '800px',
                height: isSmallScreen ? '200px' : isMobile ? '300px' : '500px'
              }}
            />
          )}
        </div>
      ) : null}

      <div className="SelectedCoach__howWorks">
        <h4 className="SelectedCoach__howWorks_title">
          {t('pages.myCoach.howWork')}
        </h4>
        <div className="SelectedCoach__howWorks_description">
          {translatedHowWork ? translatedHowWork : coach?.howWork}
        </div>
      </div>
    </div>
  );
}

export default MyCoach;
