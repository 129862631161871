import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultWorkSchedules from './utilities/DefaultWorkSchedules.utility';
import { setWorkSchedule } from 'redux/slices/onboarding';
import { useTranslation } from 'react-i18next';
import RangePicker from './RangePicker';

function WorkSchedule() {
  const { workSchedule } = useSelector((state) => state.onboarding);
  const [schedules, setSchedules] = useState(DefaultWorkSchedules);
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  const handleScheduleCheckbox = (scheduleToModify) => {
    const newSchedules = schedules.map((schedule) => {
      if (schedule.id !== scheduleToModify.id) return schedule;

      return { ...scheduleToModify, Work: !schedule.Work };
    });
    setSchedules(newSchedules);
  };
  const databaseFormat = 'HH:mm';

  const translateDay = (day) => {
    switch (day.toLowerCase()) {
      case 'monday':
        return t('pages.onboarding.components.workSchedule.days.monday');
      case 'tuesday':
        return t('pages.onboarding.components.workSchedule.days.tuesday');
      case 'wednesday':
        return t('pages.onboarding.components.workSchedule.days.wednesday');
      case 'thursday':
        return t('pages.onboarding.components.workSchedule.days.thursday');
      case 'friday':
        return t('pages.onboarding.components.workSchedule.days.friday');
      case 'saturday':
        return t('pages.onboarding.components.workSchedule.days.saturday');
      case 'sunday':
        return t('pages.onboarding.components.workSchedule.days.sunday');
      default:
        return 'Lunes';
    }
  };

  const handleScheduleChange = (scheduleModified, event) => {
    console.log(event);
    const newSchedules = schedules.map((schedule) => {
      if (schedule.id !== scheduleModified.id) return schedule;

      return {
        ...scheduleModified,
        InitialDate: event[0]?.format(databaseFormat),
        EndDate: event[1]?.format(databaseFormat)
      };
    });
    setSchedules(newSchedules);
  };

  useEffect(() => {
    dispatch(setWorkSchedule(schedules));
  }, [schedules]);

  useEffect(() => {
    if (workSchedule.length > 0) {
      dispatch(setWorkSchedule(workSchedule));
      setSchedules(workSchedule);
      return;
    }
  }, []);

  return (
    <div className="WorkSchedule">
      <h2 className="WorkSchedule__title">
        {t('pages.onboarding.components.workSchedule.title')}
      </h2>
      <p className="WorkSchedule__subtitle">
        {t('pages.onboarding.components.workSchedule.subtitle')}
        <br /> {t('pages.onboarding.components.workSchedule.description')}
      </p>

      <p className="WorkSchedule__subtitle"></p>

      {schedules.map((schedule) => (
        <RangePicker
          key={schedule.id}
          schedule={schedule}
          translateDay={translateDay}
          handleScheduleChange={handleScheduleChange}
          handleScheduleCheckbox={handleScheduleCheckbox}
        />
      ))}
    </div>
  );
}

export default WorkSchedule;
