import { useContext, useEffect, useState } from 'react';
import Checked from 'assets/images/icons/boton-siguiente.png';
import { Image } from '@chakra-ui/react';
import MySessionsContext from 'pages/MySessions/context/MySessionsContext';
import { mongoDateToSessionDate } from 'utilities';
import { useDispatch, useSelector } from 'react-redux';
import { modifySession } from 'redux/slices/session';
import adaptedSession from 'adapters/sessionsAdapter.adapter';
import { useNavigate } from 'react-router-dom';
import { useFetchAndLoad } from 'hooks';
import {
  cancelSession,
  updateSession,
  updateSessionNoShow
} from 'services/sessions.service';
import { updateCoachee, updateNoShowAcc } from 'services/user.service';
import { Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

function Session({ session, index }) {
  const { setSelectedSession } = useContext(MySessionsContext);
  const user = useSelector((state) => state.user);
  const { callEndpoint } = useFetchAndLoad();
  const dispatch = useDispatch();
  const navigate = useNavigate('/');
  const { t } = useTranslation('global');

  const [hours, setHours] = useState('');

  const cancelMySession = async (id) => {
    try {
      await callEndpoint(cancelSession(session));

      navigate(`/reschedule/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const getHoursDifference = () => {
    const currentDate = new Date();
    const sessionDate = new Date(session.date);
    const hoursDifference =
      (sessionDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60);
    setHours(Math.round(hoursDifference));
  };

  useEffect(() => {
    getHoursDifference();
  }, []);

  const reschedule = (id) => navigate(`/reschedule/${id}`);

  return (
    <div
      className="MySessions__session"
      onKeyDown={() => null}
      role="button"
      tabIndex={index}
    >
      <div className="MySessions__session_info">
        <div className="MySessions__session_title_wrapper">
          <h5 className="MySessions__session_info_title">
            {t('pages.mySessions.components.session.session')}{' '}
            {session?.type === 'initial'
              ? t('pages.myCoachees.components.initialAlignment')
              : session.type === 'final'
              ? t('pages.myCoachees.components.finalAlignment')
              : session.number}
          </h5>
        </div>
        <p className="MySessions__session_info_date">
          {mongoDateToSessionDate(session.date)}
        </p>
      </div>
      <div className="flex MySessions__session_info align-center">
        {session.type !== 'initial' &&
          session.type !== 'final' &&
          session.status &&
          ((user && user.role === 'coach' && !session.evaluatedByCoach) ||
            (user &&
              user.role === 'coachee' &&
              !session.evaluatedByCoachee)) && (
            <p
              onClick={() => {
                dispatch(modifySession(adaptedSession(session)));
                navigate('/sessionevaluation');
              }}
              style={{ marginRight: 20 }}
              href="javascript:void(0)"
              className="MySessions__session_info_as_button"
            >
              {t('pages.mySessions.components.session.evaluate')}
            </p>
          )}

        {!session.status &&
          (!session.canceled ? (
            <Popconfirm
              title={`${
                session?.coachee?.noShow === true && hours < 12
                  ? `${t('pages.mySessions.components.session.popConfirmMsg1')}`
                  : `${t('pages.mySessions.components.session.popConfirmMsg2')}`
              }`}
              onConfirm={() => cancelMySession(session.id)}
              okText={`${t('pages.mySessions.components.session.accept')}`}
              cancelText={`${t('pages.mySessions.components.session.cancel')}`}
            >
              <p
                style={{ marginRight: 20 }}
                className="MySessions__session_info_as_button"
              >
                {t('pages.mySessions.components.session.cancelSession')}
              </p>
            </Popconfirm>
          ) : (
            <p
              onClick={() => reschedule(session.id)}
              style={{ marginRight: 20 }}
              className="MySessions__session_info_as_button"
            >
              {t('pages.mySessions.components.session.reschedule')}
            </p>
          ))}
        {session.noShow && (
          <Tag color="red">
            {t('pages.mySessions.components.session.noShow')}
          </Tag>
        )}
        {session.status && (
          <Image
            onClick={() => setSelectedSession(session)}
            src={Checked}
            className="MySessions__session_icon"
          />
        )}
      </div>
    </div>
  );
}

export default Session;
