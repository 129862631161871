import React from 'react';
import { Button, Image } from '@chakra-ui/react';
import leftArrow from 'assets/images/icons/boton-anterior.png';
import rightArrow from 'assets/images/icons/boton-siguiente.png';

function Arrows({ prevStep, nextStep, disableArrows }) {
  const classDisable = disableArrows ? 'Arrows__button--disable' : '';

  return (
    <div className="Arrows">
      <Button onClick={prevStep} className={`Arrows__Left ${classDisable}`}>
        <Image src={leftArrow} />
      </Button>
      <Button onClick={nextStep} className={`Arrows__Right ${classDisable}`}>
        <Image src={rightArrow} />
      </Button>
    </div>
  );
}

export default Arrows;
