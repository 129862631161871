import SessionEvaluationContextWrapper from './context/SessionEvaluationContextWrapper';
import Evaluation from './SessionEvaluation';

export default function () {
  return (
    <SessionEvaluationContextWrapper>
      <Evaluation />
    </SessionEvaluationContextWrapper>
  );
}
