import React, { useEffect, useState } from 'react';
import { Button, Input } from '@chakra-ui/react';
import { useFormik } from 'formik';
import {
  blockSchedule,
  deleteBlockedSchedule,
  getUserBlockSchedule
} from 'services/calendar.service';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import { useSelector } from 'react-redux';
import validationSchema from './blockSchedule.validationSchema';
import displayToast from 'utilities/toast.utility';
import { useForceUpdate } from 'hooks/useForceUpdate';
import { useTranslation } from 'react-i18next';
import { orderBy, size } from 'lodash';
import { BlockedSchedule } from './components/BlockedSchedule';
import NoData from 'components/NoData/NoData';

function BlockSchedule({ setDisplayBlockSchedule }) {
  const { loading, callEndpoint } = useFetchAndLoad();
  const { mongoID, timezone } = useSelector((state) => state.user);
  const [blockedSchedules, setBlockedSchedules] = useState([]);
  const forceUpdate = useForceUpdate();
  const { t } = useTranslation('global');

  const initialValues = () => ({
    block_date_from: '',
    block_date_until: '',
    block_time_from: '',
    block_time_until: ''
  });

  useEffect(() => {
    getBlockSchedule();
  }, []);

  const errorGet = t('pages.coachCalendar.errorGet');
  const successBlock = t(
    'pages.coachCalendar.components.blockSchedule.sucessBlock'
  );

  const getBlockSchedule = async () => {
    try {
      const { data } = await callEndpoint(getUserBlockSchedule());
      const orderedSchedules = orderBy(data.data, ['InitialDate', 'asc']);
      setBlockedSchedules(orderedSchedules);
    } catch (error) {
      console.log(error);
      displayToast(errorGet, 'error');
    }
  };

  const handleDeleteSchedule = async (schedule) => {
    try {
      const { data } = await callEndpoint(deleteBlockedSchedule(schedule));
      displayToast('Ok', 'success');
      getBlockSchedule();
    } catch (error) {
      displayToast(error.message, 'error');
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    values,
    errors,
    handleBlur,
    validateField
  } = useFormik({
    initialValues: initialValues(),
    validationSchema,
    onSubmit: async (formValues) => {
      try {
        await callEndpoint(blockSchedule(formValues, mongoID, timezone));
        displayToast(successBlock, 'success');
        setDisplayBlockSchedule(false);
      } catch (error) {
        console.log(error);
      }
    }
  });

  return (
    <form className="BlockSchedule background" onSubmit={handleSubmit}>
      <h2>{t('pages.coachCalendar.components.blockSchedule.title')}</h2>
      <p className="BlockSchedule__subtitle">
        {t('pages.coachCalendar.components.blockSchedule.subtitle')}
      </p>

      <div className="BlockSchedule__from-until">
        <p className="BlockSchedule__subtitles">
          {t('pages.coachCalendar.components.blockSchedule.from')}
        </p>
        <p className="BlockSchedule__subtitles">
          {t('pages.coachCalendar.components.blockSchedule.until')}
        </p>
      </div>

      <div className="BlockSchedule__block">
        <div className="BlockSchedule__block_date">
          <p className="BlockSchedule__subtitles">
            {t('pages.coachCalendar.components.blockSchedule.date')}
          </p>
          <Input
            type="date"
            name="block_date_from"
            className="BlockSchedule__block_input"
            value={values.block_date_from}
            onBlur={handleBlur}
            onChange={handleChange}
            isInvalid={errors?.block_date_from}
          />
          <Input
            type="date"
            name="block_date_until"
            className="BlockSchedule__block_input"
            value={values.block_date_until}
            onBlur={handleBlur}
            onChange={handleChange}
            isInvalid={errors?.block_date_until}
          />
        </div>

        <div className="BlockSchedule__block_time">
          <p className="BlockSchedule__subtitles">
            {t('pages.coachCalendar.components.blockSchedule.time')}
          </p>
          <Input
            type="time"
            name="block_time_from"
            className="BlockSchedule__block_input"
            value={values.block_time_from}
            onBlur={handleBlur}
            onChange={handleChange}
            isInvalid={errors?.block_time_from}
          />
          <Input
            type="time"
            name="block_time_until"
            className="BlockSchedule__block_input"
            value={values.block_time_until}
            onBlur={handleBlur}
            onChange={handleChange}
            isInvalid={errors?.block_time_until}
          />
        </div>

        {errors?.block_date_until && (
          <p className="BlockSchedule__error">
            {t(
              `pages.coachCalendar.components.validationErrors.${errors.block_date_until}`
            )}
          </p>
        )}

        {values?.block_time_until && errors?.block_time_until ? (
          <p className="BlockSchedule__error">
            {t(
              `pages.coachCalendar.components.validationErrors.${errors.block_time_until}`
            )}
          </p>
        ) : null}

        <div className="BlockSchedule__buttons">
          <span />
          <Button
            className="Button Button--secondary Button--cancel BlockSchedule__button"
            onClick={() => setDisplayBlockSchedule(false)}
          >
            {t('pages.coachCalendar.buttonCancel')}
          </Button>
          <Button
            className="Button Button--primary BlockSchedule__button"
            type="submit"
            isLoading={loading}
            loadingText={t(
              'pages.coachCalendar.components.blockSchedule.loadingText'
            )}
            disabled={loading}
          >
            {t('pages.coachCalendar.buttonSave')}
          </Button>
        </div>
      </div>

      <div className="BlockSchedule__list">
        <h2>Horarios Bloqueados</h2>
        {size(blockedSchedules) > 0 ? (
          blockedSchedules.map((blockedSchedule) => (
            <BlockedSchedule
              key={blockedSchedule._id}
              blockedSchedule={blockedSchedule}
              handleDeleteSchedule={handleDeleteSchedule}
            />
          ))
        ) : (
          <NoData title="Aún no tienes ningún horario bloqueado" />
        )}
      </div>
    </form>
  );
}

export default BlockSchedule;
