import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFetchAndLoad, useUserUtilities } from 'hooks';
import { updateCoach } from 'services/coach.service';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';

function AlternateCall() {
  const { alternateCall, mongoID } = useSelector((state) => state.user);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { refreshUser } = useUserUtilities();
  const validationSchema = Yup.object({
    link: Yup.string().required('El link es requerido')
  });

  const { t } = useTranslation('global');

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    setValues,
    setTouched
  } = useFormik({
    initialValues: {
      link: alternateCall
    },
    validationSchema,
    onSubmit: async (form) => {
      try {
        await callEndpoint(
          updateCoach({ linkExternalCall: form.link, id: mongoID })
        );
        displayToast('Link guardado con èxito', 'success');
        setValues({ link: alternateCall });
        setTouched({ link: null });
        await refreshUser();
      } catch (error) {
        displayToast('Error al actualizar tú link', 'error');
      }
    }
  });

  return (
    <div className="AlternateCall background">
      <h3 className="AlternateCall__title">
        {t('pages.preferences.alternateCall.title')}
      </h3>
      <p className="AlternateCall__subtitle">
        {t('pages.preferences.alternateCall.subtitle')}
      </p>

      <form className="AlternateCall__form" onSubmit={handleSubmit}>
        <FormControl isInvalid={errors?.link && touched.link}>
          <FormLabel>Link</FormLabel>
          <Input
            name="link"
            className="AlternateCall__form_input"
            placeholder={`${t(
              'pages.preferences.alternateCall.linkPlaceholder'
            )}`}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.link}
            type="text"
          />

          {errors?.link && touched.link && (
            <FormErrorMessage fontSize="lg">{errors.link}</FormErrorMessage>
          )}
        </FormControl>

        <Button
          className="Button Button--primary"
          mt={'2em'}
          type="submit"
          isDisabled={loading}
          isLoading={loading}
        >
          {t('pages.preferences.alternateCall.saveButton')}
        </Button>
      </form>
    </div>
  );
}

export default AlternateCall;
