import React, { useContext, useState } from 'react';
import { Input, Image, Button } from '@chakra-ui/react';
import CheckFalse from 'assets/images/icons/no-seleccionado.png';
import CheckTrue from 'assets/images/icons/seleccionado.png';
import displayToast from 'utilities/toast.utility';
import { useFetchAndLoad } from 'hooks';
import {
  getUserWorkingHours,
  saveUserWorkingHours
} from 'services/calendar.service';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading';
import CoachCalendarContext from 'pages/CoachCalendar/context/CoachCalendarContext';
import { TimePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function WorkingHours({ setWorkingHours }) {
  const { loading, callEndpoint } = useFetchAndLoad();
  const { mongoID } = useSelector((state) => state.user);
  const {
    schedules: contextSchedules,
    loading: loadingContext,
    updateWorkingUI
  } = useContext(CoachCalendarContext);

  const [schedules, setSchedules] = useState(contextSchedules);
  const { t } = useTranslation('global');

  const handleScheduleCheckbox = (scheduleToModify) => {
    const newSchedules = schedules.map((schedule) => {
      if (schedule.id !== scheduleToModify.id) return schedule;

      return { ...scheduleToModify, Work: !schedule.Work };
    });
    setSchedules(newSchedules);
  };

  const timePickerFormat = 'hh:mma';
  const databaseFormat = 'HH:mm';

  const translateDay = (day) => {
    switch (day.toLowerCase()) {
      case 'monday':
        return t('pages.onboarding.components.workSchedule.days.monday');
      case 'tuesday':
        return t('pages.onboarding.components.workSchedule.days.tuesday');
      case 'wednesday':
        return t('pages.onboarding.components.workSchedule.days.wednesday');
      case 'thursday':
        return t('pages.onboarding.components.workSchedule.days.thursday');
      case 'friday':
        return t('pages.onboarding.components.workSchedule.days.friday');
      case 'saturday':
        return t('pages.onboarding.components.workSchedule.days.saturday');
      case 'sunday':
        return t('pages.onboarding.components.workSchedule.days.sunday');
      default:
        return t('pages.onboarding.components.workSchedule.days.monday');
    }
  };

  const handleScheduleChange = (scheduleModified, event) => {
    const newSchedules = schedules.map((schedule) => {
      if (schedule.id !== scheduleModified.id) return schedule;

      return {
        ...scheduleModified,
        InitialDate: event[0]?.format(databaseFormat),
        EndDate: event[1]?.format(databaseFormat)
      };
    });
    setSchedules(newSchedules);
  };

  const errorGet = t('pages.coachCalendar.errorGet');
  const sucessSave = t('pages.coachCalendar.sucessSave');
  const errorSave = t('pages.coachCalendar.sucessSave');

  const getWorkingHours = async (id) => {
    try {
      const { data } = await callEndpoint(getUserWorkingHours(id));
      updateWorkingUI(data.data);
    } catch (error) {
      displayToast(errorGet, 'error');
    }
  };

  const saveWorkingHours = async () => {
    try {
      await callEndpoint(saveUserWorkingHours(schedules, mongoID));
      getWorkingHours();
      displayToast(sucessSave, 'success');
      setWorkingHours(false);
    } catch (error) {
      displayToast(errorSave, 'error');
    }
  };

  if (loadingContext) return <Loading />;

  return (
    <div className="WorkingHours background">
      <h2 className="WorkingHours__title">
        {t('pages.coachCalendar.workingHours')}
      </h2>
      <p className="WorkingHours__subtitle">
        {t('pages.coachCalendar.subtitle')}
      </p>

      {schedules.map((schedule) => (
        <div className="WorkingHours__schedule" key={schedule.id}>
          <p className="WorkingHours__schedule_day">
            {translateDay(schedule.Day)}
          </p>
          <TimePicker.RangePicker
            size="large"
            defaultValue={[
              moment(
                schedule.InitialDate ? schedule.InitialDate : '08:00',
                timePickerFormat
              ),
              moment(
                schedule.EndDate ? schedule.EndDate : '17:00',
                timePickerFormat
              )
            ]}
            value={[
              moment(
                schedule.InitialDate ? schedule.InitialDate : '08:00',
                timePickerFormat
              ),
              moment(
                schedule.EndDate ? schedule.EndDate : '17:00',
                timePickerFormat
              )
            ]}
            format={timePickerFormat}
            className="WorkSchedule__schedule_input"
            name={`${schedule.Day}_InitialDate`}
            onChange={(values) => handleScheduleChange(schedule, values)}
          />
          <Image
            src={schedule.Work ? CheckTrue : CheckFalse}
            className="WorkingHours__schedule_checkbox"
            onClick={() => handleScheduleCheckbox(schedule)}
          />
        </div>
      ))}

      <div className="WorkingHours__buttons">
        <span />
        <Button
          onClick={() => setWorkingHours(false)}
          className="Button Button--secondary Button--cancel "
        >
          {t('pages.coachCalendar.buttonCancel')}
        </Button>

        <Button
          className="Button Button--primary"
          onClick={saveWorkingHours}
          isLoading={loading}
          disabled={loading}
        >
          {t('pages.coachCalendar.buttonSave')}
        </Button>
      </div>
      <span />
    </div>
  );
}

export default WorkingHours;
