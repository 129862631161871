import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import CoacheeStatus from './components/CoacheeStatus';

function SessionTabs() {
  const { t } = useTranslation('global');

  return (
    <Tabs
      sx={{
        '& .chakra-tabs__tab[aria-selected=true]': {
          borderBottomWidth: '1px'
        }
      }}
      isFitted
    >
      <TabList mt="2em" mb="1em">
        <Tab>{t('pages.myCoachees.components.Active')}</Tab>
        <Tab>{t('pages.myCoachees.components.Unactive')}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <CoacheeStatus activeCoachees={true} />
        </TabPanel>

        <TabPanel>
          <CoacheeStatus activeCoachees={false} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default SessionTabs;
