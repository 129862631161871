import { useEffect, useState } from 'react';
import { Rate } from 'antd';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function EvaluationRate() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation('global');
  const { ratingSessionsCoach, ratingCoach, sessions } = useSelector(
    (state) => state.user
  );

  const [evaluations, setEvaluations] = useState([
    {
      id: 1,
      title: t('pages.coachEvaluation.titleEvaluations'),
      ratingValue: 5,
      redirect: 'sessions'
    },
    {
      id: 2,
      title: t('pages.coachEvaluation.titleCoach'),
      ratingValue: 5,
      redirect: 'coach'
    }
  ]);

  const getAverage = () => {
    let averageRatingCoach = 5,
      averageSessionsRating = 5;

    if (Array.isArray(ratingSessionsCoach) && ratingSessionsCoach.length > 0) {
      averageSessionsRating =
        ratingSessionsCoach.reduce(
          (total, session) => total + session.Score,
          0
        ) / ratingSessionsCoach.length;
    }

    if (Array.isArray(ratingCoach) && ratingCoach.length > 0) {
      averageRatingCoach =
        ratingCoach.reduce((total, session) => total + session.Score, 0) /
        ratingCoach.length;
    }

    setEvaluations([
      {
        id: 1,
        title: t('pages.coachEvaluation.titleEvaluations'),
        ratingValue: averageSessionsRating,
        redirect: 'sessions'
      },
      {
        id: 2,
        title: t('pages.coachEvaluation.titleCoach'),
        ratingValue: averageRatingCoach,
        redirect: 'coach'
      }
    ]);
  };

  useEffect(() => {
    getAverage();
  }, [ratingSessionsCoach, ratingCoach, i18n.language]);

  return (
    <div className="CoachEvaluation__evaluation">
      <h2 className="CoachEvaluation__title">
        {t('pages.coachEvaluation.evaluationTitle')}
      </h2>
      <div>
        {evaluations.map((evaluation) => (
          <div className="EvaluationRateCoach" key={evaluation.id}>
            <div className="EvaluationRateCoach__card">
              <h3 className="EvaluationRateCoach__title">
                {evaluation && evaluation.title}
              </h3>
              <Rate
                className="EvaluationRateCoach__stars"
                allowHalf
                value={evaluation.ratingValue}
                defaultValue={5}
                disabled
              />
            </div>
            <Button
              className="EvaluationRateCoach__button"
              onClick={() => navigate(evaluation.redirect)}
            >
              {t('pages.coachEvaluation.button')}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EvaluationRate;
