import Menu from 'components/Menu';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import bonumLogo from '../../assets/images/bonum_logo.png';

function Header() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate('/');

  const isMenuActive = user?.uid && user?.onboardingCompleted;

  return (
    <header className="Header">
      <img
        src={bonumLogo}
        alt="Bonum Logo"
        className="Header__logo"
        onClick={() => navigate('/')}
        onKeyDown={() => navigate('/')}
        role="presentation"
      />
      {isMenuActive && <Menu />}
    </header>
  );
}

export default Header;
