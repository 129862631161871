import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Button
} from '@chakra-ui/react';
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setCreateYourAccount } from 'redux/slices/onboarding';
import { auth } from 'utilities/firebase.utility';
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider
} from 'firebase/auth';
import validationSchema from './validationSchema';
import { useTranslation } from 'react-i18next';

function CreateYourAccount({ nextStep }) {
  const [viewPassword, setViewPassword] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const createYourAccount = useSelector(
    (state) => state.onboarding.createYourAccount
  );
  const { name, lastname } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  const {
    handleSubmit,
    handleChange: formikHandleChange,
    values,
    errors,
    handleBlur,
    touched,
    setValues
  } = useFormik({
    initialValues: {
      name: name || '',
      lastname: name || '',
      old_password: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (formValues) => {
      try {
        setLoading(true);
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email,
          formValues.old_password
        );

        await reauthenticateWithCredential(auth.currentUser, credential);

        await updatePassword(auth.currentUser, formValues.password);

        nextStep();
      } catch (err) {
        setLoading(false);
        if (err.code === 'auth/wrong-password')
          setError('La contraseña actual es incorrecta');
      }

      setLoading(false);
    }
  });

  const handleChange = (e) => {
    dispatch(
      setCreateYourAccount({
        ...createYourAccount,
        [e.target.name]: e.target.value
      })
    );
    formikHandleChange(e);
  };

  useEffect(() => {
    setValues({ name, lastname, password: '', old_password: '' });
    dispatch(setCreateYourAccount(values));
  }, []);

  useEffect(() => {
    dispatch(setCreateYourAccount(values));
  }, [values]);

  return (
    <div className="CreateYourAccount">
      <h3>{t('pages.onboarding.components.createYourAccount.title')}</h3>
      <p>{t('pages.onboarding.components.createYourAccount.subtitle')}</p>
      <form
        className="CreateYourAccount__form Card"
        onSubmit={handleSubmit}
        id="CreateYourAccount"
      >
        <FormControl isInvalid={errors?.name && touched.name}>
          <Input
            name="name"
            className="CreateYourAccout__form_input"
            placeholder={t(
              'pages.onboarding.components.createYourAccount.fieldName'
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />
          {errors?.name && touched.name ? (
            <FormErrorMessage fontSize="lg">
              {t(
                `pages.onboarding.components.createYourAccount.validation.${errors.name}`
              )}
            </FormErrorMessage>
          ) : null}
        </FormControl>

        <FormControl isInvalid={errors?.lastname && touched.lastname}>
          <Input
            name="lastname"
            className="CreateYourAccout__form_input"
            placeholder={t(
              'pages.onboarding.components.createYourAccount.fieldLastname'
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastname}
          />
          {errors?.lastname && touched.lastname ? (
            <FormErrorMessage fontSize="lg">
              {t(
                `pages.onboarding.components.createYourAccount.validation.${errors.lastname}`
              )}
            </FormErrorMessage>
          ) : null}
        </FormControl>

        <FormControl
          isInvalid={
            (errors?.old_password && touched.old_password) || errors.confirm
          }
        >
          <InputGroup>
            <Input
              name="old_password"
              className="CreateYourAccout__form_input"
              placeholder={t(
                'pages.onboarding.components.createYourAccount.fieldPassword'
              )}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.old_password}
              type={viewPassword ? 'text' : 'password'}
            />
          </InputGroup>
          {errors?.old_password && touched.old_password ? (
            <FormErrorMessage fontSize="lg">
              {t(
                `pages.onboarding.components.createYourAccount.validation.${errors.old_password}`
              )}
            </FormErrorMessage>
          ) : null}
        </FormControl>

        <FormControl
          isInvalid={(errors?.password && touched.password) || error}
        >
          <InputGroup>
            <Input
              name="password"
              className="CreateYourAccout__form_input"
              placeholder={t(
                'pages.onboarding.components.createYourAccount.fieldNewPassword'
              )}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type={viewPassword ? 'text' : 'password'}
            />
            <InputRightElement
              h="100%"
              mr={3}
              onClick={() => setViewPassword(!viewPassword)}
            >
              {viewPassword ? (
                <ViewOffIcon w={8} h={8} />
              ) : (
                <ViewIcon w={8} h={8} />
              )}
            </InputRightElement>
          </InputGroup>
          {errors?.password && touched.password ? (
            <FormErrorMessage fontSize="lg">
              {t(
                `pages.onboarding.components.createYourAccount.validation.${errors.password}`
              )}
            </FormErrorMessage>
          ) : null}

          {error && <FormErrorMessage fontSize="lg">{error}</FormErrorMessage>}
        </FormControl>
      </form>

      <Button
        type="submit"
        form="CreateYourAccount"
        className="Button Button--primary"
        isLoading={loading}
        isDisabled={loading}
      >
        {t('pages.onboarding.components.createYourAccount.button')}
      </Button>
    </div>
  );
}

export default CreateYourAccount;
