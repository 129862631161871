import React, { useContext, useEffect, useState } from 'react';
import { size } from 'lodash';
import NoData from 'components/NoData/NoData';
import Checked from 'assets/images/icons/realizado.png';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import MyCoacheesContext from 'pages/MyCoachees/context/MyCoacheesContext';
import { useFetchAndLoad } from 'hooks';
import {
  getAllAlignmentSessionCoachee,
  getCoacheeSessions
} from 'services/sessions.service';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import adaptedSession from 'adapters/sessionsAdapter.adapter';
import { mongoDateToSessionDate } from 'utilities';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';
import ModalCloseSession from 'components/ModalCloseSession/ModalCloseSession';
import { useDispatch } from 'react-redux';
import { modifySession } from 'redux/slices/session';
import { orderBy } from 'lodash';
import { Tag } from 'antd';

function CoacheeSessions() {
  const { setSelectedSession, selectedCoachee } = useContext(MyCoacheesContext);
  const [showCloseSessionModal, setShowCloseSessionModal] = useState(false);
  const [sessionToClose, setSessionToClose] = useState({});
  const { loading, callEndpoint } = useFetchAndLoad();
  const [sessions, setSessions] = useState([]);
  const { userId } = useParams();
  const { t } = useTranslation('global');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [program, setProgram] = useState(0);
  const [orderedSessions, setOrderedSessions] = useState([]);
  const [alignmentSessions, setAlignmentSessions] = useState(null);

  const getMyCoacheeSesions = async () => {
    try {
      const { data } = await callEndpoint(
        getCoacheeSessions(selectedCoachee._id)
      );

      const adaptedSessions = data.data.map((session) =>
        adaptedSession(session)
      );

      setSessions(adaptedSessions);
    } catch (error) {
      console.log(error);
    }
  };

  const errorHandle = t('pages.myCoachees.components.errorHandle');
  const handleSelectedSession = (session) => {
    if (!session.status) {
      displayToast(errorHandle, 'error');
      return;
    }

    setSelectedSession(session);
  };

  const handleShowCloseSessionModal = (sessionToClose) => {
    setSessionToClose(sessionToClose);
    setShowCloseSessionModal(true);
  };

  const handleEvaluateSession = (sessionToEvaluate) => {
    dispatch(
      modifySession(adaptedSession({ ...sessionToEvaluate, status: true }))
    );
    navigate('/sessionevaluation');
  };

  const getCoacheeProgram = () => {
    const additionalSessions = selectedCoachee?.additionalSessions
      ? selectedCoachee.additionalSessions
      : 0;
    const cohort = selectedCoachee?.cohort?.Program
      ? selectedCoachee?.cohort?.Program
      : 0;
    setProgram(additionalSessions + cohort);
  };

  useEffect(() => {
    getMyCoacheeSesions();
    getCoacheeProgram();
    getAlignmentSessions();
  }, []);

  useEffect(() => {
    setOrderedSessions(orderBy(sessions, 'date', 'asc'));
  }, [sessions]);

  const getAlignmentSessions = async () => {
    try {
      const res = await callEndpoint(getAllAlignmentSessionCoachee(userId));
      setAlignmentSessions(res.data.data);
    } catch (err) {
      console.log('err', err);
    }
  };

  const combinedSessions = alignmentSessions
    ? [...alignmentSessions, ...orderedSessions]
    : orderedSessions;

  const endedSesiones = sessions?.filter((session) => session.status === true);

  if (loading) return <Loading title={'pages.myCoachees.components.loading'} />;

  return (
    <>
      <h3 className="CoacheeSessions CoacheeSessions__program">
        {endedSesiones?.length ? endedSesiones?.length : 0}{' '}
        {t('pages.myCoachees.components.of')} {program}{' '}
        {t('pages.myCoachees.components.sessions')}
      </h3>
      <Box className="CoacheeSessions background">
        <ModalCloseSession
          session={sessionToClose}
          showModal={showCloseSessionModal}
          setShowModal={setShowCloseSessionModal}
          key={`modal_${sessionToClose._id}`}
          getMyCoacheeSessions={getMyCoacheeSesions}
        />
        {size(combinedSessions) > 0 ? (
          combinedSessions.map((session, index) => (
            <Flex
              className="CoacheeSessions__session font-bold"
              tabIndex={index}
              key={session.id}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                className="CoacheeSessions__session_info"
                onClick={() => handleSelectedSession(session)}
                onKeyDown={() => null}
                role="button"
              >
                <Text
                  style={{ fontWeight: 'bold' }}
                  className="CoacheeSessions__session_info_title"
                >
                  <span>
                    {t('pages.myCoachees.components.session')}{' '}
                    {session.type === 'initial'
                      ? t('pages.myCoachees.components.initialAlignment')
                      : session.type === 'final'
                      ? t('pages.myCoachees.components.finalAlignment')
                      : session.number
                      ? session.number
                      : session.canceled
                      ? ''
                      : t('pages.myCoachees.components.Scheduled')}
                  </span>
                  {session.noShow && (
                    <Tag color="red" style={{ marginLeft: '0.5em' }}>
                      {t('pages.mySessions.components.session.noShow')}
                    </Tag>
                  )}
                </Text>
                <Text className="CoacheeSessions__session_info_date">
                  {mongoDateToSessionDate(session.date)}
                </Text>
              </Box>

              <Box className="CoacheeSessions__session_image-wrapper">
                {session?.type !== 'initial' &&
                  session?.type !== 'final' &&
                  session?.status &&
                  !session?.evaluatedByCoach &&
                  !session?.canceled &&
                  !session.noShow && (
                    <Text
                      style={{ marginRight: 20 }}
                      className="MySessions__session_info_as_button"
                      onClick={() => handleEvaluateSession(session)}
                    >
                      {t('pages.myCoachees.components.evaluateSession')}
                    </Text>
                  )}

                {!session?.status && !session?.canceled && (
                  <Text
                    style={{ marginRight: 20 }}
                    className="MySessions__session_info_as_button"
                    onClick={() => handleShowCloseSessionModal(session)}
                  >
                    {t('pages.myCoachees.components.signOff')}
                  </Text>
                )}

                {session?.canceled && (
                  <Text
                    className={`MySessions__session_info_status MySessions__session_info_status--red`}
                    style={{ marginRight: 20 }}
                  >
                    {t('pages.myCoachees.components.Cancelled')}
                  </Text>
                )}

                <Image
                  src={Checked}
                  className={`CoacheeSessions__session_icon ${
                    !session.status && 'CoacheeSessions__session_icon--dimmed'
                  }`}
                />
              </Box>
            </Flex>
          ))
        ) : (
          <NoData title={t('pages.myCoachees.components.noData')} />
        )}
      </Box>
    </>
  );
}

export default CoacheeSessions;
