import React from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { RiUserVoiceFill } from 'react-icons/ri';
import {
  TbCalendar,
  TbPhoneCalling,
  TbWorld,
  TbNotes,
  TbVideo
} from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Sidebar() {
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.user);

  const isCoach = role === 'coach';

  const { t } = useTranslation('global');

  return (
    <div className="Sidebar">
      <Button
        className="Sidebar__button"
        leftIcon={<RiUserVoiceFill />}
        onClick={() => navigate('/preferences')}
      >
        {t('pages.preferences.sidebar.profile')}
      </Button>

      <Button
        className="Sidebar__button"
        leftIcon={<TbWorld />}
        onClick={() => navigate('timezone')}
      >
        {t('pages.preferences.sidebar.timezone')}
      </Button>

      {isCoach && (
        <Button
          className="Sidebar__button"
          leftIcon={<TbCalendar />}
          onClick={() => navigate('calendar')}
        >
          {t('pages.preferences.sidebar.calendars')}
        </Button>
      )}

      {isCoach && (
        <>
          <Button
            className="Sidebar__button"
            leftIcon={<TbPhoneCalling />}
            onClick={() => navigate('alternatecall')}
          >
            {t('pages.preferences.sidebar.alternateCall')}
          </Button>

          <Button
            className="Sidebar__button"
            leftIcon={<TbNotes />}
            onClick={() => navigate('work')}
          >
            {t('pages.preferences.sidebar.howDoYouWork')}
          </Button>

          <Button
            className="Sidebar__button"
            leftIcon={<TbVideo />}
            onClick={() => navigate('video')}
          >
            {t('pages.preferences.sidebar.video')}
          </Button>

          <Button
            className="Sidebar__button"
            leftIcon={<TbCalendar />}
            onClick={() => navigate('eventscalendar')}
          >
            Calendario de Eventos
          </Button>
        </>
      )}
    </div>
  );
}

export default Sidebar;
