import { components } from 'react-select';
import { Image } from '@chakra-ui/react';

function IconOption(props) {
  const { Option } = components;
  const { data } = props;
  return (
    <Option {...props} className="IconOption">
      <Image src={data.icon} className="IconOption__icon" />
    </Option>
  );
}

export default IconOption;
