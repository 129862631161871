import { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import iconUpload from 'assets/images/icons/subir-video.png';
import Uploader from 'components/Uploader';
import { useTranslation } from 'react-i18next';
import { updateVideoCoachYoutube } from 'services/coach.service';
import { useFetchAndLoad } from 'hooks';

function UploadVideo({ nextStep }) {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation('global');
  const { mongoID } = useSelector((state) => state.user);

  const { callEndpoint } = useFetchAndLoad();

  useEffect(() => {}, [progress]);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleLoading = () => {
    // Simular el progreso de carga durante 6 segundos
    let currentProgress = 0;
    const interval = setInterval(() => {
      currentProgress += 16.67; // Avance del progreso cada segundo
      setProgress(Math.min(currentProgress, 100)); // Limitar el progreso a 100
    }, 1000);

    // Detener la simulación después de 6 segundos
    setTimeout(() => {
      clearInterval(interval);
      setLoading(false); // Establecer loading a false una vez que se completa la simulación
    }, 4000);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('video', selectedFile);

      try {
        setLoading(true); // Establecer loading a true antes de comenzar la carga
        handleLoading();

        await callEndpoint(
          updateVideoCoachYoutube({ formData: formData, id: mongoID })
        );
      } catch (error) {
        handleLoading();
        console.error('Error al cargar el video:', error);
        return;
      }
    } else {
      alert('Selecciona un archivo de video primero.');
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleUpload();
    }
  }, [selectedFile]);

  console.log('URL', URL);

  return (
    <div className="UploadVideo">
      <h5>{t('pages.onboarding.components.uploadVideo.title')}</h5>
      <div className="UploadVideo__card Card">
        {loading && (
          <Uploader
            progress={progress}
            title={t('pages.onboarding.components.uploadVideo.subtitle')}
          />
        )}
        {!selectedFile && (
          <label htmlFor="fileInput">
            <img
              src={iconUpload}
              width="60%"
              className="UploadVideo__icon"
              alt="Upload Video Icon"
            />
          </label>
        )}
        {!loading && selectedFile && (
          <div className="UploadVideo__video">
            <video src={URL?.createObjectURL(selectedFile)} muted controls>
              <track kind="captions" />
            </video>
            <p className="UploadVideo__video_replace">
              {t('pages.onboarding.components.uploadVideo.replace')}
            </p>
          </div>
        )}
        <input
          id="fileInput"
          type="file"
          accept="video/*"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      <Button className="Button" onClick={nextStep}>
        {t('pages.onboarding.components.uploadVideo.buttonNextTime')}
      </Button>
    </div>
  );
}

export default UploadVideo;
