import { useEffect, useState } from 'react';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import Loading from 'components/Loading';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import {
  cancelSession,
  cancelSessionByCoach,
  EndSession,
  updateSession,
  updateSessionNoShow,
  updateSessionNumber
} from 'services/sessions.service';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CoacheeAssistModal = ({ coacheeModal, setCoacheeModal }) => {
  const [loading, setLoading] = useState(false);

  const { callEndpoint } = useFetchAndLoad();

  const { t } = useTranslation('global');

  const session = useSelector((state) => state.session);

  const navigate = useNavigate();

  const handleOk = async () => {
    try {
      setCoacheeModal(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      setLoading(true);

      await callEndpoint(cancelSession({ ...session, noShow: true }));

      setCoacheeModal(false);

      navigate('/');

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if (loading === false) {
    return (
      <>
        <Modal
          title={
            <div style={{ textAlign: 'center' }}>
              {t('pages.mySessions.components.session.theCoachee')}{' '}
              <span style={{ fontWeight: 'bolder' }}>
                {session?.coachee?.name} {session?.coachee?.lastname}{' '}
              </span>
              {t('pages.mySessions.components.session.present')}{' '}
            </div>
          }
          visible={coacheeModal}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          width={600}
          centered
          bodyStyle={{ padding: '20px', textAlign: 'center' }}
          maskClosable={false}
          footer={null}
        >
          <div>
            <Button
              key="cancel"
              onClick={handleCancel}
              style={{ marginRight: '0.5vw' }}
            >
              No
            </Button>
            <Button
              key="ok"
              type="primary"
              loading={loading}
              onClick={handleOk}
            >
              Sí
            </Button>
          </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-64 py-24">
            {/*body*/}
            <div className="p-6 text-center">
              <div className="">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default CoacheeAssistModal;
