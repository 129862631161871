import React from 'react';
import { Image } from '@chakra-ui/react';
import { Rate } from 'antd';
import { mongoDateToShortDate } from 'utilities';

function EvaluationContent({ evaluation, isSession }) {
  const { image, name, date, value, comment } = evaluation;

  return (
    <article className="EvaluationContent">
      <header className="EvaluationContent__profile">
        <figure className="EvaluationContent__profile_image">
          <Image src={image} objectFit="cover" />
        </figure>
        <div className="EvaluationContent__profile_data">
          <h3 className="EvaluationContent__profile_title">{name}</h3>
          <span className="EvaluationContent__profile_date">
            {mongoDateToShortDate(date)}
          </span>
          <Rate
            allowHalf
            disabled
            defaultValue={0}
            value={value}
            className="EvaluationContent__rating"
          />
        </div>
      </header>
      {!isSession && <p className="EvaluationContent__comment">{comment}</p>}
    </article>
  );
}

export default EvaluationContent;
