import React, { useContext, useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import SessionEvaluationContext from 'pages/SessionEvaluation/context/SessionEvaluationContext';
import { useSelector } from 'react-redux';

function Rating({ id }) {
  const { questionsCoach, questionsCoachee } = useContext(
    SessionEvaluationContext
  );
  const user = useSelector((state) => state.user);
  const [questionIndexCoach, setQuestionIndexCoach] = useState(0);
  const [questionIndexCoachee, setQuestionIndexCoachee] = useState(0);
  const [stars, setStars] = useState(0);

  useEffect(() => {
    const QuestionIndexCoach = questionsCoach.findIndex((x) => x.id === id);
    setQuestionIndexCoach(QuestionIndexCoach);
    const QuestionIndexCoachee = questionsCoachee.findIndex((x) => x.id === id);
    setQuestionIndexCoachee(QuestionIndexCoachee);
  }, []);

  return (
    <div className="Rating">
      {[...Array(5)].map((item, index) => {
        const givenRating = index + 1;
        return (
          <label key={index}>
            <input
              className="Rating__radio"
              type="radio"
              value={givenRating}
              onClick={() => {
                if (user?.role === 'coach') {
                  questionsCoach[questionIndexCoach].ratingValue = givenRating;
                  setStars(givenRating);
                } else {
                  questionsCoachee[questionIndexCoachee].ratingValue =
                    givenRating;
                  setStars(givenRating);
                }
              }}
            />
            <div className="Rating__rating">
              <FaStar
                color={
                  user?.role === 'coach'
                    ? givenRating <
                        questionsCoach[questionIndexCoach].ratingValue ||
                      givenRating ===
                        questionsCoach[questionIndexCoach].ratingValue
                      ? '#f4e31f'
                      : 'rgb(192,192,192)'
                    : givenRating <
                        questionsCoachee[questionIndexCoachee].ratingValue ||
                      givenRating ===
                        questionsCoachee[questionIndexCoachee].ratingValue
                    ? '#f4e31f'
                    : 'rgb(192,192,192)'
                }
              />
            </div>
          </label>
        );
      })}
    </div>
  );
}

export default Rating;
