import { FaStar } from 'react-icons/fa';

function Rating({ starsNumber = 5, question = '', value = 0, setValue }) {
  return (
    <div className="EvaluationRate">
      <h3 className="EvaluationRate__title">{question}</h3>
      <div className="Rating">
        {[...Array(starsNumber)].map((item, index) => {
          const givenRating = index + 1;
          return (
            <label key={index}>
              <input
                className="Rating__radio"
                type="radio"
                value={givenRating}
                onClick={() => {
                  setValue(givenRating);
                }}
              />
              <div className="Rating__rating">
                <FaStar
                  color={
                    givenRating < value || givenRating === value
                      ? '#f4e31f'
                      : 'rgb(192,192,192)'
                  }
                />
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default Rating;
