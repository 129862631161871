import React from 'react';
import { Icon } from '@chakra-ui/react';

function ItemMenu({ menuItem, handleMenuClick }) {
  return (
    !menuItem.disabled && (
      <li
        className={`${
          menuItem.isResponsive && 'Menu__list_item--enable'
        } Menu__list_item`}
        onClick={menuItem.onClick}
        onKeyDown={handleMenuClick}
        role="presentation"
      >
        <Icon as={menuItem.icon} className="Menu__list_item_icon" />
        <p className="Menu__list_item_title">{menuItem.name}</p>
      </li>
    )
  );
}

export default ItemMenu;
