import { useEffect, useState } from 'react';
import { Grid, GridItem, Divider, Button } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import NoData from 'components/NoData/NoData';
import { useFetchAndLoad } from 'hooks';
import {
  getAutoEvaluationById,
  getInitialAutoevaluationByEvaluator,
  updateAutoEvaluationAnswers
} from 'services/evaluations.service';
import Loading from 'components/Loading';
import displayToast from 'utilities/toast.utility';
import { filter, find, size } from 'lodash';
import Question from './components/Question';
import Congratulations from './components/Congratulations';
import { useTranslation } from 'react-i18next';

function AutoEvaluation() {
  const { id_autoevaluation } = useParams();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [autoevaluation, setAutoevaluation] = useState(null);
  const [errors, setErrors] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [initialEvaluation, setInitialEvaluation] = useState({});
  const { t, i18n } = useTranslation('global');

  const getAuto = t('pages.autoevaluation.messages.getAuto');
  const sendAuto = t('pages.autoevaluation.messages.sendAuto');
  const sendError = t('pages.autoevaluation.messages.sendError');

  const getEvaluation = async () => {
    try {
      const { data } = await callEndpoint(
        getAutoEvaluationById(id_autoevaluation)
      );
      setAutoevaluation(data.data);
      if (data.data?.evaluationType === 'final')
        getInitialEvaluation(data.data);
    } catch (error) {
      displayToast(getAuto, 'error');
    }
  };

  useEffect(() => {
    getEvaluation();
  }, []);

  useEffect(() => {
    if (autoevaluation?.status === 'resolved') setCompleted(true);
  }, [autoevaluation]);

  useEffect(() => {}, [errors, i18n.language]);

  const handleValueChange = (id, value) => {
    const newAnswers = autoevaluation.answers.map((answer) => {
      if (answer._id === id) return { ...answer, value: parseInt(value) };
      return answer;
    });

    setAutoevaluation({ ...autoevaluation, answers: newAnswers });
  };

  const handleSend = async () => {
    const unaswered = filter(autoevaluation.answers, { value: 0 });
    if (size(unaswered) > 0) {
      setErrors(unaswered);
      displayToast(sendAuto, 'error');
      return;
    }

    try {
      await callEndpoint(
        updateAutoEvaluationAnswers({
          answers: autoevaluation.answers,
          _id: autoevaluation._id
        })
      );

      setCompleted(true);
    } catch (error) {
      displayToast(sendError, 'error');
    }
  };

  const getInitialEvaluation = async (evaluation) => {
    try {
      const { data } = await callEndpoint(
        getInitialAutoevaluationByEvaluator(evaluation?.coachee?._id)
      );
      setInitialEvaluation(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  if (loading)
    return <Loading title={t('pages.autoevaluation.messages.loading')} />;

  if (!id_autoevaluation || !autoevaluation)
    return <NoData title={t('pages.autoevaluation.messages.noData')} />;

  if (completed)
    return (
      <div className="AutoEvaluation__resolved">
        <Congratulations
          coachee={autoevaluation.coachee}
          final={autoevaluation.evaluationType === 'final'}
        />
      </div>
    );

  return (
    <div className="AutoEvaluation">
      {/* <Image src={BonumIcon} className="AutoEvaluation__icon" /> */}
      <div className="AutoEvaluation__title">
        {t('pages.autoevaluation.title')}
      </div>
      <div className="AutoEvaluation__desc">
        {t('pages.autoevaluation.description', {
          name: autoevaluation?.coachee?.name,
          lastname: autoevaluation?.coachee?.lastname
        })}
      </div>
      <div className="AutoEvaluation__data_user">
        <h5>{t('pages.autoevaluation.dataUser')}</h5>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem w="100%">
            <p>
              {t('pages.autoevaluation.fieldName')}:{' '}
              <b>
                {autoevaluation?.coachee?.name}{' '}
                {autoevaluation?.coachee?.lastname}
              </b>
            </p>
          </GridItem>
          <GridItem w="100%">
            <p>
              {t('pages.autoevaluation.fieldDepartment')}:{' '}
              <b>{autoevaluation?.coachee?.department}</b>
            </p>
          </GridItem>
        </Grid>
      </div>
      <Divider />
      <div className="AutoEvaluation__checkbox">
        <p className="AutoEvaluation__checkbox_subtitle">
          {t('pages.autoevaluation.instructions', {
            name: autoevaluation?.coachee?.name,
            lastname: autoevaluation?.coachee?.lastname
          })}
        </p>
        <b className="AutoEvaluation__questions_title">
          {t('pages.autoevaluation.fieldQuestion')}
        </b>
      </div>

      <div className="AutoEvaluation__questions">
        {size(autoevaluation?.answers) > 0 &&
          autoevaluation?.answers.map((answer, index) => (
            <Question
              index={index + 1}
              error={find(errors, { _id: answer._id })}
              answer={answer}
              key={answer.question._id}
              handleValueChange={handleValueChange}
              initialEvaluation={initialEvaluation}
              language={i18n.language}
            />
          ))}
      </div>

      <div className="AutoEvaluation__button">
        <Button className="Button Button--primary" onClick={handleSend}>
          {t('pages.autoevaluation.button')}
        </Button>
      </div>
    </div>
  );
}

export default AutoEvaluation;
