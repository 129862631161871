import React from 'react';
import { IconButton } from '@chakra-ui/react';
import store from 'redux/store';
// import AddIcon from '@material-ui/icons/Add';
import { useNavigate } from 'react-router-dom';
import { post } from 'services/meeting.service';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import './index.scss';
import displayToast from 'utilities/toast.utility';

const InitMeeting = () => {
  const router = useNavigate();
  const { loading, callEndpoint } = useFetchAndLoad()

  const createMeeting = async () => {
    try {
      const { user } = store.getState();
      await callEndpoint(
        post({
          IdCoachee: user.mongoID,
          IdCoach: user.coach._id,
          Date: new Date(),
          InProcess: true
        })
      );
    } catch (err) {
      displayToast('Error al crear el meeting', 'error');
    }
  };

  return (
    <div className="initmeeting">
      <h1>Iniciar MEETING</h1>
      {/* <IconButton
        aria-label="test"
        icon={<AddIcon className="icon-btn" />}
        onClick={createMeeting}
      /> */}
    </div>
  );
};

export default InitMeeting;
