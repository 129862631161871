import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import Assignations from './components/Assignations';
import { useFetchAndLoad } from 'hooks';
import { getUserAssignations } from 'services/assignations.service';
import { useSelector } from 'react-redux';
import assignationsAdapted from 'adapters/assignations.adapter';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';

function MyAssignations() {
  const { loading, callEndpoint } = useFetchAndLoad();
  const { mongoID } = useSelector((state) => state.user);
  const [assignations, setAssignations] = useState([]);
  const { t } = useTranslation('global');

  const getError = t('pages.myAssignations.getError');

  const getMyAssignations = async () => {
    try {
      const { data } = await callEndpoint(getUserAssignations(mongoID));
      setAssignations(
        data.data.map((assignation) => assignationsAdapted(assignation))
      );
    } catch (error) {
      console.log(error);
      displayToast(getError, 'error');
    }
  };

  useEffect(() => {
    getMyAssignations();
  }, []);

  return (
    <Tabs isFitted className="MyAssignations__container">
      <TabList mb="1em" className="MyAssignations__list">
        <Tab>{t('pages.myAssignations.tabPendings')}</Tab>
        <Tab>{t('pages.myAssignations.tabComplete')}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Assignations
            assignations={assignations}
            completed={false}
            setAssignations={setAssignations}
          />
        </TabPanel>
        <TabPanel>
          <Assignations
            completed
            assignations={assignations}
            setAssignations={setAssignations}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default MyAssignations;
