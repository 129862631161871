import React from 'react';
import { useTranslation } from 'react-i18next';
import { mongoDateToLongDate } from 'utilities';

function Comment({ comment }) {
  const { i18n } = useTranslation('global');
  return (
    <div className="Comment">
      <h3 className="Comment__title">
        {mongoDateToLongDate({
          unformatedDate: comment.title,
          language: i18n.language
        })}
      </h3>
      <p className="Comment__comment">{comment.comment}</p>
    </div>
  );
}

export default Comment;
